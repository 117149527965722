import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as awaitingicon } from "./Awaiting.svg";
import { ReactComponent as awaitingchangeicon } from "./AwaitingChange.svg";
import styled from "styled-components";
const AwaitingIcon = styled(awaitingicon)``;
const AwaitingChangeIcon = styled(awaitingchangeicon)``;

type awaitingIconLogoProps = {
  scale?: number;
  altText: string;
  color: string;
};

const Awaiting: React.FC<awaitingIconLogoProps> = ({
  scale = 1,
  altText,
  color,
}) => {
  return (
    <IconWrapper width={0.6 * scale} height={0.8 * scale} aria-label={altText}>
      {altText === "AwaitingChange" ? <AwaitingChangeIcon /> : <AwaitingIcon />}
    </IconWrapper>
  );
};

export default Awaiting;
