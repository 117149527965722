import styled from "styled-components";

export const BubbleButton = styled.div`
  height: 59px;
  width: 59px;
  border-radius: 100px;
  padding: 11px 17px;
  background-color: #DD296E;
    @media (min-height: 780px) {
      top: 90%;
      left: 94.8%;
    }
    @media (min-height: 920px) {
      top: 92%;
      left: 95%;
    }
    :hover {
      background: linear-gradient(90deg, #CF022B 0%, #EA5599 100%);    
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      }
      :active{
      background: #D10726;
      }
`;

export const SideActionButtonConatainer = styled.div`
  height: 307px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .action-button{
    width: 121px;
    height: 74px;
    border: 1.5px solid #959199;
    border-Radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    background: none ;
  }
  
`