//Library
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Files & Functions
import {
  Container,
  HomePageContainer,
  Heading,
  ActionButton,
} from "./StyledComponent";
import { usehomeSNData } from "../../hooks/UseApiContext/UseApiContext";
import {
  useAboutSection,
  useAnimation,
  useHomeRecall,
  useSetHomeRecall,
  useSetSnLoginWindow,
  useSnLoginWindow,
  usedisplaySize,
} from "../../hooks/useMenuContext/useMenuContext";
import useChatLogin from "../../utils/utilsFunction";

//Components
import { getSNJSON } from "../../api";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";

//Icons
import RLogo from "../../Icons/RIcon";
import Chat from "../../Icons/Footer-icons/chat-icon";
import Ticket from "../../Icons/Footer-icons/ticket-icon";
import Call from "../../Icons/Footer-icons/call-icon";
import InputField from "../InputFieldComps";
import AlertMassage from "../HomeAlertMassage";
import HomePage from "../../Icons/HomePageIcon/HomePageIcon.svg";
import MobileHomePage from "../../Icons/HomePageIcon/MobileHomePageIcon.svg";
import PopUpWindow from "../PopUpWindowComps";

type HomeProps = {};

const Home: React.FC<HomeProps> = ({}) => {
  const { chatLogin } = useChatLogin();
  const navigate = useNavigate();
  const displaySize = usedisplaySize();
  const homeSNData = usehomeSNData();
  const homeRecall = useHomeRecall();
  const setHomeRecall = useSetHomeRecall();
  const snLoginWindow = useSnLoginWindow();
  const setSnLoginWindow = useSetSnLoginWindow();
  const [postAppVersionJSON, performAppVersionJSON] = useApiAction(getSNJSON);

  const animation = useAnimation();
  const massage = homeSNData["HomePageMessageDetails"]
    ? homeSNData["HomePageMessageDetails"]
    : "";
  const [alertMassage, setAlertMassage] = useState("");
  const aboutSection = useAboutSection();
  useApiAction(getSNJSON);

  const [showVersionPopUp, setShowversionPopUp] = useState(false);
  const [new_version, setNew_version] = useState("");

  useEffect(() => {
    setAlertMassage(massage);
  }, [massage]);

  // useEffect(() => {
  //   if (snLoginWindow === true) {
  //     chatLogin("OnlySN");
  //     setSnLoginWindow(false);
  //   }
  // }, []);

  function setpage(page: any) {
    switch (page) {
      case "ticket":
        return navigate("/ticketform");
      case "chat":
        return chatLogin("");
      case "call":
        return navigate("/callform");
    }
  }
  function get_icon(icon_name: string) {
    switch (icon_name) {
      case "chat":
        return <Chat scale={0.7} altText="attachment" color="" />;
      case "ticket":
        return <Ticket scale={0.7} altText="attachment" color="" />;
      case "call":
        return <Call scale={0.7} altText="attachment" />;
      case "callblue":
        return <Call scale={0.7} altText="callblue" />;
      case "chatblue":
        return <Chat scale={0.7} altText="chatblue" color="chatblue" />;
      case "ticketblue":
        return <Ticket scale={0.7} altText="ticketblue" color="" />;
    }
  }

  useEffect(() => {
    if (postAppVersionJSON && postAppVersionJSON.data) {
      setHomeRecall(!homeRecall);
    }
  }, [postAppVersionJSON.data]);

  useEffect(() => {
    navigator.serviceWorker.addEventListener("message", (event) => {
      if (
        event.data.type === "versionUpdate" &&
        event &&
        event.data &&
        event.data.new_version
      ) {
        setNew_version(event.data.new_version);
        var Feild = [
          "Post",
          "urlAppVersion",
          "",
          { latestversion: event.data.new_version },
        ];
        performAppVersionJSON(Feild);
        setShowversionPopUp(true);
      }
    });
  }, []);
  return (
    <div>
      {showVersionPopUp && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            zIndex: "3",
            alignItems: "center",
            width: "100%",
            height: "100%",
            left: "0px",
          }}
        >
          <PopUpWindow
            visible={showVersionPopUp}
            setvisible={setShowversionPopUp}
            from={"versionUpdate"}
            currentVersion={new_version}
          />
        </div>
      )}
      {displaySize === "isMobile" ? (
        <div style={{ background: "#EBEAF4" }}>
          <div style={{ height: "102px" }}> </div>
          <div
            style={{
              height: "136px",
              padding: "10px, 32px, 10px, 32px",
              gap: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Heading
              style={{
                fontSize: "28px",
                lineHeight: "36px",
                fontWeight: "530",
                width: "329px",
              }}
            >
              {homeSNData["HomePageDetails"]
                ? homeSNData["HomePageDetails"]["Headline"]
                : ""}
            </Heading>
            <div
              style={{
                height: "56px",
                width: "329px",
                background: "white",
                borderRadius: "50px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InputField />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              height: "70%",
            }}
          >
            <img src={MobileHomePage} />
          </div>
          <AlertMassage alertMassage={alertMassage} />
        </div>
      ) : (
        <HomePageContainer
          style={{
            background: aboutSection || showVersionPopUp ? "#F5F5F5" : "",
          }}
        >
          <AlertMassage alertMassage={alertMassage} />
          <div
            style={{
              width: "90%",
              height: "90%",
              bottom: "0px",
              right: "0px",
              position: "absolute",
            }}
          >
            <img src={HomePage} />
          </div>
          <Container style={{ zIndex: "1" }}>
            <div style={{ height: "137px" }}>
              <div
                style={{
                  height: "69px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "69px", height: "69px" }}>
                  <RLogo scale={2.1} altText="Send" />
                </div>
              </div>
              <Heading>
                {homeSNData["HomePageDetails"]
                  ? homeSNData["HomePageDetails"]["Headline"]
                  : ""}
              </Heading>
            </div>
            {animation ? (
              <div>
                <div style={{ height: "44px" }}>
                  <InputField />
                </div>
                <div
                  style={{
                    height: "74px",
                    marginTop: "60px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ display: "flex", width: "411px" }}>
                    {homeSNData["ActionDetails"] &&
                      homeSNData["ActionDetails"]["button"].map(
                        (e: any, index: any) => (
                          <h1
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={e["label"]}
                            data-tooltip-offset={7}
                            data-tooltip-place="top"
                            key={index}
                            style={{ width: "121px", marginRight: "16px" }}
                          >
                            <ActionButton
                              className="button"
                              key={index}
                              style={{
                                marginRight: "4px",
                                borderRadius: "5px",
                                width: "121px",
                                height: "74px",
                                marginTop: "0px",
                                zIndex: "10",
                              }}
                              onClick={() => setpage(e["icon"])}
                            >
                              <div
                                className="blackIcon"
                                style={{
                                  marginTop: "0px",
                                  width: "30px",
                                  height: "43%",
                                  marginLeft: "41px",
                                }}
                              >
                                {get_icon(e["icon"])}
                              </div>
                              <div
                                className="blueIcon"
                                style={{
                                  marginTop: "0px",
                                  width: "30px",
                                  height: "43%",
                                  marginLeft: "41px",
                                }}
                              >
                                {get_icon(e["icon"] + "blue")}
                              </div>
                              <div
                                style={{
                                  marginTop: "4px",
                                  marginLeft: "3px",
                                  width: "100px",
                                  height: "30%",
                                  textAlign: "center",
                                  fontSize: "14px",
                                }}
                              >
                                {e["label"]}
                              </div>
                            </ActionButton>
                          </h1>
                        )
                      )}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <InputField />
              </div>
            )}
          </Container>
        </HomePageContainer>
      )}
    </div>
  );
};
export default Home;
