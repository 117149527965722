// @ts-nocheck
import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import styled from "styled-components";

import { loginRequest } from "../../../authentication/authConfig";
import LoginIcon from "../../../Icons/LoginIcon";
import SopraSteriaIcon from "../../../Icons/SopraSteriaIcon";
import { useLocation } from "react-router-dom";

export const Menucontainer = styled.div`
  height: 443px;
  width: 500px;
`;

export const ContainerFirst = styled.div`
  width: 100%;
  height: 52%;
`;

export const ContainerSecond = styled.div`
  width: 100%;
  height: 48%;
  text-align: center;
`;

export const SigninButton = styled.div`
  width: fit-content;
  color: #ffffff;
  height: 38px;
  padding: 6px 16px;
  background-color: #dd296e;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  :hover {
    background: linear-gradient(90deg, #cf022b 0%, #ea5599 100%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  // :active {
  //   background: #d10726;
  // }
`;

function handleLogin(instance: IPublicClientApplication, domainHint): void {
  instance.loginRedirect(loginRequest(domainHint)).catch((e) => {
    throw e;
  });
}

export default function LoginComponet() {
  const { instance } = useMsal();
  const location = useLocation();
  const [isActive, setIsActive] = useState(true);

  function handleLoginClick() {
    setIsActive(false);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {location.pathname === "/chat" ? (
        <div
          style={{
            width: "fit-content",
            height: "fit-content",
            textAlign: "center",
            boxShadow: "10px 10px 10px 10px rgba(0, 0, 0, 0.04)",
          }}
        >
          <div
            className="FontRoboto"
            style={{
              height: "52px",
              fontSize: "27px",
              color: "#0E090C",
              lineHeight: "52px",
            }}
          >
            Uh... Error has occurred
          </div>
          <div style={{ height: "40px", fontSize: "18px", color: "#0E090C" }}>
            Try refreshing?
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SigninButton onClick={() => window.location.reload()}>
              Refresh this page
            </SigninButton>
          </div>
        </div>
      ) : (
        <Menucontainer>
          <ContainerFirst>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <div style={{ width: "276px", height: "229px" }}>
                <LoginIcon scale={1} altText="Minimize"></LoginIcon>
              </div>
            </div>
          </ContainerFirst>
          <ContainerSecond>
            <div
              className="FontRoboto"
              style={{
                height: "52px",
                fontSize: "36px",
                color: "#0E090C",
                lineHeight: "52px",
              }}
            >
              {location.pathname === "/healthcheck"
                ? "RightSupport is Working"
                : "Velkommen til RightSupport"}
            </div>
            <div style={{ height: "60px", fontSize: "20px", color: "#0E090C" }}>
              {location.pathname === "/healthcheck"
                ? ""
                : "IT-hjelp når du trenger det"}
            </div>
            <div style={{ height: "33px", fontSize: "12px", color: "#767675" }}>
              {location.pathname === "/healthcheck"
                ? ""
                : " Vennligst logg inn for å få hjelp"}
            </div>
            <div
              style={{
                height: "39px",
                display: location.pathname === "/healthcheck" ? "none" : "flex",

                justifyContent: "center",
              }}
            >
              <SigninButton
                onClick={() => {
                  handleLoginClick();
                  handleLogin(instance, "");
                }}
                style={{ background: isActive ? "" : "#4A4A49" }}
              >
                Logg inn
              </SigninButton>
            </div>
          </ContainerSecond>
        </Menucontainer>
      )}
    </div>
  );
}
