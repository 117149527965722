import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";
import Checkmark from "../../Icons/Checkmark_icon";
import NoResultFound from "../../Icons/NoResultFound";
import { Caption, ImageContainer, ResultPageMainContainer, StatusContent, StatusGuideSteps, StatusTitle } from "./styled-component";


type NoResultPageProps = {
    inputText: string;
};

const NoResultPage: React.FC<NoResultPageProps> = ( { inputText } ) => {  
    const languageSNData = uselanguageSNData();

    return(
        <ResultPageMainContainer>
            <StatusContent>
                <StatusTitle>
                {languageSNData && languageSNData["searcharticle_noresulttitle_text"]? languageSNData["searcharticle_noresulttitle_text"] : "" } "{inputText}"
                </StatusTitle>
                <StatusGuideSteps>
                    <div style={{ height: "21px", width: "436px", fontSize: "14px", color: "#959199", fontWeight: "400", marginTop: "15px"}}>
                        {languageSNData && languageSNData["seracharticle_trysteptitle_text"]? languageSNData["seracharticle_trysteptitle_text"] : ""}
                    </div>
                    <div style={{ width: "468px", height: "22px", display: "flex", marginTop: "5px"}}>
                        <div style={{ width: "16px", height: "fit-content ", marginTop: "4px" }}>
                            <Checkmark scale={0.9} altText="attachment" viewIn="search_page" />
                        </div>
                        <div style={{ width: "444px", height: "20px ", fontSize: "14px", color: "#959199", fontWeight: "400", paddingLeft: "7px"}}>
                            {languageSNData && languageSNData["seracharticle_makesurestep_text"]? languageSNData["seracharticle_makesurestep_text"] : ""}
                        </div>
                    </div>
                    <div style={{ width: "468px", height: "22px", display: "flex"}}>
                        <div style={{ width: "16px", height: "16px ", marginTop: "4px", paddingLeft: "1px" }}>
                            <Checkmark scale={0.9} altText="attachment" viewIn="search_page" />
                        </div>
                        <div style={{ width: "444px", height: "20px ", fontSize: "14px", color: "#959199", fontWeight: "400", paddingLeft: "7px"}}>
                            {languageSNData && languageSNData["seracharticle_differentKeyword_text"]? languageSNData["seracharticle_differentKeyword_text"] : ""}
                        </div>
                    </div>
                </StatusGuideSteps>
            </StatusContent>
            <ImageContainer>
                <NoResultFound scale={7} altText="NoResultFound"/>
            </ImageContainer>
            <Caption>
                {languageSNData && languageSNData["seracharticle_buttonsbelowtitle_text"]? languageSNData["seracharticle_buttonsbelowtitle_text"] : ""}
            </Caption>
        </ResultPageMainContainer>
    )
}

export default NoResultPage;