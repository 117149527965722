//Library
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import {
  usehomeSNData,
  uselanguageSNData,
} from "../../hooks/UseApiContext/UseApiContext";
import Avatar from "../AvatarComps";

//Files & Functions
import {
  SendButton,
  ChatDiv,
  DownloadFile,
  DocumentFile,
} from "./StyledComponent";

//Components

//Icons
import Emoji from "../../Icons/Emoji_icon/Emoji";
import Attachment from "../../Icons/Attachment_icon";
import Send from "../../Icons/Send_icon";
import ReactTextareaAutosize from "react-textarea-autosize";
import DOCIcon from "../../Icons/Doc_extension_icon";
import PDFIcon from "../../Icons/pdf_extension_icon";
import ImgIcon from "../../Icons/img_extension_icon";
import Download from "../../Icons/Download_icon";
import EmojiPicker from "emoji-picker-react";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import { getSNJSON } from "../../api";
import {
  useAttachmentmanage,
  useInboxRecall,
  useInputStates,
  useSetAttachmentmanage,
  useSetInboxRecall,
  useSetInputStates,
  useSetNotsupportAttachment,
  useSetTicketSysId,
  usedisplaySize,
} from "../../hooks/useMenuContext/useMenuContext";
import CommentNotSupport from "../../Icons/CommentNotSupport";
import CommentNotFound from "../../Icons/CommentsNotFound";
import CommentBox from "../CommentBoxForInbox";
import Close from "../../Icons/Header_close_icon/Close";
import axios from "axios";
import Delete from "../../Icons/Delete_icon/Delete";

type InboxChatProps = {
  ticket: any;
  setSidebar: any;
  sidebar: any;
};

const InboxChat: React.FC<InboxChatProps> = ({
  ticket,
  setSidebar,
  sidebar,
}) => {
  const fileInputRef = useRef(null);
  const homeSNData = usehomeSNData();
  const displaySize = usedisplaySize();
  const inboxRecall = useInboxRecall();
  const setNotsupportAttachment = useSetNotsupportAttachment();
  const setInboxRecall = useSetInboxRecall();
  const [fetchCommentJSON, performCommentJSON] = useApiAction(getSNJSON);
  const [fetchAttachmentJSON, performAttachmentJSON] = useApiAction(getSNJSON);
  const [comment, setComment] = useState("");
  const [file64, setFile64] = useState("");
  const [filename, setFilename] = useState([]);
  const [filetype, setFiletype] = useState([]);
  const [attachmentPayload, setAttachmentPayload] = useState([]);
  const [keyDecte, setKeyDecte] = useState(false);
  const [addComment, setAddComment] = useState("");
  const [addAttachment, setAddAttachment] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [chatdiv, setchatdiv] = useState("");
  const contentRef = useRef(null); // create a ref to the content div
  const languageSNData = uselanguageSNData();
  const messagesEndRef = useRef(null);
  const [attachmentStatus, setAttachmentStatus] = useState("");
  let file_type = ".xlsx, .pptx, .jpg, .pdf, .docx, .eml, .msg, .png";
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const [multipleAttachments, setMultipleAttachments] = useState([]);
  const [attachmentNumber, setAttachmentNumber] = useState(0);
  const [progress, setProgress] = useState(0);
  const attachmentmanage = useAttachmentmanage();
  const setAttachmentmanage = useSetAttachmentmanage();
  const inputStates = useInputStates();
  const setInputStates = useSetInputStates();
  const setTicketSysId = useSetTicketSysId();
  const scrollableContentRef = useRef(null);
  let base64 = null;
  var ProfilePic =
    homeSNData &&
    homeSNData["userDetails"] &&
    homeSNData["userDetails"]["ProfilePic"];
  const inputRef = useRef();
  const [Mobileticket, setMobileTicket] = useState([]);

  const emojiMap = {
    ":)": "1F642",
    ":(": "1F641",
    ":D": "1F600",
    ":P": "1F61B",
    ";)": "1F609",
    ":|": "1F610",
    ":O": "1F632",
    ":*": "1F618",
    ":/": "1F615",
    ":'(": "1F622",
    ":@": "1F620",
    ":s": "1F615",
    ":$": "1F911",
    ":#": "1F910",
    ":*(": "1F62D",
    "<3": "2764",
    ":+1": "1F44D",
  };

  const inputTextHandler = (e, ticketNumber) => {
    let newComment = e.target.value;
    for (const key in emojiMap) {
      const regex = new RegExp(
        key.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"),
        "g"
      );
      const hexValue = emojiMap[key];
      const codePoint = parseInt(hexValue, 16);
      const emojiSymbol = String.fromCodePoint(codePoint);
      newComment = newComment.replace(regex, emojiSymbol);
    }

    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [ticketNumber]: newComment.trimStart(),
    }));
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file" && item.type.includes("image")) {
        const file = item.getAsFile();
        displayImage(file);
      }
    }
  };

  const displayImage = (file) => {
    const reader = new FileReader();
    let payloadAttachment = [];
    let allFileName = [];
    reader.onload = (event) => {
      let base64 =
        event &&
        event.target &&
        event.target.result.toString().startsWith("data:image/png;base64,") &&
        event.target.result.toString().split("base64,")[1];
      payloadAttachment.push({
        file_name:
          file.name === "image.png"
            ? attachmentmanage &&
              attachmentmanage[ticket && ticket["Sys ID"]] &&
              attachmentmanage[ticket && ticket["Sys ID"]].length !== "0"
              ? "RightSupport" +
                " - " +
                `${
                  attachmentNumber +
                  (attachmentmanage[ticket && ticket["Sys ID"]].length + 1)
                }` +
                ".png"
              : "RightSupport" + " - " + `${attachmentNumber + 1}` + ".png"
            : file.name,
        content_type: "Rightsupport/png",
        sys_id: ticket && ticket["Sys ID"],
        base64: base64,
      });
      allFileName.push({ file_name: "Rightsupport" });
      setFilename(allFileName);
      setMultipleAttachments(payloadAttachment);
    };
    reader.readAsDataURL(file);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    let arr = [];
    ticket && ticket["Comments"] && ticket["Comments"].length !== 0
      ? ticket["Comments"].forEach((e) => {
          e &&
            e["comments"] &&
            e["comments"].length !== 0 &&
            e["comments"].forEach((e) => {
              if (
                e &&
                e["Type"] === "attachment" &&
                e &&
                e["FileName"].startsWith("RightSupport")
              ) {
                let file = e["FileName"].split(".");
                let number = file[0].slice(15);
                arr.push(parseInt(number));
                setAttachmentNumber(
                  Math.max.apply(null, arr) === 0
                    ? 0
                    : Math.max.apply(null, arr)
                );
              }
            });
        })
      : setAttachmentNumber(0);
  }, [ticket]);

  function handleDropValue(event) {
    event.preventDefault();
    const { files } = event && event.dataTransfer;
    (async () => await handleChange(files))();
  }

  const handleChange = async (event) => {
    const files = event;
    let allFileName = [];
    let allFiletype = [];
    let payloadAttachment = [];
    let sys_id = ticket && ticket["Sys ID"];

    const readFileAsync = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          let base64 = event && event.target && event.target.result;
          resolve(String(base64));
        };

        reader.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentLoaded = (event.loaded / event.total) * 100;
            setProgress(percentLoaded);
          }
        };

        reader.readAsDataURL(file);
      });
    };

    for (let i = 0; i < files.length; i++) {
      const currentfile = files[i];

      try {
        const base64 = await readFileAsync(currentfile);
        let stringToIterate = String(base64);
        let startIndex = stringToIterate.indexOf("data");
        let endIndex = stringToIterate.indexOf("base64,") + "base64,".length;
        let substringToSplit = stringToIterate.substring(startIndex, endIndex);
        let splitSubstring = stringToIterate.split(substringToSplit);
        let pfrr = splitSubstring[1];
        allFileName.push(currentfile["name"]);
        allFiletype.push(currentfile["type"]);
        payloadAttachment.push({
          file_name: currentfile["name"],
          content_type: currentfile["type"],
          sys_id: sys_id,
          base64: pfrr,
        });
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }

    setMultipleAttachments(payloadAttachment);
    setFilename(allFileName);
    setFiletype(allFiletype);
  };

  // useEffect(() => {
  //   // const updatedArray = [...attachmentPayload, multipleAttachments];
  //   const singleArray = updatedArray.reduce((acc, curr) => {
  //     return acc.concat(curr);
  //   }, []);
  //   setAttachmentPayload(singleArray);
  // }, [multipleAttachments]);

  //Update attachmentmanage if any nw attachment add

  useEffect(() => {
    setMobileTicket(ticket);
  }, [ticket]);

  useEffect(() => {
    if (displaySize === "isMobile") {
      if (scrollableContentRef.current) {
        scrollableContentRef.current.scrollTop =
          scrollableContentRef.current.scrollHeight - 1;
      }
      if (ticket && ticket.Comments && Array.isArray(ticket.Comments)) {
        ticket.Comments.sort((a, b) => {
          const dateA = new Date(
            a.date.split(".").reverse().join("-")
          ).getTime();
          const dateB = new Date(
            b.date.split(".").reverse().join("-")
          ).getTime();
          return dateA - dateB;
        });
        ticket.Comments.forEach((obj) => {
          const comments = obj.comments;
          comments.sort((a, b) => {
            const dateA = a.Created;
            const dateB = b.Created;
            return dateA.localeCompare(dateB);
          });
        });
      }
    }
  }, [Mobileticket, ticket]);

  useEffect(() => {
    const updatedArray = { ...attachmentmanage, ...multipleAttachments };
    const singleArray = Object.values(updatedArray).reduce((acc, curr) => {
      return acc.concat(curr);
    }, []);
    //Grouped attachment according to ticket number(means according to sys_is)
    const groupedAttachments = singleArray.reduce((acc, attachment) => {
      const ticketId = attachment.sys_id;
      if (!acc[ticketId]) {
        acc[ticketId] = [];
      }
      acc[ticketId].push(attachment);
      return acc;
    }, {});

    setAttachmentmanage(groupedAttachments);
    setAttachmentPayload(singleArray);
  }, [multipleAttachments]);

  // Remove Attachment Functionality
  const removeAttachment = (item, attachmentId) => {
    // setAttachmentPayload(
    //   attachmentPayload.filter((items) => items["file_name"] !== item)
    // );
    setAttachmentmanage((prevAttachmentManage) => {
      const updatedAttachments = {
        ...prevAttachmentManage,
        [attachmentId]: prevAttachmentManage[attachmentId].filter(
          (items, key) => key !== item
        ),
      };
      return updatedAttachments;
    });
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  function getExtension(filename) {
    return filename.split(".").pop();
  }

  function EmojiFunction(emojiData, event) {
    setComment((prev) => prev + emojiData["emoji"]);
    setShowEmoji(false);
  }

  useEffect(() => {
    if (fetchCommentJSON.data) {
      setAddComment(fetchCommentJSON.data);
      setInboxRecall(!inboxRecall);
      setKeyDecte(false);
    }
  }, [fetchCommentJSON.data]);

  useEffect(() => {
    if (fetchAttachmentJSON && fetchAttachmentJSON.data) {
      setAddAttachment(fetchCommentJSON.data);
      setMultipleAttachments([]);
      setInboxRecall(!inboxRecall);
      setNotsupportAttachment(fetchAttachmentJSON.data);
    }
  }, [fetchAttachmentJSON.data]);

  function addComent(ticketNumber) {
    // @ts-ignore
    let newComme = (inputStates && inputStates[ticketNumber]) || "";
    let commentCheck = newComme.replaceAll("\n", "");
    let sys_id = ticket && ticket["Sys ID"];
    setTicketSysId(sys_id);
    var FeildComment = [
      "Put",
      "urlComment",
      "",
      { sys_id: sys_id, comments: inputStates && inputStates[ticketNumber] },
    ];
    var FeildAttachment = [
      "Post",
      "urlAttachment",
      "",
      attachmentmanage && attachmentmanage[ticketNumber],
    ];
    // var FeildAttachment = ["Post", "urlAttachment", "", attachmentPayload];

    function addAttacmentWithComment() {
      // Comment();
      performCommentJSON(FeildComment);
      // Attacment();
      performAttachmentJSON(FeildAttachment);
    }

    // comment !== "" && filename !== ""
    //   ? addAttacmentWithComment()
    //   : filename !== ""
    //   ? performAttachmentJSON(FeildAttachment)
    //   : comment !== "" && aqibcheck !== ""
    //   ? performCommentJSON(FeildComment)
    //   : console.log("sorry");

    if (
      inputStates[ticketNumber] !== "" &&
      inputStates[ticketNumber] != null &&
      filename.length !== 0
    ) {
      addAttacmentWithComment();
    } else if (inputStates[ticketNumber] !== "" && commentCheck !== "") {
      performCommentJSON(FeildComment);
    } else if (filename.length !== 0) {
      performAttachmentJSON(FeildAttachment);
    }

    // setComment("");
    setFilename([]);
    // setAttachmentPayload([]);
    setInputStates((prevInputStates) => {
      const updatedInputStates = { ...prevInputStates };
      delete updatedInputStates[ticketNumber];
      return updatedInputStates;
    });

    setAttachmentmanage((Attachments) => {
      const updatedAttachmentArray = { ...Attachments };
      delete updatedAttachmentArray[ticketNumber];
      return updatedAttachmentArray;
    });
  }

  // Calling Enter Key Function
  const detectEnterKey = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      ticket && ticket["active"] && addComent(ticket && ticket["Sys ID"]);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", detectEnterKey, true);
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      setchatdiv("calc(100% - " + contentRef.current.offsetHeight + "px)"); // log the height of the content div
    }
    // }, [comment, filename, attachmentPayload]);
  }, [inputStates, filename, attachmentmanage, sidebar]);
  useEffect(() => {
    if (attachmentStatus !== "") {
      let base64Attachment =
        attachmentStatus &&
        attachmentStatus["result"] &&
        attachmentStatus["result"]["Base64"];
      let pfrr = "";
      if (base64Attachment.includes("data")) {
        let stringToIterate = base64Attachment;
        let startIndex = stringToIterate.indexOf("data");
        let endIndex = stringToIterate.indexOf("base64") + "base64".length;
        let substringToSplit = stringToIterate.substring(startIndex, endIndex);
        let splitSubstring = stringToIterate.split(substringToSplit);
        pfrr = splitSubstring[1];
      } else {
        pfrr = base64Attachment;
      }
      const linkSource = `data:${attachmentDetails["ContentType"]};base64,${pfrr}`;
      const downloadLink = document.createElement("a");
      const fileName = attachmentDetails["FileName"];
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }, [attachmentStatus]);

  const base64toBlob = async (e) => {
    setAttachmentDetails(e);
    async function getBase64Attachment() {
      await axios
        .get(e["Base64"])
        .then((response) => setAttachmentStatus(response.data));
    }
    getBase64Attachment();
  };

  return (
    <div>
      {ticket && ticket["CommentsSupported"] && ticket["active"] === true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <div
            ref={contentRef}
            style={{
              order: displaySize === "isMobile" ? "2" : "1",
              height: "fit-content",
              padding: "16px",
              gap: "16px",
              display: "flex",
              justifyContent: "center",
              borderBottom: "1px solid #D9D9D9",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "fit-content",
              }}
            >
              {displaySize !== "isMobile" && (
                <div
                  style={{
                    height: "24px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "calc(100% - 40px)",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "16px",
                      color: "#0E090C",
                      fontWeight: "600",
                      height: "19px",
                      lineHeight: "19px",
                    }}
                  >
                    {}{" "}
                    {languageSNData && ticket && ticket["active"]
                      ? languageSNData["inbox_commentplaceholder_text"]
                      : ""}
                  </div>
                  <button
                    onClick={() => setSidebar(false)}
                    className="back-button-hover"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={
                      languageSNData &&
                      languageSNData["inboxchat_closeicontooltip_text"]
                        ? languageSNData["inboxchat_closeicontooltip_text"]
                        : "Close comments"
                    }
                    data-tooltip-offset={4}
                    data-tooltip-place="left"
                    style={{
                      width: "35px",
                      marginTop: "-5px",
                      height: "40px",
                      opacity: "60%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      background: "none",
                    }}
                  >
                    <Close scale={0.7} altText={"Close"} color="" />
                  </button>
                </div>
              )}
              {ticket && ticket["active"] ? (
                <div
                  style={{
                    height: "fit-content",
                    marginTop: "9px",
                    width: "100%",
                    padding: "8px",
                    borderRadius: displaySize === "isMobile" ? "30px" : "4px",
                    color: "#49484D",
                    border:
                      (inputStates[ticket && ticket["Sys ID"]] !== "" &&
                        inputStates[ticket && ticket["Sys ID"]] != null) ||
                      filename.length !== 0
                        ? "2px solid #959199"
                        : "1.5px solid #959199",
                    display: "flex",
                  }}
                >
                  <div>
                    {/* {attachmentPayload.length !== 0 ? ( */}
                    {attachmentmanage &&
                    attachmentmanage[ticket && ticket["Sys ID"]] &&
                    attachmentmanage[ticket && ticket["Sys ID"]].length !==
                      0 ? (
                      <div
                        style={{
                          height:
                            // attachmentPayload.length === 1 ? "48px" : "87px",
                            attachmentmanage &&
                            attachmentmanage[ticket && ticket["Sys ID"]] &&
                            attachmentmanage[ticket && ticket["Sys ID"]]
                              .length === 1
                              ? "48px"
                              : "87px",
                          borderBottom: "1px solid #E6E6E6",
                        }}
                      >
                        {/* {attachmentPayload.length !== 0 ? ( */}
                        {attachmentmanage &&
                        attachmentmanage[ticket && ticket["Sys ID"]] &&
                        attachmentmanage[ticket && ticket["Sys ID"]].length !==
                          0 ? (
                          <div
                            style={{
                              overflowY: "auto",
                            }}
                          >
                            {/* {attachmentPayload.map((e, key) => ( */}
                            {attachmentmanage &&
                            attachmentmanage[ticket && ticket["Sys ID"]]
                              ? attachmentmanage[
                                  ticket && ticket["Sys ID"]
                                ].map((e, key) => (
                                  <DocumentFile
                                    progress={progress}
                                    key={key}
                                    style={{
                                      // width:
                                      //   attachmentPayload.length === 1 ? "" : "98%",
                                      width:
                                        attachmentmanage &&
                                        attachmentmanage[
                                          ticket && ticket["Sys ID"]
                                        ] &&
                                        attachmentmanage[
                                          ticket && ticket["Sys ID"]
                                        ].length === 1
                                          ? ""
                                          : "98%",
                                      display: "flex",
                                      // marginBottom:
                                      //   attachmentPayload.length === 1
                                      //     ? "0px"
                                      //     : "4px",
                                      marginBottom:
                                        attachmentmanage &&
                                        attachmentmanage[
                                          ticket && ticket["Sys ID"]
                                        ] &&
                                        attachmentmanage[
                                          ticket && ticket["Sys ID"]
                                        ].length === 1
                                          ? "0px"
                                          : "4px",
                                      height: "40px",
                                      border: "1px solid #E6E6E6",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "35px",
                                        paddingTop: "3px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                          marginLeft: "5px",
                                          borderRadius: "100%",
                                        }}
                                      >
                                        <Avatar
                                          homedata={ticket && ticket["Name"]}
                                          FontSize="9.54545px"
                                          MarginLeft="5px"
                                          MarginTop="8px"
                                          inboxNameImage={true}
                                          Color={"#4A2878"}
                                          fontColor="white"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        width: "calc(100% - 60px)",
                                        height: "40px",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "5px",
                                      }}
                                    >
                                      <div
                                        className="filename"
                                        style={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          color: "#4A4A49",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {e["file_name"]}
                                      </div>
                                    </div>
                                    <div
                                      className="back-button-hover"
                                      style={{
                                        width: "25px",
                                        paddingLeft: "2px",
                                      }}
                                      onClick={() =>
                                        removeAttachment(
                                          key,
                                          ticket && ticket["Sys ID"]
                                        )
                                      }
                                    >
                                      <Delete scale={0.6} altText="Delete" />
                                    </div>
                                  </DocumentFile>
                                ))
                              : ""}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        height: "fit-content",

                        display: "flex",
                        alignItems: "end",
                        // marginTop: attachmentPayload.length !== 0 ? "8px" : "",
                        marginTop:
                          attachmentmanage &&
                          attachmentmanage[ticket && ticket["Sys ID"]] &&
                          attachmentmanage[ticket && ticket["Sys ID"]]
                            .length !== 0
                            ? "8px"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          height: "fit-content",
                          // maxHeight:
                          //   attachmentPayload.length !== 0 ? "200px" : "250px",
                          maxHeight:
                            attachmentmanage &&
                            attachmentmanage[ticket && ticket["Sys ID"]] &&
                            attachmentmanage[ticket && ticket["Sys ID"]]
                              .length !== 0
                              ? "200px"
                              : "250px",
                          width: "calc(100% - 78px)",
                          display: "flex",
                          alignItems: "center",
                          padding: "0px 0px 8px 0px",
                        }}
                      >
                        <ReactTextareaAutosize
                          // maxRows={attachmentPayload.length !== 0 ? 11 : 14}
                          maxRows={
                            attachmentmanage &&
                            attachmentmanage[ticket && ticket["Sys ID"]] &&
                            attachmentmanage[ticket && ticket["Sys ID"]]
                              .length !== 0
                              ? 11
                              : 14
                          }
                          style={{
                            outline: "none",
                            display: "block",
                            overflowY: "auto",
                            border: "none",
                            resize: "none",
                            borderRadius: "4px",
                            lineHeight: "18px",
                            fontSize: "16px",
                            color: "#0E090C",
                          }}
                          ref={inputRef}
                          onPaste={handlePaste}
                          onDrop={handleDropValue}
                          value={inputStates[ticket && ticket["Sys ID"]] || ""}
                          onChange={(event) =>
                            inputTextHandler(event, ticket && ticket["Sys ID"])
                          }
                          onKeyDown={detectEnterKey}
                        />
                      </div>
                      <div
                        style={{
                          width: "78px",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "end",
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "4px",
                          }}
                        >
                          <input
                            onChange={(event) =>
                              handleChange(
                                event && event.target && event.target.files
                              )
                            }
                            ref={fileInputRef}
                            type="file"
                            hidden
                            multiple
                            accept={file_type}
                          />
                          <div
                            className="back-button-hover"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              languageSNData
                                ? languageSNData[
                                    "inbox_attachmentbuttontitle_text"
                                  ]
                                : ""
                            }
                            style={{
                              padding: "2px",
                              borderRadius: "4px",
                              width: "fit-content",
                              height: "fit-content",
                              cursor: "pointer",
                            }}
                            onClick={() => fileInputRef.current.click()}
                          >
                            <Attachment scale={0.8} altText={"Attachment"} />
                          </div>{" "}
                        </div>
                        <SendButton
                          data-tooltip-id="my-tooltip"
                          data-tooltip-offset={4}
                          data-tooltip-content={
                            languageSNData &&
                            languageSNData["inbox_sendbuttontitle_text"]
                              ? languageSNData["inbox_sendbuttontitle_text"]
                              : "Send"
                          }
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius:
                              displaySize === "isMobile" ? "30px" : "",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            background:
                              ticket && ticket["active"] === false
                                ? "#E6E6E6"
                                : "",
                          }}
                          onClick={() =>
                            ticket &&
                            ticket["active"] &&
                            addComent(ticket && ticket["Sys ID"])
                          }
                        >
                          <Send scale={0.7} altText={"Send"} color="White" />
                        </SendButton>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <ChatDiv
            height={chatdiv}
            style={{
              order: displaySize === "isMobile" ? "1" : "2",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                width: "96%",
                overflowY: "scroll",
                scrollBehavior: "revert",
              }}
              ref={scrollableContentRef}
            >
              {ticket["Comments"].map((detail, id) => (
                <div key={id} style={{ height: "fit-content", width: "97%" }}>
                  <div
                    style={{
                      height: "30px",
                      padding: "0px 0px",
                      fontWeight: "600",
                      fontSize: "12px",
                      display: "flex",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          border: "0.5px solid #E6E6E6",
                          height: "1px",
                          marginTop: "15px",
                        }}
                      ></div>
                    </div>
                    {detail["date"] && (
                      <div style={{ marginTop: "7px", textAlign: "center" }}>
                        {detail["date"]}
                      </div>
                    )}
                    <div>
                      <div
                        style={{
                          border: "0.5px solid #E6E6E6",
                          height: "1px",
                          marginTop: "15px",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div style={{ height: "fit-content" }}>
                    {detail &&
                      detail["comments"].map((e, id) => (
                        <div>
                          <div
                            key={id}
                            style={{
                              borderTop:
                                0 === id
                                  ? ""
                                  : e["TimeStamp"] ===
                                    detail["comments"][id - 1]["TimeStamp"]
                                  ? ""
                                  : "1px solid #E6E6E6",
                              height: "fit-content",
                              paddingBottom: "8px",
                              paddingTop:
                                0 === id
                                  ? ""
                                  : e["TimeStamp"] ===
                                    detail["comments"][id - 1]["TimeStamp"]
                                  ? ""
                                  : "8px",
                              display:
                                e["Type"] !== "attachment" &&
                                e["Value"].replaceAll("\n", "") === ""
                                  ? "none"
                                  : "flex",
                            }}
                          >
                            <div style={{ width: "36px" }}>
                              {0 !== id &&
                              e["TimeStamp"] ===
                                detail["comments"][id - 1]["TimeStamp"] ? (
                                ""
                              ) : (
                                <div
                                  style={{
                                    width: "25px",
                                    height:
                                      0 !== id &&
                                      e["TimeStamp"] ===
                                        detail["comments"][id - 1]["TimeStamp"]
                                        ? ""
                                        : "25px",
                                    borderRadius: "100%",
                                  }}
                                >
                                  {e["Made by end user"] && ProfilePic ? (
                                    <img
                                      src={
                                        "data:image/png;base64," + ProfilePic
                                      }
                                    />
                                  ) : (
                                    <Avatar
                                      homedata={e["UserFullName"]}
                                      FontSize="10px"
                                      MarginLeft="5px"
                                      MarginTop="6px"
                                      inboxNameImage={true}
                                      Color="#FEEDF3"
                                      fontColor="#DD296E"
                                    />
                                  )}
                                </div>
                              )}
                            </div>

                            <div
                              style={{
                                width: "calc(100% - 36px)",
                              }}
                            >
                              {0 !== id &&
                              e["TimeStamp"] ===
                                detail["comments"][id - 1]["TimeStamp"] ? (
                                ""
                              ) : (
                                <div
                                  style={{
                                    height: "17px",
                                    marginTop: "4px",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      lineHeight: "17px",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      color: "#4A4A49",
                                      width: "fit-content",
                                    }}
                                  >
                                    {e["Made by end user"]
                                      ? e["UserFullName"]
                                      : e["UserFirstName"]}
                                  </div>

                                  <div
                                    style={{
                                      color: "#767675",
                                      fontSize: "12px",
                                      width: "fit-content",
                                      marginLeft: "4px",
                                    }}
                                  >
                                    {e["TimeStamp"]}
                                  </div>
                                </div>
                              )}
                              <div
                                style={{
                                  height: "fit-content",
                                  fontSize: "16px",
                                  color: "#4A4A49",
                                  marginTop: "4px",
                                  width: "fit-content",
                                }}
                              >
                                {e["Type"] === "attachment" ? (
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "normal",
                                      height: "fit-content",
                                      width: "fit-content",
                                      fontFamily: "inter",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    <DownloadFile
                                      style={{
                                        height: "42px",
                                        display: "flex",
                                        borderRadius: "4px",
                                        border: "1px solid #E6E6E6",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "13%",
                                          paddingTop: "0px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            borderRadius: "100%",
                                            background: "#4A2878",
                                            height: "30px",
                                            width: "30px",
                                          }}
                                        >
                                          <Avatar
                                            homedata={
                                              e["UserFullName"]
                                                ? e["UserFullName"]
                                                : ticket && ticket["Name"]
                                            }
                                            FontSize="9.54545px"
                                            MarginLeft="6px"
                                            MarginTop="9px"
                                            inboxNameImage={true}
                                            Color={"#4A2878"}
                                            fontColor="white"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "75%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            height: "20px",
                                            marginTop: "2px",
                                            fontSize: "14px",
                                            color: "#4A4A49",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          {e["FileName"]}
                                        </div>
                                        {e && e["Updated"] && (
                                          <div
                                            style={{
                                              height: "12px",
                                              width: "75px",
                                              marginTop: "0px",
                                              fontSize: "12px",
                                              color: "#767675",
                                              lineHeight: "12px",
                                            }}
                                          >
                                            {e["Updated"]}
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        style={{ width: "12%" }}
                                        className="download"
                                      >
                                        <button
                                          data-tooltip-id="my-tooltip"
                                          data-tooltip-content={
                                            languageSNData &&
                                            languageSNData[
                                              "inboxchat_downloadicontooltip_text "
                                            ]
                                              ? languageSNData[
                                                  "inboxchat_downloadicontooltip_text "
                                                ]
                                              : "Download"
                                          }
                                          data-tooltip-offset={4}
                                          style={{
                                            background: "none",
                                            border: "none",
                                            marginLeft: "-5px",
                                          }}
                                          onClick={() => base64toBlob(e)}
                                        >
                                          <Download
                                            scale={0.8}
                                            altText="attachment"
                                          />
                                        </button>
                                      </div>
                                    </DownloadFile>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "normal",
                                      height: "fit-content",
                                      width: "fit-content",
                                      fontFamily: "inter",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {e["Value"].trimEnd().trimStart()}
                                    {e["ContainsArticle"] && (
                                      <Link
                                        to={"/article?sys_id=" + e["ArticleID"]}
                                      >
                                        <div
                                          style={{
                                            width: "fit-content",
                                            // height: "30px",
                                            height: "fit-content",
                                          }}
                                        >
                                          <a
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {e["ArticleTitle"]}
                                          </a>
                                        </div>
                                      </Link>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div ref={messagesEndRef} />
                </div>
              ))}
            </div>
          </ChatDiv>
        </div>
      ) : (
        <div style={{}}>
          {displaySize !== "isMobile" && (
            <div
              style={{
                height: "50px",
                padding: "5px 3px 16px 16px",
                gap: "16px",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => setSidebar(false)}
                style={{
                  width: "40px",
                  height: "35px",
                  opacity: "60%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "none",
                  background: "none",
                }}
              >
                <Close scale={0.7} altText={"Close"} color="" />
              </button>
            </div>
          )}
          <div
            style={{
              height: "90%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "fit-content",
                width: "fit-content",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CommentNotFound scale={15} altText={"CommentNotFound"} />
            </div>
            <div
              style={{
                height: "fit-content",
                marginTop: "20px",
                width: "100%",
                color: "#49484D",
                fontSize: "14px",
                textAlign: "center",
                fontFamily: "Inter",
                fontWeight: "400px",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              {languageSNData &&
              languageSNData["inbox_nocommentsthisticket_text"]
                ? languageSNData["inbox_nocommentsthisticket_text"]
                : "There are no comments on this ticket"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InboxChat;
