import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 25%;
  boxshadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  borderradius: 3px;
  top: 0%;
  left: 0%;
  z-index: 2;
`;
export const PopUpContainer = styled.div`
  display: flex;
  position: absolute;
  width: 500px;
  height: 278px;
  background: #ffffff;
  boxshadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  borderradius: 3px;
  top: calc(50% - 132px);
  left: calc(50% - 250px);
  z-index: 2;
  @media(max-width: 480px){
    margin: 15px;
    width: 90%;
    border-radius: 10px;
    left: 2%;
    padding: 5px;
    height: 210px;
  }
`;
export const Header = styled.div`
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
`;
export const HeaderText = styled.div`
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
  width: fit-content;
  height: fit-content;
  font-family: "Roboto Slab";
`;
export const CloseButton = styled.div`
  width: 25px;
  height: 27px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  @media(max-width: 480px){
    top: 2px;
    right: 2px;
    }
`;
export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100% - 66px);
`;
export const MessageText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #49484d;
  font-family: Inter;
  over-flowy: hidden;
  text-align: center;
  height: fit-content;
  width: 436px;
  @media(max-width: 480px){
    width: 90%;
    top: 1px;
    right: 2px;
    }
`;
export const ButtonContainer = styled.div`
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
  display: flex;
`;
export const ButtonNotNow = styled.div`
  height: 38px;
  width: fit-content;
  margin-right: 5px;
  align-items: center;
  display: flex;
  padding: 5px 5px 5px 5px;
  border-radius: 3px;
  color: #675b98;
  font-family: Inter;
  font-size: 16px;
  line-height: 16px;
  background: #ffffff;
  font-weight: 500;
  cursor: pointer;
  :hover {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
`;
export const ButtonInstall = styled.div`
height: 38px;
  width: fit-content;
  align-items: center;
  display: flex;
  padding: 5px 15px 5px 15px;
  border-radius: 3px;
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  line-height: 16px;
  background: #675B98;
  font-weight: 500;
  cursor: pointer;
  :hover {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
`;
