const isLocalhost = window.location.href.indexOf("localhost") > -1;

//All Get Call Api
const SN_Url = {
  //Get Requests
  gethomePageApi:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/homepage/landingpage",
  getinboxPageApi:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/ticket/getTickets?",
  getcallDetailsPageApi:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/2s_schedule_call/getCallDetails",
  getLanguageDetails:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/homepage/getLanguageDetails?",
  getSearchData: `https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/2s_desktop_knowledge/getKnowledgeSearch?query=`,
  getSuggestion: `https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/2s_desktop_knowledge/getsuggestions?sysparm_keyword=`,
  getChangeLanguage: `https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/homepage/setPreference?sysparm_preference=`,
  getFullArticle: `https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/2s_desktop_knowledge/getKnowledgeContent?sys_id=`,
  getCheckIfUserSessionIsActive:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/homepage/checkIfUserSessionIsActive",
  getDeleteAttachment:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/ticket/deleteAttachment",
  getFavouriteArticle:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/2s_user_info/getUserFavourite",
  putReOpenTicket:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/ticket/reOpenTicket",
  getRemoveFavouriteArticle:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/2s_user_info/removeUserFavourite",

  // Post requests
  postComment:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/ticket/commentAdded",
  postAttachment:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/ticket/addAttachment",
  postUpdateVeiwEndUser:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/ticket/endUserViewedTicket",
  postAddFavouriteArticle:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/2s_user_info/addUserFavourite",
  postCreateTicket:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/ticket/createTicket",
  postUpdateDiviceID:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/2s_user_info/updateDeviceID",

  //Post amazon
  postCallBack:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/2s_amazon_connect_callback/createCallback",
  postGetQueue:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/2s_amazon_connect_callback/getAmazonQueueInfo",
  postAppVersion:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/2s_version_store/versionStore",
  cancelCallBackQueue:
    "https://soprasteriadev.service-now.com/api/x_steas_2s_desktop/2s_amazon_connect_callback/cancelCallback",

  // Iframe
  chatFrameURL:
    "https://soprasteriadev.service-now.com/sp?id=rs_2s_login&type=chat&item_id=6529bf891b9d0450f650ed7cee4bcbb8&user_email=",
  // "https://soprasteriadev.service-now.com/sn_va_web_client_app_embed.do?sysparm_requester_session_language=",

  // SNLogin
  loginSN:
    "https://soprasteriadev.service-now.com/login_with_sso.do?glide_sso_id=",
};

const environments = {
  localhost: {
    api: {
      //Get
      urlHome: SN_Url.gethomePageApi,
      urlInbox: SN_Url.getinboxPageApi,
      urlCallDetails: SN_Url.getcallDetailsPageApi,
      urlLanguage: SN_Url.getLanguageDetails,
      urlSearchData: SN_Url.getSearchData,
      urlSuggestion: SN_Url.getSuggestion,
      urlChangeLanguage: SN_Url.getChangeLanguage,
      urlFullArticle: SN_Url.getFullArticle,
      urlCheckIfUserSessionIsActive: SN_Url.getCheckIfUserSessionIsActive,
      urlReOpenTicket: SN_Url.putReOpenTicket,
      urlDeleteAttachment: SN_Url.getDeleteAttachment,
      urlgetRemoveFavouriteArticle: SN_Url.getRemoveFavouriteArticle,
      urlgetFavouriteArticle: SN_Url.getFavouriteArticle,

      //Post
      urlComment: SN_Url.postComment,
      urlAttachment: SN_Url.postAttachment,
      urlUpdateVeiwEndUser: SN_Url.postUpdateVeiwEndUser,
      urlCreateTicket: SN_Url.postCreateTicket,
      urlUpdateDiviceID: SN_Url.postUpdateDiviceID,
      urlAddSaveArticle: SN_Url.postAddFavouriteArticle,
      urlPostAppVersion: SN_Url.postAppVersion,
      //Post Amazon
      urlCallBack: SN_Url.postCallBack,
      urlGetQueue: SN_Url.postGetQueue,
      urlcancelCallBackQueue: SN_Url.cancelCallBackQueue,

      //Iframe
      urlFrameURL: SN_Url.chatFrameURL,

      matomoID: 3,

      // SNLogin
      urlLoginSN: SN_Url.loginSN,
      glideSsoIdSopraSteria: "1caf45d91b2dfb0054e4c88f2e4bcb5a",
      glideSsoIdSintef: "1caf45d91b2dfb0054e4c88f2e4bcb5a",

      //OneSignalAppid
      oneSignalAppid: "f94e7828-2b9a-4baf-9040-674f9fad6295",

      // Microsoft Login URl
      userInfoUri: "https://graph.microsoft.com/oidc/userinfo",
    },
    environment: "localhost",
    clientId: "6a71e75d-b4e2-4cf3-a79e-56223bc5383f",
    redirectUri: "http://localhost:3000/",
  },
  test: {
    api: {
      //Get
      urlHome: SN_Url.gethomePageApi.replace("dev.", "test."),
      urlInbox: SN_Url.getinboxPageApi.replace("dev.", "test."),
      urlCallDetails: SN_Url.getcallDetailsPageApi.replace("dev.", "test."),
      urlLanguage: SN_Url.getLanguageDetails.replace("dev.", "test."),
      urlSearchData: SN_Url.getSearchData.replace("dev.", "test."),
      urlSuggestion: SN_Url.getSuggestion.replace("dev.", "test."),
      urlChangeLanguage: SN_Url.getChangeLanguage.replace("dev.", "test."),
      urlFullArticle: SN_Url.getFullArticle.replace("dev.", "test."),
      urlCheckIfUserSessionIsActive:
        SN_Url.getCheckIfUserSessionIsActive.replace("dev.", "test."),
      urlReOpenTicket: SN_Url.putReOpenTicket.replace("dev.", "test."),
      urlDeleteAttachment: SN_Url.getDeleteAttachment.replace("dev.", "test."),
      urlgetRemoveFavouriteArticle: SN_Url.getRemoveFavouriteArticle.replace(
        "dev.",
        "test."
      ),
      urlgetFavouriteArticle: SN_Url.getFavouriteArticle.replace(
        "dev.",
        "test."
      ),

      //Post
      urlComment: SN_Url.postComment.replace("dev.", "test."),
      urlAttachment: SN_Url.postAttachment.replace("dev.", "test."),
      urlUpdateVeiwEndUser: SN_Url.postUpdateVeiwEndUser.replace(
        "dev.",
        "test."
      ),
      urlCreateTicket: SN_Url.postCreateTicket.replace("dev.", "test."),
      urlUpdateDiviceID: SN_Url.postUpdateDiviceID.replace("dev.", "test."),
      urlAddSaveArticle: SN_Url.postAddFavouriteArticle.replace(
        "dev.",
        "test."
      ),
      urlPostAppVersion: SN_Url.postAppVersion.replace("dev.", "test."),

      //Post Amazon
      urlCallBack: SN_Url.postCallBack.replace("dev.", "test."),
      urlGetQueue: SN_Url.postGetQueue.replace("dev.", "test."),
      urlcancelCallBackQueue: SN_Url.cancelCallBackQueue.replace(
        "dev.",
        "test."
      ),

      //Iframe
      urlFrameURL: SN_Url.chatFrameURL.replace("dev.", "test."),

      matomoID: 4,

      // SNLogin
      urlLoginSN: SN_Url.loginSN.replace("dev.", "test."),
      glideSsoIdSopraSteria: "23bb5eef37293b400ecb12c543990ea9",
      glideSsoIdSintef: "abbcdb25db419cd04cd2d185f3961965",

      //OneSignalAppid
      oneSignalAppid: "4e7462f0-d4b0-4eaa-bb7d-f3c3601eff66",

      // Microsoft Login URl
      userInfoUri: "https://graph.microsoft.com/oidc/userinfo",
    },
    environment: "test",
    clientId: "6a71e75d-b4e2-4cf3-a79e-56223bc5383f",
    redirectUri: "https://cx-frontend-test-poc.azurewebsites.net/",
  },
  development: {
    api: {
      //Get
      urlHome: SN_Url.gethomePageApi,
      urlInbox: SN_Url.getinboxPageApi,
      urlCallDetails: SN_Url.getcallDetailsPageApi,
      urlLanguage: SN_Url.getLanguageDetails,
      urlSearchData: SN_Url.getSearchData,
      urlSuggestion: SN_Url.getSuggestion,
      urlChangeLanguage: SN_Url.getChangeLanguage,
      urlFullArticle: SN_Url.getFullArticle,
      urlCheckIfUserSessionIsActive: SN_Url.getCheckIfUserSessionIsActive,
      urlReOpenTicket: SN_Url.putReOpenTicket,
      urlDeleteAttachment: SN_Url.getDeleteAttachment,
      urlgetRemoveFavouriteArticle: SN_Url.getRemoveFavouriteArticle,
      urlgetFavouriteArticle: SN_Url.getFavouriteArticle,

      //Post
      urlComment: SN_Url.postComment,
      urlAttachment: SN_Url.postAttachment,
      urlUpdateVeiwEndUser: SN_Url.postUpdateVeiwEndUser,
      urlCreateTicket: SN_Url.postCreateTicket,
      urlUpdateDiviceID: SN_Url.postUpdateDiviceID,
      urlAddSaveArticle: SN_Url.postAddFavouriteArticle,
      urlPostAppVersion: SN_Url.postAppVersion,

      //Post Amazon
      urlCallBack: SN_Url.postCallBack,
      urlGetQueue: SN_Url.postGetQueue,
      urlcancelCallBackQueue: SN_Url.cancelCallBackQueue,

      //Iframe
      urlFrameURL: SN_Url.chatFrameURL,

      matomoID: 6,

      // SNLogin
      urlLoginSN: SN_Url.loginSN,
      glideSsoIdSopraSteria: "1caf45d91b2dfb0054e4c88f2e4bcb5a",
      glideSsoIdSintef: "055efa74dbf85810e642d06b68961917",

      //OneSignalAppid
      oneSignalAppid: "a5aa2ed6-9fd4-45d5-aa21-539b6e0e8b7d",

      // Microsoft Login URl
      userInfoUri: "https://graph.microsoft.com/oidc/userinfo",
    },
    environment: "development",
    clientId: "6a71e75d-b4e2-4cf3-a79e-56223bc5383f",
    redirectUri: "https://cx-frontend-dev-poc.azurewebsites.net/",
  },
  prod: {
    api: {
      //Get
      urlHome: SN_Url.gethomePageApi.replace("dev", ""),
      urlInbox: SN_Url.getinboxPageApi.replace("dev", ""),
      urlCallDetails: SN_Url.getcallDetailsPageApi.replace("dev", ""),
      urlLanguage: SN_Url.getLanguageDetails.replace("dev", ""),
      urlSearchData: SN_Url.getSearchData.replace("dev", ""),
      urlSuggestion: SN_Url.getSuggestion.replace("dev", ""),
      urlChangeLanguage: SN_Url.getChangeLanguage.replace("dev", ""),
      urlFullArticle: SN_Url.getFullArticle.replace("dev", ""),
      urlCheckIfUserSessionIsActive:
        SN_Url.getCheckIfUserSessionIsActive.replace("dev", ""),
      urlReOpenTicket: SN_Url.putReOpenTicket.replace("dev", ""),
      urlDeleteAttachment: SN_Url.getDeleteAttachment.replace("dev", ""),
      urlgetRemoveFavouriteArticle: SN_Url.getRemoveFavouriteArticle.replace(
        "dev",
        ""
      ),
      urlgetFavouriteArticle: SN_Url.getFavouriteArticle.replace("dev", ""),

      //Post
      urlComment: SN_Url.postComment.replace("dev", ""),
      urlAttachment: SN_Url.postAttachment.replace("dev", ""),
      urlUpdateVeiwEndUser: SN_Url.postUpdateVeiwEndUser.replace("dev", ""),
      urlCreateTicket: SN_Url.postCreateTicket.replace("dev", ""),
      urlUpdateDiviceID: SN_Url.postUpdateDiviceID.replace("dev", ""),
      urlAddSaveArticle: SN_Url.postAddFavouriteArticle.replace("dev", ""),
      urlPostAppVersion: SN_Url.postAppVersion.replace("dev", ""),

      //Post Amazon
      urlCallBack: SN_Url.postCallBack.replace("dev", ""),
      urlGetQueue: SN_Url.postGetQueue.replace("dev", ""),
      urlcancelCallBackQueue: SN_Url.cancelCallBackQueue.replace("dev", ""),

      //Iframe
      urlFrameURL: SN_Url.chatFrameURL.replace("dev", ""),

      matomoID: 5,

      // SNLogin
      urlLoginSN: SN_Url.loginSN.replace("dev", ""),
      glideSsoIdSopraSteria: "db5a0cb61bcaf348e4f9c9506e4bcb21",
      glideSsoIdSintef: "10f59071db019c102aa87317f3961925",

      //OneSignalAppid
      oneSignalAppid: "323937d5-8c52-494f-bc12-d4b11bdc7413",

      // Microsoft Login URl
      userInfoUri: "https://graph.microsoft.com/oidc/userinfo",
    },
    environment: "prod",
    clientId: "6a71e75d-b4e2-4cf3-a79e-56223bc5383f",
    redirectUri: "https://rightsupport.soprasteria.no/",
  },
};

export const getEnvironment = (
  uri = window.location.href,
  isLocalhost = false
) => {
  switch (true) {
    case isLocalhost:
      return environments.localhost;
    case uri.indexOf("-test") > -1:
      return environments.test;
    case uri.indexOf("-dev") > -1:
      return environments.development;
    case uri.indexOf("soprasteria.no") > -1:
      return environments.prod;
    default:
      return environments.test;
  }
};

const config = {
  ...getEnvironment(window.location.href, isLocalhost),
  scopes: ["openid", "profile", "offline_access"],
};

export default config;
