// @ts-nocheck
//Library
import React, { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
  useNavigate,
  useLocation,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useCookies } from "react-cookie";
import OneSignal from "react-onesignal";
import { renderToString } from "react-dom/server";
import PullToRefresh from "pulltorefreshjs";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Files & Functions
import { getSNJSON } from "../../api/index";
import config from "../../constant/config";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import {
  MainPageHeader,
  MainPageContainer,
  MobileMainPageContainer,
} from "./StyledComponent";
import {
  useInboxRecall,
  useHomeRecall,
  useChangeLanguage,
  useSetInboxRecall,
  usedisplaySize,
  usesetDisplaySize,
  useSaved,
  useInboxLoading,
  useSetInboxLoading,
  useSetTicketData,
  useTicketSysId,
  useSetTicketSysId,
  useRefreshSavedArticle,
} from "../../hooks/useMenuContext/useMenuContext";
import {
  usehomeSNData,
  usesetHomeSNData,
  useinboxSNData,
  usesetInboxSNData,
  uselanguageSNData,
  usesetLanguageSNData,
  useerrorSNApi,
  usesaveSNArticles,
  usesetSaveSNArticles,
} from "../../hooks/UseApiContext/UseApiContext";
import { useMatomo } from "@datapunt/matomo-tracker-react/es/index";

//Components
import Header from "../HeaderComps";
import RouterComp from "../RouterComps";
import IconFooter from "../IconFooterComps";
import Loading from "../LoadingComps";
import ChatHeader from "../ChatHeaderComps";
import MobileHeader from "../MobileHeaderComps";
import OfflinePage from "../OfflinePageComps/OfflinePage";
import useChatLogin from "../../utils/utilsFunction";
import InstallApplication from "../InstallApplication";
import RightSupport from "../../Icons/RightSupportIcon";
import { LoginPageHeader } from "../../pages/login/StyledComponent";

//Icons

type MainProps = {
  notificationData: any;
  notificationOpen: any;
  userID: any;
  setUserID: any;
};

const Main: React.FC<MainProps> = ({
  notificationData,
  notificationOpen,
  userID,
  setUserID,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const divRef = useRef(null);
  const { instance, accounts } = useMsal();
  const { chatLogin, sNLogin } = useChatLogin();
  const [fetchHomeJSON, performFetchHomeJSON] = useApiAction(getSNJSON);
  const [fetchInboxJSON, performFetchInboxJSON] = useApiAction(getSNJSON);
  const [fetchLanguageDetailsJSON, performFetchLanguageDetailsJSON] =
    useApiAction(getSNJSON);
  const [postUpdateDiviceIDJSON, performUpdateDiviceIDJSON] =
    useApiAction(getSNJSON);
  const [fetchSNLoginJSON, performSNLoginJSON] = useApiAction(getSNJSON);

  const [fetchSaveArticleJSON, performSaveArticleJSON] =
    useApiAction(getSNJSON);
  const { enableLinkTracking, trackPageView } = useMatomo();
  const [notificationDeny, setNotificationDeny] = useState(false);
  const [oneSignalPopUp, setOneSignalPopUp] = useState(false);
  const checkLoginSN = localStorage.getItem("SNLogin");
  const [cookies, setCookie, removeCookie] = useCookies(["name"]);
  const [titleBar, setTitleBar] = useState();
  const [ref10ticket, setRef10Ticket] = useState(false);
  const [isMobile, serIsMobile] = useState("Desktop");
  const setInboxRecall = useSetInboxRecall();
  const inboxRecall = useInboxRecall();
  const homeRecall = useHomeRecall();
  const changeLanguage = useChangeLanguage();
  const homeSNData = usehomeSNData();
  const setHomeSNData = usesetHomeSNData();
  const inboxSNData = useinboxSNData();
  const ticketSysId = useTicketSysId();
  const setInboxSNData = usesetInboxSNData();
  const languageSNData = uselanguageSNData();
  const setLanguageSNData = usesetLanguageSNData();
  const displaySize = usedisplaySize();
  const setDisplaySize = usesetDisplaySize();
  const saveSNArticles = usesaveSNArticles();
  const inboxLoading = useInboxLoading();
  const setInboxLoading = useSetInboxLoading();
  const setTicketData = useSetTicketData();
  const setTicketSysId = useSetTicketSysId();
  const setSaveSNArticles = usesetSaveSNArticles();
  const refreshSavedArticle = useRefreshSavedArticle();
  const saved = useSaved();
  const errorSNApi = useerrorSNApi();
  let childWindow: Window | null = null;
  let code = errorSNApi && errorSNApi.status_code && errorSNApi.status_code;
  let checkTokenExpire = JSON.parse(
    localStorage.getItem(
      "server-telemetry-6a71e75d-b4e2-4cf3-a79e-56223bc5383f"
    )
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // entry.target is the observed element (in this case, your div)

        if (
          navigator &&
          navigator.windowControlsOverlay &&
          navigator.windowControlsOverlay.visible &&
          titleBar !== navigator.windowControlsOverlay.visible
        ) {
          setTitleBar(navigator.windowControlsOverlay.visible);
        }
      }
    });

    // Start observing the height changes on the div element
    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    // Cleanup: Disconnect the observer when the component is unmounted
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Empty dependency array ensures that the effect runs once after the initial render

  function SNLogin() {
    removeCookie("name", { path: "/" });
  }
  function SNChatLoding(props) {
    // window.electron.notificationApi.chatLoading(props);
  }

  function signOutClickHandler(instance) {
    const logoutRequest = {
      account: instance.getAccountByHomeId(config.clientId),
      mainWindowRedirectUri: config.redirectUri,
    };
    instance.logoutPopup(logoutRequest);
  }

  const handleLogout = (instance) => {
    navigate("/", { replace: true });
    signOutClickHandler(instance);
    localStorage.clear();
    SNLogin();
  };

  useEffect(() => {
    PullToRefresh.init({
      mainElement: "body",
      onRefresh() {
        window.location.reload();
      },
      iconArrow: renderToString(<FontAwesomeIcon icon={faSyncAlt} />),
      iconRefreshing: renderToString(
        <FontAwesomeIcon icon={faSyncAlt} spin={true} />
      ),
    });
    return () => {
      PullToRefresh.destroyAll();
    };
  }, []);

  useEffect(() => {
    const handleMessageFromServiceWorker = (event) => {
      if (event && event.data) {
        setTimeout(() => setOneSignalPopUp(event.data), 2000);
      }
    };

    navigator.serviceWorker.addEventListener(
      "message",
      handleMessageFromServiceWorker
    );

    // Clean up the event listener
    return () => {
      navigator.serviceWorker.removeEventListener(
        "message",
        handleMessageFromServiceWorker
      );
    };
  }, []);

  useEffect(() => {
    const allowButton = document.getElementById(
      "onesignal-slidedown-allow-button"
    );
    const denyButton = document.getElementById(
      "onesignal-slidedown-cancel-button"
    );
    const messageElement = document.querySelector(".slidedown-body-message");

    // Check if the element is found
    if (messageElement) {
      // Change the text content
      messageElement.textContent =
        languageSNData &&
        languageSNData["onesignal_notificationmessage_titletext"]
          ? languageSNData["onesignal_notificationmessage_titletext"]
          : "Allow the RightSupport Notification for a better user experience"; // Replace "New Message Text" with the desired text
    }

    // Check if the button element is found
    if (allowButton) {
      // Change the button text
      allowButton.textContent =
        languageSNData &&
        languageSNData["onesignal_notificationmessage_allowbutton"]
          ? languageSNData["onesignal_notificationmessage_allowbutton"]
          : "Allow"; // Replace "New Text" with the desired text
    }
    if (denyButton) {
      // Change the button text
      denyButton.textContent =
        languageSNData &&
        languageSNData["onesignal_notificationmessage_denybutton"]
          ? languageSNData["onesignal_notificationmessage_denybutton"]
          : "Deny"; // Set the button text; // Replace "New Text" with the desired text
    }

    const slidedownContainer = document.getElementById(
      "onesignal-slidedown-container"
    );

    // Check if the element is found
    if (slidedownContainer) {
      // Hide the element
      slidedownContainer.style.display =
        location.pathname === "/home" ? "" : "none";
    }
  }, [location, userID, oneSignalPopUp, notificationDeny]);

  useEffect(() => {
    const interval = setTimeout(() => {
      setNotificationDeny(true);
    }, 2000);
  }, []);

  //get recall data from SN

  useEffect(() => {
    let start_ticket = "inbox_start_position=0";
    let end_ticket = "inbox_end_position=-1";
    var Feild = ["Get", "urlInbox", start_ticket + "&" + end_ticket, {}];
    performFetchInboxJSON(Feild);
    var Feild3 = [
      "Get",
      "urlInbox",
      start_ticket +
        "&" +
        "inbox_end_position=1" +
        "&" +
        "inbox_search_term=sys_id=" +
        ticketSysId,
      {},
    ];
    ticketSysId !== "" && performFetchInboxJSON(Feild3);
  }, [inboxRecall]);

  useEffect(() => {
    let start_ticket = "inbox_start_position=0";
    let lenght10_ticket = "inbox_end_position=10";
    var Feild = ["Get", "urlInbox", start_ticket + "&" + lenght10_ticket, {}];
    performFetchInboxJSON(Feild);
    setInboxLoading(true);
  }, [ref10ticket]);

  useEffect(() => {
    if (
      homeSNData &&
      homeSNData["deactivatedFeatures"] &&
      homeSNData["deactivatedFeatures"]["preLoadChat"]
    ) {
      SNChatLoding(homeSNData["deactivatedFeatures"]["preLoadChat"]);
    }
  }, [homeSNData]);

  useEffect(() => {
    var Feild = ["Get", "urlHome", "", {}];
    performFetchHomeJSON(Feild);
  }, [homeRecall]);

  useEffect(() => {
    if (userID && userID !== "") {
      var sUsrAg = navigator.userAgent;
      var sBrowser;
      switch (true) {
        case sUsrAg.indexOf("Firefox") > -1:
          sBrowser = "Mozilla Firefox";
          break;
        case sUsrAg.indexOf("SamsungBrowser") > -1:
          sBrowser = "Samsung Internet";
          break;
        case sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1:
          sBrowser = "Opera";
          break;
        case sUsrAg.indexOf("Trident") > -1:
          sBrowser = "Microsoft Internet Explorer";
          break;
        case sUsrAg.indexOf("Edg") > -1:
          sBrowser = "Microsoft Edge";
          break;
        case sUsrAg.indexOf("Chrome") > -1:
          sBrowser = "Chrome";
          break;
        case sUsrAg.indexOf("Safari") > -1 && sUsrAg.indexOf("Chrome") === -1:
          sBrowser = "Apple Safari";
          break;
        default:
          sBrowser = "unknown";
          break;
      }
      var source = displaySize === "isMobile" ? "mobile" : "desktop";
      var browser = sBrowser;
      var Feild = [
        "Post",
        "urlUpdateDiviceID",
        "",
        { deviceID: userID, browser: browser, source: source },
      ];
      performUpdateDiviceIDJSON(Feild);
    }
  }, [userID]);
  useEffect(() => {
    if (window.innerWidth <= 480) {
      setDisplaySize("isMobile");
    }
    serIsMobile(
      navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
        ? "Mobile"
        : "Desktop"
    );
  }, []);

  enableLinkTracking();
  useEffect(() => {
    trackPageView();
  }, [window.location.href]);

  useEffect(() => {
    let homeData = JSON.parse(localStorage.getItem("homePageData"))
      ? JSON.parse(localStorage.getItem("homePageData"))
      : "";
    let LanguageData = JSON.parse(localStorage.getItem("LanguageData"))
      ? JSON.parse(localStorage.getItem("LanguageData"))
      : "";
    let inboxPageData = JSON.parse(localStorage.getItem("inboxPageData"))
      ? JSON.parse(localStorage.getItem("inboxPageData"))
      : "";
    setHomeSNData(homeData);
    inboxSNData === "" && setInboxSNData(inboxPageData);
    setLanguageSNData(LanguageData);
  }, [fetchInboxJSON.data, fetchLanguageDetailsJSON.data, fetchHomeJSON.data]);

  //get data from SN
  useEffect(() => {
    if (fetchHomeJSON.data && fetchHomeJSON.data["result"]) {
      localStorage.setItem(
        "homePageData",
        JSON.stringify(fetchHomeJSON.data["result"])
      );
    } else {
      var Feild = ["Get", "urlHome", "", {}];
      performFetchHomeJSON(Feild);
    }
  }, [fetchHomeJSON.data, homeSNData]);

  useEffect(() => {
    if (fetchInboxJSON.data && fetchInboxJSON.data["result"]) {
      let ticketCount =
        fetchInboxJSON.data["result"]["meta"] &&
        fetchInboxJSON.data["result"]["meta"]["totalTicketCount"] &&
        fetchInboxJSON.data["result"]["meta"]["totalTicketCount"];
      let totalTicketCount =
        fetchInboxJSON.data["result"]["Tickets"] &&
        fetchInboxJSON.data["result"]["Tickets"].length;
      ticketCount <= 10 && setInboxLoading(false);
      if (totalTicketCount == 1) {
        setTicketData(fetchInboxJSON.data["result"]);
      } else {
        setInboxSNData(fetchInboxJSON.data["result"]);
      }
      // setInboxSNData(fetchInboxJSON.data["result"]);
      if (totalTicketCount <= 10) {
        localStorage.setItem(
          "inboxPageData",
          JSON.stringify(fetchInboxJSON.data["result"])
        );
      }
      if (totalTicketCount > 10) {
        setInboxLoading(false);
        setTicketData({});
        setTicketSysId("");
      }
    }
  }, [fetchInboxJSON.data, inboxSNData]);

  useEffect(() => {
    if (
      fetchLanguageDetailsJSON.data &&
      fetchLanguageDetailsJSON.data["result"]
    ) {
      localStorage.setItem(
        "LanguageData",
        JSON.stringify(fetchLanguageDetailsJSON.data["result"])
      );
    } else {
      var Feild = [
        "Get",
        "urlLanguage",
        window.innerWidth <= 480 ? "device=mobile" : "device=desktop",
        {},
      ];
      performFetchLanguageDetailsJSON(Feild);
    }
  }, [fetchLanguageDetailsJSON.data, languageSNData]);

  //get change language data from SN

  useEffect(() => {
    var Feild = ["Get", "urlgetFavouriteArticle", "", {}];
    performSaveArticleJSON(Feild);
  }, [refreshSavedArticle]);

  useEffect(() => {
    if (fetchSaveArticleJSON && fetchSaveArticleJSON.data) {
      setSaveSNArticles(fetchSaveArticleJSON.data);
      localStorage.setItem(
        "savedArticle",
        JSON.stringify(fetchSaveArticleJSON.data)
      );
    }
  }, [fetchSaveArticleJSON.data]);

  function noificationAction(content, apiRefrsh, navigatePage) {
    if (apiRefrsh) {
      if (content["refreshapi"] === "refInbox") {
        setInboxRecall(!inboxRecall);
        // setRef10Ticket(!ref10ticket);
        setTicketSysId(content["sys_id"]);
      } else if (content["refreshapi"] === "refHome") {
        var FeildHome = ["Get", "urlHome", "", {}];
        performFetchHomeJSON(FeildHome);
      }
    }
    if (navigatePage) {
      if (content["openurl"] !== "null") {
        if (window.opener) {
          window.opener.focus();
          window.opener.location.href = config.redirectUri + content["openurl"];
        } else {
          navigate("/" + content["openurl"]);
        }
      }
    }
  }

  useEffect(() => {
    if (code >= 400) {
      navigate("/internalserver?error=" + code);
    }
  }, [errorSNApi]);
  useEffect(() => {
    if (
      notificationData !== undefined &&
      notificationData &&
      notificationData["additionalData"] &&
      notificationData["additionalData"]["content"] &&
      notificationData["additionalData"]["content"]["actiontype"] &&
      notificationData["additionalData"]["content"]["refreshapi"] &&
      notificationData["additionalData"]["content"]["openurl"]
    ) {
      if (
        notificationData["additionalData"]["content"]["actiontype"] ===
        "Clickable"
      ) {
        noificationAction(
          notificationData["additionalData"]["content"],
          true,
          false
        );
      } else if (
        notificationData["additionalData"]["content"]["actiontype"] === "Auto"
      ) {
        noificationAction(
          notificationData["additionalData"]["content"],
          true,
          true
        );
      } else if (
        notificationData["additionalData"]["content"]["actiontype"] ===
        "ClickwithAuto"
      ) {
        noificationAction(
          notificationData["additionalData"]["content"],
          true,
          false
        );
      }
    }
  }, [notificationData]);
  useEffect(() => {
    if (
      notificationOpen !== undefined &&
      notificationOpen &&
      notificationOpen["additionalData"] &&
      notificationOpen["additionalData"]["content"] &&
      notificationOpen["additionalData"]["content"]["actiontype"] &&
      notificationOpen["additionalData"]["content"]["refreshapi"] &&
      notificationOpen["additionalData"]["content"]["openurl"]
    ) {
      if (
        notificationOpen["additionalData"]["content"]["actiontype"] ===
        "Clickable"
      ) {
        noificationAction(
          notificationOpen["additionalData"]["content"],
          true,
          true
        );
      } else if (
        notificationOpen["additionalData"]["content"]["actiontype"] ===
        "ClickwithAuto"
      ) {
        noificationAction(
          notificationOpen["additionalData"]["content"],
          false,
          true
        );
      }
    }
  }, [notificationOpen]);

  if (!navigator.onLine) {
    return (
      <>
        <MainPageHeader>
          <Header />
        </MainPageHeader>
        <MainPageContainer>
          <OfflinePage />
          <IconFooter showBubbleIcon={false} />
        </MainPageContainer>
      </>
    );
  } else {
    return (
      <div>
        {isMobile === "Mobile" ? (
          <div>
            {languageSNData ? (
              homeSNData && languageSNData ? (
                <div>
                  <div style={{ height: "48px", background: "#EBEAF4" }}>
                    <Header />
                  </div>
                  <MobileMainPageContainer
                    style={{
                      height:
                        isMobile === "Desktop" ? "100%" : "calc(100% - 120px)",
                      background: "white",
                    }}
                  >
                    <RouterComp />
                  </MobileMainPageContainer>
                  {isMobile !== "Desktop" && (
                    <MainPageHeader
                      style={{
                        height: "72px",
                        background: "white",
                        paddingTop: "5px",
                      }}
                    >
                      {location.pathname !== "/internalserver" && (
                        <MobileHeader />
                      )}
                    </MainPageHeader>
                  )}
                </div>
              ) : code >= 400 ? (
                <RouterComp />
              ) : (
                <Loading />
              )
            ) : (
              <div
                style={{
                  background: "#F5F5F5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ width: "200px", height: "200px", display: "flex" }}
                >
                  <div className="welcome"></div>
                  <div className="welcome1"></div>
                  <div className="welcome2"></div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div ref={divRef}>
            {location.pathname === "/chat" ||
            location.pathname === "/healthcheck" ? (
              <div>
                {location.pathname === "/healthcheck" ? (
                  <LoginPageHeader>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          // width: "fit-content",
                          display: "flex",
                          width: "112px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="header-drag"
                      ></div>
                      <div
                        className="header-drag"
                        style={{
                          // width: "fit-content",
                          display: "flex",
                          width: "calc(100% - 224px)",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "120px", height: "27px" }}>
                          <RightSupport scale={0.9} altText="Maximize" />
                        </div>
                      </div>
                    </div>
                  </LoginPageHeader>
                ) : (
                  <MainPageHeader style={{ height: "35px" }}>
                    <ChatHeader />
                  </MainPageHeader>
                )}
                <MainPageContainer style={{ height: "calc(100% - 35px)" }}>
                  <RouterComp />
                  <IconFooter showBubbleIcon={true} />
                </MainPageContainer>
              </div>
            ) : languageSNData ? (
              homeSNData && languageSNData ? (
                <div>
                  <MainPageHeader>
                    <Header titleBar={titleBar} />
                  </MainPageHeader>
                  <MainPageContainer>
                    <RouterComp />
                    {location.pathname !== "/internalserver" && (
                      <IconFooter showBubbleIcon={true} />
                    )}
                  </MainPageContainer>
                </div>
              ) : code >= 400 ? (
                <RouterComp />
              ) : (
                <Loading />
              )
            ) : (
              <div
                style={{
                  background: "#F5F5F5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ width: "200px", height: "200px", display: "flex" }}
                >
                  <div className="welcome"></div>
                  <div className="welcome1"></div>
                  <div className="welcome2"></div>
                </div>
              </div>
            )}
          </div>
        )}
        <InstallApplication />
      </div>
    );
  }
};

export default Main;
