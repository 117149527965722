import styled from "styled-components";

type MessageProps={  
  toastMassage: any;
}

export const DownloadFile = styled.div`
  width: 330px;
  margin-right: 8px;
  .download {
    visibility: hidden;
    background-color: none;
  }
  .delete {
    visibility: hidden;
    background-color: none;
  }
  .download :hover {
    background: #d8d7df !important;
  }
  .delete :hover {
    background: #d8d7df !important;
  }
  :hover {
    background: #ebeaf4 !important;
    color: black !important;
    .download {
      visibility: visible;
    }
    .delete {
      visibility: visible;
    }
  }
  @media (max-width: 1062px) {
    margin-right: 4px;
  }
`;

export const Attachments = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TicketDetails = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  height: fit-content;
  padding-top: 10px;
  padding-bottom: 10px;
  width: calc(100% - 16px);
`;

export const ShowMenuContainer = styled.div`
  height: 17%;
  @media (min-height: 720px) {
    height: 6%;
  }
  @media (min-height: 920px) {
    height: 5%;
  }
`;

export const StatusContainer = styled.div`
  height: calc(100% - 97px);
  // @media (min-height: 720px) {
  //   height: 86%;
  // }
  // @media (min-height: 920px) {
  //   height: 89%;
  // }
`;

export const Subject = styled.div`
  white-space: pre-line;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SeeDetailContainer = styled.div`
  width: calc(50% - 66px);
`;

export const ViewCommentButton = styled.div`
  border-radius: 4px;
  :hover {
    background: #feedf3 !important;
  }
`;

export const ProgressBar = styled.div`
  width: 323px;
  height: 108px;
  @media (max-width: 1200px) {
    width: 151px;
    height: 201px;
  }
`;

export const MassageContainer = styled.div`
  width: fit-content;
  min-width: 306px;
  height: fit-content;
  min-height: 220px;
  position: absolute;
  background: #ffffff;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const MassageContainerText1 = styled.div`
  font-weight: 500;
  padding: 0px 10px;
  height: fit-content;
  width: fit-content;
  font-size: 28px;
  color: #0e090c;
`;

export const MassageContainerText2 = styled.div<MessageProps>`
  font-size: 14px;
  margin-top: 20px;
  width: ${ (props: MessageProps) => props.toastMassage? "230px" : "250px" };
  color: ${(props: MessageProps) => props.toastMassage? "#767675" : "#959199" };
  line-height: ${(props: MessageProps) => props.toastMassage? "" : "15.4px" };
  font-weight: ${(props: MessageProps) => props.toastMassage? "" : "400" };
`;

export const MassageContainerButton = styled.div`
  background-color: #755b98;
  height: 38px;
  color: #ffffff;
  font-size: 16px;
  border-radius: 3px;
  padding: 7px 15px;
  cursor: pointer;
  :hover {
    background: linear-gradient(90deg, #4a2878 0%, #755b98 100%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #4a2878;
  }
`;

export const View_more_or_less_text = styled.span`
  color: #DD296E;
  font-weight: 600;
  text-decoration: underline #DD296E 1.3px;
  cursor: pointer;
  padding: 0px 8px;
  padding-bottom: 4px;
  width: fit-content;
  border-radius: 4px;
  text-underline-offset: 1.5px;

  :hover{
    background: #feedf3 !important;
  }
`
