//Library
import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";
import {
  useHomeRecall,
  useSetHomeRecall,
  usedisplaySize,
} from "../../hooks/useMenuContext/useMenuContext";
import Close from "../../Icons/Close_icon/Close";
import Incedent from "../../Icons/Incedent_icon/Incedent";
import IncedentBlack from "../../Icons/IncidentBlack_icon";
import {
  Container,
  NotificationView,
  MessageContainer,
  IconContainer,
} from "./StyledComponent";

//Files & Functions

//Components

//Icons

type AlertMassageProps = {
  alertMassage: any;
};

const AlertMassage: React.FC<AlertMassageProps> = ({ alertMassage }) => {
  const homeRecall = useHomeRecall();
  const setHomeRecall = useSetHomeRecall();
  const languageSNData = uselanguageSNData();
  const displaySize = usedisplaySize();
  function massageClose(value) {
    async function DeleteMassage() {
      await axios.post(value).then((response) => console.log(response.data));
    }
    DeleteMassage();
    setHomeRecall(!homeRecall);
  }

  return (
    <Container>
      {alertMassage
        ? alertMassage.map((item, key) => (
            <NotificationView
              key={key}
              style={{
                background: item["Colour"] ? item["Colour"] : "black",
              }}
            >
              <IconContainer>
                <div
                  style={{
                    height: "fit-content",
                    width: "fit-content",
                    marginBottom: displaySize === "isMobile" && "4px",
                  }}
                >
                  {item["Importance"] === "system_down" ? (
                    <Incedent
                      scale={displaySize !== "isMobile" ? 0.7 : 0.67}
                      altText="Close"
                    />
                  ) : (
                    <IncedentBlack
                      scale={displaySize !== "isMobile" ? 0.7 : 0.67}
                      altText="BlackIncident"
                    />
                  )}
                </div>
              </IconContainer>
              <div
                style={{
                  width: "95%",
                  height: "auto",
                  maxHeight: "82px",
                  color: "#0E090C",
                  fontFamily: "inter",
                  display: "flex",
                  fontSize: "12px",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <MessageContainer
                  style={{
                    display:
                      displaySize === "isMobile" &&  (item["Message"] && item["Message"].length) > 30
                        ? "-webkit-box"
                        : "flex",
                  }}
                >
                  {item["Message"]}
                </MessageContainer>
                {item["ViewArticle"] === null ? (
                  ""
                ) : (
                  <Link
                    to={"/article?sys_id=" + item["ViewArticle"]}
                    style={{
                      color: "rgba(14, 9, 12, 0.8)",
                      width: "40px",
                      height: "auto",
                      maxHeight: "72px",
                      textDecoration: "none",
                      marginTop: "-4px",
                    }}
                  >
                    <div
                      className=""
                      style={{
                        width: "fit-content",
                        marginLeft: "7px",
                        height: "fit-content",
                        color: "rgba(14, 9, 12, 0.8)",
                        fontSize: "12px",
                        background: "none",
                        borderBottom: "1px solid",
                        fontWeight: "400",
                      }}
                    >
                      {languageSNData &&
                      languageSNData["altermessage_open_text"]
                        ? languageSNData["altermessage_open_text"]
                        : "Open"}
                    </div>
                  </Link>
                )}
              </div>
              <div
                style={{
                  width: "45px",
                  height: "auto",
                  maxHeight: "82px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => massageClose(item["APIAdress"])}
              >
                <div
                  style={{
                    padding: "8px",
                    opacity: "0.5",
                    height: "fit-content",
                    width: "fit-content",
                    marginTop: "0px",
                    cursor: "pointer",
                  }}
                >
                  <Close scale={0.6} altText="Close" />
                </div>
              </div>
            </NotificationView>
          ))
        : ""}
    </Container>
  );
};

export default AlertMassage;
