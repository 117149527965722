import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as icon } from './checkmark.svg';
import styled from "styled-components";
const Icon = styled(icon)`
  

`


type CheckMarkIconProps = {
  scale?: number;
  altText: string;
};

const CheckMarkIcon: React.FC<CheckMarkIconProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2* scale} height={2 * scale} aria-label={altText} >
      <Icon/>
    </IconWrapper>
  );
};

export default CheckMarkIcon;