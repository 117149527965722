import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  @media (min-height: 780px) {
    height: 93%;
  }
  @media (min-height: 920px) {
    height: 94%;
  }
`;

export const MobileContainer = styled.div`
  background: #ebeaf4;
  padding: 15px;
  height: 100%;
`;

export const Header = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  padding-left: 15px;
  align-items: center;
`;
export const HeaderText = styled.div`
  font-size: 28px;
  line-height: 36px;
  fontweight: 400;
  margin-left: 15px;
`;
export const IframContainer = styled.div`
border-radius: 5px;
margin-top: 15px;
height: calc(100% - 70px);
border
`;
