import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as alert } from './alert.svg';
import styled from "styled-components";
const Alert = styled(alert)`
  
`

type AlertLogoProps = {
  scale?: number;
  altText: string;
};

const Attachment: React.FC<AlertLogoProps> = ({ scale = 1, altText }) => {
  
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      <Alert/>
    </IconWrapper>
  );
};

export default Attachment;