// @ts-nocheck
//Library
import React, { useEffect, useState, useRef } from "react";
import ReactTextareaAutosize from "react-textarea-autosize";
import { getSNJSON } from "../../api";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";
import {
  useInboxRecall,
  useSetInboxRecall,
  usedisplaySize,
} from "../../hooks/useMenuContext/useMenuContext";
import Close from "../../Icons/Header_close_icon/Close";
import TicketProgressBar from "../TicketProgressBarComps";

//Files & Functions

//Components
import {
  MassageContainerButton1,
  MassageContainer,
  MassageContainerText1,
  MassageContainerText2,
  MassageContainerButton,
  ProgressBar,
} from "./StyledComponent";
import PopUpWindow from "../PopUpWindowComps";
//Icons

type ReopenProps = {
  ticket: any;
  sidebar: any;
};

const Reopen: React.FC<ReopenProps> = ({ ticket, sidebar }) => {
  const [toastMassage, setToastMassage] = useState(false);
  const displaySize = usedisplaySize();
  const inboxRecall = useInboxRecall();
  const setInboxRecall = useSetInboxRecall();
  const [fetchReOpenTicketJSON, performReOpenTicketJSON] =
    useApiAction(getSNJSON);
  const languageSNData = uselanguageSNData();
  const [comment, setComment] = useState("");

  const inputTextHandler = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    if (fetchReOpenTicketJSON.data) {
      setInboxRecall(!inboxRecall);
      setToastMassage(false);
      setComment("");
    }
  }, [fetchReOpenTicketJSON.data]);
  function showToastMassage(value) {
    setToastMassage(value);
  }
  useEffect(() => {
    let sys_id = ticket["Sys ID"];
    var FeildReOpenTicket = [
      "Put",
      "urlReOpenTicket",
      "",
      { sys_id: sys_id, comments: comment },
    ];
    comment !== "" && performReOpenTicketJSON(FeildReOpenTicket);
  }, [comment]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: toastMassage ? "" : "none",
          background: "black",
          opacity: "25%",
          position: "absolute",
          bottom: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
        }}
        onClick={() => showToastMassage(false)}
      ></div>
      {toastMassage && (
        <PopUpWindow
          visible={toastMassage}
          setvisible={setToastMassage}
          from={"reOpen"}
          editNumber={comment}
          setEditNumber={setComment}
        />
      )}
      <div
        style={{
          width: "323px",
          height: sidebar ? "fit-content" : "fit-content",
        }}
      >
        <div
          style={{
            height: "67px",
          }}
        >
          <div
            className="FontInter"
            style={{
              textAlign: "center",
              height: "20px",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "17px",
              color: "#49484D",
              fontStyle: "normal",
            }}
          >
            {languageSNData && languageSNData["inbox_reopentext_text"]
              ? languageSNData["inbox_reopentext_text"]
              : "Reopen the ticket to add a comment"}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "13px",
            }}
          >
            <MassageContainerButton
              className="FontInter"
              onClick={() => setToastMassage(true)}
              style={{
                fontWeight: "500",
                width: "fit-content",
                color: "#FFFFFF",
                fontSize: "14px",
                height: "34px",
                borderRadius: "3px",
                padding: "7px 15px",
                marginLeft: "16px",
              }}
            >
              {languageSNData && languageSNData["inbox_reopentext_button"]
                ? languageSNData["inbox_reopentext_button"]
                : "Reopen ticket"}
            </MassageContainerButton>
          </div>
        </div>
        {sidebar ? (
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProgressBar
              style={
                sidebar
                  ? {}
                  : {
                      width: "323px",
                      height: "108px",
                    }
              }
            >
              <TicketProgressBar ticket={ticket} sidebar={sidebar} />
            </ProgressBar>
          </div>
        ) : (
          <div style={{ marginTop: "18px" }}>
            <TicketProgressBar ticket={ticket} sidebar={sidebar} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Reopen;
