//Library
import React, { useEffect, useState, useRef } from "react";
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";
import CommentNotSupport from "../../Icons/CommentNotSupport";
import TicketProgressBar from "../TicketProgressBarComps";

//Files & Functions
import { ProgressBar } from "./StyledComponent";

//Components

//Icons

type TicketprogressProps = {
  ticket: any;
  sidebar: any;
};

const Ticketprogress: React.FC<TicketprogressProps> = ({ ticket, sidebar }) => {
  const languageSNData = uselanguageSNData();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "324px",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "190px",
            marginLeft: "67px",
            height: "fit-content",
          }}
        >
          <p
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#8898A9",
              textAlign: "center",
            }}
          >
            {languageSNData
              ? languageSNData["inbox_nocommentsupporttitle_text"]
              : ""}
          </p>
          <CommentNotSupport scale={8} altText={"CommentNotSupport"} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ProgressBar
            style={
              sidebar
                ? {}
                : {
                    width: "323px",
                    height: "108px",
                  }
            }
          >
            <TicketProgressBar ticket={ticket} sidebar={sidebar} />
          </ProgressBar>
        </div>
        {/* {ticket["State"] !== "New" && sidebar ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ProgressBar
              style={{
                width: sidebar ? "300px" : "400px",
                border: "1px solid red",
              }}
            >
              <TicketProgressBar ticket={ticket} sidebar={sidebar} />
            </ProgressBar>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ProgressBar>
              <TicketProgressBar ticket={ticket} sidebar={sidebar} />
            </ProgressBar>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Ticketprogress;
