import styled from "styled-components";

export const Articles = styled.div`
  width: calc(100% - 3px);
  height: 130px;
  margin: 5px 0px 5px 2px;
  border-radius: 3px;
  padding-top: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.20);
  padding-bottom: 5px;
  .showButton {
    visibility: hidden;
  }
  :hover {
    background: #ebeaf4 !important;
    color: black !important;
    .showButton {
      visibility: visible;
    }
  }
  @media (max-width: 480px) {
    padding-top: 0px;
    box-shadow: none;
    :hover{
      background: none !important;
      color: none !important;
    }
`;
export const SearchData = styled.div`
    overflow-y: searchdata["data"] ? "scroll" : "-moz-hidden-unscrollable";
    height: 100%;

    justify-content: space-between;
    ::-webkit-scrollbar {
        width: 4px;
      }
      @media (max-width: 480px) {
    justify-content: space-between;
    ::-webkit-scrollbar {
      display: none;
      }
      }
`;

export const MainContainer = styled.div`
  width: 700px;
  height: 90%;
  zindex: 1;
  display: flex;
  justify-content: space-between;
  margin-left: 200px;
  .card-container {
    width: 240px;
    height: 268px;
    text-decoration: none;
    display: flex;
    border-radius: 3px;
    padding: 16px;
    margin-bottom: 10px;
    background: #ffffff;
  }

  .card-icon-container {
    width: 20%;
  }

  .card-value-continer {
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  .card-title {
    height: 60px;
    color: #49484d;
    font-size: 14px;
    font-weight: 600;
  }

  @media (min-width: 1100px) {
    width: 800px;
    height: 91%;
    margin-left: 195px;

    .card-container {
      width: 290px;
      height: 210px;
    }

    .card-title {
      height: 38px;
    }

    .card-icon-container {
      width: 15%;
    }

    .card-value-continer {
      width: 85%;
    }
  }

  @media (min-width: 1400px) {
    width: 1000px;
    height: 91%;
    margin-left: 300px;

    .card-container {
      width: 340px;
      height: 185px;
    }
    .card-icon-container {
      width: 10%;
    }
    .card-value-continer {
      width: 90%;
    }
  }
  @media (max-width: 480px) {
    justify-content: flex-start;
    align-items: flex-start;
    background: #ebeaf4;
    width: 100%;
    height: 100%;
    zindex: 1;
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: 0px;
  }
`;
export const SearchBarAndArticle = styled.div`
  width: 500px;
  .article-list-container {
    height: 90%;
    padding-top: 16px;
  }

  .show-footer {
    width: 600px;
    display: flex;
    align-items: center;
    background: white;
  }

  @media (min-width: 1100px) {
    width: 600px;
  }
  @media (min-width: 1400px) {
    width: 700px;
    .article-list-container {
      height: 93%;
    }
    .show-footer {
      display: none;
    }
  }
`;
export const SerachPageToggle = styled.div`
  width: 180px;

  .toggle-button {
    display: flex;
    height: fit-content;
  }

  @media (min-width: 1100px) {
    width: 160px;
  }

  @media (min-width: 1400px) {
    width: 250px;
  }
`;

export const ActionButton = styled.div`
  display: none;
  margin-top: 30px;

  @media (min-width: 1400px) {
    display: block;
  }
`;
