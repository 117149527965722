import styled, { StyledComponent } from "styled-components";
interface MassageContainerProps {
  versionUpdate?: boolean;
}

export const Container = styled.div`
  width: 500px;
  height: 252px;
  text-align: center;
`;

export const MassageContainer: StyledComponent<
  "div",
  any,
  MassageContainerProps
> = styled.div.attrs((props: MassageContainerProps) => ({
  versionUpdate: props.versionUpdate || false,
}))`
  width: 384px;
  display: inline-flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  min-height: 272px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  @media (max-width: 480px) {
    margin: 5%;
    width: 90%;
    border-radius: 10px;
    height: ${({ versionUpdate }) => (versionUpdate ? "fit-content" : "100px")};
  }
`;

export const SetIcon = styled.div`
  height: 20%;
  width: 30%;
  position: absolute;
  top: 17%;
  right: 17%;
  font-size: 20px;
`;

export const MassageContainerText1 = styled.div`
  color: var(--black, #0e090c);
  text-align: center;
  /* Display/Header - M */
  font-family: Roboto Slab;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  height: 36px;
  line-height: 36px;
`;

export const MassageContainerText2 = styled.div`
  width: 220px;
  color: var(--grey-medium, #959199);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
`;

export const MassageContainerButton1 = styled.div`
  // background-color: #755b98;
  color: #675b98;
  font-size: 16px;
  border-radius: 3px;
  padding: 7px 15px;
  :hover {
    background: linear-gradient(90deg, #4a2878 0%, #755b98 100%);
    color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #4a2878;
  }
`;

export const HomeButton = styled.div`
  height: 35px;
  width: fit-content;
  margin-top: 30px;
  background-color: #dd296e;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  display: flex;
  :hover {
    background: linear-gradient(90deg, #cf022b 0%, #ea5599 100%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #d10726;
  }
`;

type ButtonProps = {
  color: any;
  hoverColor: any;
  backgroundColor: any;
};

export const MassageContainerButton = styled.div<ButtonProps>`
  background-color: ${(props: ButtonProps) => props.backgroundColor};
  height: 38px;
  min-width: 103px;
  text-align: center;
  color: ${(props: ButtonProps) => props.color};
  font-size: 16px;
  border-radius: 3px;
  padding: 7px 15px;
  cursor: pointer;
  :hover {
    background: ${(props: ButtonProps) => props.hoverColor};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #4a2878;
  }
`;
export const SetSopraIcon = styled.div`
  height: 40px;
  width: 150px;
  position: absolute;
  bottom: 6px;
  left: 25px;
  font-size: 20px;
`;
export const ProgressBar = styled.div`
  width: 323px;
  height: 108px;
  @media (max-width: 1200px) {
    width: 151px;
    height: 201px;
  }
`;

export const ArticleHeading = styled.div`
  margin-left: 4px;
  color: var(--grey-dark, #49484d);
  font-family: Inter;
  font-size: 12px;
  width: calc(100% - 24px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
`;
export const CloseButton: StyledComponent<
  "div",
  any,
  MassageContainerProps
> = styled.div.attrs((props: MassageContainerProps) => ({
  versionUpdate: props.versionUpdate || false,
}))`
  height: 30px;
  padding: 3px;
  display: flex;
  justify-content: end;
  @media (max-width: 480px) {
    height: ${({ versionUpdate }) => (versionUpdate ? "fit-content" : "30px")};
  }
`;
// export const Buttonstyle= styled.button<ButtonProps>`
//     background-color:${(props: ButtonProps) => props.colorbackground};
//     border: ${(props: ButtonProps) => props.border};
//     color: ${(props: ButtonProps) => props.color};
//     font-size: ${(props: ButtonProps) => props.size};
//     border-radius: ${(props: ButtonProps) => props.radius};
//     display: ${(props: ButtonProps) => props.display}
// `;
