// @ts-nocheck
//Library
import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Files & Functions
import { HeaderContainer, ContentLeft, ContentRight } from "./StyledComponent";

//Components

//Icons
import Close from "../../Icons/Header_close_icon/Close";
import Minimize from "../../Icons/Minimize_icon/Minimize";
import Chat from "../../Icons/Footer-icons/chat-icon";
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";
import { Tooltip } from "react-tooltip";

type ChatHeaderProps = {};

const ChatHeader: React.FC<ChatHeaderProps> = ({}) => {
  const languageSNData = uselanguageSNData();

  return (
    <HeaderContainer>
      <ContentLeft className="header-drag">
        <Chat scale={0.8} altText="Minimize" color="pink" />
        <p
          className="header-drag"
          style={{
            width: "100%",
            height: "100%",
            color: "#DD296E",
            padding: "5px 0px 0px 10px",
            fontSize: "18px",
            letterSpacing: "-0.02em",
            font: "inherit",
            fontWeight: "600",
            fontStyle: "normal",
          }}
        >
          {languageSNData ? languageSNData["chatheader_headertitle_text"] : ""}
        </p>
      </ContentLeft>
      {/* <ContentRight>
        <Tooltip
          id="my-tooltip"
          place="bottom"
          effect="solid"
          style={{
            height: "23px",
            zIndex: "5",
            background: "#49484D",
            borderRadius: "4px",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            padding: "4px 8px",
          }}
        />
        <div style={{ width: "113px", height: "33px", display: "flex" }}>
          <div
            className="back-button-hover-chatheader"
            data-tooltip-id="my-tooltip"
            data-tooltip-content={
              languageSNData &&
              languageSNData["header_minimizeicontooltip_text"]
                ? languageSNData["header_minimizeicontooltip_text"]
                : "Minimize"
            }
            data-tooltip-offset={4}
            onClick={() => {
              // window.electron.notificationApi.childMinimize("childMinimize");
            }}
            style={{
              width: "37px",
              height: "33px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Minimize scale={0.4} altText="Minimize" color={"white"} />
          </div>
          <div
            className="back-button-hover-chatheader"
            data-tooltip-id="my-tooltip"
            data-tooltip-content={
              languageSNData && languageSNData["header_closeicontooltip_text"]
                ? languageSNData["header_closeicontooltip_text"]
                : "Close"
            }
            data-tooltip-offset={4}
            onClick={() => {
              // window.electron.notificationApi.chileClose("chileClose");
            }}
            style={{
              width: "37px",
              height: "33px",
              display: "flex",
              justifyContent: "center",
              padding: "2px 0px 0px 0px",
            }}
          >
            <Close scale={0.6} altText="Close" color={"white"} />
          </div>
        </div>
      </ContentRight> */}
    </HeaderContainer>
  );
};

export default ChatHeader;
