import styled from "styled-components";

export const BubbleButton = styled.div`
  height: 59px;
  width: 59px;
  border-radius: 100px;
  padding: 11px 17px;
  background-color: #DD296E;
  cursor: pointer;
    @media (min-height: 780px) {
      top: 90%;
      left: 94.8%;
    }
    @media (min-height: 920px) {
      top: 92%;
      left: 95%;
    }
    :hover {
      background: linear-gradient(90deg, #CF022B 0%, #EA5599 100%);    
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      }
      :active{
      background: #D10726;
      }
`;