//Library
import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//Files & Functions
import { Menucontainer, Container, NavigateButton } from "./StyledComponent";
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";
import SubmitTicket from "../../Icons/SubmitTicketIcon";
import {
  useSetInputText,
  usedisplaySize,
} from "../../hooks/useMenuContext/useMenuContext";

//Components

//Icons
import Back from "../../Icons/Back_icon/Back";
import Ticket from "../../Icons/Footer-icons/ticket-icon";
import Home from "../../Icons/Home_icon";

type TicketStatusProps = {};

const TicketStatus: React.FC<TicketStatusProps> = ({}) => {
  const navigate = useNavigate();
  const languageSNData = uselanguageSNData();
  const setInputText = useSetInputText();
  const location = useLocation();
  const displaySize = usedisplaySize();

  return (
    <Menucontainer>
      <Container>
        {/* {displaySize === "isMobile" && (
          <button
            className="back-button-hover"
            onClick={() => {
              navigate(-1);
            }}
            style={{
              border: "none",
              width: "fit-content",
              height: "30px",
              position: "absolute",
              top: displaySize !== "isMobile" ? "10%" : "40px",
              left: displaySize !== "isMobile" ? "40px" : "20px",
              display: "flex",
              borderRadius: "3px",
              textAlign: "left",
              background: displaySize !== "isMobile" ? "none" : "#EBEAF4",
            }}
          >
            <Back scale={0.7} altText="Back" type="" />
          </button>
        )} */}
        {displaySize !== "isMobile" && (
          <div
            style={{
              width: "100%",
              height: "50%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <SubmitTicket scale={12} altText="attachment" />
            </div>
          </div>
        )}
        <div
          style={{
            width: "100%",
            height: displaySize !== "isMobile" ? "80px" : "auto",
            maxHeight: displaySize !== "isMobile" ? "" : "160px",
            marginTop: "20px"
          }}
        >
          <div
            className="FontRoboto"
            style={{
              width: "100%",
              height: displaySize !== "isMobile" ? "52px" : "auto",
              maxHeight: displaySize !== "isMobile" ? "" : "104px",
              fontSize: "36px",
              lineHeight: "52px",
              fontWeight: displaySize !== "isMobile" ? "500" : "400px",
              color: "#0E090C",
              textAlign: displaySize !== "isMobile" ? "center" : "left",
            }}
          >
            {languageSNData
              ? location.pathname === "/requeststatus"
                ? languageSNData["requeststatus_heading_text"]
                  ? languageSNData["requeststatus_heading_text"]
                  : ""
                : languageSNData["ticketstatus_heading_text"]
              : ""}
          </div>
          <div
            style={{
              width: "100%",
              height: displaySize !== "isMobile" ? "28px" : "auto",
              maxHeight: displaySize !== "isMobile" ? "" : "60px",
              fontSize: "20px",
              fontFamily: "revert-layer",
              fontWeight: displaySize !== "isMobile" ? "500" : "400px",
              color: "#4A2878",
              textAlign: displaySize !== "isMobile" ? "center" : "left",
            }}
          >
            {languageSNData
              ? location.pathname === "/requeststatus"
                ? languageSNData["requeststatus_responsetitle_text"]
                  ? languageSNData["requeststatus_responsetitle_text"]
                  : ""
                : languageSNData["ticketstatus_responsetitle_text"]
              : ""}
          </div>
        </div>
        <div
          style={{
            height: displaySize !== "isMobile" ? "38px" : "fit-content",
            marginTop: displaySize !== "isMobile" ? "10px" : "32px",
            display: "flex",
            flexDirection: displaySize === "isMobile" ? "column" : "row",
            justifyContent: displaySize !== "isMobile" ? "center" : "normal",
            alignItems: "center",
          }}
        >
          <NavigateButton
            onClick={() => navigate(`/inbox?Number=${location.search.split("?")[1]}`)}
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <div
              style={{
                width: "fit-content",
                height: "24px",
                alignItems: 'center',
              }}
            >
              <Ticket scale={0.53} altText="attachment" color="white" />
            </div>
            <div
              style={{
                width: "fit-content",
                height: "24px",
                marginLeft: "4px",
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {languageSNData
                ? location.pathname === "/requeststatus"
                  ? languageSNData["requeststatus_inbox_gotoinbox"]
                    ? languageSNData["requeststatus_inbox_gotoinbox"]
                    : ""
                  : languageSNData["ticketstatus_inbox_gotoinbox"]
                : ""}
            </div>
          </NavigateButton>
          <NavigateButton
            onClick={() => {
              navigate("/");
              setInputText("");
            }}
            style={{
              marginLeft: displaySize !== "isMobile" ? "16px" : "0px",
              marginTop: displaySize !== "isMobile" ? "0px" : "32px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <div
               style={{
                width: "fit-content",
                height: "24px",
              }}
            >
              <Home scale={displaySize !== "isMobile" ? 0.61 : 0.58} altText="attachment" color="white" />
            </div>
            <div
              style={{
                width: "fit-content",
                height: "24px",
                marginLeft: "4px",
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {languageSNData
                ? location.pathname === "/requeststatus"
                  ? languageSNData["requeststatus_home_gotohome"]
                    ? languageSNData["requeststatus_home_gotohome"]
                    : ""
                  : languageSNData["ticketstatus_home_gotohome"]
                : ""}
            </div>
          </NavigateButton>
        </div>
        {displaySize !== "isMobile" && (
          <div
            style={{
              width: "100%",
              height: "19px",
              marginTop: "16px",
              color: "#959199",
              fontSize: "16px",
              lineHeight: "19px",
            }}
          >
            {languageSNData
              ? location.pathname === "/requeststatus"
                ? languageSNData["requeststatus_Updatesvisibleinbox_text"]
                  ? languageSNData["requeststatus_Updatesvisibleinbox_text"]
                  : ""
                : languageSNData["ticketstatus_Updatesvisibleinbox_text"]
              : "Updates will be visible in your inbox"}
          </div>
        )}
        {displaySize === "isMobile" ? (
          <div
            style={{
              width: "fit-content",
              height: "fit-content",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: "32px",
            }}
          >
            <div
              style={{
                width: "329px",
                height: "242px",
              }}
            >
              <SubmitTicket scale={12} altText="attachment" />
            </div>
          </div>
        ) : null}
      </Container>
    </Menucontainer>
  );
};

export default TicketStatus;
