import { useState } from "react";
import constate from "constate";

function useMenu() {
  const [navigationToggle, setNavigationToggle] = useState(false);
  const [languageNavigationToggle, setLanguageNavigationToggle] =
    useState(true);
  const [inboxRecall, setInboxRecall] = useState(true);
  const [homeRecall, setHomeRecall] = useState(true);
  const [languageData, setLanguageData] = useState({});
  const [changeLanguage, setChangeLanguage] = useState(true);
  const [bubbleShow, setBubbleShow] = useState(true);
  const [page, setPage] = useState("");
  const [inboxpage, setInboxpage] = useState("");
  const [animation, setAnimation] = useState(true);
  const [inputText, setInputText] = useState("");
  const [content, setContent] = useState("blank");
  const [fullArticleContent, setFullArticleContent] = useState("");
  const [listening, setListening] = useState(true);
  const [aboutSection, setAboutSection] = useState(false);
  const [appScreenSize, setAppScreenSize] = useState("");
  const [chatScreenHide, setChatScreenHide] = useState(false);
  const [inputStates, setInputStates] = useState({});
  const [attachmentmanage, setAttachmentmanage] = useState([]);
  const [displaySize, setDisplaySize] = useState("");
  const [saved, setSaved] = useState(false);
  const [inboxLoading, setInboxLoading] = useState(true);
  const [mobileNavBar, setMobileNavbar] = useState(false);
  const [ticketSysId, setTicketSysId] = useState("");
  const [ticketData, setTicketData] = useState({});
  const [openChatWindow, setOpenChatWindow] = useState(null);
  const [notsupportAttachment, setNotsupportAttachment] = useState("");
  const [openSnLoginWindow, setOpenSnLoginWindow] = useState(null);
  const [snLoginWindow, setSnLoginWindow] = useState(true);
  const [userNotificationId, setUserNotificationId] = useState("");
  const [refreshSavedArticle, setRefreshSavedArticle] = useState(false);
  return {
    navigationToggle: navigationToggle,
    setNavigationToggle: setNavigationToggle,
    languageNavigationToggle: languageNavigationToggle,
    setLanguageNavigationToggle: setLanguageNavigationToggle,
    inboxRecall: inboxRecall,
    setInboxRecall: setInboxRecall,
    homeRecall: homeRecall,
    setHomeRecall: setHomeRecall,
    languageData: languageData,
    setLanguageData: setLanguageData,
    changeLanguage: changeLanguage,
    setChangeLanguage: setChangeLanguage,
    bubbleShow: bubbleShow,
    setBubbleShow: setBubbleShow,
    page: page,
    setPage: setPage,
    inboxpage: inboxpage,
    setInboxpage: setInboxpage,
    animation: animation,
    setAnimation: setAnimation,
    inputText: inputText,
    setInputText: setInputText,
    content: content,
    setContent: setContent,
    fullArticleContent: fullArticleContent,
    setFullArticleContent: setFullArticleContent,
    listening: listening,
    setListening: setListening,
    aboutSection: aboutSection,
    setAboutSection: setAboutSection,
    appScreenSize: appScreenSize,
    setAppScreenSize: setAppScreenSize,
    chatScreenHide: chatScreenHide,
    setChatScreenHide: setChatScreenHide,
    inputStates: inputStates,
    setInputStates: setInputStates,
    attachmentmanage: attachmentmanage,
    setAttachmentmanage: setAttachmentmanage,
    displaySize: displaySize,
    setDisplaySize: setDisplaySize,
    userNotificationId: userNotificationId,
    setUserNotificationId: setUserNotificationId,
    saved: saved,
    setSaved: setSaved,
    inboxLoading: inboxLoading,
    setInboxLoading: setInboxLoading,
    mobileNavBar: mobileNavBar,
    setMobileNavbar: setMobileNavbar,
    ticketSysId: ticketSysId,
    setTicketSysId: setTicketSysId,
    ticketData: ticketData,
    setTicketData: setTicketData,
    openChatWindow: openChatWindow,
    setOpenChatWindow: setOpenChatWindow,
    notsupportAttachment: notsupportAttachment,
    setNotsupportAttachment: setNotsupportAttachment,
    openSnLoginWindow: openSnLoginWindow,
    setOpenSnLoginWindow: setOpenSnLoginWindow,
    snLoginWindow: snLoginWindow,
    setSnLoginWindow: setSnLoginWindow,
    refreshSavedArticle: refreshSavedArticle,
    setRefreshSavedArticle: setRefreshSavedArticle,
  };
}

const [
  MenuProvider,
  useNavigationToggle,
  useSetNavigationToggle,
  useLanguageNavigationToggle,
  useSetLanguageNavigationToggle,
  useInboxRecall,
  useSetInboxRecall,
  useHomeRecall,
  useSetHomeRecall,
  useLanguageData,
  useSetLanguageData,
  useChangeLanguage,
  useSetChangeLanguage,
  useBubbleShow,
  useSetBubbleShow,
  usePage,
  useSetPage,
  useInboxpage,
  useSetInboxpage,
  useAnimation,
  useSetAnimation,
  useInputText,
  useSetInputText,
  useContent,
  useSetContent,
  useFullArticleContent,
  useSetFullArticleContent,
  useListening,
  useSetListening,
  useAboutSection,
  useSetAboutSection,
  useAppScreenSize,
  useSetAppScreenSize,
  useChatScreenHide,
  useSetChatScreenHide,
  useInputStates,
  useSetInputStates,
  useAttachmentmanage,
  useSetAttachmentmanage,
  usedisplaySize,
  usesetDisplaySize,
  useUserNotificationId,
  useSetUserNotificationId,
  useSaved,
  useSetSaved,
  useInboxLoading,
  useSetInboxLoading,
  useMobileNavBar,
  useSetMobileNavBar,
  useTicketSysId,
  useSetTicketSysId,
  useTicketData,
  useSetTicketData,
  useOpenChatWindow,
  useSetOpenChatWindow,
  useNotsupportAttachment,
  useSetNotsupportAttachment,
  useOpenSnLoginWindow,
  useSetOpenSnLoginWindow,
  useSnLoginWindow,
  useSetSnLoginWindow,
  useRefreshSavedArticle,
  useSetRefreshSavedArticle,
] = constate(
  useMenu,
  (value) => value.navigationToggle,
  (value) => value.setNavigationToggle,
  (value) => value.languageNavigationToggle,
  (value) => value.setLanguageNavigationToggle,
  (value) => value.inboxRecall,
  (value) => value.setInboxRecall,
  (value) => value.homeRecall,
  (value) => value.setHomeRecall,
  (value) => value.languageData,
  (value) => value.setLanguageData,
  (value) => value.changeLanguage,
  (value) => value.setChangeLanguage,
  (value) => value.bubbleShow,
  (value) => value.setBubbleShow,
  (value) => value.page,
  (value) => value.setPage,
  (value) => value.inboxpage,
  (value) => value.setInboxpage,
  (value) => value.animation,
  (value) => value.setAnimation,
  (value) => value.inputText,
  (value) => value.setInputText,
  (value) => value.content,
  (value) => value.setContent,
  (value) => value.fullArticleContent,
  (value) => value.setFullArticleContent,
  (value) => value.listening,
  (value) => value.setListening,
  (value) => value.aboutSection,
  (value) => value.setAboutSection,
  (value) => value.appScreenSize,
  (value) => value.setAppScreenSize,
  (value) => value.chatScreenHide,
  (value) => value.setChatScreenHide,
  (value) => value.inputStates,
  (value) => value.setInputStates,
  (value) => value.attachmentmanage,
  (value) => value.setAttachmentmanage,
  (value) => value.displaySize,
  (value) => value.setDisplaySize,
  (value) => value.userNotificationId,
  (value) => value.setUserNotificationId,
  (value) => value.saved,
  (value) => value.setSaved,
  (value) => value.inboxLoading,
  (value) => value.setInboxLoading,
  (value) => value.mobileNavBar,
  (value) => value.setMobileNavbar,
  (value) => value.ticketSysId,
  (value) => value.setTicketSysId,
  (value) => value.ticketData,
  (value) => value.setTicketData,
  (value) => value.openChatWindow,
  (value) => value.setOpenChatWindow,
  (value) => value.notsupportAttachment,
  (value) => value.setNotsupportAttachment,
  (value) => value.openSnLoginWindow,
  (value) => value.setOpenSnLoginWindow,
  (value) => value.snLoginWindow,
  (value) => value.setSnLoginWindow,
  (value) => value.refreshSavedArticle,
  (value) => value.setRefreshSavedArticle
);

export {
  MenuProvider,
  useNavigationToggle,
  useSetNavigationToggle,
  useLanguageNavigationToggle,
  useSetLanguageNavigationToggle,
  useInboxRecall,
  useSetInboxRecall,
  useHomeRecall,
  useSetHomeRecall,
  useLanguageData,
  useSetLanguageData,
  useChangeLanguage,
  useSetChangeLanguage,
  useBubbleShow,
  useSetBubbleShow,
  usePage,
  useSetPage,
  useInboxpage,
  useSetInboxpage,
  useAnimation,
  useSetAnimation,
  useInputText,
  useSetInputText,
  useContent,
  useSetContent,
  useFullArticleContent,
  useSetFullArticleContent,
  useListening,
  useSetListening,
  useAboutSection,
  useSetAboutSection,
  useAppScreenSize,
  useSetAppScreenSize,
  useChatScreenHide,
  useSetChatScreenHide,
  useInputStates,
  useSetInputStates,
  useAttachmentmanage,
  useSetAttachmentmanage,
  usedisplaySize,
  usesetDisplaySize,
  useUserNotificationId,
  useSetUserNotificationId,
  useSaved,
  useSetSaved,
  useInboxLoading,
  useSetInboxLoading,
  useMobileNavBar,
  useSetMobileNavBar,
  useTicketSysId,
  useSetTicketSysId,
  useTicketData,
  useSetTicketData,
  useOpenChatWindow,
  useSetOpenChatWindow,
  useNotsupportAttachment,
  useSetNotsupportAttachment,
  useOpenSnLoginWindow,
  useSetOpenSnLoginWindow,
  useSnLoginWindow,
  useSetSnLoginWindow,
  useRefreshSavedArticle,
  useSetRefreshSavedArticle,
};
