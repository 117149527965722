import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as close } from './dismiss.svg';
import { ReactComponent as dismisswhite } from './dismisswhite.svg';
import styled from "styled-components";
const CloseIcon = styled(close)`
  
`
const WhiteCloseIcon = styled(dismisswhite)`
  
`

type CloseLogoProps = {
  scale?: number;
  altText: string;
  color:string;
};

const Close: React.FC<CloseLogoProps> = ({ scale = 1, altText, color }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} >
      {color==="white"?
      <WhiteCloseIcon/>:
      <CloseIcon/>}
    </IconWrapper>
  );
};

export default Close;