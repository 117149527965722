//Librarys
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "./Components/MainComps";
import "react-tooltip/dist/react-tooltip.css";
import OneSignal from "react-onesignal";
import config from "./constant/config";
import {
  useSetUserNotificationId,
  useUserNotificationId,
} from "./hooks/useMenuContext/useMenuContext";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

function sentryinit() {
  Sentry.init({
    dsn: "https://f3ba1acd1a2e43bf9ba621ca0ed63780@o4504716862554112.ingest.sentry.io/4504716934184960",
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const App: React.FC = ({}) => {
  const [notificationData, setNotificationData] = useState();
  const [notificationOpen, setNotificationOpen] = useState();
  const [name, setName] = useState(true); // Set initial state to true
  const [a, setA] = useState(true); // Set initial state to true
  const setUserNotificationId = useSetUserNotificationId();
  const userNotificationId = useUserNotificationId();

  useEffect(() => {
    if (name === true) {
      // Check for true, not false
      setName(false); // Set it to false
    } else if (!window.opener) {
      OneSignal.init({
        appId: config.api.oneSignalAppid,
        notificationClickHandlerMatch: config.redirectUri,
        notificationClickHandlerAction: config.redirectUri,
      }).then(() => {
        OneSignal.Slidedown.promptPush();
        setA(true);
      });
    }
  }, [name]);

  useEffect(() => {
    (config.redirectUri.includes("frontend-test") ||
      config.redirectUri.includes("rightsupport.soprasteria")) &&
      sentryinit();

    function PushSubscription(event) {
      setUserNotificationId(event && event.current && event.current.id);
    }

    OneSignal.User.PushSubscription.addEventListener(
      "change",
      PushSubscription
    );
    return () => {
      OneSignal.User.PushSubscription.removeEventListener(
        "change",
        PushSubscription
      );
    };
  }, []);

  useEffect(() => {
    if (a === true) {
      // Check for true, not false
      setA(false); // Set it to false
    } else {
      setUserNotificationId(OneSignal.User.PushSubscription.id);
      const onNotificationClicked = function (data) {
        setNotificationOpen(data);
      };
      const onNotification = function (data) {
        setNotificationData(data);
      };
      OneSignal.Notifications.addEventListener(
        "foregroundWillDisplay",
        (data) => {
          onNotification(data && data["notification"]);
        }
      );
      OneSignal.Notifications.addEventListener("click", (event) => {
        event &&
          event["result"] &&
          event["result"]["actionId"] !== "111" &&
          onNotificationClicked(event && event["notification"]);
      });
    }
  }, [a]);

  return (
    <div style={{ background: "white" }}>
      <Router>
        <Main
          notificationData={notificationData}
          notificationOpen={notificationOpen}
          userID={userNotificationId}
          setUserID={setUserNotificationId}
        />
      </Router>
    </div>
  );
};

export default App;
