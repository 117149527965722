import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as edit } from "./Edit.svg";
import styled from "styled-components";
const Editicon = styled(edit)``;

type AlertLogoProps = {
  scale?: number;
  altText: string;
};

const Edit: React.FC<AlertLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper
      width={2 * scale}
      height={3 * scale}
      aria-label={altText}
      style={{ marginTop: "-7px" }}
    >
      <Editicon />
    </IconWrapper>
  );
};

export default Edit;
