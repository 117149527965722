import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as incedentBlack } from './incident.svg';
import styled from "styled-components";
const IncedentIconBlack = styled(incedentBlack)`
  
`

type IncedentLogoProps = {
  scale?: number;
  altText: string;
};

const IncedentBlack: React.FC<IncedentLogoProps> = ({ scale = 1, altText }) => { 
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{ "width": "1rem" }}>
      <IncedentIconBlack/>
    </IconWrapper>
  );
};

export default IncedentBlack;