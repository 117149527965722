import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as up_arrow } from './upArrow.svg';
import styled from "styled-components";
const Up_arrowIcon = styled(up_arrow)`
  
`

type Up_arrowLogoProps = {
  scale?: number;
  altText: string;
};

const UpArrow: React.FC<Up_arrowLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{"width": "1rem", marginTop: '-2px'}}>
      <Up_arrowIcon/>
    </IconWrapper>
  );
};

export default UpArrow;