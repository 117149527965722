import React from 'react';

import { IconWrapper } from '../styled-components';

import {ReactComponent as commentNotFound_icon} from './5nocomments.svg'
import styled from "styled-components";
const CommentNotSupportIcon = styled(commentNotFound_icon)`
  
`

type CommentNotFoundIconLogoProps = {
  scale?: number;
  altText: string;
};

const CommentNotFound: React.FC<CommentNotFoundIconLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={1.3* scale} height={0.9 * scale} aria-label={altText}>
      <CommentNotSupportIcon/>
    </IconWrapper>
  );
};

export default CommentNotFound;