import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as back } from "./back.svg";
import { ReactComponent as next } from "./next.svg";
import styled from "styled-components";
const BackIcon = styled(back)``;

const NextIcon = styled(next)``;

type BackLogoProps = {
  scale?: number;
  altText: string;
  type: string;
};

const Back: React.FC<BackLogoProps> = ({ scale = 1, altText, type }) => {
  return (
    <IconWrapper
      width={2 * scale}
      height={type === "mobile" ? 2.2 : 2.2 * scale}
      aria-label={altText}
    >
      {type === "next" ? <NextIcon /> : <BackIcon />}
    </IconWrapper>
  );
};

export default Back;
