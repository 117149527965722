//Library
import React, { useEffect, useState } from "react";

//Components

//Icons
import { useCookies } from "react-cookie";
import { getSNJSON } from "../api";
import { useApiAction } from "../hooks/use-api-action/use-api-action";
import {
  useOpenChatWindow,
  useOpenSnLoginWindow,
  useSetChatScreenHide,
  useSetOpenChatWindow,
  useSetOpenSnLoginWindow,
  usedisplaySize,
} from "../hooks/useMenuContext/useMenuContext";
import config from "../constant/config";

import { useMsal } from "@azure/msal-react";
import axios from "axios";
import OneSignal from "react-onesignal";
import { usehomeSNData } from "../hooks/UseApiContext/UseApiContext";

const useChatLogin = () => {
  const homeSNData = usehomeSNData();
  const [fetchSNLoginJSON, performSNLoginJSON] = useApiAction(getSNJSON);
  const setChatScreenHide = useSetChatScreenHide();
  const [userID, setUserID] = useState(null);
  const [loginWindow, setLoginWindow] = useState("");
  let childWindow1: Window | null = null;
  const { accounts } = useMsal();
  const [secondWindow, setSecondWindow] = useState("");
  const openChatWindow = useOpenChatWindow();
  const setOpenChatWindow = useSetOpenChatWindow();
  const openSnLoginWindow = useOpenSnLoginWindow();
  const setOpenSnLoginWindow = useSetOpenSnLoginWindow();
  let interval = null;
  let interval1 = null;
  const [checkSNLogin, setCheckSNLogin] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["name"]);
  const displaySize = usedisplaySize();

  function SNLogin() {
    removeCookie("name", { path: "/" });
  }

  function windowCheck() {
    if (openSnLoginWindow) {
      // URL in the child window changed to "testing.com", close it
      openSnLoginWindow.close();
    }
    clearInterval(interval);
    clearInterval(interval1);
  }

  useEffect(() => {
    const handleMessageFromServiceWorker = (event) => {
      setSecondWindow(event.data);
    };

    navigator.serviceWorker.addEventListener(
      "message",
      handleMessageFromServiceWorker
    );

    // Clean up the event listener
    return () => {
      navigator.serviceWorker.removeEventListener(
        "message",
        handleMessageFromServiceWorker
      );
    };
  }, []);

  const sendMessageToServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      navigator &&
        navigator.serviceWorker &&
        navigator.serviceWorker.controller &&
        navigator.serviceWorker.controller.postMessage &&
        navigator.serviceWorker.controller.postMessage("RightSupport");
    }
  };

  useEffect(() => {
    if (fetchSNLoginJSON.data && fetchSNLoginJSON.data["result"]) {
      setCheckSNLogin(fetchSNLoginJSON.data["result"]);
    }
  }, [fetchSNLoginJSON.data, checkSNLogin]);

  const openChildWindow = () => {
    // Open the child window and store its reference
    if (userID === null) {
      interval1 = setInterval(() => {
        axios.get(config.api.urlCheckIfUserSessionIsActive).then((response) => {
          if (
            response &&
            response.data &&
            response.data["result"] &&
            response.data["result"]["isLoggedIn"] &&
            response.data["result"]["isLoggedIn"]
          ) {
            windowCheck();
          }
        });
        interval = setInterval(() => {
          windowCheck();
        }, 60000);
      }, 500);
    }

    if (parseInt(secondWindow) === 1) {
      let newChildWindow = window.open(
        config.redirectUri +
          `chat?language=${
            homeSNData &&
            homeSNData["userDetails"] &&
            homeSNData["userDetails"]["language"]
          }`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=1000,width=420,height=508"
      );
      setOpenChatWindow(newChildWindow);
    } else {
      openChatWindow?.focus();
    }
  };

  useEffect(() => {
    if (loginWindow === "OnlySN") {
      let childWindow2 = window.open(
        accounts &&
          accounts[0] &&
          accounts[0] &&
          accounts[0]["username"] &&
          accounts[0]["username"].search("soprasteria.com") === -1
          ? config.api.urlLoginSN + config.api.glideSsoIdSintef
          : config.api.urlLoginSN + config.api.glideSsoIdSopraSteria,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=500"
      );
      var ini = setInterval(() => {
        childWindow2.close();
        clearInterval(ini);
      }, 5000);
    } else {
      if (checkSNLogin && checkSNLogin !== "") {
        if (checkSNLogin && checkSNLogin["isLoggedIn"] === false) {
          SNLogin();

          if (childWindow1) {
          } else {
            openChildWindow();
            let childWindow2 = window.open(
              accounts &&
                accounts[0] &&
                accounts[0] &&
                accounts[0]["username"] &&
                accounts[0]["username"].search("soprasteria.com") === -1
                ? config.api.urlLoginSN + config.api.glideSsoIdSintef
                : config.api.urlLoginSN + config.api.glideSsoIdSopraSteria,
              "_blank",
              "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=500"
            );
            setOpenSnLoginWindow(childWindow2);
          }
        }
        if (checkSNLogin && checkSNLogin["isLoggedIn"] === true) {
          if (parseInt(secondWindow) === 1) {
            let newChildWindow = window.open(
              config.redirectUri +
                `chat?language=${
                  homeSNData &&
                  homeSNData["userDetails"] &&
                  homeSNData["userDetails"]["language"]
                }`,
              "_blank",
              "toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=1000,width=420,height=508"
            );
            setOpenChatWindow(newChildWindow);
          } else {
            openChatWindow?.focus();
          }
        }
      }
    }
  }, [checkSNLogin]);

  // const chatLogin = (e) => {
  //   // OneSignal.getUserId().then(function (userId) {
  //   //   setUserID(userId);
  //   // });
  //   // setUserID(OneSignal.User.PushSubscription.id);
  //   setLoginWindow(e);
  //   sendMessageToServiceWorker();
  //   var sUsrAg = navigator.userAgent;
  //   var sBrowser;
  //   switch (true) {
  //     case sUsrAg.indexOf("Firefox") > -1:
  //       sBrowser = "Mozilla Firefox";
  //       break;
  //     case sUsrAg.indexOf("SamsungBrowser") > -1:
  //       sBrowser = "Samsung Internet";
  //       break;
  //     case sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1:
  //       sBrowser = "Opera";
  //       break;
  //     case sUsrAg.indexOf("Trident") > -1:
  //       sBrowser = "Microsoft Internet Explorer";
  //       break;
  //     case sUsrAg.indexOf("Edg") > -1:
  //       sBrowser = "Microsoft Edge";
  //       break;
  //     case sUsrAg.indexOf("Chrome") > -1:
  //       sBrowser = "Chrome";
  //       break;
  //     case sUsrAg.indexOf("Safari") > -1 && sUsrAg.indexOf("Chrome") === -1:
  //       sBrowser = "Apple Safari";
  //       break;
  //     default:
  //       sBrowser = "unknown";
  //       break;
  //   }
  //   var source = displaySize === "isMobile" ? "mobile" : "desktop";
  //   var browser = sBrowser;
  //   var queryParams = `?user_uses_notification=${
  //     userID === null ? false : true
  //   }&source=${source}&browser=${browser}`;
  //   var Feild = ["Get", "urlCheckIfUserSessionIsActive", queryParams, {}];
  //   performSNLoginJSON(Feild);
  //   setChatScreenHide(true);
  // };

  const chatLogin = (e) => {
    function opneChat() {
      let newChildWindow = window.open(
        config.redirectUri +
          `chat?language=${
            homeSNData &&
            homeSNData["userDetails"] &&
            homeSNData["userDetails"]["language"]
          }`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=1000,width=420,height=508"
      );
      setOpenChatWindow(newChildWindow);
    }
    if (openChatWindow === null) {
      opneChat();
    }
    function checkWin() {
      if (!openChatWindow) {
      } else {
        if (openChatWindow.closed) {
          opneChat();
        }
      }
    }
    checkWin();
    openChatWindow?.focus();
  };
  const sNLogin = () => {
    windowCheck();
  };

  return { chatLogin: chatLogin, sNLogin: sNLogin };
};

export default useChatLogin;
