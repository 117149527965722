import styled from "styled-components";

export const HeaderContainer = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  background: #ffffff;
  .header-drag {
    -webkit-app-region: drag;
  }
`;

export const Nav = styled.div`
  padding: 6% 3%;
  @media (min-height: 720px) {
    padding: 5% 3%;
  }
  @media (min-height: 920px) {
    padding: 4% 3%;
  }
`;

export const ChatContentCenter = styled.div`
  width: 76%;
  height: 43px;
`;

export const ChatContentLeft = styled.div`
  width: 9%;
  height: 100%;
  padding: 5px 10px;
`;

export const ContentLeft = styled.div`
  width: 81%;
  height: 100%;
  display: flex;
  padding: 1px 0px 0px 8px;
`;

export const ContentRight = styled.div`
  width: 19%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Content = styled.div`
  width: 40px;
  height: 100%;
`;
