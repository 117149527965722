//Library
import React from "react";
import { useNavigate } from "react-router-dom";

//Files & Functions
import { SideActionButtonConatainer } from "./StyledComponent";
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";
import useChatLogin from "../../utils/utilsFunction";

//Components

//Icons
import Chat from "../../Icons/Footer-icons/chat-icon";
import Ticket from "../../Icons/Footer-icons/ticket-icon";
import Call from "../../Icons/Footer-icons/call-icon";

// Tooltip
import { Tooltip } from "react-tooltip";

type FooterProps = {
  dataSN: any;
  move_footer: string;
};

const Footer: React.FC<FooterProps> = ({ dataSN, move_footer }) => {
  const { chatLogin, sNLogin } = useChatLogin();
  const navigate = useNavigate();
  const languageSNData = uselanguageSNData();

  function setpage(page: any) {
    switch (page) {
      case "ticket":
        return navigate("/ticketform");
      case "chat":
        return chatLogin("");
      case "call":
        return navigate("/callform");
    }
  }

  function get_icon(icon_name: string) {
    switch (icon_name) {
      case "chat":
        return (
          <div style={{ marginTop: "3px" }}>
            <Chat scale={0.7} altText="attachment" color="" />
          </div>
        );
      case "ticket":
        return (
          <div style={{ marginTop: "-1px" }}>
            <Ticket scale={0.7} altText="attachment" color="" />
          </div>
        );
      case "call":
        return (
          <div style={{ marginTop: "0px" }}>
            <Call scale={0.67} altText="attachment" />
          </div>
        );
      default:
        return <Chat scale={0.59} altText="attachment" color="" />;
    }
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: move_footer === "bottom" ? "center" : "",
      }}
    >
      {move_footer === "bottom" ? (
        <div
          style={{
            height: "62px",
            display: "flex",
            justifyContent: "center",
            zIndex: "1",
            alignItems: "center",
          }}
        >
          {dataSN
            ? dataSN["button"].map((e: any, index: any) => (
                <button
                  key={index}
                  className="back-button-hover"
                  style={{
                    border: "none",
                    borderColor: e["boarder_color"]
                      ? e["boarder_color"]
                      : "black",
                    borderRadius: "3px",
                    background: "white",
                    width: "fit-content",
                    height: "35px",
                    display: "flex",
                    marginRight: "10px",
                    padding: "0px 0px 5px 5px",
                  }}
                  onClick={() => setpage(e["icon"])}
                >
                  {/* <Tooltip id="my-tooltip" place="right" /> */}
                  <div
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={e["label"]}
                    data-tooltip-offset={10}
                  >
                    <div style={{ width: "25px", height: "100%" }}>
                      {get_icon(e["icon"])}
                    </div>
                  </div>
                  {/* <div
                    style={{ marginTop: "6px", width: "fit-content", height: "70%", textAlign: "left", fontSize: "14px", color: e["text_color"] ? e["text_color"] : "black" }}
                  >
                    {e["label"]}
                  </div> */}
                </button>
              ))
            : ""}
        </div>
      ) : (
        <SideActionButtonConatainer>
          <div style={{ height: "40px" }}>
            <div
              style={{
                height: "20px",
                fontSize: "14px",
                fontWeight: "400",
                color: "#4A2878",
              }}
            >
              {languageSNData &&
              languageSNData["searcharticle_rightarticletitle_text"]
                ? languageSNData["searcharticle_rightarticletitle_text"]
                : ""}
            </div>
            <div
              style={{
                height: "20px",
                fontSize: "14px",
                fontWeight: "400",
                color: "#49484D",
              }}
            >
              {languageSNData &&
              languageSNData["seracharticle_servicecentertitle_text"]
                ? languageSNData["seracharticle_servicecentertitle_text"]
                : ""}
            </div>
          </div>
          <div style={{ height: "254px" }}>
            {dataSN
              ? dataSN["button"].map((element: any, index: any) => {
                  return (
                    <>
                      <button
                        className="back-button-hover action-button"
                        onClick={() => setpage(element["icon"])}
                        key={index}
                      >
                        <div
                          data-tooltip-id="new-tooltip"
                          data-tooltip-content={element["label"]}
                        >
                          <div
                            style={{
                              height: "32px",
                              // width: "fit-content",
                              marginTop: "8px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "fit-content",
                              }}
                            >
                              {get_icon(element["icon"])}
                            </div>
                          </div>
                          <div
                            style={{
                              height: "20px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#49484D",
                              textAlign: "center",
                            }}
                          >
                            {element["label"]}
                          </div>
                        </div>
                        <Tooltip
                          id="new-tooltip"
                          place="right"
                          classNameArrow="my-arrow"
                          offset={13}
                          style={{
                            height: "23px",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            borderRadius: "4px",
                            background: "#49484D",
                            padding: "4px 8px",
                          }}
                        />
                      </button>
                    </>
                  );
                })
              : ""}
          </div>
        </SideActionButtonConatainer>
      )}
    </div>
  );
};

export default Footer;
