//Library
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { getSNJSON } from "../../api";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import {
  useinboxSNData,
  uselanguageSNData,
} from "../../hooks/UseApiContext/UseApiContext";
import {
  useInboxRecall,
  useNotsupportAttachment,
  useSetInboxRecall,
  useSetNotsupportAttachment,
  useSetTicketSysId,
  usedisplaySize,
} from "../../hooks/useMenuContext/useMenuContext";
import CommentNotSupport from "../../Icons/CommentNotSupport";
import Delete from "../../Icons/Delete_icon/Delete";
import DOCIcon from "../../Icons/Doc_extension_icon";
import DownArrow from "../../Icons/DownArrow/DownArrow";
import Download from "../../Icons/Download_icon";
import ImgIcon from "../../Icons/img_extension_icon";
import PDFIcon from "../../Icons/pdf_extension_icon";
import UpArrow from "../../Icons/UpArrow/UpArrow";
import Avatar from "../AvatarComps";
import InboxChat from "../InboxChatComps";
import Reopen from "../InboxReopneComps";
import TicketProgressBar from "../TicketProgressBarComps";
import Ticketprogress from "../TicketProgressComps";
import Close from "../../Icons/Header_close_icon/Close";

//Files & Functions
import {
  Attachments,
  DownloadFile,
  Subject,
  TicketDetails,
  SeeDetailContainer,
  HeaderContainer,
  StatusContainer,
  ViewCommentButton,
  ProgressBar,
  MassageContainer,
  MassageContainerText1,
  MassageContainerText2,
  MassageContainerButton,
  View_more_or_less_text,
} from "./StyledComponent";
import Awaiting from "../../Icons/Awaiting_icon";

//Components

//Icons

type InboxHeaderProps = {
  setSidebar: any;
  sidebar: any;
  ticket: any;
};

const InboxHeader: React.FC<InboxHeaderProps> = ({
  setSidebar,
  sidebar,
  ticket,
}) => {
  const inboxSNData = useinboxSNData();
  const languageSNData = uselanguageSNData();
  const inboxRecall = useInboxRecall();
  const setTicketSysId = useSetTicketSysId();
  const setInboxRecall = useSetInboxRecall();
  const notsupportAttachment = useNotsupportAttachment();
  const setNotsupportAttachment = useSetNotsupportAttachment();

  const [fetchDeleteAttachmentJSON, perDeleteAttachmentJSON] =
    useApiAction(getSNJSON);
  const [fetchUpdateVeiwEndUserJSON, performUpdateVeiwEndUserJSON] =
    useApiAction(getSNJSON);

  const [detail, setDetail] = useState(false);
  const [read, setRead] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [toastMassage, setToastMassage] = useState(false);
  const [attachmentStatus, setAttachmentStatus] = useState("");
  const [deleteAttachments, setDeleteAttachments] = useState("");
  const [deleteAttachmentsys_id, setDeleteAttachmentsys_id] = useState("");
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const displaySize = usedisplaySize();

  function getExtension(filename) {
    return filename.split(".").pop();
  }

  useEffect(() => {
    // if (attachmentStatus !== "") {
    //   let base64Attachment =
    //     attachmentStatus &&
    //     attachmentStatus["result"] &&
    //     attachmentStatus["result"]["Base64"];
    //   let pdf = base64Attachment.split("/pdfbase64");
    //   let pfrr = pdf[1];
    //   const linkSource = `data:application/pdf;base64,${pfrr}`;
    //   const downloadLink = document.createElement("a");
    //   const fileName = base64Attachment["FileName"];
    //   downloadLink.href = linkSource;
    //   downloadLink.download = fileName;
    //   downloadLink.click();
    // }

    if (attachmentStatus !== "") {
      let base64Attachment =
        attachmentStatus &&
        attachmentStatus["result"] &&
        attachmentStatus["result"]["Base64"];
      let pfrr = "";
      if (base64Attachment.includes("data")) {
        let stringToIterate = base64Attachment;
        let startIndex = stringToIterate.indexOf("data");
        let endIndex = stringToIterate.indexOf("base64") + "base64".length;
        let substringToSplit = stringToIterate.substring(startIndex, endIndex);
        let splitSubstring = stringToIterate.split(substringToSplit);
        pfrr = splitSubstring[1];
      } else {
        pfrr = base64Attachment;
      }
      const linkSource = `data:${attachmentDetails["ContentType"]};base64,${pfrr}`;
      const downloadLink = document.createElement("a");
      const fileName = attachmentDetails["FileName"];
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }, [attachmentStatus]);

  const base64toBlob = async (e) => {
    setAttachmentDetails(e);
    async function getBase64Attachment() {
      await axios
        .get(e["Base64"])
        .then((response) => setAttachmentStatus(response.data));
    }
    getBase64Attachment();
  };

  const DownloadAllAttachment = async (e) => {
    async function getBase64Attachment(data) {
      await axios.get(data["Base64"]).then((response) => {
        setAttachmentStatus(response.data);
        setAttachmentDetails({ FileName: data["FileName"] });
      });
    }
    e.map((data, key) => {
      getBase64Attachment(data);
    });
  };

  useEffect(() => {
    if (fetchDeleteAttachmentJSON.data) {
      setDeleteAttachments(fetchDeleteAttachmentJSON.data.result);
      setInboxRecall(!inboxRecall);
      setToastMassage(false);
    }
  }, [fetchDeleteAttachmentJSON.data, deleteAttachments]);

  function deleteAttachment(Item) {
    let Url = Item && Item["Base64"];
    let sys_id = Url.split("?sys_id=")[1];
    var Feild = ["Post", "urlDeleteAttachment", "", { sys_id: sys_id }];
    perDeleteAttachmentJSON(Feild);
  }

  function showToastMassage(value, attachment) {
    setToastMassage(value);
    setDeleteAttachmentsys_id(attachment);
    setNotsupportAttachment("");
  }

  useEffect(() => {
    if (
      read &&
      read["result"] &&
      read["result"]["status"] &&
      read["result"]["status"] === "200"
    ) {
      setInboxRecall(!inboxRecall);
    }
  }, [read]);

  useEffect(() => {
    if (fetchUpdateVeiwEndUserJSON.data) {
      setRead(fetchUpdateVeiwEndUserJSON.data);
    }
  }, [fetchUpdateVeiwEndUserJSON.data, read]);

  useEffect(() => {
    if (
      ticket &&
      ticket["additionalTicketDetails"] &&
      ticket["additionalTicketDetails"]["isViewedByEndUser"] === false
    ) {
      let ticketID = ticket && ticket["Sys ID"];
      let ticketTable = ticket && ticket["ticketTable"];
      setTicketSysId(ticketID);
      var Feild = [
        "Post",
        "urlUpdateVeiwEndUser",
        "",
        { ticketID: ticketID, ticketTable: ticketTable },
      ];
      performUpdateVeiwEndUserJSON(Feild);
    }

    if (
      ticket &&
      ticket["additionalTicketDetails"] &&
      ticket["additionalTicketDetails"]["ExclamationMark"]
    ) {
      setSidebar(true);
    }
  }, [ticket]);

  return (
    <div style={{ height: "100%", display: "flex" }}>
      {ticket ? (
        <div>
          <div
            style={{
              display: toastMassage ? "" : "none",
              background: "black",
              opacity: "25%",
              position: "absolute",
              bottom: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
            }}
            onClick={() => showToastMassage(false, "")}
          ></div>
          <MassageContainer
            style={{
              display:
                (notsupportAttachment &&
                  notsupportAttachment["result"] &&
                  notsupportAttachment["result"]["allFilesUploaded"] ===
                    false) ||
                toastMassage
                  ? ""
                  : "none",
            }}
          >
            <div
              style={{
                height: "30px",
                padding: "3px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div
                className="back-button-hover"
                onClick={() => showToastMassage(false, "")}
                style={{ width: "25px", cursor: "pointer" }}
              >
                <Close scale={0.8} altText="Minimize" color="" />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                height: "fit-content",
                justifyContent: "center",
              }}
            >
              <MassageContainerText1 className="FontRoboto">
                {" "}
                {toastMassage
                  ? languageSNData &&
                    languageSNData["inboxdelete_toastmessage_headingtitle"]
                    ? languageSNData["inboxdelete_toastmessage_headingtitle"]
                    : "Delete attachment"
                  : languageSNData &&
                    languageSNData["inboxsupport_toastmessage_title"]
                  ? languageSNData["inboxsupport_toastmessage_title"]
                  : "Not supported"}
              </MassageContainerText1>
            </div>
            <div
              style={{
                display: "flex",
                height: "fit-content",
                padding: "0px 15px",
                justifyContent: "center",
              }}
            >
              <MassageContainerText2 toastMassage={toastMassage}>
                {toastMassage ? (
                  languageSNData &&
                  languageSNData["inboxdelete_toastmessage_subtitle"] ? (
                    languageSNData["inboxdelete_toastmessage_subtitle"]
                  ) : (
                    "Do you really want to delete this attachment?"
                  )
                ) : (
                  <>
                    {languageSNData &&
                    languageSNData["inboxsupport_toastmessage_subtitle"]
                      ? languageSNData["inboxsupport_toastmessage_subtitle"]
                      : "These file type is not supported"}
                    <div style={{ maxHeight: "100px", overflowY: "scroll" }}>
                      {notsupportAttachment &&
                      notsupportAttachment["result"] &&
                      notsupportAttachment["result"]["unUploadedFiles"]
                        ? notsupportAttachment["result"]["unUploadedFiles"].map(
                            (fileName, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    marginTop: "10px",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "20px",
                                    color: "#959199",
                                  }}
                                >
                                  <span
                                    style={{
                                      paddingRight: "6px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {index + 1})
                                  </span>
                                  {fileName}
                                </div>
                              );
                            }
                          )
                        : ""}
                    </div>
                  </>
                )}
              </MassageContainerText2>
            </div>
            <div
              style={{
                height: "58px",
                marginTop: "32px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {toastMassage ? (
                <div style={{ width: "fit-content", display: "flex" }}>
                  <MassageContainerButton
                    style={{ width: "fit-content" }}
                    onClick={() => showToastMassage(false, "")}
                  >
                    {languageSNData &&
                    languageSNData["inboxdelete_toastmessage_buttonkeep"]
                      ? languageSNData["inboxdelete_toastmessage_buttonkeep"]
                      : "Keep"}
                  </MassageContainerButton>
                  <MassageContainerButton
                    // onClick={() => showToastMassage(false)}
                    onClick={() => deleteAttachment(deleteAttachmentsys_id)}
                    style={{
                      width: "fit-content",
                      color: "#FFFFFF",
                      fontSize: "16px",
                      borderRadius: "3px",
                      padding: "7px 15px",
                      marginLeft: "16px",
                      cursor: "pointer",
                    }}
                  >
                    {languageSNData &&
                    languageSNData["inboxdelete_toastmessage_buttondelete"]
                      ? languageSNData["inboxdelete_toastmessage_buttondelete"]
                      : "Delete"}
                  </MassageContainerButton>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={() => showToastMassage(false, "")}
                    style={{
                      width: "214px",
                      height: "38px",
                      background: "#DD296E",
                      color: "white",
                      border: "none",
                      borderRadius: "3px",
                    }}
                  >
                    Ok
                  </button>
                </div>
              )}
            </div>
          </MassageContainer>

          {displaySize !== "isMobile" && (
            <HeaderContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: "fit-content",
                  marginLeft: "16px",
                  marginRight: "16px",
                  width: sidebar ? "calc(100% - 32px)" : "calc(100% - 172px)",
                }}
              >
                <div
                  style={{
                    height: "24px",
                    color: "#0E090C",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  {ticket["Short description"]}
                </div>
                <div
                  style={{
                    height: "22px",
                    marginTop: "4px",
                    color: "#4A4A49",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {ticket["HideNumber"] ? (
                    ""
                  ) : (
                    <div style={{ width: "110px", height: "20px" }}>
                      {" "}
                      {ticket["Number"]}
                    </div>
                  )}
                  <div
                    style={{ width: "79%", height: "23px", display: "flex" }}
                  >
                    <div
                      style={{
                        borderRadius: "10px",
                        marginTop: "6px",
                        width: "10px",
                        height: "10px",
                        backgroundColor: inboxSNData["meta"]["stateColours"]
                          ? inboxSNData["meta"]["stateColours"][
                              ticket["stateValue"]
                            ]
                          : "",
                      }}
                    ></div>
                    {/* <div
                      style={{
                        marginLeft: "3px",
                        color: "#4A4A49",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {" "}
                      {ticket["State"]}
                    </div> */}
                  </div>
                </div>
                {detail === false && (
                  <div
                    style={{
                      height: "24px",
                      color: "#4A4A49",
                      fontSize: "14px",
                      marginTop: "7px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {ticket && ticket["isResolved"] && (
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          fontStyle: "normal",
                        }}
                      >
                        {" "}
                        {languageSNData &&
                        languageSNData["inbox_resolutionnotes_text"]
                          ? languageSNData["inbox_resolutionnotes_text"]
                          : "Resolution Notes"}{" "}
                        {":"}
                      </span>
                    )}{" "}
                    {ticket && ticket["isResolved"]
                      ? ticket["resolution_notes"]
                      : ticket["Description"]}
                  </div>
                )}
              </div>
              <div
                style={{
                  height: "67px",
                  display: sidebar ? "none" : "",
                  width: sidebar ? "" : "140px",
                }}
              >
                {!sidebar && ticket["CommentsSupported"] ? (
                  <div
                    style={{
                      justifyContent: "right",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ViewCommentButton
                      onClick={() => setSidebar(!sidebar)}
                      style={{
                        width: "fit-content",
                        height: "34px",
                        padding: "6px 10px",
                        color: "#DD296E",
                        fontSize: "14px",
                        // border: "none",
                        cursor: "pointer",
                        fontWeight: "600",
                      }}
                    >
                      {languageSNData &&
                      languageSNData["inbox_viewcomments_openSidebar"]
                        ? languageSNData["inbox_viewcomments_openSidebar"]
                        : "View Comments"}
                    </ViewCommentButton>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </HeaderContainer>
          )}
          <StatusContainer
            style={{
              overflowY: "auto",
            }}
          >
            {displaySize !== "isMobile" ? (
              <>
                {detail && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "fit-content",
                      padding: "0px 16px 16px 16px",
                      justifyContent: "space-between",
                    }}
                  >
                    {ticket && ticket["additionalTicketDetails"] && (
                      <div
                        style={{
                          height: "fit-content",
                        }}
                      >
                        <div
                          style={{
                            height: "19px",
                            lineHeight: "19px",
                            width: "fit-content",
                            background:
                              ticket["stateSymbol"] === 2
                                ? "var(--brand-brand-pink, #DD296E)"
                                : "",
                            marginTop: "8px",
                            border:
                              ticket["stateSymbol"] === 4
                                ? " 0.559px solid var(--secondary-green-main, #00A188)"
                                : ticket["stateSymbol"] === 1
                                ? "0.56px solid var(--grey-medium, #959199)"
                                : "",

                            color:
                              ticket["stateSymbol"] === 2
                                ? "var(--White, #FFF)"
                                : ticket["stateSymbol"] === 4
                                ? "var(--secondary-green-main, #00A188)"
                                : ticket["stateSymbol"] === 1
                                ? "var(--grey-medium, #959199)"
                                : "var(--grey-dark, #49484D)",
                            fontSize: "7.825px",
                            padding: "0px 5.589px",
                            gap: "4.471px",
                            borderRadius: "1.68px",
                            display: "flex",
                          }}
                          className="FontInter"
                        >
                          {ticket["stateSymbol"] === 2 && (
                            <Awaiting
                              scale={1.3}
                              altText={"Awaiting"}
                              color=""
                            />
                          )}
                          {ticket["stateSymbol"] === 3 && (
                            <Awaiting
                              scale={1.3}
                              altText={"AwaitingChange"}
                              color=""
                            />
                          )}
                          {ticket["State"]}{" "}
                        </div>
                      </div>
                    )}
                    {ticket && ticket["isResolved"] && (
                      <div
                        style={{
                          borderBottom: "1px solid #E6E6E6",
                          paddingBottom: "32px",
                          marginTop: "32px",
                          height: "fit-content",
                        }}
                      >
                        <div
                          style={{
                            height: "20px",
                            fontWeight: "600",
                            fontSize: "14px",
                            color: "#0E090C",
                          }}
                        >
                          {languageSNData &&
                          languageSNData["inbox_resolutionnotes_text"]
                            ? languageSNData["inbox_resolutionnotes_text"]
                            : "Resolution Notes"}
                        </div>
                        <Subject
                          style={{
                            fontSize: "16px",
                            lineHeight: "19px",
                            letterSpacing: "-0.01em",
                            fontWeight: "normal",
                            marginTop: "8px",
                            color: "#767675",
                            height: "fit-content",
                            paddingBottom: "10px",
                          }}
                        >
                          {ticket["resolution_notes"]}
                        </Subject>
                      </div>
                    )}
                    <div
                      style={{
                        borderBottom: "1px solid #E6E6E6",
                        paddingBottom: "32px",
                        marginTop: "32px",
                        height: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          height: "20px",
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#0E090C",
                        }}
                      >
                        {languageSNData &&
                        languageSNData["inbox_description_text"]
                          ? languageSNData["inbox_description_text"]
                          : "Description"}
                      </div>
                      <Subject
                        style={{
                          fontSize: "16px",
                          lineHeight: "19px",
                          letterSpacing: "-0.01em",
                          fontWeight: "normal",
                          marginTop: "8px",
                          color: "#767675",
                          height: "fit-content",
                          paddingBottom: "10px",
                        }}
                      >
                        {showMore
                          ? ticket && ticket["Description"]
                          : `${
                              ticket && ticket["Description"].substring(0, 150)
                            }`}
                        {ticket && ticket["Description"].length > 150 ? (
                          <View_more_or_less_text
                            onClick={() => setShowMore(!showMore)}
                          >
                            {showMore
                              ? languageSNData &&
                                languageSNData["inbox_viewless_text"]
                                ? languageSNData["inbox_viewless_text"]
                                : "view less"
                              : languageSNData &&
                                languageSNData["inbox_viewmore_text"]
                              ? languageSNData["inbox_viewmore_text"]
                              : "view more"}
                          </View_more_or_less_text>
                        ) : (
                          ""
                        )}
                      </Subject>
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid #E6E6E6",
                        paddingBottom: "32px",
                        display: "flex",
                        flexWrap: "wrap",
                        height: "fit-content",
                        marginTop: "32px",

                        // flexDirection: sidebar ? "column" : "row",
                      }}
                    >
                      <div style={{ flex: "1 0 200px" }}>
                        <div
                          style={{
                            height: "16px",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "16px",
                            color: "#0E090C",
                          }}
                        >
                          {languageSNData
                            ? languageSNData["inbox_created_text"]
                            : ""}
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            color: "#767675",
                            height: "21px",
                            lineHeight: "21px",
                            marginTop: "8px",
                          }}
                        >
                          {ticket && ticket["Created"]}
                        </div>
                      </div>
                      <div style={{ flex: "1 0 200px" }}>
                        <div
                          style={{
                            height: "16px",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "16px",
                            color: "#0E090C",
                          }}
                        >
                          {languageSNData
                            ? languageSNData["inbox_lastupdate_text"]
                            : ""}
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            color: "#767675",

                            height: "21px",
                            lineHeight: "21px",
                            marginTop: "8px",
                          }}
                        >
                          {ticket && ticket["Updated"]}
                        </div>
                      </div>
                      {ticket && ticket["customerTicketNumber"] ? (
                        <div style={{ flex: "1 0 200px" }}>
                          <div
                            style={{
                              height: "16px",
                              fontWeight: "600",
                              fontSize: "14px",

                              lineHeight: "16px",
                              color: "#0E090C",
                            }}
                          >
                            {languageSNData &&
                            languageSNData["inbox_idnumber_text"]
                              ? languageSNData["inbox_idnumber_text"]
                              : "ID Number"}
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              color: "#767675",
                              height: "21px",
                              lineHeight: "21px",
                              marginTop: "8px",
                            }}
                          >
                            {ticket && ticket["customerTicketNumber"]}
                          </div>
                        </div>
                      ) : (
                        <div style={{ flex: "1 0 200px" }}></div>
                      )}
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid #E6E6E6",
                        paddingBottom: "32px",
                        display: "flex",
                        flexWrap: "wrap",
                        height: "fit-content",
                        marginTop: "32px",
                      }}
                    >
                      <div style={{ flex: "1 0 200px" }}>
                        <div
                          style={{
                            height: "16px",
                            fontWeight: "600",
                            fontSize: "14px",
                            lineHeight: "16px",
                            color: "#0E090C",
                          }}
                        >
                          {languageSNData && languageSNData["inbox_type_text"]
                            ? languageSNData["inbox_type_text"]
                            : "Type"}
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            color: "#767675",
                            height: "21px",
                            lineHeight: "21px",
                            marginTop: "8px",
                          }}
                        >
                          {ticket &&
                            ticket["additionalTicketDetails"] &&
                            ticket["additionalTicketDetails"]["TicketType"]}
                        </div>
                      </div>
                      {ticket &&
                      ticket["Requested by"] &&
                      ticket["Requested by"] !== "" ? (
                        <div style={{ flex: "1 0 200px" }}>
                          <div
                            style={{
                              height: "16px",
                              fontWeight: "600",
                              fontSize: "14px",
                              lineHeight: "16px",
                              color: "#0E090C",
                            }}
                          >
                            {languageSNData &&
                            languageSNData["inbox_requestedby_text"]
                              ? languageSNData["inbox_requestedby_text"]
                              : "Requested By"}
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              color: "#767675",

                              height: "21px",
                              lineHeight: "21px",
                              marginTop: "8px",
                            }}
                          >
                            {ticket["Requested by"]}
                          </div>
                        </div>
                      ) : (
                        <div style={{ flex: "1 0 200px" }}></div>
                      )}
                      {ticket &&
                      ticket["Requested for"] &&
                      ticket["Requested for"] !== "" ? (
                        <div style={{ flex: "1 0 200px" }}>
                          <div
                            style={{
                              height: "16px",
                              fontWeight: "600",
                              fontSize: "14px",

                              lineHeight: "16px",
                              color: "#0E090C",
                            }}
                          >
                            {languageSNData &&
                            languageSNData["inbox_requestedfor_text"]
                              ? languageSNData["inbox_requestedfor_text"]
                              : "Requested for"}
                          </div>
                          <div
                            style={{
                              fontSize: "16px",
                              color: "#767675",
                              height: "21px",
                              lineHeight: "21px",
                              marginTop: "8px",
                            }}
                          >
                            {ticket["Requested for"]}
                          </div>
                        </div>
                      ) : (
                        <div style={{ flex: "1 0 200px" }}></div>
                      )}
                    </div>
                    {ticket &&
                    ticket["attachments"] &&
                    ticket["attachments"]["attachments"][0] ? (
                      <div
                        style={{
                          marginTop: "32px",
                          height: "fit-content",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            height: "fit-content",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontWeight: "600",
                            fontSize: "14px",
                            color: "#0E090C",
                          }}
                        >
                          <div style={{ width: "fit-content" }}>
                            {" "}
                            {languageSNData
                              ? languageSNData["inbox_updated_text"]
                              : ""}
                          </div>
                          <div
                            onClick={() =>
                              DownloadAllAttachment(
                                ticket &&
                                  ticket["attachments"] &&
                                  ticket["attachments"]["attachments"]
                              )
                            }
                            style={{
                              cursor: "pointer",
                              width: "fit-content",
                              padding: "4px 10px",
                              minWidth: "112px",
                              display: "flex",
                              fontSize: "14px",
                              justifyContent: "space-between",
                              color: "#DD296E",
                            }}
                          >
                            {" "}
                            {languageSNData &&
                            languageSNData["inbox_attachmentalldownload_button"]
                              ? languageSNData[
                                  "inbox_attachmentalldownload_button"
                                ]
                              : "Download all"}
                            <Download
                              scale={0.7}
                              altText="Download"
                              colour="pink"
                            />
                          </div>
                        </div>

                        <Attachments
                          style={{
                            height: "fit-content",
                            display: "flex",
                            width: "100%",
                            marginTop: "8px",
                            flexWrap: "wrap",
                            overflowY: "scroll",
                            padding: "0px 0px 0px 0px",
                          }}
                        >
                          {ticket &&
                            ticket["attachments"] &&
                            ticket["attachments"]["attachments"].map(
                              (attachment, index) => {
                                let attachmentName =
                                  attachment["FileName"].split(".");
                                return (
                                  <DownloadFile
                                    key={index}
                                    style={{
                                      height: "42px",
                                      display: "flex",
                                      borderRadius: "4px",
                                      border: "1px solid #E6E6E6",
                                      marginBottom: sidebar ? "5px" : "8px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "13%",
                                        paddingTop: "0px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          borderRadius: "100%",
                                          background: "#4A2878",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      >
                                        <Avatar
                                          homedata={
                                            attachment["UserFullName"]
                                              ? attachment["UserFullName"]
                                              : ticket && ticket["Name"]
                                          }
                                          FontSize="9.54545px"
                                          MarginLeft="6px"
                                          MarginTop="9px"
                                          inboxNameImage={true}
                                          Color={"#4A2878"}
                                          fontColor="white"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        width: "63%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: "20px",
                                          marginTop: "2px",
                                          fontSize: "14px",
                                          color: "#4A4A49",
                                          lineHeight: "20px",
                                          display: "flex",
                                        }}
                                      >
                                        {/* {attachment["FileName"]} */}
                                        <div
                                          style={{
                                            width: "auto",
                                            maxWidth: "70%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {attachmentName[0]}
                                        </div>
                                        <div
                                          style={{
                                            width: "30%",
                                            margin: "0",
                                            padding: "0",
                                          }}
                                        >
                                          {"." +
                                            attachmentName[
                                              attachmentName.length - 1
                                            ]}
                                        </div>
                                      </div>
                                      {attachment && attachment["Updated"] && (
                                        <div
                                          style={{
                                            height: "12px",
                                            width: "75px",
                                            marginTop: "0px",
                                            fontSize: "12px",
                                            color: "#767675",
                                            lineHeight: "12px",
                                          }}
                                        >
                                          {attachment["Updated"]}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        width: "12%",
                                      }}
                                      className="download"
                                    >
                                      <button
                                        className="back-button-hover"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={
                                          languageSNData &&
                                          languageSNData[
                                            "inboxchat_downloadicontooltip_text"
                                          ]
                                            ? languageSNData[
                                                "inboxchat_downloadicontooltip_text"
                                              ]
                                            : "Download"
                                        }
                                        data-tooltip-offset={4}
                                        style={{
                                          background: "none",
                                          border: "none",
                                          paddingRight: "10px",
                                        }}
                                        onClick={() => base64toBlob(attachment)}
                                      >
                                        <Download
                                          scale={0.8}
                                          altText="Download"
                                        />
                                      </button>
                                    </div>
                                    <div
                                      style={{
                                        width: "12%",
                                        marginTop: "-1px",
                                      }}
                                      className="delete"
                                    >
                                      <button
                                        className="back-button-hover"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={
                                          languageSNData &&
                                          languageSNData[
                                            "inboxdelete_toastmessage_buttondelete"
                                          ]
                                            ? languageSNData[
                                                "inboxdelete_toastmessage_buttondelete"
                                              ]
                                            : "Delete"
                                        }
                                        data-tooltip-offset={4}
                                        style={{
                                          background: "none",
                                          border: "none",
                                          marginLeft: "1px",
                                          paddingLeft: "9px",
                                        }}
                                        onClick={() =>
                                          showToastMassage(true, attachment)
                                        }
                                      >
                                        <Delete scale={0.6} altText="Delete" />
                                      </button>
                                    </div>
                                  </DownloadFile>
                                );
                              }
                            )}
                        </Attachments>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "fit-content",
                  padding: "0px 16px 16px 16px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    height: "fit-content",
                    marginTop: "32px",
                  }}
                >
                  <div style={{ flex: "1 0 200px" }}>
                    <div
                      style={{
                        height: "15px",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "15px",
                        color: "#0E090C",
                      }}
                    >
                      {languageSNData
                        ? languageSNData["inbox_created_text"]
                        : ""}
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        color: "#767675",
                        height: "21px",
                        lineHeight: "21px",
                        marginTop: "8px",
                      }}
                    >
                      {ticket && ticket["Created"]}
                    </div>
                  </div>
                  <div style={{ flex: "1 0 200px" }}>
                    <div
                      style={{
                        height: "15px",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "15px",
                        color: "#0E090C",
                        marginTop: "10px",
                      }}
                    >
                      {languageSNData
                        ? languageSNData["inbox_lastupdate_text"]
                        : ""}
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        color: "#767675",
                        height: "21px",
                        lineHeight: "21px",
                        marginTop: "8px",
                      }}
                    >
                      {ticket && ticket["Updated"]}
                    </div>
                  </div>
                  {ticket && ticket["customerTicketNumber"] && (
                    <div style={{ flex: "1 0 200px" }}>
                      <div
                        style={{
                          height: "14px",
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "14px",
                          color: "#0E090C",
                          marginTop: "10px",
                        }}
                      >
                        {languageSNData && languageSNData["inbox_idnumber_text"]
                          ? languageSNData["inbox_idnumber_text"]
                          : "ID Number"}
                      </div>
                      <div
                        style={{
                          fontSize: "16px",
                          color: "#767675",
                          height: "21px",
                          lineHeight: "21px",
                          marginTop: "8px",
                        }}
                      >
                        {ticket && ticket["customerTicketNumber"]}
                      </div>
                    </div>
                  )}
                </div>
                {ticket &&
                ticket["attachments"] &&
                ticket["attachments"]["attachments"][0] ? (
                  <div
                    style={{
                      marginTop: "32px",
                      height: "fit-content",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        height: "fit-content",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#0E090C",
                      }}
                    >
                      {languageSNData
                        ? languageSNData["inbox_updated_text"]
                        : ""}
                    </div>

                    <Attachments
                      style={{
                        height: "fit-content",
                        display: "flex",
                        width: "100%",
                        marginTop: "8px",
                        flexWrap: "wrap",
                        overflowY: "scroll",
                        padding: "0px 0px 0px 0px",
                      }}
                    >
                      {ticket &&
                        ticket["attachments"] &&
                        ticket["attachments"]["attachments"].map(
                          (attachment, index) => {
                            let attachmentName =
                              attachment["FileName"].split(".");
                            return (
                              <DownloadFile
                                key={index}
                                style={{
                                  height: "42px",
                                  display: "flex",
                                  borderRadius: "30px",
                                  border: "1px solid #E6E6E6",
                                  marginBottom: sidebar ? "5px" : "8px",
                                  cursor: "pointer",
                                }}
                              >
                                <div
                                  style={{
                                    width: "13%",
                                    paddingTop: "0px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      borderRadius: "100%",
                                      background: "#4A2878",
                                      height: "30px",
                                      width: "30px",
                                    }}
                                  >
                                    <Avatar
                                      homedata={
                                        attachment["UserFullName"]
                                          ? attachment["UserFullName"]
                                          : ticket && ticket["Name"]
                                      }
                                      FontSize="9.54545px"
                                      MarginLeft="6px"
                                      MarginTop="9px"
                                      inboxNameImage={true}
                                      Color={"#4A2878"}
                                      fontColor="white"
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "63%",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "20px",
                                      marginTop: "2px",
                                      fontSize: "14px",
                                      color: "#4A4A49",
                                      lineHeight: "20px",
                                      display: "flex",
                                    }}
                                  >
                                    {/* {attachment["FileName"]} */}
                                    <div
                                      style={{
                                        width: "auto",
                                        maxWidth: "70%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {attachmentName[0]}
                                    </div>
                                    <div
                                      style={{
                                        width: "30%",
                                        margin: "0",
                                        padding: "0",
                                      }}
                                    >
                                      {"." +
                                        attachmentName[
                                          attachmentName.length - 1
                                        ]}
                                    </div>
                                  </div>
                                  {attachment && attachment["Updated"] && (
                                    <div
                                      style={{
                                        height: "12px",
                                        width: "75px",
                                        marginTop: "0px",
                                        fontSize: "12px",
                                        color: "#767675",
                                        lineHeight: "12px",
                                      }}
                                    >
                                      {attachment["Updated"]}
                                    </div>
                                  )}
                                </div>
                                <div
                                  style={{
                                    width: "12%",
                                  }}
                                  className="download"
                                >
                                  <button
                                    className="back-button-hover"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={
                                      languageSNData &&
                                      languageSNData[
                                        "inboxchat_downloadicontooltip_text"
                                      ]
                                        ? languageSNData[
                                            "inboxchat_downloadicontooltip_text"
                                          ]
                                        : "Download"
                                    }
                                    data-tooltip-offset={4}
                                    style={{
                                      background: "none",
                                      border: "none",
                                      paddingRight: "10px",
                                    }}
                                    onClick={() => base64toBlob(attachment)}
                                  >
                                    <Download scale={0.8} altText="Download" />
                                  </button>
                                </div>
                                <div
                                  style={{
                                    width: "12%",
                                    marginTop: "-1px",
                                  }}
                                  className="delete"
                                >
                                  <button
                                    className="back-button-hover"
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={
                                      languageSNData &&
                                      languageSNData[
                                        "inboxdelete_toastmessage_buttondelete"
                                      ]
                                        ? languageSNData[
                                            "inboxdelete_toastmessage_buttondelete"
                                          ]
                                        : "Delete"
                                    }
                                    data-tooltip-offset={4}
                                    style={{
                                      background: "none",
                                      border: "none",
                                      marginLeft: "1px",
                                      paddingLeft: "9px",
                                    }}
                                    onClick={() =>
                                      showToastMassage(true, attachment)
                                    }
                                  >
                                    <Delete scale={0.6} altText="Delete" />
                                  </button>
                                </div>
                              </DownloadFile>
                            );
                          }
                        )}
                    </Attachments>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            {displaySize !== "isMobile" && (
              <div style={{ height: "6%" }}>
                <div style={{ display: "flex", height: "29px" }}>
                  <SeeDetailContainer>
                    <div
                      style={{
                        borderBottom: "1px solid #E6E6E6",
                        height: "1px",
                        marginTop: "15px",
                      }}
                    ></div>
                  </SeeDetailContainer>
                  <ViewCommentButton
                    style={{
                      width: "132px",
                      background: "white",
                    }}
                  >
                    <div
                      onClick={() => {
                        setDetail(!detail);
                        setShowMore(false);
                      }}
                      style={{
                        height: "27px",
                        marginTop: "4px",
                        cursor: "pointer",
                        textAlign: "center",
                        display: "flex",
                        paddingRight: "5px",
                      }}
                    >
                      <div style={{ width: sidebar ? "95%" : "80%" }}>
                        {detail ? (
                          <p
                            className="FontInter"
                            style={{
                              color: "#DD296E",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {languageSNData &&
                            languageSNData["inbox_hidedetails_text"]
                              ? languageSNData["inbox_hidedetails_text"]
                              : "Hide details"}
                          </p>
                        ) : (
                          <p
                            className="FontInter"
                            style={{
                              color: "#DD296E",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {languageSNData &&
                            languageSNData["inbox_showdetails_text"]
                              ? languageSNData["inbox_showdetails_text"]
                              : "Show details"}
                          </p>
                        )}
                      </div>
                      {detail ? (
                        <div style={{ width: sidebar ? "27%" : "20%" }}>
                          <UpArrow scale={0.6} altText={"Down_Arrow"} />
                        </div>
                      ) : (
                        <div
                          style={{
                            width: sidebar ? "30%" : "20%",
                            paddingLeft: "3px",
                          }}
                        >
                          <DownArrow scale={0.6} altText={"Up_Arrow"} />
                        </div>
                      )}
                    </div>
                  </ViewCommentButton>
                  <SeeDetailContainer>
                    <div
                      style={{
                        border: "0.5px solid #E6E6E6",
                        height: "1px",
                        marginTop: "15px",
                      }}
                    ></div>
                  </SeeDetailContainer>
                </div>
              </div>
            )}
            {displaySize !== "isMobile" && (
              <div
                style={{
                  height: "94%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {ticket && ticket["isResolved"] ? (
                  <Reopen ticket={ticket} sidebar={sidebar} />
                ) : ticket["CommentsSupported"] ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* {ticket && ticket["active"] === false && (
                    <div style={{ width: "323px", height: "60px" }}>
                      {languageSNData
                        ? languageSNData["inbox_nocommentsupporttitle_text"]
                        : ""}
                    </div>
                  )} */}
                    <ProgressBar
                      style={
                        sidebar
                          ? {}
                          : {
                              width: "323px",
                              height: "108px",
                            }
                      }
                    >
                      <TicketProgressBar ticket={ticket} sidebar={sidebar} />
                    </ProgressBar>
                  </div>
                ) : (
                  <Ticketprogress ticket={ticket} sidebar={sidebar} />
                )}
              </div>
            )}
          </StatusContainer>
        </div>
      ) : languageSNData && languageSNData["inbox_ticketisnotselected_text"] ? (
        languageSNData["inbox_ticketisnotselected_text"]
      ) : (
        "The ticket is not selected"
      )}
    </div>
  );
};

export default InboxHeader;
