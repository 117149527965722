import Checkmark from "../../Icons/Checkmark_icon";
import OfflinePageShow from "../../Icons/OfflinePage_icon";
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";
import { ContentContainer, HeadingContainer, NoInternet, OfflinePageMainContainer, RefreshButtonContainer, StepOne, StepThree, StepTwo } from "./StyledComponent";

type OfflinePageProps = {
};

const OfflinePage: React.FC<OfflinePageProps> = ( {  } ) => {  

    const languageSNData = uselanguageSNData();

    function refreshThePage(){
        window.location.reload();
    }
    return(
        <OfflinePageMainContainer>
            <div className="items-container">
                <NoInternet >
                    <div className="icon-container">
                        <OfflinePageShow scale={2.5} altText="No Internet" />
                    </div>
                </NoInternet>
                <HeadingContainer >
                    <div className="heading-title">
                        {languageSNData && languageSNData["offlinepage_headingtitle_text"]
                        ? 
                            languageSNData["offlinepage_headingtitle_text"] 
                        : 
                            "Your Internet is little wonky"
                        }
                    </div>
                </HeadingContainer>
                <ContentContainer>
                    <div className="step-container">
                        <div className="step-title-container">
                            {languageSNData && languageSNData["offilepage_steptitle_text"]
                                ? 
                                    languageSNData["offilepage_steptitle_text"] 
                                :   
                                    "Try these step to get back online:"
                            }
                        </div>
                        <div className="steps" >
                            <StepOne>
                                <div className="check-icon-stepone">
                                    <Checkmark scale={0.9} altText="No Internet check" viewIn="search_page" />
                                </div>
                                <div className="stepone-text">
                                    {languageSNData && languageSNData["offilepage_checkmorderrouter_text"]
                                        ? 
                                        languageSNData["offilepage_checkmorderrouter_text"] 
                                        : 
                                            "Check your modem and router"
                                    }
                                </div>
                            </StepOne>
                            <StepTwo>
                                <div className="check-icon-steptwo">
                                    <Checkmark scale={0.9} altText="No Internet check" viewIn="search_page" />
                                </div>
                                <div className="steptwo-text">
                                    {languageSNData && languageSNData["offlinepage_checkyourcable_text"]
                                        ?   
                                            languageSNData["offlinepage_checkyourcable_text"] 
                                        : 
                                            "Check your cables"
                                    } 
                                </div>
                            </StepTwo>
                            <StepThree>
                                <div className="check-icon-stepthree">
                                    <Checkmark scale={0.9} altText="No Internet check" viewIn="search_page" />
                                </div>
                                <div className="stepthree-text">
                                    {languageSNData && languageSNData["offlinepage_reconnecttowifi_text"]
                                        ? 
                                            languageSNData["offlinepage_reconnecttowifi_text"] 
                                        : 
                                            "Reconnect to Wi-Fi"
                                    }
                                </div>
                            </StepThree>
                        </div>
                    </div>
                </ContentContainer>
                <RefreshButtonContainer onClick={refreshThePage}>
                    <button className="button-container">
                        { languageSNData && languageSNData["offlinepage_refreshbutton_text"]
                            ? 
                                languageSNData["offlinepage_refreshbutton_text"] 
                            :   
                                "Refresh"
                        }
                    </button>
                </RefreshButtonContainer>
            </div>
        </OfflinePageMainContainer>
    )
}

export default OfflinePage