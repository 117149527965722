import React, { useState, useRef, useEffect } from "react";
import {
    useTicketData
  } from "../../hooks/useMenuContext/useMenuContext";
import {useinboxSNData } from "../../hooks/UseApiContext/UseApiContext";
import {PillContainer,PillButtonContainer} from "./style"
  

type PinkPillButtonProps = {};

const PinkPillButton: React.FC<PinkPillButtonProps> = ({}) => {
      const ticketData = useTicketData();
      const inboxSNData = useinboxSNData();

    return (
        <PillButtonContainer>
             {(ticketData &&
                ticketData["meta"] &&
                ticketData["meta"]["PinkPillCounter"] &&
                ticketData["meta"]["PinkPillCounter"] !== 0) ||
                (inboxSNData &&
                    inboxSNData["meta"] &&
                    inboxSNData["meta"]["PinkPillCounter"] &&
                    inboxSNData["meta"]["PinkPillCounter"] !== 0) ? (
                    <PillContainer>
                           {ticketData && Object.keys(ticketData).length !== 0
                            ? ticketData &&
                            ticketData["meta"] &&
                            ticketData["meta"]["PinkPillCounter"]
                            : inboxSNData &&
                            inboxSNData["meta"] &&
                            inboxSNData["meta"]["PinkPillCounter"]}
                    </PillContainer>
                ): ("")}
        </PillButtonContainer>
    )
}
export default PinkPillButton