import styled from 'styled-components';

export const Menucontainer = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
  justify-content: flex-start;
  align-items: flex-start;
  background: yellow;
  }
`;

export const Container = styled.div`
   min-Width: 458px;
   @media (max-width: 480px) {
    min-Width: 90%;
  }
  
`;
export const MobileMainPageContainer = styled.div`
  width: 100%;
`;
export const MainPageHeader = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
`;
export const CallButton = styled.div`
  width:fit-content;
  color: #FFFFFF;
  border-radius: 3px;
  background: #DD296E; 
  padding: 7px 16px;
  cursor: pointer;
  :hover {
    background: linear-gradient(90deg, #CF022B 0%, #EA5599 100%);    
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }
    @media (max-width: 480px) {
      padding: 7px 20px;
    }
`;
export const EditNumberContainer = styled.div`
width: 20px;
height: 20px;
padding: 4px;
margin-Left: 3px;
margin-top: 1px;
@media (max-width: 480px) {
   
}
`;