import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as rowContent } from "./rowContent.svg";
import { ReactComponent as columnContent } from "./columnContent.svg";
import styled from "styled-components";
const ViewRowContentIcon = styled(rowContent)`
  fill: #959199;
`;
const ViewColumnContentIcon = styled(columnContent)`
  fill: #959199;
`;

type ViewContentLogoProps = {
  scale?: number;
  altText: string;
  clicked: any;
};

const ViewContent: React.FC<ViewContentLogoProps> = ({
  scale = 1,
  altText,
  clicked,
}) => {
  return (
    <IconWrapper
      width={2 * scale}
      height={2 * scale}
      aria-label={altText}
      style={{
        width: "1rem",
        marginTop: "0px",
        marginLeft: altText === "column" ? "2px" : "",
      }}
    >
      {altText === "row" ? (
        <ViewRowContentIcon
          style={{ fill: clicked === "row" ? "#212121" : "#959199" }}
        />
      ) : (
        <ViewColumnContentIcon
          style={{ fill: clicked === "column" ? "#212121" : "#959199" }}
        />
      )}
    </IconWrapper>
  );
};

export default ViewContent;
