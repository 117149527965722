import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as icon } from './LanguageIcon.svg';
import styled from "styled-components";
const Icon = styled(icon)`
  
`

type LanguageIconProps = {
  scale?: number;
  altText: string;
};

const LanguageIcon: React.FC<LanguageIconProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2 * scale} height={2.1 * scale} aria-label={altText} >
      <Icon/>
    </IconWrapper>
  );
};

export default LanguageIcon;