import styled from "styled-components";

type ChatDivProps = {
  height: any;
};
type DocumentFileProps = {
  progress: any;
};

export const SendButton = styled.div`
  background-color: #dd296e;
  border-radius: 4px;

  :hover {
    background: linear-gradient(90deg, #cf022b 0%, #ea5599 100%);
  }
`;
export const ChatDiv = styled.div<ChatDivProps>`
  height: ${(props: ChatDivProps) => props.height};
`;

export const DownloadFile = styled.div`
  width: 286px;
  margin-right: 8px;
  .download {
    visibility: hidden;
    background-color: none;
  }
  :hover {
    background: #ebeaf4 !important;
    color: black !important;
    .download {
      visibility: visible;
      background: #ebeaf4 !important;
    }
  }
  @media (max-width: 1062px) {
    margin-right: 4px;
  }
`;

export const DocumentFile = styled.div<DocumentFileProps>`
position: relative;

&::after{ 
  content: ""; 
  position: absolute;
  background: rgba(225, 214, 244, .3);
  width: ${(props: DocumentFileProps) =>  props.progress}%;
  height: 100%;
  .filename{
    font-weight: 800;
  }
}
&:hover::after{
  width: ${(props: DocumentFileProps) =>  props.progress-8.5}%;
}
`
