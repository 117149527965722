import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as inbox } from "./Inbox.svg";
import { ReactComponent as activeinbox } from "./ActiveInbox.svg";
import styled from "styled-components";
const InboxIcon = styled(inbox)``;
const ActiveInboxIcon = styled(activeinbox)``;

type InboxLogoProps = {
  scale?: number;
  altText: string;
};

const Inbox: React.FC<InboxLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      {altText === "active" ? <ActiveInboxIcon /> : <InboxIcon />}
    </IconWrapper>
  );
};

export default Inbox;
