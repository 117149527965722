import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as Icon } from './Icon.svg';

type LoginIconProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: string;
  /**
   * If true the icon would have white text instead of black.
   */
  light: boolean;
  theme:any;
};

const LoginIcon: React.FC<LoginIconProps> = ({ scale = 1, altText}) => {
  return (
    // <IconWrapper width={8 * scale} height={2 * scale} aria-label={altText}>
       <Icon/>
    // </IconWrapper>
  );
};

export default LoginIcon;