import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as download } from "./download.svg";
import { ReactComponent as downloadPink } from "./downloadPink.svg";
import styled from "styled-components";
const DownloadIcon = styled(download)``;
const DownloadPinkIcon = styled(downloadPink)``;

type DownloadLogoProps = {
  scale?: number;
  altText: string;
  colour?: string;
};

const Download: React.FC<DownloadLogoProps> = ({
  scale = 1,
  altText,
  colour,
}) => {
  return (
    <IconWrapper
      width={2 * scale}
      height={2 * scale}
      aria-label={altText}
      style={{
        width: "1rem",
        marginLeft: altText === "Download" ? "-4px" : "",
      }}
    >
      {colour === "pink" ? <DownloadPinkIcon /> : <DownloadIcon />}
    </IconWrapper>
  );
};

export default Download;
