import styled from "styled-components";

export const SidebarContainer = styled.div`
  width: 296px;
  // @media (min-width: 1350px) {
  //   width: 26.6%;
  // }
  // @media (min-width: 1600px) {
  //   width: 23.6%;
  // }
  // @media (min-width: 1900px) {
  //   width: 22.6%;
  // }
`;

export const ChatContainer = styled.div`
  width: calc(100% - 296px);
  // @media (min-width: 1350px) {
  //   width: 73.4%;
  // }
  // @media (min-width: 1600px) {
  //   width: 76.4%;
  // }
  // @media (min-width: 1900px) {
  //   width: 77.4%;
  // }
`;
export const DetailContainer = styled.div`
  width: calc(100% - 360px);
  // @media (min-width: 1350px) {
  //   width: 65%;
  // }
  // @media (min-width: 1600px) {
  //   width: 70%;
  // }
  // @media (min-width: 1900px) {
  //   width: 75%;
  // }
`;

export const MenuContainer = styled.div`
  width: 360px;
  // @media (min-width: 1350px) {
  //   width: 35%;
  // }
  // @media (min-width: 1600px) {
  //   width: 30%;
  // }
  // @media (min-width: 1900px) {
  //   width: 25%;
  // }
`;
