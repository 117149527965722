import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as request_form } from './cartIcon.svg';
import styled from "styled-components";
const RequestFormIcon = styled(request_form)`
  
`

type RequestFormIconLogoProps = {
  scale?: number;
  altText: string;
  viewType: string;
};

const RequestForm: React.FC<RequestFormIconLogoProps> = ({ scale = 1, altText, viewType }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{"width": "1rem", marginTop: viewType === "row"? '5px' : '3px', marginLeft: viewType === "row"? '-2px' : '0px'}}>
      <RequestFormIcon/>
    </IconWrapper>
  );
};

export default RequestForm;