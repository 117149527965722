import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as send } from './send.svg';
import { ReactComponent as send_pink } from './sendWhite.svg';
import styled from "styled-components";
const SendIcon = styled(send)`
  
`
const Send_PinkIcon = styled(send_pink)`
  
`

type SendLogoProps = {
  scale?: number;
  altText: string;
  color: any;
};

const Send: React.FC<SendLogoProps> = ({ scale = 1, altText, color }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{ "width": "1rem", marginTop: '-5px' }}>
      {
        color === "White" ?
          <Send_PinkIcon />
          : <SendIcon />
      }

    </IconWrapper>
  );
};

export default Send;