import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as ticket } from "./Shapeticket.svg";
import styled from "styled-components";
const TicketIcon = styled(ticket)``;

type TicketIconProps = {
  scale?: number;
  altText: string;
};

const Ticket: React.FC<TicketIconProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={1.5 * scale} height={1.8 * scale} aria-label={altText}>
      <TicketIcon />
    </IconWrapper>
  );
};

export default Ticket;
