import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as error } from './error501.svg';
import styled from "styled-components";
const ErrorIcon = styled(error)`
  
`

type ErrorLogoProps = {
  scale?: number;
  altText: string;
};

const Error501: React.FC<ErrorLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{"width": "1rem"}}>
      <ErrorIcon/>
    </IconWrapper>
  );
};

export default Error501;