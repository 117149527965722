// @ts-nocheck
//Library
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import OneSignal from "react-onesignal";
import QRCode from "qrcode.react";

//Files & Functions
import {
  useNavigationToggle,
  useSetNavigationToggle,
  useSetLanguageNavigationToggle,
  useLanguageNavigationToggle,
  useSetChangeLanguage,
  useChangeLanguage,
  useListening,
  useSetListening,
  useAboutSection,
  useSetAboutSection,
  usedisplaySize,
  useOpenChatWindow,
  useSetOpenChatWindow,
  useUserNotificationId,
  useLanguageData,
  useHomeRecall,
  useSetHomeRecall,
} from "../../hooks/useMenuContext/useMenuContext";
import {
  usehomeSNData,
  uselanguageSNData,
} from "../../hooks/UseApiContext/UseApiContext";
import config from "../../constant/config";
import { getSNJSON } from "../../api/index";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import packageJson from "../../../package.json";
import redirectUri from "../../constant/config";

//Components
import Avatar from "../AvatarComps";

//Icons
import Close from "../../Icons/Header_close_icon/Close";
import ExclamationMark from "../../Icons/Exclamation_mark";
import Back from "../../Icons/Back_icon/Back";
import ProfileIcon from "../../Icons/ProfileIcon";
import LanguageIcon from "../../Icons/LanguageIcon";
import CheckMarkIcon from "../../Icons/CheckMarkIcon";
import CheckmarkRed from "../../Icons/CheckMarkRed_icon";
import Information from "../../Icons/Information_icon";
import Incedent from "../../Icons/Incedent_icon/Incedent";
import IncedentBlack from "../../Icons/IncidentBlack_icon";
import AlertIcon from "../../Icons/Alert_icon";
import DownloadIcon from "../../Icons/Download_icon";
import ToggleSwitch from "../SwitchButton";
import { useCookies } from "react-cookie";

type MenuProps = {};

const Menu: React.FC<MenuProps> = ({}) => {
  const ref = useRef();
  const nRef = useRef();
  const aboutRef = useRef();
  const [fetchsetPreferenceJSON, performFetchSetPreferenceJSON] =
    useApiAction(getSNJSON);
  const [postAppVersionJSON, performAppVersionJSON] = useApiAction(getSNJSON);
  const [setpreference, setSetPreference] = useState();
  const navigationToggle = useNavigationToggle();
  const setNavigationToggle = useSetNavigationToggle();
  const languageNavigationToggle = useLanguageNavigationToggle();
  const setLanguageNavigationToggle = useSetLanguageNavigationToggle();
  const setChangeLanguage = useSetChangeLanguage();
  const changeLanguage = useChangeLanguage();
  const languageData = useLanguageData();
  const homeSNData = usehomeSNData();
  const homeRecall = useHomeRecall();
  const setHomeRecall = useSetHomeRecall();
  const openChatWindow = useOpenChatWindow();
  const setOpenChatWindow = useSetOpenChatWindow();
  var ProfilePic =
    homeSNData &&
    homeSNData["userDetails"] &&
    homeSNData["userDetails"]["ProfilePic"];
  const languageSNData = uselanguageSNData();
  const [notificationToggle, setNotificationToggle] = useState(false);
  const aboutSection = useAboutSection();
  const setAboutSection = useSetAboutSection();
  const listening = useListening();
  const setlistening = useSetListening();
  let os_name = getOperatingSystem(window);
  const [cookies, setCookie, removeCookie] = useCookies(["name"]);
  const displaySize = usedisplaySize();
  const [notificationSubscribe, setNotificationSubscribe] = useState("");
  const userNotificationId = useUserNotificationId();
  const optedIn = OneSignal.User.PushSubscription.optedIn;
  const [updatedVersion, setUpdatedVersion] = useState("");
  const [download, setDownload] = useState(false);

  function SNLogin() {
    removeCookie("name", { path: "/" });
  }

  useEffect(() => {
    if (optedIn && userNotificationId && userNotificationId !== undefined) {
      setNotificationSubscribe(true);
    } else {
      setNotificationSubscribe(false);
    }
  }, [optedIn, userNotificationId]);

  // replace OS string
  let notification_message = languageSNData
    ? languageSNData["about_content"]
    : "";
  notification_message = notification_message.replace(
    "$supported_os$",
    os_name
  );

  // get Apversion dynamic
  var appVersion = document.cookie
    .split("; ")
    .find((row) => row.startsWith("AppVersion"))
    ?.split("=")[1];

  useEffect(() => {
    if (postAppVersionJSON && postAppVersionJSON.data) {
      setHomeRecall(!homeRecall);
    }
  }, [postAppVersionJSON.data]);

  useEffect(() => {
    if (updatedVersion !== "") {
      var Feild = [
        "Post",
        "urlAppVersion",
        "",
        { latestversion: updatedVersion },
      ];
      performAppVersionJSON(Feild);
    }
  }, [updatedVersion]);

  useEffect(() => {
    const handleMessageFromServiceWorker = (event) => {
      if (typeof event.data === "string") {
        setUpdatedVersion(event.data);
      }
    };

    navigator.serviceWorker.addEventListener(
      "message",
      handleMessageFromServiceWorker
    );

    // Clean up the event listener
    return () => {
      navigator.serviceWorker.removeEventListener(
        "message",
        handleMessageFromServiceWorker
      );
    };
  }, []);

  // var reactAppVersion = packageJson.version;

  useEffect(() => {
    if (fetchsetPreferenceJSON.data && fetchsetPreferenceJSON.data["result"]) {
      setSetPreference(fetchsetPreferenceJSON.data.result);
      setChangeLanguage(!changeLanguage);
      window.location.reload();
      // window.electron.notificationApi.chileReload("chileReload");
    }
  }, [fetchsetPreferenceJSON.data, setpreference]);

  const navigate = useNavigate();
  const { instance } = useMsal();

  const navigatepage = () => {
    navigate("/", { replace: true });
  };

  function LanguageSelector(query_Parameter) {
    var Feild = ["Get", "urlChangeLanguage", query_Parameter, {}];
    performFetchSetPreferenceJSON(Feild);
    setNavigationToggle(displaySize !== "isMobile" ? false : true);
    setLanguageNavigationToggle(false);
  }

  function signOutClickHandler(instance) {
    const logoutRequest = {
      account: instance.getAccountByHomeId(config.clientId),
      mainWindowRedirectUri: config.redirectUri,
    };
    instance.logoutPopup(logoutRequest);
  }
  const handleLogout = (instance) => {
    navigatepage();
    signOutClickHandler(instance);
    localStorage.clear();
    SNLogin();
    if (openChatWindow) {
      openChatWindow.close();
      setOpenChatWindow(null);
    }
  };

  function ShowLanguage() {
    setNavigationToggle(displaySize !== "isMobile" ? false : true);
    setLanguageNavigationToggle((oldState) => !oldState);
    setNotificationToggle(false);
    setAboutSection(false);
  }

  function CloseMenu() {
    setNavigationToggle(displaySize !== "isMobile" ? true : false);
    setLanguageNavigationToggle(displaySize !== "isMobile" ? true : true);
  }

  function showNotification() {
    setNotificationToggle(true);
    setNavigationToggle(displaySize !== "isMobile" ? false : true);
    setLanguageNavigationToggle(true);
    setlistening(!listening);
  }

  function closeNotification() {
    setNotificationToggle(false);
    setNavigationToggle(displaySize !== "isMobile" ? false : true);
  }

  function showAboutSection(download) {
    setAboutSection(true);
    setNavigationToggle(displaySize !== "isMobile" ? true : false);
    setLanguageNavigationToggle(true);
    download === "download" ? setDownload(true) : setDownload(false);
  }

  function closeAboutSection() {
    setAboutSection(false);
    setNavigationToggle(displaySize !== "isMobile" ? false : true);
    setLanguageNavigationToggle(true);
  }

  function clickedCheck(path) {
    navigate("/article?sys_id=" + path);
    setNavigationToggle(displaySize !== "isMobile" ? true : false);
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        languageNavigationToggle &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setLanguageNavigationToggle(true);
        // setAboutSection(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [languageNavigationToggle]);

  useEffect(() => {
    const clickoutsideOfNotification = (e) => {
      if (nRef.current && !nRef.current.contains(e.target)) {
        setNotificationToggle(false);
        setlistening(true);
      }
    };
    document.addEventListener("click", clickoutsideOfNotification);
    return () => {
      document.removeEventListener("click", clickoutsideOfNotification);
    };
  });

  useEffect(() => {
    const clickoutsideOfNotification = (e) => {
      if (aboutRef.current && !aboutRef.current.contains(e.target)) {
        setAboutSection(false);
      }
    };
    document.addEventListener("click", clickoutsideOfNotification);
    return () => {
      document.removeEventListener("click", clickoutsideOfNotification);
    };
  });

  // Get operating System name
  function getOperatingSystem(window) {
    let operatingSystem = "Not known";
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      operatingSystem = "Windows OS";
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      operatingSystem = "MacOS";
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
      operatingSystem = "UNIX OS";
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      operatingSystem = "Linux OS";
    }
    return operatingSystem;
  }

  return (
    <div>
      {displaySize !== "isMobile" ? (
        <div>
          <div
            style={{
              display:
                navigationToggle === false || languageNavigationToggle
                  ? "none"
                  : "block",
              border: "1px solid #E6E6E6",
              padding: "16px 20px",
              width: "250px",
              height: "124px",
              background: "#FFFFFF",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              position: "absolute",
              left: "340px",
              top: "196px",
              zIndex: "102",
              borderRadius: "3px",
            }}
          >
            <div style={{ width: "208px", height: "92px", padding: "8px 0px" }}>
              <div
                className="back-button-hover"
                onClick={() => LanguageSelector("en")}
                style={{
                  cursor: "pointer",
                  height: "34px",
                  color: "#07284B",
                  fontSize: "14px",
                  fontWeight: "600",
                  padding: "5px 7px",
                  display: "flex",
                }}
              >
                <div style={{ width: "85%" }}>English</div>
                {homeSNData["userDetails"] &&
                homeSNData["userDetails"]["language"] === "en" ? (
                  <CheckMarkIcon scale={0.6} altText="CheckMarkIcon" />
                ) : (
                  ""
                )}
              </div>
              <div
                className="back-button-hover"
                onClick={() => LanguageSelector("nb")}
                style={{
                  height: "34px",
                  marginTop: "8px",
                  color: "#07284B",
                  fontSize: "14px",
                  fontWeight: "600",
                  padding: "5px 7px",
                  display: "flex",
                }}
              >
                <div style={{ width: "85%" }}>Norsk</div>
                {homeSNData["userDetails"] &&
                homeSNData["userDetails"]["language"] === "nb" ? (
                  <CheckMarkIcon scale={0.6} altText="CheckMarkIcon" />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {/* NotificationToggle */}
          <div
            style={{
              display:
                notificationToggle === false || listening ? "none" : "block",
              width: "100%",
              height: "100%",
              background: "red",
              position: "absolute",
              background: "black",
              opacity: "25%",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              borderRadius: "3px",
              top: "0%",
              left: "0%",
              zIndex: "102",
            }}
          ></div>
          <div
            style={{
              display:
                notificationToggle === false || listening ? "none" : "block",
              position: "absolute",
              width: "520px",
              height: "528px",
              background: "#FFFFFF",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              left: "260px",
              top: "90px",
              zIndex: "102",
              borderRadius: "3px",
              cursor: "default",
            }}
          >
            <div
              className="back-button-hover"
              onClick={closeNotification}
              style={{
                width: "25px",
                height: "27px",
                position: "absolute",
                left: "470px",
                top: "14px",
                cursor: "pointer",
                paddingLeft: "1.2px",
                borderRadius: "4px",
              }}
            >
              <Close scale={0.7} altText="Close" color="" />
            </div>
            <div
              style={{
                height: "66px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "1px solid #E6E6E6",
              }}
            >
              <div
                style={{
                  fontSize: "28px",
                  fontWeight: "400",
                  width: "fit-content",
                  height: "fit-content",
                }}
              >
                {languageSNData &&
                languageSNData["hedermenu_notificationcenter_titletext"]
                  ? languageSNData["hedermenu_notificationcenter_titletext"]
                  : "Notifications Center"}
              </div>
            </div>
            <div
              style={{
                overflowY: "scroll",
                marginLeft: "10px",
                height: "calc(100% - 66px)",
              }}
            >
              {homeSNData["notificationCenterNotifications"] &&
              homeSNData["notificationCenterNotifications"].length > 0 ? (
                homeSNData["notificationCenterNotifications"].map(
                  (item, key) => {
                    return (
                      <div
                        key={key}
                        className="back-button-hover"
                        style={{
                          minHeight: "30px",
                          height: "auto",
                          maxHeight: "130px",
                          width: "500px",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 0px 10px 10px",
                          borderBottom: "1px solid #E6E6E6",
                          cursor: "default",
                        }}
                      >
                        <div
                          style={{
                            minHeight: "23px",
                            height: "auto",
                            maxHeight: "130px",
                            width: "8%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ height: "fit-content" }}>
                            {item["Importance"] === "system_down" ? (
                              <Incedent scale={0.7} altText="Close" />
                            ) : (
                              <IncedentBlack
                                scale={0.7}
                                altText="BlackIncident"
                              />
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            height: "fit-content",
                            width: item["ViewArticle"] ? "87%" : "92%",
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          {/* <div style={{ height: "20px", fontSize: "14px", display: "flex", alignItems: "center", color: "#49484D", fontFamily: "Inter" }}>
                                            {item["Company"]}
                                        </div> */}
                          <div
                            style={{
                              width: "90%",
                              minHeight: "30px",
                              height: "auto",
                              maxHeight: "103px",
                              fontSize: "14px",
                              color: "#959199",
                              fontFamily: "Inter",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ height: "fit-content" }}>
                              {item["Message"]}
                            </div>
                          </div>
                        </div>
                        {item["ViewArticle"] ? (
                          <div
                            style={{
                              height: "auto",
                              minHeight: "25px",
                              maxHeight: "100px",
                              width: "fit-content",
                              display: "flex",
                              alignItems: "end",
                              wordWrap: "nowrap",
                              wordBreak: "normal",
                            }}
                          >
                            <div
                              style={{
                                textDecoration: "underline",
                                textUnderlineOffset: "3px",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#49484D",
                                cursor: "pointer",
                                paddingBottom: "0px",
                                height: "fit-content",
                                width: "fit-content",
                              }}
                              onClick={() => clickedCheck(item["ViewArticle"])}
                            >
                              {languageSNData &&
                              languageSNData["altermessage_open_text"]
                                ? languageSNData["altermessage_open_text"]
                                : "Open"}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  }
                )
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "fit-content",
                      textAlign: "center",
                      width: "436px",
                      fontSize: "18px",
                      lineHeight: "22px",
                      fontFamily: "Inter",
                      color: "#959199",
                      fontWeight: "400px",
                    }}
                  >
                    {languageSNData
                      ? languageSNData[
                          "notification_center_no_notification_text"
                        ]
                      : ""}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* About Section */}
          <div
            onClick={closeAboutSection}
            style={{
              display: aboutSection ? "block" : "none",
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "black",
              opacity: "25%",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              borderRadius: "3px",
              top: "0%",
              left: "0%",
              zIndex: "100",
            }}
          ></div>
          <div
            ref={aboutRef}
            style={{
              display: aboutSection ? "block" : "none",
              position: "absolute",
              width: "500px",
              height: download ? "300px" : "264px",
              background: "#FFFFFF",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              borderRadius: "3px",
              top: "calc(50% - 132px)",
              left: "calc(50% - 250px)",
              zIndex: "101",
            }}
          >
            <div
              className="back-button-hover"
              onClick={closeAboutSection}
              style={{
                width: "25px",
                height: "27px",
                position: "absolute",
                left: "470px",
                top: "14px",
                cursor: "pointer",
                paddingLeft: "1.2px",
                borderRadius: "4px",
              }}
            >
              <Close scale={0.7} altText="Close" color="" />
            </div>
            <div onClick={() => showAboutSection("about")}>
              <div
                style={{
                  height: "66px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "1px solid #E6E6E6",
                }}
              >
                <div
                  style={{
                    fontSize: "28px",
                    fontWeight: "400",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  {languageSNData
                    ? download
                      ? "Download"
                      : languageSNData["about"]
                    : ""}
                </div>
              </div>
              <div style={{ paddingTop: "10px" }}>
                {!download && (
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#49484D",
                      fontFamily: "Inter",
                      overflowY: "hidden",
                      textAlign: "center",
                      height: "fit-content",
                      paddingTop: "40px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: languageSNData
                        ? notification_message.replace(
                            "$electron_version$",
                            homeSNData &&
                              homeSNData["userDetails"] &&
                              homeSNData["userDetails"]["app_version"]
                              ? homeSNData["userDetails"]["app_version"]
                              : "2.0.0"
                          )
                        : "",
                    }}
                  ></div>
                )}
                {download && (
                  <QRCode
                    style={{ margin: "40px 180px" }}
                    value={redirectUri.redirectUri}
                    renderAs="canvas"
                  />
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: navigationToggle === false ? "none" : "block",
              width: "100%",
              height: "100%",
              background: "red",
              position: "absolute",
              background: "black",
              opacity: "25%",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              borderRadius: "3px",
              top: "0%",
              left: "0%",
              zIndex: "101",
            }}
          ></div>
          <div
            style={{
              cursor: "default",
              display: navigationToggle === false ? "none" : "block",
              border: "1px solid #DDDDDD",
              position: "absolute",
              marginTop: "10px",
              width: "350px",
              height: "430px",
              background: "#FFFFFF",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              listStyle: "none",
              borderRadius: "3px",
              padding: "16px",
              zIndex: "101",
              marginLeft: "-2px",
            }}
          >
            <div>
              <div style={{ height: "140px" }}>
                <div
                  style={{
                    height: "27px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div
                    className="back-button-hover"
                    onClick={CloseMenu}
                    style={{ cursor: "pointer", width: "25px", zIndex: "1" }}
                  >
                    <Close scale={0.7} altText="Close" color="" />
                  </div>
                </div>
                <div
                  onClick={() => setNavigationToggle(false)}
                  style={{
                    height: "55px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "54px",
                      height: "54px",
                      borderRadius: "100%",
                    }}
                    title=""
                  >
                    {homeSNData &&
                    homeSNData["userDetails"] &&
                    homeSNData["userDetails"]["ProfilePic"] === "" ? (
                      <Avatar
                        homedata={homeSNData && homeSNData["userDetails"]}
                        FontSize="30px"
                        MarginLeft="5px"
                        MarginTop="5px"
                        inboxNameImage={false}
                        Color="#FEEDF3"
                        fontColor="#DD296E"
                      />
                    ) : (
                      <img src={"data:image/png;base64," + ProfilePic} />
                    )}
                  </div>
                </div>
                <div
                  onClick={() => setNavigationToggle(false)}
                  style={{ height: "45px" }}
                >
                  <div
                    style={{
                      height: "19px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: " #4A4A49",
                      letterSpacing: "-0.01em",
                      textAlign: "center",
                    }}
                  >
                    {homeSNData["userDetails"]
                      ? homeSNData["userDetails"]["FirstName"] +
                        " " +
                        homeSNData["userDetails"]["LastName"]
                      : "Name ..."}
                  </div>
                  <div
                    style={{
                      height: "25px",
                      textAlign: "center",
                      fontSize: "16px",
                      color: "#755B98",
                    }}
                  >
                    {homeSNData["userDetails"]
                      ? homeSNData["userDetails"]["Email"]
                      : "Email ..."}
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "250px",
                  marginTop: "6px",
                }}
              >
                <div
                  className="back-button-hover"
                  style={{
                    cursor: "pointer",
                    height: "34px",
                    marginBottom: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "3px",
                    padding: "3px 5px 5px 5px",
                  }}
                >
                  <div style={{ width: "10%", paddingLeft: "2px" }}>
                    <AlertIcon scale={0.75} altText="" color="" />
                  </div>
                  <div
                    style={{
                      width: "75%",
                      color: "#07284B",
                      fontSize: "14px",
                      fontWeight: "600",
                      paddingTop: "4px",
                      paddingLeft: "3px",
                    }}
                  >
                    {languageSNData && languageSNData["headermenu_alert_text"]
                      ? languageSNData["headermenu_alert_text"]
                      : ""}
                  </div>
                  <div
                    style={{
                      width: "15%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ToggleSwitch
                      isNotificationSubscribe={notificationSubscribe}
                      optedIn={optedIn}
                    />
                  </div>
                </div>
                <div
                  ref={ref}
                  className="back-button-hover"
                  onClick={() => ShowLanguage()}
                  style={{
                    cursor: "pointer",
                    height: "34px",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "3px",
                    padding: "0px 5px 5px 5px",
                  }}
                >
                  <div style={{ width: "10%", padding: "4px 0px 2px 2px" }}>
                    <LanguageIcon
                      scale={0.7}
                      altText="ProfileIcon"
                    ></LanguageIcon>
                  </div>
                  <div
                    style={{
                      width: "81%",
                      color: "#07284B",
                      fontSize: "14px",
                      padding: "5px 0px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        fontWeight: "600",
                        color: "#07284B",
                        fontSize: "14px",
                        padding: "2px 0px 0px 0px",
                        height: "30px",
                      }}
                    >
                      {languageSNData
                        ? languageSNData["headermenu_language_selectlanguage"]
                        : ""}
                    </div>
                    <div style={{ width: "25%", color: "#8898A9" }}>
                      {homeSNData["userDetails"] &&
                      homeSNData["userDetails"]["language"] === "en"
                        ? "English"
                        : "Norsk"}
                    </div>
                  </div>
                  <div
                    style={{ width: "7%", height: "32px", paddingTop: "5px" }}
                  >
                    <Back scale={0.7} altText="Back" type="next"></Back>
                  </div>
                </div>
                <div
                  ref={nRef}
                  onClick={showNotification}
                  className="back-button-hover"
                  style={{
                    cursor: "pointer",
                    height: "34px",
                    marginTop: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "3px",
                    padding: "0px 5px 5px 5px",
                  }}
                >
                  <div style={{ width: "10%", padding: "6px 0px 0px 0px" }}>
                    <ExclamationMark
                      scale={1.4}
                      altText="ExclamationMark"
                      color="black"
                    />
                  </div>
                  <div
                    style={{
                      width: "81%",
                      color: "#07284B",
                      fontSize: "14px",
                      padding: "7px 0px",
                      fontWeight: "600",
                    }}
                  >
                    {languageSNData &&
                    languageSNData["headermenu_notifications_text"]
                      ? languageSNData["headermenu_notifications_text"]
                      : "Notifications"}
                  </div>
                  <div
                    style={{
                      width: "7%",
                      height: "34px",
                      paddingTop: "5px",
                    }}
                  >
                    <Back scale={0.7} altText="Back" type="next"></Back>
                  </div>
                </div>

                <div
                  className="back-button-hover"
                  onClick={() => showAboutSection("about")}
                  style={{
                    cursor: "pointer",
                    height: "34px",
                    marginTop: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "3px",
                    padding: "0px 5px 5px 5px",
                  }}
                >
                  <div style={{ width: "10%", padding: "1px 0px 0px 0px" }}>
                    <Information scale={0.8} altText="InformationMark" />
                  </div>
                  <div
                    style={{
                      width: "81%",
                      color: "#07284B",
                      fontSize: "14px",
                      padding: "7px 0px",
                      fontWeight: "600",
                    }}
                  >
                    {languageSNData
                      ? languageSNData["headermenue_about_text"]
                      : ""}
                  </div>
                  <div
                    style={{ width: "7%", height: "34px", paddingTop: "5px" }}
                  >
                    <Back scale={0.7} altText="Back" type="next"></Back>
                  </div>
                </div>

                {/* for the download */}
                <div
                  className="back-button-hover"
                  onClick={() => {
                    //console.log("what is in the download",redirectUri.redirectUri)
                    showAboutSection("download");
                  }}
                  style={{
                    cursor: "pointer",
                    height: "34px",
                    marginTop: "2px",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "3px",
                    padding: "5px 5px 5px 5px",
                  }}
                >
                  <div style={{ width: "10%", padding: "1px 0px 0px 0px" }}>
                    <DownloadIcon scale={0.7} altText="InformationMark" />
                  </div>
                  <div
                    style={{
                      width: "81%",
                      color: "#07284B",
                      fontSize: "14px",
                      padding: "2px 0px",
                      fontWeight: "600",
                    }}
                  >
                    {languageSNData && languageSNData["headermenu_downloadApp"]
                      ? languageSNData["headermenu_downloadApp"]
                      : "Download mobile app"}
                  </div>
                  <div style={{ width: "7%", height: "34px" }}>
                    <Back scale={0.7} altText="Back" type="next"></Back>
                  </div>
                </div>

                {/* for the download */}
                <div
                  style={{
                    height: "78px",
                    marginTop: "8px",
                    borderTop: "1px solid #D9D9D9",
                  }}
                >
                  <div
                    className="back-button-hover"
                    onClick={() => handleLogout(instance)}
                    style={{
                      cursor: "pointer",
                      height: "34px",
                      marginTop: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        color: " #DD296E",
                        fontSize: "16px",
                        padding: "5px 16px",
                        fontWeight: "600",
                      }}
                    >
                      {languageSNData
                        ? languageSNData["header_signout_gotologout"]
                        : ""}
                      {/* {languageData ?
                                        languageData["header_signout_gotologout"] :
                                        ""
                                    } */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        //for Mobile View : -
        <div>
          {/* NotificationToggle */}
          <div
            style={{
              display:
                notificationToggle === false || listening ? "none" : "block",
              width: "100%",
              height: "100%",
              background: "red",
              position: "absolute",
              background: "black",
              opacity: "25%",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              borderRadius: "3px",
              top: "0%",
              left: "0%",
              zIndex: "102",
            }}
          ></div>
          <div
            style={{
              display:
                notificationToggle === false || listening ? "none" : "block",
              position: "absolute",
              width: "100%",
              height: "95%",
              bottom: "0px",
              background: "#FFFFFF",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              zIndex: "102",
              borderRadius: "3px",
              cursor: "default",
            }}
          >
            <div
              style={{
                height: "66px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "1.2px solid #E6E6E6",
                marginTop: "16px",
                width: "92%",
                marginLeft: "4%",
                marginRight: "4%",
              }}
            >
              <div
                className="FontRoboto"
                style={{
                  fontSize: "28px",
                  fontWeight: "400",
                  width: "fit-content",
                  height: "fit-content",
                  lineHeight: "36px",
                }}
              >
                {languageSNData &&
                languageSNData["hedermenu_notificationcenter_titletext"]
                  ? languageSNData["hedermenu_notificationcenter_titletext"]
                  : "Notifications Center"}
              </div>
              <div
                className="back-button-hover"
                onClick={closeNotification}
                style={{
                  width: "25px",
                  height: "fit-content",
                  cursor: "pointer",
                  borderRadius: "4px",
                  position: "absolute",
                  right: "16px",
                  paddingTop: "3px",
                }}
              >
                <Close scale={0.7} altText="Close" color="" />
              </div>
            </div>
            <div
              style={{
                overflowY: "scroll",
                marginLeft: "10px",
                height: "calc(100% - 66px)",
              }}
            >
              {homeSNData["notificationCenterNotifications"] &&
              homeSNData["notificationCenterNotifications"].length > 0 ? (
                homeSNData["notificationCenterNotifications"].map(
                  (item, key) => {
                    return (
                      <div
                        key={key}
                        className="back-button-hover"
                        style={{
                          minHeight: "30px",
                          height: "auto",
                          maxHeight: "130px",
                          width: "95%",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 0px 10px 10px",
                          borderBottom: "1px solid #E6E6E6",
                          cursor: "default",
                        }}
                      >
                        <div
                          style={{
                            minHeight: "23px",
                            height: "auto",
                            maxHeight: "130px",
                            width: "8%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ height: "fit-content" }}>
                            {item["Importance"] === "system_down" ? (
                              <Incedent scale={0.7} altText="Close" />
                            ) : (
                              <IncedentBlack
                                scale={0.7}
                                altText="BlackIncident"
                              />
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            height: "fit-content",
                            width: item["ViewArticle"] ? "87%" : "92%",
                            display: "flex",
                            flexDirection: "column",
                            flex: "1",
                          }}
                        >
                          {/* <div style={{ height: "20px", fontSize: "14px", display: "flex", alignItems: "center", color: "#49484D", fontFamily: "Inter" }}>
                                            {item["Company"]}
                                        </div> */}
                          <div
                            style={{
                              width: "90%",
                              minHeight: "30px",
                              height: "auto",
                              maxHeight: "103px",
                              fontSize: "14px",
                              color: "#959199",
                              fontFamily: "Inter",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ height: "fit-content" }}>
                              {item["Message"]}
                            </div>
                          </div>
                        </div>
                        {item["ViewArticle"] ? (
                          <div
                            style={{
                              height: "auto",
                              minHeight: "25px",
                              maxHeight: "100px",
                              width: "fit-content",
                              display: "flex",
                              alignItems: "end",
                              wordWrap: "nowrap",
                              wordBreak: "normal",
                            }}
                          >
                            <div
                              style={{
                                textDecoration: "underline",
                                textUnderlineOffset: "3px",
                                fontSize: "12px",
                                fontWeight: "400",
                                color: "#49484D",
                                cursor: "pointer",
                                paddingBottom: "0px",
                                height: "fit-content",
                                width: "fit-content",
                              }}
                              onClick={() => clickedCheck(item["ViewArticle"])}
                            >
                              {languageSNData &&
                              languageSNData["altermessage_open_text"]
                                ? languageSNData["altermessage_open_text"]
                                : "Open"}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  }
                )
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "fit-content",
                      textAlign: "center",
                      width: "90%",
                      fontSize: "18px",
                      lineHeight: "22px",
                      fontFamily: "Inter",
                      color: "#959199",
                      fontWeight: "400px",
                      marginRight: "5%",
                    }}
                  >
                    {languageSNData
                      ? languageSNData[
                          "notification_center_no_notification_text"
                        ]
                      : ""}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* About Section */}
          <div
            onClick={closeAboutSection}
            style={{
              display: aboutSection ? "block" : "none",
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "black",
              opacity: "25%",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              borderRadius: "3px",
              top: "0%",
              left: "0%",
              zIndex: "100",
            }}
          ></div>
          <div
            //ref={aboutRef}
            style={{
              display: aboutSection ? "block" : "none",
              position: "absolute",
              background: "#FFFFFF",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              borderRadius: "3px",
              bottom: "0px",
              left: "0px",
              zIndex: "101",
              height: "95%",
              width: "100%",
              padding: "16px",
            }}
          >
            <div>
              <div
                style={{
                  height: "66px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom: "1px solid #E6E6E6",
                  padding: "16px",
                }}
              >
                <div
                  style={{
                    fontSize: "28px",
                    fontWeight: "400",
                    width: "fit-content",
                    lineHeight: "36px",
                    fontFamily: "Roboto Slab",
                    height: "fit-content",
                    color: "#000",
                  }}
                >
                  {languageSNData ? languageSNData["about"] : ""}
                </div>
                <div
                  className="back-button-hover"
                  onClick={closeAboutSection}
                  style={{
                    width: "25px",
                    height: "27px",
                    cursor: "pointer",
                    borderRadius: "4px",
                    position: "absolute",
                    right: "16px",
                  }}
                >
                  <Close scale={0.7} altText="Close" color="" />
                </div>
              </div>
              <div style={{ marginTop: "32px", height: "fit-content" }}>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#49484D",
                    fontFamily: "Inter",
                    overflowY: "hidden",
                    textAlign: "center",
                    height: "132px",
                    paddingTop: "16px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: languageSNData
                      ? notification_message.replace(
                          "$electron_version$",
                          homeSNData &&
                            homeSNData["userDetails"] &&
                            homeSNData["userDetails"]["app_version"]
                            ? homeSNData["userDetails"]["app_version"]
                            : "2.0.0"
                        )
                      : "",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: navigationToggle === false ? "none" : "block",
              width: "100%",
              height: "100%",
              background: "red",
              position: "absolute",
              background: "black",
              opacity: "25%",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              borderRadius: "3px",
              top: "0%",
              left: "0%",
              zIndex: "101",
            }}
          ></div>
          <div
            style={{
              cursor: "default",
              display: navigationToggle === false ? "none" : "block",
              border: "1px solid #DDDDDD",
              position: "absolute",
              top: "0px",
              width: "90%",
              background: "#FFFFFF",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              listStyle: "none",
              borderRadius: "3px",
              padding: "40px 16px 30px  16px",
              zIndex: "101",
            }}
          >
            <div>
              <div style={{ height: "140px", marginTop: "10px" }}>
                <div
                  style={{
                    height: "27px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div
                    className="back-button-hover"
                    onClick={CloseMenu}
                    style={{ cursor: "pointer", width: "25px", zIndex: "1" }}
                  >
                    <Close scale={0.7} altText="Close" color="" />
                  </div>
                </div>
                <div
                  onClick={() => setNavigationToggle(false)}
                  style={{
                    height: "55px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "54px",
                      height: "54px",
                      borderRadius: "100%",
                    }}
                    title=""
                  >
                    {homeSNData &&
                    homeSNData["userDetails"] &&
                    homeSNData["userDetails"]["ProfilePic"] === "" ? (
                      <Avatar
                        homedata={homeSNData && homeSNData["userDetails"]}
                        FontSize="14px"
                        inboxNameImage={false}
                        Color="#FEEDF3"
                        fontColor="#DD296E"
                      />
                    ) : (
                      <img src={"data:image/png;base64," + ProfilePic} />
                    )}
                  </div>
                </div>
                <div
                  onClick={() => setNavigationToggle(false)}
                  style={{ height: "45px", marginTop: "2px" }}
                >
                  <div
                    style={{
                      height: "19px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: " #4A4A49",
                      letterSpacing: "-0.01em",
                      textAlign: "center",
                      marginTop: "2px",
                    }}
                  >
                    {homeSNData["userDetails"]
                      ? homeSNData["userDetails"]["FirstName"] +
                        " " +
                        homeSNData["userDetails"]["LastName"]
                      : "Name ..."}
                  </div>
                  <div
                    style={{
                      height: "25px",
                      textAlign: "center",
                      fontSize: "16px",
                      color: "#755B98",
                    }}
                  >
                    {homeSNData["userDetails"]
                      ? homeSNData["userDetails"]["Email"]
                      : "Email ..."}
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "6px" }}>
                <div
                  className="back-button-hover"
                  style={{
                    cursor: "pointer",
                    height: "34px",
                    marginTop: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "3px",
                    padding: "3px 16px 5px 16px",
                  }}
                >
                  <div style={{ width: "10%", paddingLeft: "2px" }}>
                    <AlertIcon scale={0.75} altText="" color="" />
                  </div>
                  <div
                    style={{
                      width: "75%",
                      color: "#07284B",
                      fontSize: "14px",
                      fontWeight: "600",
                      paddingTop: "4px",
                      paddingLeft: "3px",
                    }}
                  >
                    {languageSNData && languageSNData["headermenu_alert_text"]
                      ? languageSNData["headermenu_alert_text"]
                      : "Alerts"}
                  </div>
                  <div
                    style={{
                      width: "15%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ToggleSwitch
                      isNotificationSubscribe={notificationSubscribe}
                      optedIn={optedIn}
                    />
                  </div>
                </div>

                <div
                  ref={ref}
                  className="back-button-hover"
                  onClick={() => {
                    ShowLanguage();
                  }}
                  style={{
                    cursor: "pointer",
                    height: "fit-content",
                    marginTop: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "3px",
                    padding: "6px 16px",
                  }}
                >
                  <div style={{ height: "fit-content", display: "flex" }}>
                    <div
                      style={{
                        height: "30px",
                        width: "12%",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                    >
                      <LanguageIcon
                        scale={0.7}
                        altText="ProfileIcon"
                      ></LanguageIcon>
                    </div>
                    <div
                      style={{
                        fontWeight: "600",
                        color: "#07284B",
                        fontSize: "14px",
                        alignItems: "center",
                        height: "32px",
                        display: "flex",
                        marginLeft: "6px",
                      }}
                    >
                      {languageSNData
                        ? languageSNData["headermenu_language_selectlanguage"]
                        : ""}
                    </div>
                  </div>

                  <div style={{ width: "25%", color: "#8898A9" }}>
                    {homeSNData["userDetails"] &&
                    homeSNData["userDetails"]["language"] === "en"
                      ? "English"
                      : "Norsk"}
                  </div>
                </div>
                <div
                  style={{
                    display:
                      navigationToggle === false || languageNavigationToggle
                        ? "none"
                        : "block",
                    width: "100%",
                    height: "fit-content",
                    background: "#FFFFFF",
                    padding: "0px 16px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "92px",
                      padding: "8px 0px",
                    }}
                  >
                    <div
                      className="back-button-hover"
                      onClick={() => LanguageSelector("en")}
                      style={{
                        cursor: "pointer",
                        height: "34px",
                        fontSize: "14px",
                        fontWeight: "600",
                        padding: "5px 7px",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          height: "30px",
                          width: "10%",
                          display: "flex",
                          color: "#959199",
                        }}
                      ></div>
                      <div style={{ width: "80%", color: "#959199" }}>
                        English
                      </div>
                      {homeSNData["userDetails"] &&
                      homeSNData["userDetails"]["language"] === "en" ? (
                        <CheckmarkRed scale={0.7} altText="CheckmarkRed" />
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="back-button-hover"
                      onClick={() => LanguageSelector("nb")}
                      style={{
                        height: "34px",
                        marginTop: "8px",
                        color: "#07284B",
                        fontSize: "14px",
                        fontWeight: "600",
                        padding: "5px 7px",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          height: "30px",
                          width: "10%",
                          display: "flex",
                        }}
                      ></div>
                      <div style={{ width: "85%", color: "#959199" }}>
                        Norsk
                      </div>
                      {homeSNData["userDetails"] &&
                      homeSNData["userDetails"]["language"] === "nb" ? (
                        <CheckmarkRed scale={0.7} altText="CheckmarkRed" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div
                  ref={nRef}
                  onClick={showNotification}
                  className="back-button-hover"
                  style={{
                    cursor: "pointer",
                    height: "fit-content",
                    marginTop: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "3px",
                    padding: "6px 16px",
                  }}
                >
                  <div
                    style={{
                      height: "30px",
                      width: "8%",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ExclamationMark
                      scale={1.4}
                      altText="ExclamationMark"
                      color="black"
                    />
                  </div>
                  <div
                    style={{
                      width: "81%",
                      color: "#07284B",
                      fontSize: "14px",
                      padding: "7px 0px",
                      fontWeight: "600",
                    }}
                  >
                    {languageSNData &&
                    languageSNData["headermenu_notifications_text"]
                      ? languageSNData["headermenu_notifications_text"]
                      : "Notifications"}
                  </div>
                  <div
                    style={{ width: "7%", height: "34px", paddingTop: "5px" }}
                  >
                    <Back scale={0.7} altText="Back" type="next"></Back>
                  </div>
                </div>
                <div
                  className="back-button-hover"
                  onClick={() => showAboutSection("about")}
                  style={{
                    cursor: "pointer",
                    height: "fit-content",
                    marginTop: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "3px",
                    padding: "6px 16px",
                  }}
                >
                  <div style={{ width: "10%" }}>
                    <Information scale={0.8} altText="InformationMark" />
                  </div>
                  <div
                    style={{
                      width: "81%",
                      color: "#07284B",
                      fontSize: "14px",
                      padding: "7px 0px",
                      fontWeight: "600",
                    }}
                  >
                    {languageSNData
                      ? languageSNData["headermenue_about_text"]
                      : ""}
                  </div>
                  <div
                    style={{ width: "7%", height: "34px", paddingTop: "5px" }}
                  >
                    <Back scale={0.7} altText="Back" type="next"></Back>
                  </div>
                </div>
                <div
                  style={{
                    height: "78px",
                    marginTop: "8px",
                    borderTop: "1px solid #D9D9D9",
                    paddingTop: 16,
                  }}
                >
                  <div
                    className="back-button-hover"
                    onClick={() => handleLogout(instance)}
                    style={{
                      cursor: "pointer",
                      height: "34px",
                      marginTop: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        color: " #DD296E",
                        fontSize: "16px",
                        padding: "5px 16px",
                        fontWeight: "600",
                      }}
                    >
                      {languageSNData
                        ? languageSNData["header_signout_gotologout"]
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
