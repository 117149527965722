import styled from "styled-components";

export const Menucontainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 30px;
    padding-right: 30px;
    background: #EBEAF4;
    }
`;

export const Container = styled.div`
  width: 500px;
  height: 72%;
  text-align: center;
  @media (max-width: 480px) {
    height: 100%;
    }
`;

export const NavigateButton = styled.button`
  width: fit-content;
  color: #ffffff;
  height: 100%;
  background-color: #dd296e;
  border-radius: 3px;
  border: none;
  font-size: 14px;
  align-items: center;
  display: flex;
  :hover {
    background: linear-gradient(90deg, #cf022b 0%, #ea5599 100%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #4a2878;
  }
  @media (max-width: 480px) {
    width: fit-content;
    height: 36px;
    background-color: #dd296e;
    border-radius: 30px;
    align-items: center;
    display: flex;
    }
`;
