import styled from "styled-components";


export const ResultPageMainContainer = styled.div`
    padding-top: 20px;
    padding-left: 30px;
`
export const StatusContent = styled.div`
    height: 120px;
`

export const StatusTitle = styled.div`
    width: 436px;
    height: 22px;
    font-size: 14px;
    font-weight: 600;
    color: #49484D;
`

export const StatusGuideSteps = styled.div`
    height: 100px;
`

export const ImageContainer = styled.div`
    margin: auto;
    width: 294.47px;
    height: 226.95px;
`

export const Caption = styled.div`
    margin: auto;
    height: 20px;
    width: 436px;
    font-size: 14px;
    font-weight: 600;
    color: #49484D;
    text-align: center;
    margin-top: 10px;
    
`


