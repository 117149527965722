import React from 'react';

import { IconWrapper } from '../styled-components';
// import { ReactComponent as commentNot_support_icon } from './CommnetNotSupport.svg';
import { ReactComponent as commentNot_support_icon } from './NewNoCommentImage.svg';
import styled from "styled-components";
const CommentNotSupportIcon = styled(commentNot_support_icon)`
  
`

type CommentNotSupportIconLogoProps = {
  scale?: number;
  altText: string;
};

const CommentNotSupport: React.FC<CommentNotSupportIconLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={1.43* scale} height={1.4 * scale} aria-label={altText} style={{ marginLeft: "2px"}}>
      <CommentNotSupportIcon/>
    </IconWrapper>
  );
};

export default CommentNotSupport;