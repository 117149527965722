import styled from "styled-components";

export const ProgressBar = styled.div`
  width: 323px;
  height: 108px;
  @media (max-width: 1200px) {
    width: 151px;
    height: 201px;
  }
`;
