import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as no_result } from './NoresultFoundImage.svg';
import { ReactComponent as session_out } from './SessionOut.svg';
import styled from "styled-components";
const NoResultFoundIcon = styled(no_result)`
  
`
const SessionOutIcon = styled(session_out)`
  
`


type MinimizeLogoProps = {
  scale?: number;
  altText: string;
};

const NoResultFound: React.FC<MinimizeLogoProps> = ({ scale = 1, altText }) => {
  return (
    <>
      {altText !== "SessionTimeout"? 
        <IconWrapper width={2.4* scale} height={3.3 * scale} aria-label={altText} style={{marginTop:"0px"}} >
              <NoResultFoundIcon/>
        </IconWrapper>
        : 
        <IconWrapper width={2* scale} height={2* scale} aria-label={altText} style={{marginTop:"0px"}} >
              <SessionOutIcon/>
        </IconWrapper>
      }
    </>
  );
};

export default NoResultFound;