// @ts-nocheck
//Library
import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

//Files & Functions
import {
  HeaderContainer,
  ContentLeft,
  ContentCenter,
  ContentRight,
  SaveArticleButton,
} from "./StyledComponent";
import {
  useNavigationToggle,
  useSetNavigationToggle,
  useSetLanguageNavigationToggle,
  useSetInputText,
  useAppScreenSize,
  useSetAppScreenSize,
  useChatScreenHide,
  useSetChatScreenHide,
  useSetSaved,
  useSaved,
  usedisplaySize,
  useTicketData,
} from "../../hooks/useMenuContext/useMenuContext";
import {
  usehomeSNData,
  uselanguageSNData,
  useinboxSNData,
  useerrorSNApi,
  usesetInboxSNData,
} from "../../hooks/UseApiContext/UseApiContext";

//Components
import Avatar from "../AvatarComps";
import PinkPillButton from "../PinkPillCounter";

//Icons
import Close from "../../Icons/Header_close_icon/Close";
import Maximize from "../../Icons/Maximize_icon/Maximize";
import Minimize from "../../Icons/Minimize_icon/Minimize";
import RightSupport from "../../Icons/RightSupportIcon";
import Menu from "../HeaderMenu";
import Star from "../../Icons/StarIcon";
import Back from "../../Icons/Back_icon/Back";

// Tooltip
import { Tooltip } from "react-tooltip";
import SavedArticle from "../SavedArticleComp";
import Bookmarks from "../../Icons/Star_icon/Bookmarks";

type HeaderProps = { titleBar: any };

const Header: React.FC<HeaderProps> = ({ titleBar }) => {
  const ref = useRef();
  const clearTimerRef = useRef();
  const location = useLocation();
  const contentRef = useRef(null);
  const homeSNData = usehomeSNData();
  const languageSNData = uselanguageSNData();
  const inboxSNData = useinboxSNData();
  const chatScreenHide = useChatScreenHide();
  const setChatScreenHide = useSetChatScreenHide();
  var ProfilePic =
    homeSNData &&
    homeSNData["userDetails"] &&
    homeSNData["userDetails"]["ProfilePic"];
  const [contentRightWidth, setContentRightWidth] = useState("");
  const [contentCenterWidth, setContentCenterWidth] = useState("");
  const [width, setWidth] = useState<number>(window.innerWidth);
  const appScreenSize = useAppScreenSize();
  const setInboxSNData = usesetInboxSNData();
  const setAppScreenSize = useSetAppScreenSize();
  const setInputText = useSetInputText();
  const navigationToggle = useNavigationToggle();
  const setNavigationToggle = useSetNavigationToggle();
  const setLanguageNavigationToggle = useSetLanguageNavigationToggle();
  const setSaved = useSetSaved();
  const saved = useSaved();
  const displaySize = usedisplaySize();
  const ticketData = useTicketData();
  const errorSNApi = useerrorSNApi();
  const [activeTicket, setActiveTicket] = useState("");
  const [ticket, setTicket] = useState("");
  const [isMobile, setIsMobile] = useState("Desktop");


  let code = errorSNApi && errorSNApi.status_code ? errorSNApi.status_code : 0;
  const navigate = useNavigate()

  useEffect(() => {
    setActiveTicket(
      location.search.includes("?Number=")
        ? location.search.split("?Number=")[1]
        : ""
    );
  }, [location]);

  useEffect(() => {
    if ("Tickets" in ticketData) {
      setTicket(
        ticketData && ticketData["Tickets"] && ticketData["Tickets"][0]
      );
      let dummyInboxSNData = inboxSNData;
      const item =
        dummyInboxSNData &&
        dummyInboxSNData["Tickets"].filter((setticket) => {
          return (
            setticket["Number"] === activeTicket ||
            setticket["Number"] === ticketData["Tickets"][0]["Number"]
          );
        });

      if (item.length === 0) {
        dummyInboxSNData &&
          dummyInboxSNData["Tickets"] &&
          dummyInboxSNData["Tickets"].unshift(
            ticketData && ticketData["Tickets"] && ticketData["Tickets"][0]
          );
        setInboxSNData(dummyInboxSNData);
      }
    } else {
      const item =
        inboxSNData &&
        inboxSNData["Tickets"].filter((setticket) => {
          return setticket["Number"] === activeTicket;
        });
      setTicket(item[0]);
    }
  }, [ticketData]);

  useEffect(() => {
    const item =
      inboxSNData &&
      inboxSNData["Tickets"].filter((setticket) => {
        return setticket["Number"] === activeTicket;
      });
    setTicket(item[0]);
  }, [activeTicket, inboxSNData]);


  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (navigationToggle && ref.current && !ref.current.contains(e.target)) {
        setNavigationToggle(false);
        setLanguageNavigationToggle(true);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [navigationToggle]);

  useEffect(() => {
    if (contentRef.current) {
      setContentRightWidth(contentRef.current.offsetWidth);
      setContentCenterWidth(
        "calc(100% - " + contentRef.current.offsetWidth * 2 + "px)"
      );
    }
  });

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (window.screen.width === width) {
      setAppScreenSize("");
    } else {
      setAppScreenSize("Minimize");
    }
  }, [width]);
  function maximizeIcon() {
    // window.electron.notificationApi.sendNotification("test");
  }

  
  function SavedArticleShow() {
    setSaved(!saved);
  }
  function TitleText(location: string) {
    let backgroundColor, Text;
    switch (location) {
      case "/inbox":
        backgroundColor = "white";
        Text = activeTicket !== ""
          ? ticket && ticket["Short description"]
          : languageSNData
            ? languageSNData["header_inbox_gotoinbox"]
            : ""
        break;

      case "/chat":
        backgroundColor = "#EBEAF4";
        Text = languageSNData
          ? languageSNData["home_bubblechattooltip_text"]
          : ""
        break;

      default:
        backgroundColor = "#EBEAF4";
        Text = "";
    }

    return { backgroundColor, Text };
  }

  useEffect(() => {
    setIsMobile(
      navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
        ? "Mobile"
        : "Desktop"
    );
  }, []);

  return (
    <div>
      {isMobile !== "Mobile" ? (
        <HeaderContainer>
          <ContentLeft
            style={{
              visibility: location.pathname === "/login" ? "hidden" : "visible",
            }}
            ref={contentRef}
          >
            {homeSNData &&
              languageSNData["header_home_gotohome"] &&
              navigator.onLine &&
              code < 400 ? (
              <div
                style={{
                  display: "flex",
                  width: "fit-content",
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    ref={ref}
                    onClick={() => setNavigationToggle((oldState) => !oldState)}
                    style={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "100%",
                      cursor: "pointer",
                    }}
                  >
                    {homeSNData &&
                      homeSNData["userDetails"] &&
                      homeSNData["userDetails"]["ProfilePic"] === "" ? (
                      <>
                        <Tooltip
                          id="my-tooltip"
                          place="bottom"
                          // effect="solid"
                          style={{
                            height: "23px",
                            zIndex: "5",
                            background: "#49484D",
                            borderRadius: "4px",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center",
                            padding: "4px 8px",
                          }}
                        />
                        <div
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            languageSNData &&
                              languageSNData["avatar_settingsrecognizetitle_text"]
                              ? languageSNData[
                              "avatar_settingsrecognizetitle_text"
                              ]
                              : "Settings"
                          }
                          data-tooltip-offset={10}
                        >
                          <Avatar
                            homedata={homeSNData && homeSNData["userDetails"]}
                            FontSize="14px"
                            MarginLeft="4px"
                            MarginTop="4px"
                            inboxNameImage={false}
                            Color="#FEEDF3"
                            fontColor="#DD296E"
                          />
                        </div>
                      </>
                    ) : (
                      <img
                        title="Settings"
                        src={"data:image/png;base64," + ProfilePic}
                      />
                    )}
                    <Menu />
                  </div>
                </div>
                <Link
                  to="/"
                  style={{
                    color: "#0E090C",
                    textDecoration: "none",
                    width: "fit-content",
                  }}
                >
                  <div
                    className="back-button-hover"
                    onClick={() => {
                      setInputText("");
                    }}
                    style={{
                      padding: "0px 14px",
                      borderBottom:
                        location.pathname !== "/inbox"
                          ? "2px solid #EA5599"
                          : "",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: location.pathname !== "/inbox" ? "bold" : "",
                      color:
                        location.pathname !== "/inbox" ? "#0E090C" : "#4A4A49",
                    }}
                  >
                    {languageSNData
                      ? languageSNData["header_home_gotohome"]
                      : ""}
                  </div>
                </Link>
                <Link
                  to="/inbox"
                  style={{
                    color: "#0E090C",
                    textDecoration: "none",
                    width: "fit-content",
                  }}
                >
                  <div
                    className="back-button-hover"
                    style={{
                      borderBottom:
                        location.pathname === "/inbox"
                          ? "2px solid #EA5599"
                          : "",
                      display: "flex",
                      padding: "0px 14px",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: location.pathname === "/inbox" ? "bold" : "",
                      color:
                        location.pathname === "/inbox" ? "#0E090C" : "#4A4A49",
                    }}
                  >
                    {languageSNData
                      ? languageSNData["header_inbox_gotoinbox"]
                      : ""}
                    <PinkPillButton />
                  </div>
                </Link>
                <div
                  // className="header-drag"
                  style={{
                    paddingLeft: "6px",
                    // width: parseInt(contentRightWidth) - 111 + "px",
                    display: titleBar ? "flex" : "none",
                    width: "fit-content",
                    // backfaceVisibility,: "hidden",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {navigator.onLine && code < 400 ? (
                    <SaveArticleButton
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={
                        !saved
                          ? languageSNData &&
                            languageSNData["header_savearticlestooltip_text"]
                            ? languageSNData["header_savearticlestooltip_text"]
                            : "Saved Articles"
                          : ""
                      }
                      data-tooltip-offset={2}
                      onClick={SavedArticleShow}
                    >
                      <div
                        className="svg-icon-hover"
                        style={{
                          width: "17px",
                          height: "fit-content",
                        }}
                      >
                        <Bookmarks
                          scale={0.8}
                          altText="Maximize"
                          save={saved}
                          marginLeft="3px"
                        />
                      </div>
                      <div
                        className="hover-saved-button"
                        style={{
                          width: "37px",
                          height: "fit-content",
                          fontSize: "12px",
                        }}
                      >
                        {languageSNData && languageSNData["header_saved_text"]
                          ? languageSNData["header_saved_text"]
                          : "Saved"}
                      </div>
                    </SaveArticleButton>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </ContentLeft>
          <ContentCenter width={contentCenterWidth} className="header-drag">
            <div style={{ width: "120px", height: "27px" }}>
              <RightSupport scale={0.9} altText="Maximize" />
            </div>
          </ContentCenter>
          <ContentRight style={{ width: contentRightWidth + "px" }}>
            <div
              // className="header-drag"
              style={{
                width: parseInt(contentRightWidth) - 111 + "px",
                display: titleBar ? "none" : "flex",

                // backfaceVisibility,: "hidden",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {navigator.onLine && code < 400 ? (
                <SaveArticleButton
                  style={{ marginTop: titleBar ? "3px" : "3px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={
                    !saved
                      ? languageSNData &&
                        languageSNData["header_savearticlestooltip_text"]
                        ? languageSNData["header_savearticlestooltip_text"]
                        : "Saved Articles"
                      : ""
                  }
                  data-tooltip-offset={2}
                  onClick={SavedArticleShow}
                >
                  <div
                    className="svg-icon-hover"
                    style={{
                      width: "17px",
                      height: "fit-content",
                    }}
                  >
                    <Bookmarks
                      scale={0.8}
                      altText="Maximize"
                      save={saved}
                      marginLeft="3px"
                    />
                  </div>
                  <div
                    className="hover-saved-button"
                    style={{
                      width: "36px",
                      height: "fit-content",
                      fontSize: "12px",
                    }}
                  >
                    {languageSNData && languageSNData["header_saved_text"]
                      ? languageSNData["header_saved_text"]
                      : "Saved"}
                  </div>
                </SaveArticleButton>
              ) : (
                ""
              )}
            </div>
          </ContentRight>
        </HeaderContainer>
      ) : (
        <div ref={ref}>
          {
            location.pathname === '/home' ?
              <>
                <div
                  style={{
                    height: "48px",
                    padding: "0px 32px",
                    gap: "16px",
                    display: "flex",
                    background: "#EBEAF4",
                  }}
                >
                  <div
                    style={{
                      width: "48px",
                      borderRadius: "100%",
                    }}
                    onClick={() => {
                      setNavigationToggle((oldState) => !oldState);
                    }}
                  >
                    <Avatar
                      homedata={homeSNData && homeSNData["userDetails"]}
                      FontSize="14px"
                      MarginLeft="4px"
                      MarginTop="4px"
                      inboxNameImage={false}
                      Color="#4A2878"
                      fontColor="white"
                    />
                  </div>
                  <div style={{ width: "201px", }}>
                    <div
                      style={{
                        height: "fit-content",
                        color: "var(--black, #0E090C)",
                        fontSize: "14px",
                      }}
                    >
                      {languageSNData && languageSNData["mobile_header_message"]
                        ? languageSNData["mobile_header_message"]
                        : " "}
                    </div>
                    <div
                      style={{
                        height: "fit-content",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "var(--black, #0E090C)",
                      }}
                    >
                      {homeSNData && homeSNData["userDetails"]
                        ? homeSNData["userDetails"]["FirstName"] +
                        " " +
                        homeSNData["userDetails"]["LastName"]
                        : "Name ..."}
                    </div>
                  </div>
                </div>
              </>
              :
              <div style={{
                height: "45px",
                width: "100%",
                alignItems: "center",
                display: 'flex',
                background: TitleText(location.pathname).backgroundColor,
              }}
              >
                <button
                  className="back-button-hover"
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{
                    border: "none",
                    width: "30px",
                    height: "45px",
                    borderRadius: "3px",
                    background: TitleText(location.pathname).backgroundColor,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    marginLeft: "10px",
                  }}
                >
                  <Back
                    scale={1}
                    altText="Back"
                    type=""
                  />
                </button>
                <div
                  className="FontRoboto"
                  style={{
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                    color: "#000000",
                    fontWeight: "400",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}>
                  {TitleText(location.pathname).Text}
                </div>
              </div>
          }
          {(location.pathname === '/home' || location.pathname === '/search' || location.pathname === '/article') &&
            <div
              style={{
                width: "48px",
                height: "48Px",
                borderRadius: "100%",
                background: "#B6B0CE",
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                position: 'absolute',
                right: '30px',
                top: '0px',
              }}
              onClick={SavedArticleShow}
            >
              {" "}
              <Star scale={2.4} altText="Minimize" color="white" />
            </div>
          }
          <Menu />
        </div>
      )}
      {saved && <SavedArticle />}
    </div>
  );
};

export default Header;
