import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as search } from "./search.svg";
import { ReactComponent as whitesearch } from "./whiteSearch.svg";
import styled from "styled-components";
const SearchIcon = styled(search)``;
const WhitesearchIcon = styled(whitesearch)``;
type SearchLogoProps = {
  scale?: number;
  altText: string;
};

const Search: React.FC<SearchLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper
      width={2 * scale}
      height={2 * scale}
      aria-label={altText}
      style={{ marginTop: "2px", width: "1rem", marginLeft: "1px" }}
    >
      {altText === "whiteSearch" ? <WhitesearchIcon /> : <SearchIcon />}
    </IconWrapper>
  );
};

export default Search;
