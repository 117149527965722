import styled from "styled-components";

export const TicketsContainer = styled.div`
  height: calc(100% - 118px);
`;

export const Button = styled.button`
  width: fit-content;
  border: none;
  background: white;
  padding: 4px 7px;
  font-size: 14px;
  color: #4a4a49;
  font-weight: 500;
  border-radius: 4px;
  :focus {
    background: #ebeaf4;
    color: #0e090c;
    font-weight: 600;
  }
  :hover {
    font-weight: 600;
    color: #0e090c;
    background: #ebeaf4;
  }
`;
