import styled from "styled-components";

export const MakeShadowBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background: black;
  opacity: 25%;
  z-index: 6;
  cursor: default;
`;

export const SaveArticleBox = styled.div`
  width: 500px;
  height: 540px;
  background: #ffffff;
  position: absolute;
  top: calc(50% - 250px);
  left: calc(50% - 250px);
  z-index: 8;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  cursor: default;
  @media (max-width: 480px) {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

export const SavedArticleBox = styled.div`
  width: 480px;
  max-height: 101px;
  margin: auto;
  padding: 8px;
  display: flex;
  border-radius: 3px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
export const SavedArticleContent = styled.div`
  flex: 1;
  min-width: 100px;
  height: auto;
  min-height: 25px;
  .article-title-box {
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #49484d;
    line-height: 17px;
  }

  .article-description {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: fit-content;
    font-size: 14px;
    font-weight: 400;
    color: #49484d;
    line-height: 18px;
    width: 369px;
    word-break: break-all;
  }
  @media (max-width: 480px) {
    .article-description {
      width: auto;
      white-space: normal;
    }
  }
`;

export const ArticleLinkBox = styled.div`
  width: fit-content;
  display: flex;
  align-items: end;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
`;

export const OpenArticle = styled.div`
height: fit-content;
@media (max-width: 480px) {
  display: none;
}
`;