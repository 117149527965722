import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as plus } from "./AddAdd_Icon.svg";
import styled from "styled-components";
const PlusIcon = styled(plus)``;

type PlusLogoProps = {
  scale?: number;
  altText: string;
};

const Minus: React.FC<PlusLogoProps> = ({ scale = 1,altText  }) => {
  return (
    
    <IconWrapper
      width={0.8 * scale}
      height={0.8 * scale}
    >
      <PlusIcon/>
    </IconWrapper>
  );
};

export default Minus;
