import React, { useEffect, useState } from "react";
import {
  Container,
  PopUpContainer,
  Header,
  HeaderText,
  CloseButton,
  TextContainer,
  MessageText,
  ButtonContainer,
  ButtonNotNow,
  ButtonInstall,
} from "./InstallApplicationStyle";
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";

//Icons
import Close from "../../Icons/Header_close_icon/Close";
import ShareIcon from "../../Icons/Share_icon_iphone/ShareIconIphone";

type InstallProps = {};

const InstallApplication: React.FC<InstallProps> = ({ }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const languageSNData = uselanguageSNData();
  const [showPopUp, setShowPopUp] = useState(false);
  const alreadyAppInstall = window["isAppInstall"]
  

  useEffect(() => {
    if (alreadyAppInstall !== null && localStorage.getItem("hidePopUp") === null) {
      if(alreadyAppInstall.preventDefault){
         alreadyAppInstall.preventDefault();
      }
      setDeferredPrompt(alreadyAppInstall);
      setShowPopUp(true);
    }
  }, [alreadyAppInstall]);

  const installApp = async () => {
    if (deferredPrompt !== null) {
      try {
        await deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === "accepted") {
          setShowPopUp(false);
          localStorage.setItem("hidePopUp", String(true));
          setDeferredPrompt(null);
          if (typeof window["reloadApp"] === "function") {
            window["reloadApp"](false);
          }

        }
      } catch (error) {
        console.error("Error prompting to install:", error);
      }
    } else {
      console.log("deferredPrompt is null [Website cannot be installed]");
    }
  };
  const hidePopUpClick = () => {
    localStorage.setItem("hidePopUp", String(true));
    setShowPopUp(false);
  };
  return (
    showPopUp && (
      <div style={{ display: "flex" }}>
        <Container
          onClick={() => {
            hidePopUpClick();
          }}
        ></Container>
        <PopUpContainer>
          <div>
            <Header>
              <HeaderText>
                {languageSNData && languageSNData["install_AppName"]
                  ? languageSNData["install_AppName"]
                  : " "}
              </HeaderText>
              <CloseButton
                className="back-button-hover"
                style={{}}
                onClick={() => {
                  hidePopUpClick();
                }}
              >
                <Close scale={0.7} altText="Close" color="" />
              </CloseButton>
            </Header>
            <TextContainer>
              {alreadyAppInstall === true?
                <div style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  color: "#49484d",
                  fontFamily: "Inter",
                  margin: '0px 20px ',
                  padding: "10px",
                }}>
                    {languageSNData && languageSNData["install_Message_mobile1"]
                    ? languageSNData["install_Message_mobile1"]
                    : ""}
                    <span style={{
                    display: "inline-block",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "2px",
                    marginRight: "2px",
                    height: "20px",
                    width: "20px",
                  }}>
                    <ShareIcon scale={1.5} altText="Close" />
                  </span>
                  {languageSNData && languageSNData["install_Message_mobile2"]
                    ? languageSNData["install_Message_mobile2"]
                    : ""}
                </div>
                :
                <div style={{display: "flex",justifyContent: "space-evenly",alignItems: "center",flexDirection: "column",width: "100%",height: "100%",}}>
                <MessageText>
                  {languageSNData && languageSNData["install_Message"]
                    ? languageSNData["install_Message"]
                    : ""}
                </MessageText>
                <ButtonContainer>
                <ButtonNotNow
                  onClick={() => {
                    hidePopUpClick();
                  }}
                >
                  {languageSNData && languageSNData["not_Now"]
                    ? languageSNData["not_Now"]
                    : " "}
                </ButtonNotNow>
                <ButtonInstall onClick={() => installApp()}>
                  {languageSNData && languageSNData["install_text"]
                    ? languageSNData["install_text"]
                    : " "}
                </ButtonInstall>
              </ButtonContainer>
                </div>
               }
            </TextContainer>
          </div>
        </PopUpContainer>
      </div>
    )
  );
};

export default InstallApplication;



