import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as shareIos } from "./share.svg";
import styled from "styled-components";
const ShareIconIos = styled(shareIos)``;

type shareIconProps = {
  scale?: number;
  altText: string;
};

const ShareIcon: React.FC<shareIconProps> = ({ scale = 1,altText  }) => {
  return (
    
    <IconWrapper
      width={0.8 * scale}
      height={0.8 * scale} 
    >
      <ShareIconIos/>
    </IconWrapper>
  );
};

export default ShareIcon;
