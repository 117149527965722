import styled from "styled-components";

export const Menucontainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 30px;
    padding-right: 30px;
    background: #ebeaf4;
  }
`;

export const Container = styled.div`
  width: 458px;
  height: 80%;
  text-align: left;
`;

export const Form = styled.div`
  width: 100%;
  height: 80%;
  overflow-y: auto;
  padding-bottom: 50px;
  @media (max-width: 480px) {
    height: 80%;
    padding-bottom: 130px;
  }
`;

export const YesButton = styled.div`
  width: fit-content;
  color: #ffffff;
  height: 100%;
  padding: 6px 9px;
  background-color: #755b98;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  :hover {
    background: linear-gradient(90deg, #4a2878 0%, #755b98 100%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #4a2878;
  }
  @media (max-width: 480px) {
    background: #675b98;
    width: fit-content;
    height: 40px;
    border-radius: 30px;
    padding: 9px 16px;
  }
`;

export const NoButton = styled.div`
  width: fit-content;
  color: #ffffff;
  height: 100%;
  padding: 6px 9px;
  background-color: #755b98;
  border-radius: 3px;
  border: none;
  font-size: 14px;
  margin-left: 16px;
  cursor: pointer;
  :hover {
    background: linear-gradient(90deg, #4a2878 0%, #755b98 100%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #4a2878;
  }
  @media (max-width: 480px) {
    background: #675b98;
    width: fit-content;
    height: 40px;
    border-radius: 30px;
    text-align: center;
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
  }
`;

export const SubmitButton = styled.div`
  width: 130px;
  color: #ffffff;
  height: 45px;
  padding: 10px 12px;
  background-color: #755b98;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-size: 17px;
  :hover {
    background: linear-gradient(90deg, #4a2878 0%, #755b98 100%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #4a2878;
  }
  @media (max-width: 480px) {
    background-color: #dd296e;
    border-radius: 30px;
    height: 42px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ShowArticleButton = styled.button`
  width: fit-content;
  border: none;
  background: none;
  color: var(--brand-brand-pink, #dd296e);
  height: 100%;
  :hover {
    background: var(--brand-pink-100, #feedf3);
  }
`;
