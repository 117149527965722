import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import "./style.css";
import { useLocation } from "react-router-dom";
import { useMsal, useAccount } from "@azure/msal-react";
import { getSNJSON } from "../../api";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import axios from "axios";
import config from "../../constant/config";

type LoadingProps = {};

const Loading: React.FC<LoadingProps> = ({}) => {
  const [cookies, setCookie] = useCookies(["name"]);
  const [fetchSNLoginJSON, performSNLoginJSON] = useApiAction(getSNJSON);
  const { instance, accounts } = useMsal();
  const [checkSNLoging, setCheckSNLogin] = useState(false);
  let childWindow: Window | null = null;
  const location = useLocation();
  // function SNLogin() {
  //   setCookie("name", "Yes", { path: "/" });
  // }

  // useEffect(() => {
  //   SNLogin();
  // });
  // useEffect(() => {
  //   let wait = 1;
  //   let timeout = 0;
  //   const interval1 = setInterval(() => {
  //     if (cookies["name"] === "Yes") {
  //       clearInterval(interval1);
  //     } else {
  //       openChildWindow();
  //       clearInterval(interval1);
  //       const interval = setInterval(() => {
  //         axios
  //           .get(config.api.urlCheckIfUserSessionIsActive)
  //           .then((response) => {
  //             if (
  //               response &&
  //               response.data &&
  //               response.data["result"] &&
  //               response.data["result"]["isLoggedIn"] &&
  //               response.data["result"]["isLoggedIn"]
  //             ) {
  //               setCheckSNLogin(response.data["result"]["isLoggedIn"])
  //               childWindow.close();
  //               clearInterval(interval);
  //             }
  //           });
  //         // performSNLoginJSON(Feild);
  //         // console.log(fetchSNLoginJSON);
  //         // if (
  //         //   fetchSNLoginJSON.data &&
  //         //   fetchSNLoginJSON.data["result"] &&
  //         //   fetchSNLoginJSON.data["result"]["isLoggedIn"] &&
  //         //   fetchSNLoginJSON.data["result"]["isLoggedIn"] === true
  //         // ) {
  //         //   childWindow.close();
  //         //   clearInterval(interval);
  //         // }
  //         if(checkSNLoging === false){
  //           timeout += 500;
  //           if(timeout >= 60000){
  //             childWindow.close();
  //             clearInterval(interval);
  //           }
  //         }
  //       }, 500);
  //       // window.electron.notificationApi.login("login");
  //     }
  //   }, wait * 1000);
  // }, []);

  const openChildWindow = () => {
    // Open the child window and store its reference
    // childWindow = window.open(
    //   accounts &&
    //     accounts[0] &&
    //     accounts[0] &&
    //     accounts[0]["username"] &&
    //     accounts[0]["username"].search("soprasteria.com") === -1
    //     ? config.api.urlLoginSN + config.api.glideSsoIdSintef
    //     : config.api.urlLoginSN + config.api.glideSsoIdSopraSteria,
    //   "_blank",
    //   "toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=500,width=800,height=500"
    // );
  };

  // useEffect(() => {
  //   // Open the child window when the component mounts
  //   openChildWindow();

  //   // Check the child window URL periodically
  //   const interval = setInterval(() => {
  //     if (childWindow && childWindow.closed) {
  //       // Child window was closed, stop checking
  //       clearInterval(interval);
  //       return;
  //     }

  //     if (childWindow) {
  //       // URL in the child window changed to "testing.com", close it
  //       childWindow.close();
  //     }
  //   }, 10000);

  //   // Clean up by closing the child window and stopping the interval when the component unmounts
  //   // return () => {
  //   //   if (childWindow) {
  //   //     childWindow.close();
  //   //   }
  //   //   clearInterval(interval);
  //   // };
  // }, []);
  return (
    <div
      onClick={() => {
        if (location.pathname === "/login") {
          // window.electron.notificationApi.childWindowShow("childWindowShow");
        }
      }}
      style={{
        background: "#F5F5F5",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "200px", height: "200px", display: "flex" }}>
        <div className="welcome"></div>
        <div className="welcome1"></div>
        <div className="welcome2"></div>
      </div>
    </div>
  );
};

export default Loading;
