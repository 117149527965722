//Library
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

//Files & Functions
import {
  SearchData,
  Articles,
  SearchBarAndArticle,
  SerachPageToggle,
  MainContainer,
  ActionButton,
} from "./StyledComponent";
import { getSNJSON } from "../../api/index";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import {
  useInputText,
  useSetInputText,
  usedisplaySize,
  useSetSaved,
  useSaved,
  useSetRefreshSavedArticle,
  useRefreshSavedArticle,
} from "../../hooks/useMenuContext/useMenuContext";
import { useSetFullArticleContent } from "../../hooks/useMenuContext/useMenuContext";

//Components

//Icons
import Document from "../../Icons/Document_icon/Document";
import RequestForm from "../../Icons/Request_form_icon/Request_form";
import InputField from "../InputFieldComps";
import Back from "../../Icons/Back_icon/Back";
import HomePage from "../../Icons/HomePageIcon/HomePageIcon.svg";
import Star from "../../Icons/StarIcon";
import {
  usehomeSNData,
  uselanguageSNData,
} from "../../hooks/UseApiContext/UseApiContext";
import Footer from "../FooterComps";
import ViewContent from "../../Icons/ViewContentOnSearchpage/ViewContentIcon";
import NoResultPage from "../NoResultFoundPage";
import Bookmarks from "../../Icons/Star_icon/Bookmarks";
import SavedArticle from "../SavedArticleComp";

type SearchProps = {};

const Search: React.FC<SearchProps> = ({}) => {
  const [searchArticlesSNData, setSearchArticlesSNData] = useState("");
  const [fetchSearchDataJSON, performFetchSearchDataJSON] =
    useApiAction(getSNJSON);
  const [fetchAddSaveArticleJSON, performAddSaveArticleJSON] =
    useApiAction(getSNJSON);
  const [fetchRemoveSavedArticle, performRemoveSavedArticleJSON] =
    useApiAction(getSNJSON);
  const [loading, setLoading] = useState(false);
  const displaySize = usedisplaySize();

  const location = useLocation();
  const navigate = useNavigate();

  const inputText = useInputText();
  const setInputText = useSetInputText();
  const setFullArticleContent = useSetFullArticleContent();
  const languageSNData = uselanguageSNData();
  const homeSNData = usehomeSNData();
  const dataSN = homeSNData && homeSNData["ActionDetails"];
  const [viewContent, setViewContent] = useState("row");
  const [save, setSave] = useState(false);
  const setSaved = useSetSaved();
  const saved = useSaved();
  const setRefreshSavedArticle = useSetRefreshSavedArticle();
  const refreshSavedArticle = useRefreshSavedArticle();

  function SavedArticleShow() {
    setSaved(!saved);
  }

  useEffect(() => {
    var query = location.search.includes("?query=")
      ? location.search.split("?query=")[1]
      : inputText;
    var Feild = ["Get", "urlSearchData", query, {}];
    performFetchSearchDataJSON(Feild);
    setLoading(false);
  }, [location, save]);

  function saveArticlelogic(favourite_ID, favouriteTable, is_favourite) {
    var method = "Post";
    var apiURL = is_favourite
      ? "urlgetRemoveFavouriteArticle"
      : "urlAddSaveArticle";
    var payload = { favouriteID: favourite_ID, favouriteTable: favouriteTable };
    var Field = [method, apiURL, "", payload];
    is_favourite ? setSave(false) : setSave(true);
    performAddSaveArticleJSON(Field);
  }

  useEffect(() => {
    if (
      fetchAddSaveArticleJSON.data &&
      fetchAddSaveArticleJSON.data.result &&
      fetchAddSaveArticleJSON.data.result.code === "200"
    ) {
      setRefreshSavedArticle(!refreshSavedArticle);
    }
  }, [fetchAddSaveArticleJSON.data]);

  useEffect(() => {
    setLoading(false);
    if (fetchSearchDataJSON.data && fetchSearchDataJSON.data["result"]) {
      setSearchArticlesSNData(fetchSearchDataJSON.data["result"]);
      setLoading(true);
    } else {
      var query = location.search.includes("?query=")
        ? location.search.split("?query=")[1]
        : inputText;
      var Feild = ["Get", "urlSearchData", query, {}];
      performFetchSearchDataJSON(Feild);
    }
  }, [fetchSearchDataJSON.data, searchArticlesSNData, save]);

  return (
    <>
      {displaySize !== "isMobile" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <MainContainer>
            <SearchBarAndArticle>
              <button
                className="back-button-hover"
                onClick={() => {
                  navigate("/");
                  setInputText("");
                }}
                style={{
                  border: "none",
                  width: "fit-content",
                  height: "30px",
                  position: "absolute",
                  top: "70px",
                  left: "40px",
                  display: "flex",
                  borderRadius: "3px",
                  background: "none",
                }}
              >
                <Back scale={0.7} altText="Back" type="" />
                <div style={{ marginTop: "1px" }}>
                  {languageSNData ? languageSNData["global_back_gotoback"] : ""}
                </div>
              </button>
              {/* <div style={{ height: "50px", background: "white" }}> */}
              <div style={{ height: "fit-content", background: "white" }}>
                {/* <div style={{ height: "44px" }}> */}
                <div style={{ height: "fit-content" }}>
                  <InputField />
                </div>
              </div>
              <div className="article-list-container">
                {searchArticlesSNData &&
                searchArticlesSNData["data"] &&
                loading ? (
                  searchArticlesSNData["data"]["search"]["count"] !== 0 ? (
                    <SearchData
                      className="searchData"
                      style={{
                        overflowY: "scroll",
                        display: viewContent === "column" ? "flex" : "",
                        flexWrap: "wrap",
                        paddingRight: "5px",
                      }}
                    >
                      {searchArticlesSNData["data"]["search"][
                        "searchResults"
                      ].map((e: any, index) => (
                        <>
                          {viewContent === "row" ? (
                            <div
                              style={{
                                height: "fit-content",
                                borderBottom:
                                  "2px solid var(--grey-light, #E6E6E6)",
                              }}
                            >
                              {/* <Link
                              to={
                                e["table"] && e["table"] === "sc_cat_item"
                                  ? "/form?form=" + e["link"]
                                  : "/article?sys_id=" + e["sysId"] 
                              }
                              style={{
                                color: "#0E090C",
                                textDecoration: "none",
                                width: "74px",
                                border: "1px solid"
                              }}
                              key={index}
                            > */}
                              <Articles
                                key={index}
                                onClick={() =>
                                  setFullArticleContent(e["title"])
                                }
                              >
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      width: "12%",
                                      padding: "5px 25px",
                                    }}
                                  >
                                    <div
                                      style={{ width: "24px", height: "29px" }}
                                    >
                                      {e["table"] &&
                                      e["table"] === "sc_cat_item" ? (
                                        <RequestForm
                                          scale={0.8}
                                          altText="RequestForm"
                                          viewType="row"
                                        />
                                      ) : (
                                        <Document
                                          scale={0.8}
                                          altText="document"
                                          viewType="row"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "87%",
                                      height: "100%",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      onClick={() =>
                                        saveArticlelogic(
                                          e["sysId"],
                                          e["table"],
                                          e["is_favourite"]
                                        )
                                      }
                                      style={{
                                        position: "absolute",
                                        width: "fit-content",
                                        height: "fit-content",
                                        right: "0px",
                                        top: "4px",
                                      }}
                                    >
                                      <Bookmarks
                                        scale={1}
                                        altText="Bookmarks"
                                        save={e["is_favourite"]}
                                        marginLeft={"5px"}
                                      />
                                    </div>
                                    <Link
                                      to={
                                        e["table"] &&
                                        e["table"] === "sc_cat_item"
                                          ? "/form?form=" + e["link"]
                                          : "/article?sys_id=" + e["sysId"]
                                      }
                                      style={{
                                        color: "#0E090C",
                                        textDecoration: "none",
                                        // width: "74px",
                                      }}
                                      // key={index}
                                    >
                                      <div
                                        style={{
                                          height: "16px",
                                          color: "#755B98",
                                          fontSize: "12px",
                                          marginTop: "8px",
                                        }}
                                      >
                                        {e["table"] &&
                                        e["table"] === "sc_cat_item" ? (
                                          <p>
                                            {languageSNData
                                              ? languageSNData[
                                                  "home_requestform_text"
                                                ]
                                              : ""}
                                          </p>
                                        ) : e["table"] === "kb_knowledge" ? (
                                          <p>
                                            {languageSNData
                                              ? languageSNData[
                                                  "home_knowledgebasearticle_text"
                                                ]
                                              : ""}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          height: "20px",
                                          color: "#49484D",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          marginTop: "3px",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: e["title"],
                                        }}
                                      ></div>
                                      <div
                                        style={{
                                          height: "63px",
                                          color: "#959199",
                                          fontSize: "14px",
                                          marginTop: "3px",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: e["text"],
                                        }}
                                      ></div>
                                    </Link>
                                  </div>
                                </div>
                              </Articles>
                              {/* </Link> */}
                            </div>
                          ) : (
                            <div
                              style={{
                                position: "relative",
                                height: "fit-content",
                                width: "fit-content",
                              }}
                            >
                              <div
                                onClick={() =>
                                  saveArticlelogic(
                                    e["sysId"],
                                    e["table"],
                                    e["is_favourite"]
                                  )
                                }
                                style={{
                                  position: "absolute",
                                  top: "9px",
                                  right: "0px",
                                  height: "fit-content",
                                  width: "fit-content",
                                }}
                              >
                                <Bookmarks
                                  scale={1}
                                  altText="Bookmarks"
                                  save={e["is_favourite"]}
                                  marginLeft={"0px"}
                                />
                              </div>
                              <Link
                                className="back-button-hover  card-container"
                                style={{
                                  marginTop: "2px",
                                  marginBottom: "5px",
                                  marginRight: "3px",
                                  marginLeft: "1px",
                                  boxShadow:
                                    "0px 2px 5px 0px rgba(0, 0, 0, 0.20)",
                                }}
                                to={
                                  e["table"] && e["table"] === "sc_cat_item"
                                    ? "/form?form=" + e["link"]
                                    : "/article?sys_id=" + e["sysId"]
                                }
                                key={index}
                              >
                                <div className="card-icon-container">
                                  <div
                                    style={{
                                      height: "fit-content",
                                      width: "fit-content",
                                    }}
                                  >
                                    {e["table"] &&
                                    e["table"] === "sc_cat_item" ? (
                                      <RequestForm
                                        scale={0.8}
                                        altText="RequestForm"
                                        viewType="column"
                                      />
                                    ) : (
                                      <Document
                                        scale={0.8}
                                        altText="document"
                                        viewType="column"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="card-value-continer">
                                  <div
                                    style={{
                                      height: "30px",
                                      color: "#755B98",
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {e["table"] &&
                                    e["table"] === "sc_cat_item" ? (
                                      <p>
                                        {languageSNData
                                          ? languageSNData[
                                              "home_requestform_text"
                                            ]
                                          : ""}
                                      </p>
                                    ) : e["table"] === "kb_knowledge" ? (
                                      <p>
                                        {languageSNData
                                          ? languageSNData[
                                              "home_knowledgebasearticle_text"
                                            ]
                                          : "knowledge"}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className="card-title"
                                    dangerouslySetInnerHTML={{
                                      __html: e["title"],
                                    }}
                                  ></div>
                                  <div
                                    className="card-content"
                                    style={{
                                      color: "#959199",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: e["text"],
                                    }}
                                  ></div>
                                </div>
                              </Link>
                            </div>
                          )}
                        </>
                      ))}
                    </SearchData>
                  ) : (
                    <div>
                      <NoResultPage inputText={inputText} />
                    </div>
                  )
                ) : (
                  <div>
                    {languageSNData
                      ? languageSNData["notification_loading_text"]
                      : ""}
                    ...
                  </div>
                )}
              </div>
              <div
                style={{
                  height: "7%",
                  position: "absolute",
                  left: "20px",
                  bottom: "8px",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="show-footer">
                  <Footer dataSN={dataSN} move_footer={"bottom"} />
                </div>
              </div>
            </SearchBarAndArticle>
            <SerachPageToggle>
              <div className="toggle-button">
                <button
                  className="back-button-hover"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={
                    languageSNData &&
                    languageSNData["searcharticle_listviewtooltip_text"]
                      ? languageSNData["searcharticle_listviewtooltip_text"]
                      : "List view"
                  }
                  data-tooltip-offset={4}
                  data-tooltip-place="bottom"
                  style={{
                    border: "1.5px solid #959199",
                    borderRadius: "3px 0px 0px 3px",
                    height: "44px",
                    width: "44px",
                    background: viewContent === "row" ? "#EBEAF4" : "none",
                  }}
                  onClick={() => setViewContent("row")}
                >
                  <ViewContent
                    scale={0.8}
                    altText={"row"}
                    clicked={viewContent}
                  />
                </button>
                <button
                  className="back-button-hover"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={
                    languageSNData &&
                    languageSNData["sercharticle_tileviewtooltip_text"]
                      ? languageSNData["sercharticle_tileviewtooltip_text"]
                      : "Tile view"
                  }
                  data-tooltip-offset={4}
                  data-tooltip-place="bottom"
                  style={{
                    border: "1.5px solid #959199",
                    borderRadius: "0px 3px 3px 0px",
                    height: "44px",
                    width: "44px",
                    background: viewContent === "column" ? "#EBEAF4" : "none",
                  }}
                  onClick={() => setViewContent("column")}
                >
                  <ViewContent
                    scale={0.8}
                    altText={"column"}
                    clicked={viewContent}
                  />
                </button>
              </div>
              <ActionButton>
                <Footer dataSN={dataSN} move_footer={"right"} />
              </ActionButton>
            </SerachPageToggle>
          </MainContainer>
        </div>
      ) : (
        // for the mobile view
        <div>
          <MainContainer>
            <SearchBarAndArticle>
              {/* <button
                  className="back-button-hover"
                  onClick={() => {
                    navigate("/");
                    setInputText("");
                  }}
                  style={{
                    border: "none",
                    width: "fit-content",
                    height: "30px",
                    display: "flex",
                    borderRadius: "3px",
                    background: "#EBEAF4",
                  }}
                >
                  <Back scale={0.7} altText="Back" type="" />
                </button> */}
              {/* <div
                  style={{
                    width: "48px",
                    borderRadius: "100%",
                    background: "#B6B0CE",
                    height: "48px",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={SavedArticleShow}
                >
                  {" "}
                  <Star scale={2.4} altText="Minimize" color="white" />
                </div> */}
              <div
                style={{
                  height: "56px",
                  width: "100%",
                  background: "white",
                  borderRadius: "50px",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                  marginTop: "10px",
                }}
              >
                <InputField />
              </div>
              <div className="article-list-container" style={{}}>
                {searchArticlesSNData &&
                searchArticlesSNData["data"] &&
                loading ? (
                  searchArticlesSNData["data"]["search"]["count"] !== 0 ? (
                    <SearchData
                      className="searchData"
                      style={{
                        overflowY: "scroll",
                        display: viewContent === "column" ? "flex" : "",
                        flexWrap: "wrap",
                        paddingRight: "5px",
                        marginBottom: "10px",
                        paddingBottom: "30%",
                        height: "95%",
                      }}
                    >
                      {searchArticlesSNData["data"]["search"][
                        "searchResults"
                      ].map((e: any, index) => (
                        <>
                          {viewContent === "row" ? (
                            <div
                              style={{
                                height: "fit-content",
                                width: "100%",
                                marginTop: "10px",
                                background: "white",
                                borderRadius: "30px",
                                paddingLeft: "15px",
                                paddingRight: "10px",
                                paddingTop: "10px",
                                paddingBottom: "0px",
                              }}
                            >
                              <Articles key={index}>
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "100%",
                                      position: "relative",
                                    }}
                                  >
                                    <Link
                                      to={
                                        e["table"] &&
                                        e["table"] === "sc_cat_item"
                                          ? "/form?form=" + e["link"]
                                          : "/article?sys_id=" + e["sysId"]
                                      }
                                      style={{
                                        color: "#0E090C",
                                        textDecoration: "none",
                                        background: "blue",
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: "16px",
                                          color: "#755B98",
                                          fontSize: "12px",
                                          marginTop: "8px",
                                        }}
                                      >
                                        {e["table"] &&
                                        e["table"] === "sc_cat_item" ? (
                                          <p>
                                            {languageSNData
                                              ? languageSNData[
                                                  "home_requestform_text"
                                                ]
                                              : ""}
                                          </p>
                                        ) : e["table"] === "kb_knowledge" ? (
                                          <p>
                                            {languageSNData
                                              ? languageSNData[
                                                  "home_knowledgebasearticle_text"
                                                ]
                                              : ""}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          height: "20px",
                                          color: "#49484D",
                                          fontSize: "14px",
                                          fontWeight: "600",
                                          marginTop: "3px",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: e["title"],
                                        }}
                                      ></div>
                                      <div
                                        style={{
                                          height: "63px",
                                          color: "#959199",
                                          fontSize: "14px",
                                          marginTop: "3px",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: e["text"],
                                        }}
                                      ></div>
                                    </Link>
                                  </div>
                                </div>
                              </Articles>
                              {/* </Link> */}
                            </div>
                          ) : (
                            <div
                              style={{
                                position: "relative",
                                height: "fit-content",
                                width: "fit-content",
                              }}
                            >
                              {e["table"] === "kb_knowledge" ? (
                                <div
                                  onClick={() =>
                                    saveArticlelogic(
                                      e["sysId"],
                                      e["table"],
                                      e["is_favourite"]
                                    )
                                  }
                                  style={{
                                    position: "absolute",
                                    top: "9px",
                                    right: "0px",
                                    height: "fit-content",
                                    width: "fit-content",
                                  }}
                                >
                                  <Bookmarks
                                    scale={1}
                                    altText="Bookmarks"
                                    save={e["is_favourite"]}
                                    marginLeft={"0px"}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <Link
                                className="back-button-hover card-container"
                                to={
                                  e["table"] && e["table"] === "sc_cat_item"
                                    ? "/form?form=" + e["link"]
                                    : "/article?sys_id=" + e["sysId"]
                                }
                                key={index}
                              >
                                <div className="card-icon-container">
                                  <div
                                    style={{
                                      height: "fit-content",
                                      width: "fit-content",
                                    }}
                                  >
                                    {e["table"] &&
                                    e["table"] === "sc_cat_item" ? (
                                      <RequestForm
                                        scale={0.8}
                                        altText="RequestForm"
                                        viewType="column"
                                      />
                                    ) : (
                                      <Document
                                        scale={0.8}
                                        altText="document"
                                        viewType="column"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="card-value-continer">
                                  <div
                                    style={{
                                      height: "30px",
                                      color: "#755B98",
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {e["table"] &&
                                    e["table"] === "sc_cat_item" ? (
                                      <p>
                                        {languageSNData
                                          ? languageSNData[
                                              "home_requestform_text"
                                            ]
                                          : ""}
                                      </p>
                                    ) : e["table"] === "kb_knowledge" ? (
                                      <p>
                                        {languageSNData
                                          ? languageSNData[
                                              "home_knowledgebasearticle_text"
                                            ]
                                          : "knowledge"}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div
                                    className="card-title"
                                    dangerouslySetInnerHTML={{
                                      __html: e["title"],
                                    }}
                                  ></div>
                                  <div
                                    className="card-content"
                                    style={{
                                      color: "#959199",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: e["text"],
                                    }}
                                  ></div>
                                </div>
                              </Link>
                            </div>
                          )}
                        </>
                      ))}
                    </SearchData>
                  ) : (
                    <div>
                      <NoResultPage inputText={inputText} />
                    </div>
                  )
                ) : (
                  <div>
                    {languageSNData
                      ? languageSNData["notification_loading_text"]
                      : ""}
                    ...
                  </div>
                )}
              </div>
            </SearchBarAndArticle>
          </MainContainer>
        </div>
      )}
    </>
  );
};

export default Search;
