import styled from "styled-components";

export const FormMainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .form-container {
    width: 650px;
    height: 95%;
  }

  .back-button-container {
    border: none;
    background: white;
    width: fit-content;
    height: 30px;
    position: absolute;
    top: 70px;
    left: 40px;
    display: flex;
    border-radius: 3px;
  }

  @media (min-width: 1400px) {
    .form-container {
      width: 750px;
      height: 75%;
    }
  }
  @media (max-width: 480px) {
    display: flex;
    height: 95%;
    margin-top: 5px;
    .form-container {
      width: fit-content;
    }
    .back-button-container {
      border: none;
      background: #ebeaf4;
      width: fit-content;
      height: 30px;
      display: flex;
      border-radius: 3px;
      position: absolute;
      top: 40px;
      left: 30px;
    }
  }
`;

export const FooterContainer = styled.div`
  height: 9%;
  position: absolute;
  left: 20px;
  bottom: 6px;
  width: 98.5%;
  justify-content: center;
  display: flex;
  align-items: center;
`;
