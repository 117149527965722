//Library
import React, { useEffect, useState, useRef } from "react";
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";

//Files & Functions
import {
  Container,
  ProgressContainer,
  StateBar,
  StateLine,
  ContainerStatus,
  StatusText,
} from "./StyledComponent";
//Components

//Icons
import Checkmark from "../../Icons/Checkmark_icon";

type TicketprogressProps = {
  ticket: any;
  sidebar: any;
};


const TicketProgressBar: React.FC<TicketprogressProps> = ({
  ticket,
  sidebar,
}) => {
  const languageSNData = uselanguageSNData();
  const [isSafari, setIsSafari] = useState(false);
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('mac')) {
      setIsSafari(true)
    } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
      setIsSafari(true)
    }
  }, [isSafari]);
 
  return (
    <ProgressContainer
      style={
        sidebar
          ? {}
          : {
              display: "flex",
              flexDirection: "column",
            }
      }
    >
      {ticket && ticket["additionalTicketDetails"] && (
        <Container
          style={
            sidebar
              ? {}
              : {
                  height: "32px",
                  flexDirection: "row",
                  width: "100%",
                }
          }
        >
          <StateBar style={sidebar ? {} : { width: "30px", height: "26px" }}>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              {(ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_recieved") ||
              "ticket_in_progress" ||
              "ticket_resolved" ||
              "ticket_closed" ? (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <Checkmark scale={0.7} altText="attachment" viewIn="inbox" />
                </div>
              ) : ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_recieved" ? (
                <div
                  style={{
                    height: "20px",
                    width: "20px",
                    border: "2px solid #49484D",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      background: "#49484D",
                      borderRadius: "10px",
                    }}
                  ></div>
                </div>
              ) : (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "2px solid #49484D",
                    borderRadius: "10px",
                  }}
                ></div>
              )}
            </div>
          </StateBar>
          <StateLine
            borderType={
              (ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_in_progress") ||
              (ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_resolved") ||
              (ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_closed")
                ? "1.6px solid #49484D"
                : isSafari ? "2px dashed #49484D" :"1.6px dashed #49484D"
            }
            style={
              sidebar
                ? {
              }
                : {
                    width: "51px",
                    height: "11px",
                    borderBottom:
                      (ticket["additionalTicketDetails"] &&
                        ticket["additionalTicketDetails"][
                          "ProgressBarStatus"
                        ] &&
                        ticket["additionalTicketDetails"][
                          "ProgressBarStatus"
                        ] === "ticket_in_progress") ||
                      (ticket["additionalTicketDetails"] &&
                        ticket["additionalTicketDetails"][
                          "ProgressBarStatus"
                        ] &&
                        ticket["additionalTicketDetails"][
                          "ProgressBarStatus"
                        ] === "ticket_resolved") ||
                      (ticket["additionalTicketDetails"] &&
                        ticket["additionalTicketDetails"][
                          "ProgressBarStatus"
                        ] &&
                        ticket["additionalTicketDetails"][
                          "ProgressBarStatus"
                        ] === "ticket_closed")
                        ? "1.6px solid #49484D"
                        : isSafari ? "2px dashed #49484D" :"1.6px dashed #49484D",
                    borderRight: "0px",
                    opacity: "60%",
                  }
            }
          ></StateLine>
          <StateBar style={sidebar ? {} : { width: "30px", height: "27px" }}>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              {(ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_in_progress") ||
              (ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_resolved") ||
              (ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_closed") ? (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <Checkmark scale={0.7} altText="attachment" viewIn="inbox" />
                </div>
              ) : ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_recieved" ? (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "2px solid #49484D",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      background: "#49484D",
                      borderRadius: "10px",
                    }}
                  ></div>
                </div>
              ) : (
                <div
                  style={{
                    height: "20px",
                    width: "20px",
                    border: "2px solid #49484D",
                    borderRadius: "10px",
                  }}
                ></div>
              )}
            </div>
          </StateBar>
          <StateLine
            borderType={
              (ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_resolved") ||
              (ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_closed")
                ? "1.6px solid #49484D"
                : isSafari ? "2px dashed #49484D" :"1.6px dashed #49484D"
            }
            style={
              sidebar
                ? {}
                : {
                    width: "51px",
                    height: "11px",
                    borderBottom:
                      (ticket["additionalTicketDetails"] &&
                        ticket["additionalTicketDetails"][
                          "ProgressBarStatus"
                        ] &&
                        ticket["additionalTicketDetails"][
                          "ProgressBarStatus"
                        ] === "ticket_resolved") ||
                      (ticket["additionalTicketDetails"] &&
                        ticket["additionalTicketDetails"][
                          "ProgressBarStatus"
                        ] &&
                        ticket["additionalTicketDetails"][
                          "ProgressBarStatus"
                        ] === "ticket_closed")
                        ? "1.6px solid #49484D"
                        : isSafari ? "2px dashed #49484D" :"1.6px dashed #49484D",
                    borderRight: "0px",
                    opacity: "60%",
                  }
            }
          ></StateLine>
          <StateBar style={sidebar ? {} : { width: "30px", height: "20px" }}>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              {(ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_resolved") ||
              (ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_closed") ? (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <Checkmark scale={0.7} altText="attachment" viewIn="inbox" />
                </div>
              ) : ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_in_progress" ? (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "2px solid #49484D",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      background: "#49484D",
                      borderRadius: "10px",
                    }}
                  ></div>
                </div>
              ) : (
                <div
                  style={{
                    width: "20px",
                    border: "2px solid #49484D",
                    borderRadius: "10px",
                  }}
                ></div>
              )}
            </div>
          </StateBar>
          <StateLine
            borderType={
              ticket["additionalTicketDetails"] &&
              ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
              ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                "ticket_closed"
                ? "1.6px solid #49484D"
                : isSafari ? "2px dashed #49484D":"1.6px dashed #49484D"
            }
            style={
              sidebar
                ? {}
                : {
                    width: "51px",
                    height: "11px",
                    borderBottom:
                      ticket["additionalTicketDetails"] &&
                      ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                      ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                        "ticket_closed"
                        ? "1.6px solid #49484D"
                        : "1.6px dashed #49484D",
                    borderRight: "0px",
                    opacity: "60%",
                  }
            }
          ></StateLine>
          <StateBar style={sidebar ? {} : { width: "30px", height: "20px" }}>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              {ticket["additionalTicketDetails"] &&
              ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
              ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                "ticket_closed" ? (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <Checkmark scale={0.7} altText="attachment" viewIn="inbox" />
                </div>
              ) : ticket["additionalTicketDetails"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] &&
                ticket["additionalTicketDetails"]["ProgressBarStatus"] ===
                  "ticket_resolved" ? (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "2px solid #49484D",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      background: "#49484D",
                      borderRadius: "10px",
                    }}
                  ></div>
                </div>
              ) : (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    border: "2px solid #49484D",
                    borderRadius: "10px",
                  }}
                ></div>
              )}
            </div>
          </StateBar>
        </Container>
      )}
      <ContainerStatus
        style={
          sidebar
            ? {}
            : {
                flexDirection: "row",
                width: "100%",
                height: "32px",
              }
        }
      >
        <StatusText
          width={"48px"}
          style={
            sidebar
              ? {}
              : {
                  height: "32px",
                  width: "48px",
                  fontSize: "12px",
                  lineHeight: "16px",
                  textAlign: "center",
                  color: "#49484D",
                }
          }
        >
          {languageSNData && languageSNData["inbox_ticketrecieved_text"]
            ? languageSNData["inbox_ticketrecieved_text"]
            : "Ticket recieved"}
        </StatusText>
        <StatusText
          width={"60px"}
          style={
            sidebar
              ? {}
              : {
                  width: "60px",
                  height: "32px",
                  fontSize: "12px",
                  lineHeight: "16px",
                  textAlign: "center",
                  color: "#49484D",
                }
          }
        >
          {languageSNData && languageSNData["inbox_ticketinprogess_text"]
            ? languageSNData["inbox_ticketinprogess_text"]
            : "Ticket in progess"}
        </StatusText>
        <StatusText
          width={"48px"}
          style={
            sidebar
              ? {}
              : {
                  width: "48px",
                  height: "32px",
                  fontSize: "12px",
                  lineHeight: "16px",
                  textAlign: "center",
                  color: "#49484D",
                }
          }
        >
          {languageSNData && languageSNData["inbox_ticketresolved_text"]
            ? languageSNData["inbox_ticketresolved_text"]
            : "Ticket resolved"}
        </StatusText>
        <StatusText
          width={"48px"}
          style={
            sidebar
              ? {}
              : {
                  width: "48px",
                  height: "32px",
                  fontSize: "12px",
                  lineHeight: "16px",
                  textAlign: "center",
                  color: "#49484D",
                }
          }
        >
          {languageSNData && languageSNData["inbox_ticketclosed_text"]
            ? languageSNData["inbox_ticketclosed_text"]
            : "Ticket closed"}
        </StatusText>
      </ContainerStatus>
    </ProgressContainer>
  );
};

export default TicketProgressBar;
