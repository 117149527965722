// @ts-nocheck
import React, { useState, useEffect } from "react";
import "./ToggleSwitch.css";
import OneSignal from "react-onesignal";

//Files & Functions
import { useUserNotificationId } from "../../hooks/useMenuContext/useMenuContext";

type SwitchProps = {
  isNotificationSubscribe: any;
  optedIn: any;
};

const Switch: React.FC<SwitchProps> = ({
  isNotificationSubscribe,
  optedIn,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const userNotificationId = useUserNotificationId();

  useEffect(() => {
    isNotificationSubscribe ? setIsChecked(true) : setIsChecked(false);
  }, [isNotificationSubscribe]);

  function subscribeNotification() {
    OneSignal.User.PushSubscription.optIn();
  }

  function unSubscribeNotification() {
    OneSignal.User.PushSubscription.optOut();
  }

  const handleToggle = () => {
    if (userNotificationId === undefined) {
      OneSignal.Slidedown.promptPush({ force: true });
    } else if (
      OneSignal &&
      OneSignal.User &&
      OneSignal.User.PushSubscription &&
      OneSignal.User.PushSubscription.optedIn &&
      userNotificationId !== undefined
    ) {
      setIsChecked(false);
      unSubscribeNotification();
    } else {
      setIsChecked(true);
      subscribeNotification();
    }
  };

  return (
    <label className={`switch ${isChecked ? "selected" : ""}`}>
      <input type="checkbox" checked={isChecked} onChange={handleToggle} />
      <span className="slider"></span>
    </label>
  );
};

export default Switch;
