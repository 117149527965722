//Library
import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//Files & Functions
import { FooterContainer, FormMainContainer } from "./StyledComponent";
import {
  usehomeSNData,
  uselanguageSNData,
} from "../../hooks/UseApiContext/UseApiContext";
import { useInputText, usedisplaySize } from "../../hooks/useMenuContext/useMenuContext";

//Icons
import Back from "../../Icons/Back_icon/Back";
import Footer from "../FooterComps";

import { useMsal } from "@azure/msal-react";

type FormProps = {};

const Form: React.FC<FormProps> = ({}) => {
  const displaySize = usedisplaySize();
  const navigate = useNavigate();
  const location = useLocation();
  const homeSNData = usehomeSNData();
  const inputText = useInputText()
  let childWindow1: Window | null = null;
  const { instance, accounts } = useMsal();
  var formURL = location.search.includes("?form=")
    ? location.search.split("?form=")[1]
    : "";
  let dataSN =
    homeSNData && homeSNData["ActionDetails"]
      ? homeSNData["ActionDetails"]
      : "";
  const languageSNData = uselanguageSNData();

  function handleBackButton(e){
    let serachTextString = localStorage.getItem("searchItem")
    if(inputText === "" && serachTextString === ""){
      navigate(-1)
    }
    else{
      navigate(`/search?query=${inputText? inputText : serachTextString}`)
    }   
  }

  return (
    <div>
      <div>
        {displaySize !== "isMobile" ? (
          <FormMainContainer>
            <div className="form-container">
              <button
                className="back-button-hover back-button-container"
                onClick={handleBackButton}
              >
                <Back scale={0.7} altText="Back" type="" />
                <div style={{ marginTop: "1px" }}>
                  {languageSNData ? languageSNData["global_back_gotoback"] : ""}
                </div>
              </button>
              <div
                style={{
                  height: "91%",
                  padding: "20px",
                  border: "1.5px solid #E6E6E6",
                  borderRadius: "3px",
                }}
              >
                <iframe
                  src={formURL}
                  style={{ width: "100%", height: "100%" }}
                />
                {/* {checkSNLogin["isLoggedIn"] && (
                  <iframe
                    src={formURL}
                    style={{ width: "100%", height: "100%" }}
                  />
                )} */}
              </div>
              <FooterContainer>
                <div
                  style={{
                    width: "734.29px",
                    height: "55px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Footer dataSN={dataSN} move_footer={"bottom"} />
                </div>
              </FooterContainer>
            </div>
          </FormMainContainer>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              background: "#EBEAF4",
            }}
          >
            <div>
              {/* <div
                style={{
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  width: "100%",
                  height: "fit-content",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "50px",
                    marginTop: "40px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <button
                    className="back-button-hover"
                    onClick={() => {
                      navigate(-1);
                    }}
                    style={{
                      border: "none",
                      width: "fit-content",
                      height: "30px",
                      display: "flex",
                      background: "#EBEAF4",
                    }}
                  >
                    <Back scale={0.7} altText="Back" type="" />
                  </button>
                </div>
              
              </div> */}
              <FormMainContainer>
                <div
                  style={{
                    marginBottom: "50px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "96%",
                      marginLeft: "2%",
                    }}
                  >
                    <iframe
                      src={formURL}
                      style={{ width: "100%", height: "100%" }}
                    />
                    {/* {checkSNLogin["isLoggedIn"] && (
                      <iframe
                        src={formURL}
                        style={{ width: "100%", height: "100%" }}
                      />
                    )} */}
                  </div>
                </div>
              </FormMainContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
