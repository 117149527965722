import styled from "styled-components";

export const ArticleContent = styled.div`
  textalign: left;
  width: 100%;
  height: fit-content;
  margin-top: 20px;

  @media (min-width: 1400px) {
    width: 97%;
  }
  @media (max-width: 480px) {
  textalign: left;
  height: fit-content; 
`;

export const ArticleMainContainer = styled.div`
  width: 850px;
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  padding-top: 20px;
  margin-left: 220px;
  .article-container {
    width: 560px;
    border: 1.5px solid #e6e6e6;
    height: 91%;
    border-radius: 3px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .articles {
    overflow-y: scroll;
    height: 90%;
    width: 520px;
    flex: 1;
  }

  .new-article-container {
    height: 90%;
    overflow-y: scroll;
    flex: 1;
  }

  @media(min-width: 1100px){
    .article-container{
      width: 600px;
    }

    .articles{
      width: 550px;
    }
  }

  @media (min-width: 1400px) {
    width: 1050px;
    justify-content: space-between;
    margin-left: 350px;
    .article-container {
      width: 700px;
      height: 91%;
      padding: 24px;
    }
    .articles {
      margin: auto;
      width: 640px;
      height: 92%;
    }
    .new-article-container {
      height: 92%;
    }
  }
  @media (max-width: 480px) {
    padding-top: 0px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 80%;
    display: flex;
    margin-left: 0px;
    margin-top: 10px;
    background: #ffffff;
    margin-bottom: 32px;
    border-radius: 30px;
    .article-container {
      width: 560px;
      height: 100%;
      padding: 0px, 10px, 10px, 10px;
      display: flex;
      flex-direction: column;
      position: relative;
      border: 0px solid #ffffff;
    }
    .articles {
      overflow-y: scroll;
      height: 100%;
      width: 100%;
      flex: 1;
      
    }
    .new-article-container {
      height: 100%;
      overflow-y: scroll;
      flex: 1;
    }
  }
`;
export const ArticleHeadingContainer = styled.div`
  width: 480px;
  word-wrap: break-word;

  @media (min-width: 1400px) {
    width: 603px;
  }
  @media (max-width: 480px) {
    width: 90%;
    word-wrap: break-word;
    }
`;

export const OtherAtricleContainer = styled.div`
  width: 200px;
  margin-right: 3px;

  .other-article-options {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 8px;
    margin-top: 8px;
    cursor: pointer;
    border-radius: 3px;
  }

  .article-title-name {
    width: 162px;
    height: 20px;
    font-size: 12px;
    color: #49484d;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .article-icon-container {
    width: 25px;
    height: fit-content;
    margin-top: -3px;
    
  }
  @media (min-width: 1100px){
    width: 200px;
  }

  @media (min-width: 1400px) {
    width: 278px;
    .article-title-name {
      width: 200px;
    }
  }
`;
export const SearchBarAndArticle = styled.div`
      width: 500px;
      .article-list-container{
        height: 90%;
      }

      .show-footer{
        width: 600px;
        display: flex;
        align-items: center;
        background: white;
      }

      @media( min-width: 1100px){
        width: 600px;
        
    }
      @media( min-width: 1400px){
        width: 700px;
        .article-list-container{
            height: 93%;
          }
        .show-footer{
            display: none;
        }
    }
`

