import styled from "styled-components";

export const Menucontainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    justify-content: flex-start;
    align-items: flex-start;
    background: #ebeaf4;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const Container = styled.div`
  width: 500px;
  height: 252px;
  text-align: center;
  @media (max-width: 480px) {
    width: 100%;
    margin-top: 30px;
    height: 100%;
  }
`;

export const MassageContainer = styled.div`
  width: fit-content;
  min-width: 306px;
  height: fit-content;
  min-height: 247px;
  position: absolute;
  background: #ffffff;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 480px) {
    z-index: 1;
  }
`;

export const SetIcon = styled.div`
  height: 20%;
  width: 30%;
  position: absolute;
  top: 17%;
  right: 17%;
  font-size: 20px;
  @media (max-width: 480px) {
    position: absolute;
    top: 48%;
    height: 35%;
   width: 90%;
   left: 5%;
   right: 5%;
`;

export const MassageContainerText1 = styled.div`
  font-weight: 500;
  padding: 0px 10px;
  height: fit-content;
  width: fit-content;
  font-size: 28px;
  color: #0e090c;
`;

export const MassageContainerText2 = styled.div`
  font-size: 14px;
  margin-top: 32px;
  width: 230px;
  color: #767675;
`;

type ButtonProps = {
  color: any;
  hoverColor: any;
  backgroundColor: any;
};

export const MassageContainerButton = styled.div<ButtonProps>`
  background-color: ${(props: ButtonProps) => props.backgroundColor};
  height: 38px;
  color: ${(props: ButtonProps) => props.color};
  font-size: 16px;
  border-radius: 3px;
  padding: 7px 15px;
  cursor: pointer;
  :hover {
    background: ${(props: ButtonProps) => props.hoverColor};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #4a2878;
  }
  @media (max-width: 480px) {
    background: #755b98;
    width: 100%;
`;

export const HomeButton = styled.div`
  height: 35px;
  width: fit-content;
  margin-top: 30px;
  background-color: #dd296e;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  display: flex;
  cursor: pointer;
  :hover {
    background: linear-gradient(90deg, #cf022b 0%, #ea5599 100%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #d10726;
  }
  @media (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
`;

export const SetSopraIcon = styled.div`
  height: 40px;
  width: 150px;
  position: absolute;
  bottom: 6px;
  left: 25px;
  font-size: 20px;
`;

export const HomeText = styled.div`
  padding: 6px 12px;
  @media (max-width: 480px) {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    padding-left: 3px;
    padding-top: 8px;
  }
`;
export const CancelCallText = styled.div`
  width: fit-content;
  height: 30px;
  color: #07284b;
  font-size: 16px;
  margin-top: 14px;
  border-radius: 3px;
  font-weight: 600;
  padding: 2px 10px;
  text-align: center;
  cursor: pointer;
  @media (max-width: 480px) {
    height: 40px;
    color: #ffffff;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 20px;
    padding: 10px 15px;
    background: #675b98;
  }
`;
