import styled from "styled-components";

export const ErrorMainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .content-container {
    width: fit-content;
    height: fit-content;
  }
  .massage-container {
    width: 486px;
    height: fit-content;
    max-height: 56px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #959199;
    margin: auto;
    margin-top: 10px;
  }
  @media (max-width: 480px) {
    display: block;
    justify-content: center;
    .content-container {
      width: 100%;
      height: 100%;
      justify-content: center;
      margin-top: 80px;
    }
  }
`;

export const IconContainer = styled.div`
  width: 300px;
  height: 290.23px;
  margin: auto;
  align-items: center;
  @media (max-width: 480px) {
    height: fit-content;
    width: fit-content;
    display: flex;
    alignitems: center;
  }
`;

export const ErrorTitleText = styled.div`
  width: fit-content;
  max-height: 210px;
  height: fit-content;
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  color: #000000;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  @media (max-width: 480px) {
    alignitems: center;
    height: fit-content;
    width: 245px;
    line-height: 36px;
    text-align: center;
    padding: 2px 20px 2px 15px;
    max-height: 110px;
    height: fit-content;
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    text-align: center;

  }
`;

export const HomeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 167px;
  height: 42px;
  background: #dd296e;
  border-radius: 3px;
  margin: auto;
  margin-top: 30px;

  .button-title-text {
    width: fit-content;
    height: 28px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    margin-top: -2px;
  }
  @media (max-width: 480px) {
    border-radius: 30px;
  }
`;
