//Library
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";
import Call from "../../Icons/MobileCall_icon";
import Search from "../../Icons/MobileSearch_icon";
import Ticket from "../../Icons/MobileTicket_icon";
import {
  useMobileNavBar,
  useSetMobileNavBar,
} from "../../hooks/useMenuContext/useMenuContext";

//Icons
import Chat from "../../Icons/Footer-icons/chat-icon";
type PopUpMenuProps = {};

const PopUp: React.FC<PopUpMenuProps> = ({}) => {
  const navigate = useNavigate();
  const mobileNavBar = useMobileNavBar();
  const setMobileNavBar = useSetMobileNavBar();
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (mobileNavBar && ref.current && !ref.current.contains(e.target)) {
        setMobileNavBar(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [mobileNavBar]);

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        height: "110px",
        position: "absolute",
        bottom: "70px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "50px",
          width: "50px",
          borderRadius: "25px",
          background: "#DD296E",
          marginTop: "33px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          navigate("/callform");
          setMobileNavBar(false);
        }}
      >
        <div style={{ height: "24px", width: "24px" }}>
          <Call scale={1.2} altText="Call" />
        </div>
      </div>
      <div
        style={{
          height: "50px",
          width: "50px",
          borderRadius: "25px",
          background: "#DD296E",
          margin: "11px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          navigate("/chat");
          setMobileNavBar(false);
        }}
      >
        <div
          style={{
            width: "35px",
            height: "35px",
            marginLeft: "7px",
          }}
        >
          <Chat scale={0.9} altText="Call" color="white"/>
        </div>
      </div>
      <div
        style={{
          height: "50px",
          width: "50px",
          borderRadius: "25px",
          background: "#DD296E",
          marginTop: "33px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          navigate("/ticketform");
          setMobileNavBar(false);
        }}
      >
        <div style={{ marginTop: "2px", height: "24px", width: "24px" }}>
          <Ticket scale={0.9} altText="Call"/>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
