import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as call } from "./Shapecall.svg";
import styled from "styled-components";
const CallIcon = styled(call)``;

type CallIconProps = {
  scale?: number;
  altText: string;
};

const Call: React.FC<CallIconProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={1.5 * scale} height={1.5 * scale} aria-label={altText}>
      <CallIcon />
    </IconWrapper>
  );
};

export default Call;
