import styled from "styled-components";

export const HomePageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 500px;
  height: 355px;
  @media (min-width: 1053px) {
    width: 600px;
  }
  @media (min-width: 1712px) {
    width: 700px;
  }
`;

export const Heading = styled.div`
  height: 67px;
  font-size: 36px;
  color: #0e090c;
  text-align: center;
  font-family: "Roboto Slab", serif;
`;

export const ActionButton = styled.button`
  background-color: #ffffff;
  color: #49484d;
  border: 1.5px solid #959199;
  .blueIcon {
    display: none;
  }
  :hover {
    background-color: #ebeaf4;
    border: 1.5px solid #675b98 !important;
    color: #755b98;
    .blackIcon {
      display: none;
    }
    .blueIcon {
      display: block;
    }
  }
`;

export const MainPageHeader = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
`;
