//Library
import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//Files & Functions
import {
  Menucontainer,
  Container,
  MassageContainer,
  SetIcon,
  MassageContainerText1,
  MassageContainerText2,
  MassageContainerButton,
  HomeButton,
  HomeText,
  CancelCallText,
  SetSopraIcon,
} from "./StyledComponent";
import {
  usehomeSNData,
  uselanguageSNData,
} from "../../hooks/UseApiContext/UseApiContext";

//Components

//Icons
import Close from "../../Icons/Header_close_icon/Close";
import SetCallingIcon from "../../Icons/SetCallingIcon";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import { getSNJSON } from "../../api";
import { usedisplaySize } from "../../hooks/useMenuContext/useMenuContext";

//icons:-
import Home from "../../Icons/Home_icon";
import Back from "../../Icons/Back_icon/Back";

type CallStatusProps = {};

const CallStatus: React.FC<CallStatusProps> = ({}) => {
  const displaySize = usedisplaySize();
  const location = useLocation();
  const homeSNData = usehomeSNData();
  const [toastMassage, setToastMassage] = useState(false);
  const [editNumber, setEditNumber] = useState(
    localStorage.getItem("newNumber") ? localStorage.getItem("newNumber") : "0"
  );
  const [callResponse, setCallResponse] = useState(
    location.search.split("?Queue=")[1]
  );
  let userSysID = homeSNData["callMeNowDetails"]["userSysID"]
    ? homeSNData["callMeNowDetails"]["userSysID"]
    : "";
  const [callCancelResponse, setCallCancelResponse] = useState("");
  const [fetchCancelCallJSON, performCancelCallJSON] = useApiAction(getSNJSON);
  const navigate = useNavigate();
  const languageSNData = uselanguageSNData();

  function showToastMassage(value) {
    setToastMassage(value);
  }

  useEffect(() => {
    if (
      callCancelResponse["Deleted"] === "true" ||
      callCancelResponse["Deleted"] === "false"
    ) {
      navigate("/home");
    }
  }, [callCancelResponse]);

  useEffect(() => {
    if (fetchCancelCallJSON.data) {
      setCallCancelResponse(fetchCancelCallJSON.data.result);
    }
  }, [fetchCancelCallJSON.data, callCancelResponse]);

  function cancelCalltoHomepage() {
    setToastMassage(false);
    let amazonQueueID =
      homeSNData && homeSNData["callMeNowDetails"]
        ? homeSNData["callMeNowDetails"]["AmazonQueueID"]
        : "";
    var Feild = [
      "Post",
      "urlcancelCallBackQueue",
      "",
      { userSysID: userSysID, AmazonQueueID: amazonQueueID },
    ];
    performCancelCallJSON(Feild);
  }

  return (
    <>
      <Menucontainer>
        <SetIcon>
          <SetCallingIcon />
        </SetIcon>
        <div
          style={{
            display: toastMassage ? "" : "none",
            background: "black",
            opacity: "25%",
            position: "absolute",
            bottom: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            zIndex: displaySize !== "isMobile" ? 0 : 1,
          }}
          onClick={() => showToastMassage(false)}
        ></div>
        <MassageContainer style={{ display: toastMassage ? "" : "none" }}>
          <div
            style={{
              height: "30px",
              padding: "3px",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div
              className="back-button-hover"
              onClick={() => showToastMassage(false)}
              style={{ width: "25px", cursor: "pointer" }}
            >
              <Close scale={0.8} altText="Minimize" color="" />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              height: "fit-content",
              justifyContent: "center",
            }}
          >
            <MassageContainerText1 className="FontRoboto">
              {" "}
              {languageSNData
                ? languageSNData["callinformation_toastmassage_headingtitle"]
                : ""}
            </MassageContainerText1>
          </div>
          <div
            style={{
              display: "flex",
              height: "fit-content",
              padding: "0px 15px",
              justifyContent: "center",
            }}
          >
            <MassageContainerText2>
              {languageSNData
                ? languageSNData["callinformation_toastmassage_subtitle"]
                : ""}
            </MassageContainerText2>
          </div>
          <div
            style={{
              height: "58px",
              marginTop: "32px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "fit-content",
                display: "flex",
              }}
            >
              {displaySize !== "isMobile" ? (
                <MassageContainerButton
                  color="var(--secondary-Purple-light, #675B98)"
                  hoverColor="#EBEAF4"
                  backgroundColor="white"
                  style={{
                    width: "fit-content",
                  }}
                  onClick={() => showToastMassage(false)}
                >
                  {languageSNData &&
                    languageSNData[
                      "callinformation_toastmassage_nothankstext"
                    ] &&
                    languageSNData["callinformation_toastmassage_nothankstext"]}
                </MassageContainerButton>
              ) : (
                <></>
              )}
              <MassageContainerButton
                color="#FFFFFF"
                hoverColor="linear-gradient(90deg, #4a2878 0%, #755b98 100%)"
                backgroundColor=" #755b98"
                // onClick={() => showToastMassage(false)}
                onClick={cancelCalltoHomepage}
                style={{
                  width: "fit-content",
                  fontSize: "16px",
                  borderRadius: "3px",
                  padding: displaySize !== "isMobile" ? "7px 15px" : "7px 75px",
                  marginLeft: displaySize !== "isMobile" ? "16px" : "0px",
                  cursor: "pointer",
                }}
              >
                {languageSNData ? languageSNData["global_cancel_call"] : ""}
              </MassageContainerButton>
            </div>
          </div>
        </MassageContainer>
        {homeSNData && homeSNData["callMeNowDetails"] ? (
          <Container>
            {/* {displaySize !== "isMobile" ? null : (
              <button
                className="back-button-hover"
                onClick={() => {
                  navigate(-1);
                }}
                style={{
                  border: "none",
                  width: "fit-content",
                  height: "30px",
                  alignSelf: "flex-start",
                  borderRadius: "3px",
                  cursor: "pointer",
                  position: "absolute",
                  left: "20px",
                  top: "40px",
                  background: "#EBEAF4",
                  zIndex: 0,
                }}
              >
                <div>
                  <Back scale={1} altText="Back" type="mobile" />
                </div>
              </button>
            )} */}
            <div
              style={{
                height: "80px",
                textAlign: displaySize !== "isMobile" ? "center" : "left",
              }}
            >
              <div
                className="FontRoboto"
                style={{ height: "52px", color: "#0E090C", fontSize: "36px" }}
              >
                {languageSNData
                  ? languageSNData["callinformation_headingtitle_text"]
                  : ""}
              </div>
              <div
                style={{ height: "28px", color: "#4A2878", fontSize: "20px" }}
              >
                {languageSNData
                  ? languageSNData["callinformation_peopleinline_text"].replace(
                      "{&$&}",
                      callResponse
                    )
                  : ""}
              </div>
            </div>

            <div
              style={{
                height: displaySize !== "isMobile" ? "70px" : "120px",
                marginTop: "35px",
              }}
            >
              <div
                style={{
                  height: displaySize !== "isMobile" ? "24px" : "55px",
                  color: "#4A4A49",
                  fontSize: "16px",
                }}
              >
                {languageSNData
                  ? languageSNData["callinformation_shownumbertitle_text"]
                  : ""}
                :{" "}
                {editNumber === "0"
                  ? homeSNData &&
                    homeSNData["callMeNowDetails"] &&
                    homeSNData["callMeNowDetails"]["userPhoneNumber"] + ""
                  : editNumber}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CancelCallText
                  className="back-button-hover"
                  onClick={() => showToastMassage(true)}
                >
                  {languageSNData ? languageSNData["global_cancel_call"] : ""}
                </CancelCallText>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <HomeButton onClick={() => navigate("/")}>
                {displaySize !== "isMobile" ? null : (
                  <div
                    style={{
                      height: "fit-content",
                      marginLeft: "10px",
                      width: "35px",
                      paddingBottom: "6px",
                    }}
                  >
                    <Home scale={0.7} altText="attachment" color="white" />
                  </div>
                )}
                <HomeText>
                  {" "}
                  {languageSNData ? languageSNData["header_home_gotohome"] : ""}
                </HomeText>
              </HomeButton>
            </div>
          </Container>
        ) : (
          ""
        )}
      </Menucontainer>
    </>
  );
};

export default CallStatus;
