import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as offlinePage } from './offline_page.svg';
import styled from "styled-components";
const OfflinePageIcon = styled(offlinePage)`
`


type offlinePageLogoProps = {
  scale?: number;
  altText: string;
};

const OfflinePageShow: React.FC<offlinePageLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2.4* scale} height={3.3 * scale} aria-label={altText} style={{marginTop:"0px"}} >
        <OfflinePageIcon/>
    </IconWrapper>
  );
};

export default OfflinePageShow;