import styled from "styled-components";

export const LoginPageHeader = styled.div`
  width: 100%;
  height: 7%;
  display: flex;
  @media (min-height: 720px) {
    height: 6%;
  }
  @media (min-height: 920px) {
    height: 5%;
  }
  .header-drag {
    -webkit-app-region: drag;
  }
`;

export const LoginPageContainer = styled.div`
  width: 100%;
  height: 93%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-height: 720px) {
    height: 94%;
  }
  @media (min-height: 920px) {
    height: 95%;
  }
`;
