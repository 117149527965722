import styled from "styled-components";


export const OfflinePageMainContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .items-container{
        width: 458px;
        height: 456px;
        border-radius: 3px;
        padding: 32px;
    }
`

export const NoInternet = styled.div`
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-container{
        width: fit-content;
        height: 118px
    }
`
export const HeadingContainer = styled.div`
    display: flex;
    justify-content: center;
    height: fit-content;

    .heading-title{
        height: 36px;
        width: auto;
        max-width: 394px;
        text-align: center;
        color: #000;
        font-family: Roboto Slab;
        font-size: 28px;
        line-height: 36px;
        font-weight: 400;
    }
`

export const ContentContainer = styled.div`
    width: auto;
    max-width: 390px;
    height: 200px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .step-container{
        width: auto;
        max-width: 390px;
        height: 148px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .step-title-container{
        min-width: fit-content;
        width: auto;
        max-width: 390px;
        height: 24px;
        color: #959199;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin: auto;
    }

    .steps{
        width: fit-content;
        margin: auto;
        height: fit-content;
    }
`

export const RefreshButtonContainer = styled.div`
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;

    .button-container{
        min-width: 118px;
        width: auto;
        max-width: 135px;
        height: 42px;
        border-radius: 3px;
        background: #DD296E;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #FFFFFF;
    }
`

export const StepOne = styled.div`
    height: 24px;
    display: flex;
    gap: 3px;
    align-items: center;

    .check-icon-stepone{
        width: 20px;
        height: 18px;
        margin-top: 4px;
    }

    .stepone-text{
        color: #959199;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
`
export const StepTwo = styled.div`
    height: 24px;
    display: flex;
    gap: 3px;
    margin-top: 10px;
    align-items: center;

    .check-icon-steptwo{
        width: 20px;
        height: 18px;
        margin-top: 4px;
    }

    .steptwo-text{
        color: #959199;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }

`
export const StepThree = styled.div`
    height: 24px;
    display: flex;
    gap: 3px;
    margin-top: 10px;
    align-items: center;

    .check-icon-stepthree{
        width: 20px;
        height: 18px;
        margin-top: 4px;
    }

    .stepthree-text{
        width: 241px;
        color: #959199;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
`