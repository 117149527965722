import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as Icon } from './R.svg';
import styled from "styled-components";
const RIcon = styled(Icon)`
  
`

type RLogoProps = {
  scale?: number;
  altText: string;
};

const RLogo: React.FC<RLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} >
      <RIcon/>
    </IconWrapper>
  );
};

export default RLogo;