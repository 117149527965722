import styled from "styled-components";

export const HeaderContainer = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
`;
export const NavButtonMobile = styled.div`
left: calc(50% - 40px);
`;
