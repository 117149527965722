//Library
import React from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import config from "../../constant/config";

//Files & Functions

//Components

//Icons

type LogoutProps = {

}

const Logout: React.FC<LogoutProps> = ({ }) => {
    const navigate = useNavigate();
    const { instance } = useMsal();
    function signOutClickHandler(instance) {
        const logoutRequest = {
            account: instance.getAccountByHomeId(config.clientId),
            mainWindowRedirectUri: config.redirectUri
        }
        instance.logoutPopup(logoutRequest);
    }

    const handleLogout = (instance) => {
        navigate('/', { replace: true })
        signOutClickHandler(instance)
        localStorage.clear();
    };
    handleLogout(instance)

    return (
        <div
            style={{}}>
            Logout
        </div>
    )
}

export default Logout;