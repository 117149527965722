//Library
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactTextareaAutosize from "react-textarea-autosize";

//Files & Functions
import {
  Menucontainer,
  Container,
  YesButton,
  NoButton,
  SubmitButton,
  Form,
  ShowArticleButton,
} from "./StyledComponent";
import { getSNJSON } from "../../api/index";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";

//Components

//Icons
import Star from "../../Icons/StarIcon";
import {
  useInboxRecall,
  useInputText,
  useLanguageData,
  useSetInboxRecall,
  useSetInputText,
  useSetTicketSysId,
  usedisplaySize,
} from "../../hooks/useMenuContext/useMenuContext";
import Back from "../../Icons/Back_icon/Back";
import {
  usehomeSNData,
  uselanguageSNData,
} from "../../hooks/UseApiContext/UseApiContext";
import Avatar from "../AvatarComps";
import Delete from "../../Icons/Delete_icon/Delete";
import Attachment from "../../Icons/Attachment_icon";
import PopUpWindow from "../PopUpWindowComps";

type TicketFormProps = {};

const TicketForm: React.FC<TicketFormProps> = ({}) => {
  const fileInputRef = useRef(null);
  const [fetchCreateTicketJSON, performCreateTicketJSON] =
    useApiAction(getSNJSON);
  const [fetchArticlesJSON, performFetchArticlesJSON] = useApiAction(getSNJSON);
  const [fetchAttachmentJSON, performAttachmentJSON] = useApiAction(getSNJSON);
  const inboxRecall = useInboxRecall();
  const setInputText = useSetInputText();
  const setInboxRecall = useSetInboxRecall();
  const homeSNData = usehomeSNData();
  const navigate = useNavigate();
  const inputText = useInputText();
  let file_type = ".xlsx, .pptx, .jpg, .pdf, .docx, .eml, .msg, .png";
  const displaySize = usedisplaySize();
  const [shortdescription, setShortDescription] = useState(
    inputText === "" ? "" : inputText
  );

  const [ticketResponseSN, setTicketResponseSN] = useState("");
  const [description, setDescription] = useState("");
  const [showArticles, setShowArticles] = useState(false);
  const [articles, setArticles] = useState("");
  const [attachmentPayload, setAttachmentPayload] = useState([]);
  const [multipleAttachments, setMultipleAttachments] = useState([]);
  const [pasteAttachments, setPasteAttachments] = useState([]);
  const [filename, setFilename] = useState([]);
  const [filetype, setFiletype] = useState([]);
  const [blank, setBlank] = useState(false);
  const [field3, setField3] = useState("");
  const [field4, setField4] = useState("");
  const [yescount, setYesCount] = useState(false);
  const [nocount, setNoCount] = useState(false);
  const [submit, setSubmit] = useState(false);
  const languageSNData = uselanguageSNData();
  const setTicketSysID = useSetTicketSysId();
  let base64 = null;
  const [files, setFiles] = useState([]);

  const inputRef = useRef(null);

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.Clipboard;
    const items = clipboardData.items;
    const pasteText = clipboardData.getData("text/plain");
    let hasImage = false;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file" && item.type.includes("image")) {
        const file = item.getAsFile();
        displayImage(file);
      }
    }
    if (!hasImage && pasteText) {
      const range = window.getSelection().getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(pasteText));
    }

    event.preventDefault();
  };

  const displayImage = (file) => {
    const reader = new FileReader(); 
    reader.onload = (event) => {
      const imgTag = `<img src="${event.target.result}" alt="RightSupport Image" style="height: 200px; width: 300px;" />`;
      document.execCommand("insertHTML", false, imgTag);
    };

    reader.readAsDataURL(file);
  };

  const inputTextShortDescription = (e) => {
    setShortDescription(e.target.value);
  };

  useEffect(() => {
    if (shortdescription === "") {
      setArticles("");
    }
    var Feild = ["Get", "urlSearchData", shortdescription, {}];
    let recallSuggestion;
    if (shortdescription.length > 5) {
      if (recallSuggestion !== shortdescription) {
        performFetchArticlesJSON(Feild);
      }
    }
    recallSuggestion = shortdescription;
  }, [shortdescription]);

  useEffect(() => {
    if (shortdescription.length > 5) {
      if (
        fetchArticlesJSON &&
        fetchArticlesJSON.data &&
        fetchArticlesJSON.data["result"]
      ) {
        setArticles(fetchArticlesJSON.data["result"]);
      }
    }
  }, [fetchArticlesJSON.data, shortdescription]);

  // const inputTextDescription = (e) => {
  //   setDescription(e.target.value);
  // };

  useEffect(() => {
    let payloadAttachment = [];
    for (let i = 0; i < files.length; i++) {
      payloadAttachment.push({
        file_name: files[i]["file"]["name"],
        content_type: files[i]["file"]["type"],
        sys_id: "sys_id",
        base64:
          files[i]["dataUrl"].includes("data:image/png;base64,") &&
          files[i]["dataUrl"].split("data:image/png;base64,")[1],
      });
      if (files.length === i) {
        setFiles([]);
      }
    }
    setMultipleAttachments(payloadAttachment);
  }, [files]);

  function handleYesCheckUncheckinOptional(value) {
    if (value === "Yes" && yescount === false) {
      setField4("Yes");
      setYesCount(true);
      setNoCount(false);
    } else {
      setField4("");
      setYesCount(false);
    }
  }

  function handleNoCheckUncheckinOptional(value) {
    if (value === "No" && nocount === false) {
      setField4("No");
      setNoCount(true);
      setYesCount(false);
    } else {
      setField4("");
      setNoCount(false);
    }
  }

  useEffect(() => {
    if (fetchCreateTicketJSON.data && fetchCreateTicketJSON.data["result"]) {
      setTicketResponseSN(fetchCreateTicketJSON.data.result);
    }
    // parseInt(ticketResponseSN["status"]) === 200 ? setInboxRecall(!inboxRecall) : console.log("No Sysid")
    // parseInt(ticketResponseSN["status"]) === 200 ? navigate("/ticketstatus") : console.log("No Sysid")

    if (parseInt(ticketResponseSN["status"]) === 200) {
      if (attachmentPayload.length !== 0) {
        let sys_id =
          ticketResponseSN &&
          ticketResponseSN["ticket"] &&
          ticketResponseSN["ticket"]["Sys ID"]
            ? ticketResponseSN["ticket"]["Sys ID"]
            : ticketResponseSN["ticket"]["Sys-ID"];
        attachmentPayload.map(
          (e) =>
            (attachmentPayload.find((e) => e["sys_id"] === "sys_id").sys_id =
              sys_id)
        );
        var FeildAttachment = ["Post", "urlAttachment", "", attachmentPayload];
        performAttachmentJSON(FeildAttachment);
      }
      if (pasteAttachments.length !== 0) {
        let sys_id =
          ticketResponseSN &&
          ticketResponseSN["ticket"] &&
          ticketResponseSN["ticket"]["Sys ID"]
            ? ticketResponseSN["ticket"]["Sys ID"]
            : ticketResponseSN["ticket"]["Sys-ID"];
        pasteAttachments.map(
          (e) =>
            (pasteAttachments.find((e) => e["sys_id"] === "sys_id").sys_id =
              sys_id)
        );
        var FeildAttachment = ["Post", "urlAttachment", "", pasteAttachments];
        performAttachmentJSON(FeildAttachment);
      }
      // var FeildAttachment = ["Post", "urlAttachment", "", attachmentPayload];
      setTicketSysID(
        ticketResponseSN &&
          ticketResponseSN["ticket"] &&
          ticketResponseSN["ticket"]["Sys ID"]
      );
      setInboxRecall(!inboxRecall);
      navigate(
        `/ticketstatus?${
          ticketResponseSN &&
          ticketResponseSN["ticket"] &&
          ticketResponseSN["ticket"]["Number"]
        }`
      );
    }
  }, [fetchCreateTicketJSON.data, ticketResponseSN]);

  function CreateTicketIncident() {
    let desc = inputRef && inputRef.current && inputRef.current.outerText;
    let pasteImage = inputRef && inputRef.current && inputRef.current.outerHTML;
    let payloadAttachment = [];
    if (pasteImage.search('<img src="data:image/png;base64,') !== -1) {
      let ImageTag = pasteImage.split('<img src="data:image/png;base64,');
      for (let i = 0; i < ImageTag.length; i++) {
        if (
          ImageTag[i].search(
            '" alt="RightSupport Image" style="height: 200px; width: 300px;">'
          ) !== -1
        ) {
          let Imagebase64 = ImageTag[i].split(
            '" alt="RightSupport Image" style="height: 200px; width: 300px;">'
          )[0];

          payloadAttachment.push({
            file_name: "Rightsupport" + " - " + i,
            content_type: "Rightsupport/png",
            sys_id: "sys_id",
            base64: Imagebase64,
          });
        }
      }
    }
    setPasteAttachments(payloadAttachment);
    var Feild = [
      "Post",
      "urlCreateTicket",
      "",
      {
        short_description: shortdescription,
        description: inputRef && inputRef.current && inputRef.current.outerText,
        are_other_people_affected: field3,
        are_you_blocked_from_doing_your_work: field4,
      },
    ];
    if (submit === false) {
      if (
        shortdescription === "" ||
        (desc === "" && attachmentPayload.length === 0) ||
        field3 === ""
      ) {
        setBlank(true);
      } else {
        setSubmit(true);
        performCreateTicketJSON(Feild);
      }
    }
  }

  function handleDropValue(event) {
    event.preventDefault();
    const { files } = event && event.dataTransfer;
    (async () => await handleChange(files))();
  }

  const handleChange = async (event) => {
    // let Filearr = event.target.files;
    // const file = event.target.files;
    const file = event;
    let allBase64 = [];
    let allFileName = [];
    let allFiletype = [];
    let payloadAttachment = [];
    // let sys_id = ticket && ticket["Sys ID"];
    for (let i = 0; i < file.length; i++) {
      // const file = event.target.files[i];
      const file = event[i];
      base64 = await convertBase64(file);
      let stringToIterate = base64;
      let startIndex = stringToIterate.indexOf("data");
      let endIndex = stringToIterate.indexOf("base64,") + "base64,".length;
      let substringToSplit = stringToIterate.substring(startIndex, endIndex);
      let splitSubstring = stringToIterate.split(substringToSplit);
      let pfrr = splitSubstring[1];
      allBase64.push(pfrr);
      // allFileName.push(event.target.files[i]["name"]);
      // allFiletype.push(event.target.files[i]["type"]);
      allFileName.push(event[i]["name"]);
      allFiletype.push(event[i]["type"]);
      payloadAttachment.push({
        // file_name: event.target.files[i]["name"],
        // content_type: event.target.files[i]["type"],
        file_name: event[i]["name"],
        content_type: event[i]["type"],
        sys_id: "sys_id",
        base64: pfrr,
      });
    }
    setMultipleAttachments(payloadAttachment);
    setFilename(allFileName);
    setFiletype(allFiletype);
    // base64 = await convertBase64(file);
    // console.log(base64);
    // setFile64(base64);
  };
  useEffect(() => {
    const updatedArray = [...attachmentPayload, multipleAttachments];
    const singleArray = updatedArray.reduce((acc, curr) => {
      return acc.concat(curr);
    }, []);
    setAttachmentPayload(singleArray);
  }, [multipleAttachments]);

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const removeAttachment = (item) => {
    setAttachmentPayload(
      attachmentPayload.filter((items, key) => key !== item)
    );
  };

  return (
    <Menucontainer>
      {showArticles && (
        <div
          onClick={() => setShowArticles(false)}
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            background: "rgba(0, 0, 0, 0.5)",
            display: showArticles ? "flex" : "none",
            justifyContent: "center",
            zIndex: "3",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {" "}
          <PopUpWindow
            visible={showArticles}
            setvisible={setShowArticles}
            from={"showArticles"}
            articlesData={articles}
          />
        </div>
      )}
      {/* {showArticles && (
       
      )} */}
      <Container>
      {displaySize !== "isMobile" && (
        <button
          className="back-button-hover"
          onClick={() => {
            navigate(-1);
          }}
          style={{
            border: "none",
            width: "fit-content",
            height: "30px",
            position: "absolute",
            top: displaySize !== "isMobile" ? "70px" : "40px",
            left: displaySize !== "isMobile" ? "40px" : "20px",
            display: "flex",
            borderRadius: "3px",
            textAlign: "left",
            background: displaySize !== "isMobile" ? "none" : "#EBEAF4",
          }}
        >
          <Back
            scale={0.7}
            altText="Back"
            type=""
          />
         
            <div style={{ marginTop: "1px" }}>
              {languageSNData ? languageSNData["global_back_gotoback"] : ""}
            </div>
        </button>
      )}
        <div
          style={{
            width: "100%",
            height: "fit-content",
          }}
        >
          <div
            className="FontRoboto"
            style={{
              width: "100%",
              height: displaySize !== "isMobile" ? "56px" : "fit-content",
              maxHeight: "112px",
              fontSize: "36px",
              fontWeight: "500",
              color: "#0E090C",
            }}
          >
            {languageSNData
              ? languageSNData["createticket_headingtitle_text"]
              : ""}
          </div>
          <div
            style={{
              width: "100%",
              height: displaySize !== "isMobile" ? "28px" : "fit-content",
              maxHeight: "56px",
              fontSize: "20px",
              fontFamily: "revert-layer",
              fontWeight: "500",
              color: "#4A2878",
            }}
          >
            {languageSNData ? languageSNData["createticket_subtitle_text"] : ""}
          </div>
        </div>
        <Form>
          <div
            style={{
              width: "98%",
              height: "20px",
              fontSize: "14px",
              color: blank && shortdescription === "" ? "#DD296E" : "#4A4A49",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "fit-content",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Star
                scale={1.4}
                altText="Minimize"
                color={blank && shortdescription === "" ? "pink" : ""}
              />{" "}
              <div
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  height: "20px",
                  paddingTop: "1px",
                  paddingLeft: "4px",
                }}
              >
                {" "}
                {languageSNData
                  ? languageSNData["createticket_tickettitle_text"]
                  : ""}
              </div>
            </div>{" "}
            {articles !== "" &&
              articles &&
              articles["data"] &&
              articles["data"]["search"] &&
              articles["data"]["search"]["count"] !== 0 && (
                <ShowArticleButton
                  onClick={() => {
                    setShowArticles(true);
                  }}
                  style={{}}
                >
                  {languageSNData &&
                  languageSNData["createticket_ticketaticle_text"]
                    ? languageSNData["createticket_ticketaticle_text"].replace(
                        "{$$}",
                        articles &&
                          articles["data"] &&
                          articles["data"]["search"] &&
                          articles["data"]["search"]["count"]
                      )
                    : "We found {$$} matching articles".replace(
                        "{$$}",
                        articles &&
                          articles["data"] &&
                          articles["data"]["search"] &&
                          articles["data"]["search"]["count"]
                      )}
                </ShowArticleButton>
              )}
          </div>

          <div
            style={{
              width: displaySize !== "isMobile" ? "98%" : "100%",
              height: "48px",
              marginTop: "8px",
              border:
                displaySize === "isMobile"
                  ? "2px solid #959199"
                  : "1.5px solid #D9D9D9",
              borderRadius: displaySize !== "isMobile" ? "3px" : "30px",
              display: "flex",
              background: "#ffffff",
              padding: displaySize === "isMobile" ? "2px 10px" : "0px 0px",
            }}
          >
            <input
              style={{
                width: displaySize !== "isMobile" ? "90%" : "88%",
                height: "100%",
                border: "none",
                outline: "none",
                padding: "2px 10px",
                background: "#ffffff",
              }}
              maxLength={50}
              value={shortdescription}
              onChange={inputTextShortDescription}
            ></input>
            <div
              style={{
                width: "12%",
                height: "100%",
                fontSize: "14px",
                color: "#767675",
                padding: displaySize !== "isMobile" ? "22px 0px" : "15px 0px",
              }}
            >
              {shortdescription.length}/50
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: displaySize !== "isMobile" ? "20px" : "fit-content",
              fontSize: "14px",
              color:
                blank &&
                inputRef &&
                inputRef.current &&
                inputRef.current.outerText === "" &&
                attachmentPayload.length === 0
                  ? "#DD296E"
                  : "#4A4A49",
              marginTop: "16px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "19px",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: displaySize === "isMobile" ? "-2px" : "",
              }}
            >
              <Star
                scale={1.4}
                altText="Minimize"
                color={
                  blank &&
                  inputRef &&
                  inputRef.current &&
                  inputRef.current.outerText === "" &&
                  attachmentPayload.length === 0
                    ? "pink"
                    : ""
                }
              />{" "}
            </div>{" "}
            {displaySize !== "isMobile" ? (
              languageSNData ? (
                languageSNData["createticket_descriptiontitle_text"]
              ) : (
                ""
              )
            ) : (
              <div
                style={{
                  height: "fit-content",
                  fontSize: "14px",
                  lineHeight: "20px",
                  width: "90%",
                  alignItems: "center",
                  fontFamily: "Inter",
                  display: "flex",
                }}
              >
                {languageSNData
                  ? languageSNData["createticket_descriptiontitle_text"]
                  : ""}
              </div>
            )}
          </div>
          <div
            style={{
              height: "fit-content",
              marginTop: "9px",
              width: displaySize !== "isMobile" ? "98%" : "100%",
              padding: "2px 10px",
              borderRadius: displaySize !== "isMobile" ? "4px" : "30px",
              border:
                displaySize === "isMobile"
                  ? "2px solid #959199"
                  : "1.5px solid #D9D9D9",
              display: "flex",
              background: "#ffffff",
            }}
          >
            <div>
              {attachmentPayload.length !== 0 ? (
                <div
                  style={{
                    height: attachmentPayload.length === 1 ? "48px" : "87px",
                    borderBottom: "1px solid #E6E6E6",
                  }}
                >
                  {attachmentPayload.length !== 0 ? (
                    <div
                      style={{
                        overflowY: "auto",
                      }}
                    >
                      {attachmentPayload.map((e, key) => (
                        <div
                          style={{
                            width: attachmentPayload.length !== 0 ? "98%" : "",
                            display: "flex",
                            marginBottom:
                              attachmentPayload.length === 1 ? "0px" : "4px",
                            height:
                              attachmentPayload.length !== 0 ? "44px" : "40px",
                            border: "1px solid #E6E6E6",
                            borderRadius: "4px",
                          }}
                        >
                          <div
                            style={{
                              width: "35px",
                              paddingTop: "3px",
                            }}
                          >
                            <div
                              style={{
                                height: "30px",
                                width: "30px",
                                marginLeft: "5px",
                                borderRadius: "100%",
                              }}
                            >
                              <Avatar
                                homedata={
                                  homeSNData && homeSNData["userDetails"]
                                }
                                FontSize="9.54545px"
                                MarginLeft="5px"
                                MarginTop="8px"
                                inboxNameImage={false}
                                Color={
                                  displaySize === "isMobile"
                                    ? "#4A2878"
                                    : "#FEEDF3"
                                }
                                fontColor={
                                  displaySize === "isMobile"
                                    ? "white"
                                    : "#DD296E"
                                }
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              width: "calc(100% - 60px)",
                              height: "40px",
                              display: "flex",
                              alignItems: "center",
                              padding: "5px",
                            }}
                          >
                            <div
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {e["file_name"]}
                            </div>
                          </div>
                          <div
                            className="back-button-hover"
                            style={{
                              width: "25px",
                              height:
                                e["content_type"] === "image/png" ? "35px" : "",
                              paddingLeft: "2px",
                            }}
                            onClick={() => removeAttachment(key)}
                          >
                            <Delete scale={0.6} altText="Delete" />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div
                style={{
                  height: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  marginTop: attachmentPayload.length !== 0 ? "0px" : "",
                }}
              >
                <div
                  style={{
                    height: "fit-content",
                    maxHeight:
                      attachmentPayload.length !== 0 ? "200px" : "250px",
                    width: "93%",
                    marginTop: attachmentPayload.length !== 0 ? "5px" : "",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "fit-content",
                      cursor: "text",
                      maxHeight:
                        attachmentPayload.length !== 0 ? "200px" : "250px",
                      overflowY: "auto",
                      outline: "none",
                    }}
                    contentEditable
                    ref={inputRef}
                    onPaste={handlePaste}
                    onDrop={handleDropValue}
                  />
                </div>
                <div
                  style={{
                    width: displaySize !== "isMobile" ? "7%" : "10%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: "auto",
                  }}
                >
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "4px",
                    }}
                  >
                    <input
                      onChange={(event) =>
                        handleChange(
                          event && event.target && event.target.files
                        )
                      }
                      ref={fileInputRef}
                      type="file"
                      hidden
                      multiple
                      accept={file_type}
                    />
                    <div
                      className="back-button-hover"
                      title={
                        languageSNData
                          ? languageSNData["inbox_attachmentbuttontitle_text"]
                          : ""
                      }
                      style={{
                        padding: "2px",
                        borderRadius: "4px",
                        width: "fit-content",
                        height: "fit-content",
                        cursor: "pointer",
                      }}
                      onClick={() => fileInputRef.current.click()}
                    >
                      <Attachment scale={0.8} altText={"Attachment"} />
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "20px",
              fontSize: "14px",
              color: blank && field3 === "" ? "#DD296E" : "#4A4A49",
              marginTop: "16px",
              display: "flex",
            }}
          >
            {displaySize !== "isMobile" ? (
              <div style={{ width: "19px", height: "100%" }}>
                <Star
                  scale={1.4}
                  altText="Minimize"
                  color={blank && field3 === "" ? "pink" : ""}
                />{" "}
              </div>
            ) : null}
            {languageSNData
              ? languageSNData["createticket_checkunchecktitleone_text"]
              : ""}
          </div>
          <div
            style={{
              width: "100%",
              height: displaySize !== "isMobile" ? "34px" : "40px",
              marginTop: displaySize !== "isMobile" ? "8px" : "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <YesButton
              style={{ background: field3 === "Yes" ? "#4A2878" : "" }}
              onClick={() => setField3("Yes")}
            >
              {languageSNData ? languageSNData["createticket_yes_text"] : ""}
            </YesButton>
            <NoButton
              style={{ background: field3 === "No" ? "#4A2878" : "" }}
              onClick={() => setField3("No")}
            >
              {languageSNData ? languageSNData["createticket_no_text"] : ""}
            </NoButton>
          </div>
          <div
            style={{
              width: "100%",
              height: "fit-content",
              fontSize: "14px",
              color: "#4A4A49",
              marginTop: "16px",
            }}
          >
            {languageSNData
              ? languageSNData["createticket_optionalcheckunchecktitle_text"]
              : ""}{" "}
            <span style={{ color: "#bbb" }}>
              {" "}
              (
              {languageSNData
                ? languageSNData["createticket_optional_text"]
                : ""}
              )
            </span>
          </div>
          <div
            style={{
              width: "100%",
              height: displaySize !== "isMobile" ? "34px" : "40px",
              marginTop: "8px",
              display: "flex",
            }}
          >
            <YesButton
              style={{ background: field4 === "Yes" ? "#4A2878" : "" }}
              onClick={() => handleYesCheckUncheckinOptional("Yes")}
            >
              {languageSNData ? languageSNData["createticket_yes_text"] : ""}
            </YesButton>
            <NoButton
              style={{ background: field4 === "No" ? "#4A2878" : "" }}
              onClick={() => handleNoCheckUncheckinOptional("No")}
            >
              {languageSNData ? languageSNData["createticket_no_text"] : ""}
            </NoButton>
          </div>
          <div
            style={{
              width: "100%",
              height: "42px",
              marginTop: "18px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SubmitButton
              style={{ background: submit ? "#4A2878" : "" }}
              onClick={CreateTicketIncident}
            >
              {languageSNData
                ? languageSNData["createticket_submitticket_text"]
                : ""}
            </SubmitButton>
          </div>
        </Form>
      </Container>
    </Menucontainer>
  );
};

export default TicketForm;
