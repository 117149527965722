//Library
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactTextareaAutosize from "react-textarea-autosize";

//Files & Functions
import {
  Menucontainer,
  Container,
  CallButton,
  EditNumberContainer,
} from "./StyledComponent";
import {
  usehomeSNData,
  uselanguageSNData,
} from "../../hooks/UseApiContext/UseApiContext";
import { getSNJSON } from "../../api/index";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import { usedisplaySize } from "../../hooks/useMenuContext/useMenuContext";

//Components

//Icons
import SetCallingIcon from "../../Icons/SetCallingIcon";
import Back from "../../Icons/Back_icon/Back";
import { useInputText } from "../../hooks/useMenuContext/useMenuContext";
import PopUpWindow from "../PopUpWindowComps";
import Edit from "../../Icons/Edit_icon";

type CallFormProps = {};

const CallForm: React.FC<CallFormProps> = ({}) => {
  const homeSNData = usehomeSNData();
  const navigate = useNavigate();
  const inputText = useInputText();
  const [fetchCallBackJSON, performCallBackJSON] = useApiAction(getSNJSON);
  const [fetchGetQueueJSON, performGetQueueJSON] = useApiAction(getSNJSON);

  const displaySize = usedisplaySize();
  const [callDescription, setCallDescription] = useState(
    inputText === "" ? "" : inputText
  );
  const [visible, setVisible] = useState(false);
  const [editNumber, setEditNumber] = useState(
    localStorage.getItem("newNumber") ? localStorage.getItem("newNumber") : "0"
  );
  const [callResponse, setCallResponse] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [isActive, setIsActive] = useState(true);
  const languageSNData = uselanguageSNData();

  const inputTextHandler = (e) => {
    // setCallDescription(e.target.value);
    let content = e.target.value;
    // const htmlContent = `<div>${content.replace(/\n/g, "<br />")}</div>`;
    setCallDescription(content.trim());
  };

  useEffect(() => {
    if (callResponse !== "") {
      navigate("/callstatus?Queue=" + callResponse["contactsInQueue"]);
    } else if (contactNumber && contactNumber["isInQueue"] === "true") {
      navigate("/callstatus?Queue=" + contactNumber["contactsInQueue"]);
    }
  }, [callResponse, contactNumber]);

  useEffect(() => {
    let userPhoneNumber =
      editNumber === "0"
        ? homeSNData["callMeNowDetails"]["userPhoneNumber"]
          ? homeSNData["callMeNowDetails"]["userPhoneNumber"]
          : ""
        : editNumber.toString();
    let userSysID = homeSNData["callMeNowDetails"]["userSysID"]
      ? homeSNData["callMeNowDetails"]["userSysID"]
      : "";
    let description = callDescription;
    let AmazonQueueID = homeSNData["callMeNowDetails"]["AmazonQueueID"]
      ? homeSNData["callMeNowDetails"]["AmazonQueueID"]
      : "";
    if (fetchGetQueueJSON.data) {
      setContactNumber(fetchGetQueueJSON.data.result);
    } else {
      var Feild = [
        "Post",
        "urlGetQueue",
        "",
        {
          userPhoneNumber: userPhoneNumber,
          userSysID: userSysID,
          description: description,
          AmazonQueueID: AmazonQueueID,
        },
      ];
      performGetQueueJSON(Feild);
    }
  }, [fetchGetQueueJSON.data, contactNumber]);

  useEffect(() => {
    if (fetchCallBackJSON.data) {
      setCallResponse(fetchCallBackJSON.data.result);
    }
  }, [fetchCallBackJSON.data, callResponse]);
  function sendCallRequest() {
    let userPhoneNumber =
      editNumber === "0"
        ? homeSNData["callMeNowDetails"]["userPhoneNumber"]
          ? homeSNData["callMeNowDetails"]["userPhoneNumber"]
          : ""
        : editNumber.toString();
    let userSysID = homeSNData["callMeNowDetails"]["userSysID"]
      ? homeSNData["callMeNowDetails"]["userSysID"]
      : "";
    let htmlcontent = `<div>${callDescription.replace(/\n/g, "<br />")}</div>`;
    let description = htmlcontent;
    let AmazonQueueID = homeSNData["callMeNowDetails"]["AmazonQueueID"]
      ? homeSNData["callMeNowDetails"]["AmazonQueueID"]
      : "";
    var Feild = [
      "Post",
      "urlCallBack",
      "",
      {
        userPhoneNumber: userPhoneNumber,
        userSysID: userSysID,
        description: description,
        AmazonQueueID: AmazonQueueID,
      },
    ];
    performCallBackJSON(Feild);
    setIsActive(false);
  }

  return (
    <Menucontainer
      style={{ background: displaySize === "isMobile" ? "#EBEAF4" : "white" }}
    >
      {visible && (
        <div
          onClick={() => setVisible(false)}
          style={{
            position: "absolute",
            top: "0px",
            background: "rgba(0, 0, 0, 0.5)",
            display: visible ? "flex" : "none",
            justifyContent: "center",
            zIndex: "3",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        ></div>
      )}
      {visible && (
        <PopUpWindow
          visible={visible}
          setvisible={setVisible}
          from={"editNumber"}
          editNumber={editNumber}
          setEditNumber={setEditNumber}
        />
      )}

      {contactNumber ? (
        <></>
      ) : (
        displaySize !== "isMobile" && (
          <div
            style={{
              height: "80px",
              width: "calc(100% - 16px)",
              marginLeft: "8px",
              position: "absolute",
              bottom: "8px",
              right: "8px",
              left: "0px",
              zIndex: "9",
              display: "flex",
              justifyContent: "space-between",
              fontSize: "20px",
              background: "white",
            }}
          >
            <div
              style={{
                width: "20%",
                height: "70%",
                marginTop: "15px",
                marginLeft: "10px",
                background: "#EBEAF4",
              }}
            ></div>
            <div
              style={{
                width: "60px",
                borderRadius: "100%",
                height: "60px",
                marginTop: "10px",
                marginRight: "10px",
                paddingRight: "5px",
                zIndex: "1",
                background: "#EBEAF4",
              }}
            >
              <div></div>
            </div>
          </div>
        )
      )}
      {displaySize !== "isMobile" ? (
        <>
          <div
            style={{
              height: "20%",
              width: "30%",
              position: "absolute",
              top: "17%",
              right: "17%",
              fontSize: "20px",
            }}
          >
            {contactNumber ? (
              <SetCallingIcon />
            ) : (
              <div
                style={{
                  background: "#EBEAF4",
                  width: "70%",
                  marginLeft: "30%",
                }}
              ></div>
            )}
          </div>
        </>
      ) : null}
      <Container
        style={{
          height: displaySize !== "isMobile" ? "auto" : "100%",
          width: displaySize !== "isMobile" ? "fit-content" : "90%",
          paddingLeft: displaySize !== "isMobile" ? "" : "30px",
          marginTop: "10px",
        }}
      >
        {displaySize !== "isMobile" &&
        <button
          className="back-button-hover"
          onClick={() => {
            navigate(-1);
          }}
          style={{
            border: "none",
            width: "fit-content",
            height: "30px",
            position: displaySize !== "isMobile" ? "absolute" : "relative",
            top: displaySize !== "isMobile" ? "70px" : "4%",
            left: displaySize !== "isMobile" ? "40px" : "0px",
            borderRadius: "3px",
            background: "none",
            cursor: "pointer",
          }}
        >
          {contactNumber ? (
            <div
              style={{
                display: "flex",
                height: "fit-content",
                marginTop: "-3px",
              }}
            >
              <Back scale={0.7} altText="Back" type="mobile" />
              {displaySize !== "isMobile" && (
                <div style={{ marginTop: "5px" }}>
                  {languageSNData ? languageSNData["global_back_gotoback"] : ""}
                </div>
              )}
            </div>
          ) : (
            displaySize !== "isMobile" && (
              <div style={{ background: "#EBEAF4", width: "60px" }}></div>
            )
          )}
        </button>
}
        <div
          style={{
            height: "80px",
          }}
        >
          {contactNumber ? (
            <div
              className="FontRoboto"
              style={{
                height: "52px",
                color: "#0E090C",
                fontSize: "36px",
                textAlign: "left",
              }}
            >
              {languageSNData ? languageSNData["setupcall_callme_text"] : ""}
            </div>
          ) : (
            <div
              style={{ background: "#EBEAF4", width: "30%", height: "42px" }}
            ></div>
          )}
          {contactNumber ? (
            <div
              className="FontInter"
              style={{
                height: "28px",
                color: "#4A2878",
                fontSize: "20px",
                textAlign: "left",
              }}
            >
              {languageSNData
                ? languageSNData["callinformation_peopleinline_text"].replace(
                    "{&$&}",
                    contactNumber ? contactNumber["contactsInQueue"] : " 0 "
                  )
                : ""}
            </div>
          ) : (
            <div
              style={{
                background: "#EBEAF4",
                height: "28px",
                marginTop: "10px",
                width: "70%",
              }}
            ></div>
          )}
        </div>
        <div
          style={{
            height: displaySize !== "isMobile" ? "288px" : "388%",
            marginTop: "32px",
          }}
        >
          <div style={{ height: "auto" }}>
            <div
              style={{ height: displaySize !== "isMobile" ? "128px" : "200px" }}
            >
              {contactNumber ? (
                <div
                  style={{
                    height: displaySize !== "isMobile" ? "24px" : "50px",
                    color: "#4A4A49",
                    fontSize: "16px",
                    textAlign: "left",
                    width: displaySize !== "isMobile" ? "100%" : "100%",
                  }}
                >
                  {languageSNData
                    ? languageSNData["setupcall_fillissuetitle_text"]
                    : ""}
                </div>
              ) : (
                <div
                  style={{
                    background: "#EBEAF4",
                    height: "28px",
                    marginTop: "10px",
                    width: "80%",
                  }}
                ></div>
              )}
              {contactNumber ? (
                <div
                  style={{
                    height: displaySize !== "isMobile" ? "96px" : "135px",
                    marginTop: "8px",
                    borderRadius: displaySize !== "isMobile" ? "4px" : "30px",
                    textAlign: "left",
                  }}
                >
                  {/* Todo: Move this button to button Comp  */}
                  <ReactTextareaAutosize
                    minRows={displaySize !== "isMobile" ? 3 : 5}
                    maxRows={displaySize !== "isMobile" ? 3 : 5}
                    style={{
                      overflowY:
                        callDescription.length > 80 ? "scroll" : "hidden",
                      outline: "none",
                      paddingLeft: 10,
                      display: "block",
                      border:
                        callDescription === ""
                          ? "1.5px solid #959199"
                          : "2px solid #959199",
                      resize: "none",
                      borderRadius: displaySize !== "isMobile" ? "4px" : "30px",
                      color: "#49484D",
                      fontSize: "16px",
                    }}
                    placeholder={
                      displaySize !== "isMobile"
                        ? languageSNData
                          ? languageSNData["callinformation_placeholder_text"]
                          : ""
                        : ""
                    }
                    onChange={inputTextHandler}
                  />
                </div>
              ) : (
                <div
                  style={{
                    background: "#EBEAF4",
                    height: "96px",
                    marginTop: "8px",
                  }}
                ></div>
              )}
            </div>
            {contactNumber ? (
              <div
                style={{
                  display: displaySize !== "isMobile" && "flex",
                  width: displaySize !== "isMobile" ? "fit-content" : "100%",
                  height: "fit-content",
                  marginTop: "8px",
                  color: "#4A4A49",
                  fontSize: "16px",
                }}
              >
                <div style={{ width: "fit-content" }}>
                  {languageSNData
                    ? languageSNData["callinformation_shownumbertitle_text"]
                    : ""}
                </div>
                <div style={{ display: "flex", width: "fit-content" }}>
                  {displaySize !== "isMobile" ? (
                    <div style={{ width: "fit-content", marginLeft: "5px" }}>
                      {editNumber === "0"
                        ? homeSNData &&
                          homeSNData["callMeNowDetails"] &&
                          homeSNData["callMeNowDetails"]["userPhoneNumber"] + ""
                        : editNumber}
                    </div>
                  ) : (
                    <div>
                      {editNumber === "0"
                        ? homeSNData &&
                          homeSNData["callMeNowDetails"] &&
                          homeSNData["callMeNowDetails"]["userPhoneNumber"] + ""
                        : editNumber}
                    </div>
                  )}
                  <EditNumberContainer onClick={() => setVisible(true)}>
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                    >
                      <Edit scale={0.4} altText="Minimize" />
                    </div>
                  </EditNumberContainer>
                </div>
              </div>
            ) : (
              <div
                style={{
                  background: "#EBEAF4",
                  height: "24px",
                  marginTop: "8px",
                }}
              ></div>
            )}
          </div>
          {contactNumber ? (
            <div
              style={{
                height: "fit-content",
                fontSize: "16px",
                color: "#959199",
                textAlign: "center",
                marginTop: "32px",
              }}
            >
               {homeSNData["HomePageDetails"]["CallBackInformation"]
                ? homeSNData["HomePageDetails"]["CallBackInformation"]
                : ""} 
            </div>
          ) : (
            <div
              style={{
                background: "#EBEAF4",
                height: "24px",
                marginTop: "32px",
              }}
            ></div>
          )}
          <div
            style={{
              height: "38px",
              display: "flex",
              justifyContent: "center",
              marginTop: "8px",
            }}
          >
            {contactNumber ? (
              <CallButton
                onClick={() => sendCallRequest()}
                style={{
                  background: isActive ? "" : "#4A4A49",
                  borderRadius: displaySize !== "isMobile" ? "3px" : "30px",
                }}
              >
                {languageSNData
                  ? languageSNData["setupcall_getinline_text"]
                  : ""}
              </CallButton>
            ) : (
              <div
                style={{
                  width: "109px",
                  background: "#EBEAF4",
                }}
              ></div>
            )}
          </div>
        </div>
      </Container>
    </Menucontainer>
  );
};

export default CallForm;
