//Library
import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

//Files & Functions
import { TicketsContainer, Button } from "./StyledComponent";
import {
  useinboxSNData,
  uselanguageSNData,
} from "../../hooks/UseApiContext/UseApiContext";

//Components

//Icons
import Search from "../../Icons/Search-icon";
import ExclamationMark from "../../Icons/Exclamation_mark";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import { getSNJSON } from "../../api";
import {
  useInboxLoading,
  useInboxRecall,
  useSetInboxRecall,
  useTicketData,
  usedisplaySize,
} from "../../hooks/useMenuContext/useMenuContext";
import Awaiting from "../../Icons/Awaiting_icon";

type InboxSidebarProps = {};

const InboxSidebar: React.FC<InboxSidebarProps> = ({}) => {
  const location = useLocation();
  const inboxSNData = useinboxSNData();
  const displaySize = usedisplaySize();
  const inboxRecall = useInboxRecall();
  const ticketData = useTicketData();
  const setInboxRecall = useSetInboxRecall();
  const [searchKey, setSearchKey] = useState("");
  const [filter, setFilter] = useState("All");
  const [activeTicket, setActiveTicket] = useState("");
  const inboxLoading = useInboxLoading();
  const [fetchUpdateVeiwEndUserJSON, performUpdateVeiwEndUserJSON] =
    useApiAction(getSNJSON);
  const languageSNData = uselanguageSNData();

  useEffect(() => {
    setActiveTicket(
      location.search.includes("?Number=")
        ? location.search.split("?Number=")[1]
        : ""
    );
  }, [location]);

  function filterSearch(val) {
    if (searchKey == "") {
      if (filter === val["active"].toString()) {
        return val;
      }
      if (filter === "All") {
        return val;
      }
      if (
        filter === "Unread" &&
        val["additionalTicketDetails"]["isViewedByEndUser"] === false
      ) {
        return val;
      }
      return;
    }
    if (!val["Short description"] && !val["Description"] && !val["Number"]) {
      return "";
    }
    if (
      val["Short description"]
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase()) ||
      val["Description"]
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase()) ||
      val["Number"].toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
    ) {
      if (filter === val["active"].toString()) {
        return val;
      }
      if (filter === "All") {
        return val;
      }
      if (
        filter === "Unread" &&
        val["additionalTicketDetails"]["isViewedByEndUser"] === false
      ) {
        return val;
      }
    }
  }

  return (
    <div>
      <div
        style={{
          height: "118px",
          padding: "2% 5%",
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <div style={{}}>
          <div
            style={{
              height: "40px",
              border: "1.5px solid #767675",
              borderRadius: displaySize === "isMobile" ? "40px" : "4px",
              isolation: "isolate",
              marginTop: "8px",
              display: "flex",
              alignSelf: "stretch",
            }}
          >
            <div
              style={{
                width: "35px",
                padding: "4px 6px",
                opacity: "60%",
              }}
            >
              <Search scale={0.7} altText="Search" />
            </div>
            <div style={{ width: "87%" }}>
              <input
                onChange={(event) => {
                  setSearchKey(event.target.value);
                }}
                placeholder={
                  languageSNData
                    ? languageSNData["inbox_searchinput_inputtest"]
                    : ""
                }
                style={{
                  outline: "none",
                  fontWeight: "400",
                  fontStyle: "normal",
                  fontSize: "14px",
                  color: "black",
                  border: "none",
                  letterSpacing: "-0.01em",
                }}
              />
            </div>
          </div>
          <div
            style={{
              height: "30px",
              marginTop: "18px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => setFilter("All")}
              style={{
                borderRadius: displaySize === "isMobile" ? "40px" : "",
                background: filter === "All" ? "#EBEAF4" : "",
                color: filter === "All" ? "#0E090C" : "",
                fontWeight: filter === "All" ? "600" : "",
              }}
            >
              {languageSNData
                ? languageSNData["inbox_fearchfilter_showalltickets"]
                : ""}
            </Button>
            <Button
              onClick={() => setFilter("Unread")}
              style={{
                borderRadius: displaySize === "isMobile" ? "40px" : "",
                background: filter === "Unread" ? "#EBEAF4" : "",
                color: filter === "Unread" ? "#0E090C" : "",
                fontWeight: filter === "Unread" ? "600" : "",
              }}
            >
              {languageSNData && languageSNData["inbox_searchfilter_showUnread"]
                ? languageSNData["inbox_searchfilter_showUnread"]
                : "Unread"}
            </Button>
            <Button
              onClick={() => setFilter("true")}
              style={{
                borderRadius: displaySize === "isMobile" ? "40px" : "",
                background: filter === "true" ? "#EBEAF4" : "",
                color: filter === "true" ? "#0E090C" : "",
                fontWeight: filter === "true" ? "600" : "",
              }}
            >
              {languageSNData
                ? languageSNData["inbox_searchfilter_showopentickets"]
                : ""}
            </Button>
            <Button
              onClick={() => setFilter("false")}
              style={{
                borderRadius: displaySize === "isMobile" ? "40px" : "",
                background: filter === "false" ? "#EBEAF4" : "",
                color: filter === "false" ? "#0E090C" : "",
                fontWeight: filter === "false" ? "600" : "",
              }}
            >
              {languageSNData
                ? languageSNData["inbox_searchfilter_showclosetickets"]
                : ""}
            </Button>
          </div>
        </div>
      </div>
      <TicketsContainer>
        <div style={{ overflowY: "auto" }}>
          {inboxSNData && inboxSNData["Tickets"]
            ? inboxSNData["Tickets"]
                .filter(filterSearch)
                .map((e: any, index) => (
                  <Link
                    to={"/inbox?Number=" + e["Number"]}
                    key={index}
                    style={{
                      color: "#0E090C",

                      textDecoration: "none",
                    }}
                  >
                    <div
                      className="back-button-hover"
                      // onClick={() => UpdateVeiwEndUser(e)}
                      style={{
                        height: "132px",
                        borderBottom: "1px solid #D9D9D9",
                        padding: "19px, 24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderLeft:
                          activeTicket === e["Number"]
                            ? "3px solid #DD296E"
                            : "",
                        background:
                          activeTicket === e["Number"]
                            ? "var(--brand-pink-100, #FEEDF3)"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          height: "calc(100% - 38px)",
                          width: "calc(100% - 48px)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{}}>
                          <div
                            style={{
                              height: "15px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                width: "fit-content",
                                color: e["additionalTicketDetails"][
                                  "isViewedByEndUser"
                                ]
                                  ? e["additionalTicketDetails"][
                                      "ProgressBarStatus"
                                    ] === "ticket_closed"
                                    ? "#D9D9D9"
                                    : "var(--grey-dark, #49484D)"
                                  : "var(--black, #0E090C)",

                                fontWeight: e["additionalTicketDetails"][
                                  "isViewedByEndUser"
                                ]
                                  ? "400"
                                  : "700",
                                fontSize: "12px",
                              }}
                            >
                              {" "}
                              {e["HideNumber"] ? "" : e["Number"]}
                            </div>
                            <div
                              style={{
                                color: e["additionalTicketDetails"][
                                  "isViewedByEndUser"
                                ]
                                  ? e["additionalTicketDetails"][
                                      "ProgressBarStatus"
                                    ] === "ticket_closed"
                                    ? "#D9D9D9"
                                    : "var(--grey-dark, #49484D)"
                                  : "var(--black, #0E090C)",
                                fontWeight: e["additionalTicketDetails"][
                                  "isViewedByEndUser"
                                ]
                                  ? "400"
                                  : "700",
                                fontSize: "12px",
                                textAlign: "end",
                                width: "fit-content",
                              }}
                            >
                              {e["Updated"] && e["Updated"].split(" ")[0]}
                            </div>
                          </div>
                          <div
                            style={{
                              height: "19px",
                              lineHeight: "19px",
                              marginTop: "8px",
                              color: e["additionalTicketDetails"][
                                "isViewedByEndUser"
                              ]
                                ? e["additionalTicketDetails"] &&
                                  e["additionalTicketDetails"][
                                    "ProgressBarStatus"
                                  ] === "ticket_closed"
                                  ? "#D9D9D9"
                                  : "var(--grey-dark, #49484D)"
                                : "var(--brand-brand-pink, #DD296E)",
                              fontSize: "16px",
                              fontWeight: e["additionalTicketDetails"][
                                "isViewedByEndUser"
                              ]
                                ? "500"
                                : "600",
                            }}
                          >
                            {e["Short description"]}
                          </div>
                          <div
                            style={{
                              height: "15px",
                              lineHeight: "16px",
                              marginTop: "8px",
                              color: "var(--grey-medium, #959199)",
                              fontSize: "12px",
                            }}
                          >
                            {e["Description"]}{" "}
                          </div>
                          <div
                            style={{
                              height: "19px",
                              lineHeight: "19px",
                              width: "fit-content",
                              background:
                                e["stateSymbol"] === 2
                                  ? "var(--brand-brand-pink, #DD296E)"
                                  : "",
                              marginTop: "8px",
                              border:
                                e["stateSymbol"] === 4
                                  ? " 0.559px solid var(--secondary-green-main, #00A188)"
                                  : e["stateSymbol"] === 1
                                  ? "0.56px solid var(--grey-medium, #959199)"
                                  : "",

                              color:
                                e["stateSymbol"] === 2
                                  ? "var(--White, #FFF)"
                                  : e["stateSymbol"] === 4
                                  ? "var(--secondary-green-main, #00A188)"
                                  : e["stateSymbol"] === 1
                                  ? "var(--grey-medium, #959199)"
                                  : "var(--grey-dark, #49484D)",
                              fontSize: "7.825px",
                              padding: "0px 5.589px",
                              gap: "4.471px",
                              borderRadius: "1.68px",
                              display: "flex",
                            }}
                            className="FontInter"
                          >
                            {e["stateSymbol"] === 2 && (
                              <Awaiting
                                scale={1.3}
                                altText={"Awaiting"}
                                color=""
                              />
                            )}
                            {e["stateSymbol"] === 3 && (
                              <Awaiting
                                scale={1.3}
                                altText={"AwaitingChange"}
                                color=""
                              />
                            )}
                            {e["State"]}{" "}
                          </div>
                          {/* <div style={{ width: "8%", height: "74px" }}>
                          <div
                            style={{
                              height: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {e["additionalTicketDetails"][
                              "isViewedByEndUser"
                            ] ||
                            (ticketData &&
                              ticketData["Tickets"] &&
                              ticketData["Tickets"][0] &&
                              ticketData["Tickets"][0]["Number"] ===
                                e["Number"]) ? (
                              ""
                            ) : (
                              <div
                                style={{
                                  borderRadius: "10px",
                                  width: "8px",
                                  height: "8px",
                                  backgroundColor: "#DD296E",
                                }}
                              ></div>
                            )}
                          </div>
                        </div> */}
                          {/* <div
                            style={{
                              border: "1px solid red",
                              height: "44px",
                              display: "flex",
                              marginTop: "8px",
                            }}
                          >
                            <div style={{ width: "90%" }}>
                              <div
                                style={{
                                  height: "19px",
                                  lineHeight: "19px",
                                  marginTop: "2px",
                                  color:
                                    e["additionalTicketDetails"][
                                      "isViewedByEndUser"
                                    ] ||
                                    (ticketData &&
                                      ticketData["Tickets"] &&
                                      ticketData["Tickets"][0] &&
                                      ticketData["Tickets"][0]["Number"] ===
                                        e["Number"])
                                      ? "#49484D"
                                      : "#DD296E",
                                  fontSize: "16px",
                                  fontWeight:
                                    e["additionalTicketDetails"][
                                      "isViewedByEndUser"
                                    ] ||
                                    (ticketData &&
                                      ticketData["Tickets"] &&
                                      ticketData["Tickets"][0] &&
                                      ticketData["Tickets"][0]["Number"] ===
                                        e["Number"])
                                      ? "400"
                                      : "600",
                                }}
                              >
                                {e["Short description"]}
                              </div>
                              <div
                                style={{
                                  height: "18px",
                                  lineHeight: "16px",
                                  marginTop: "7px",
                                  color: "#959199",
                                  fontSize: "14px",
                                }}
                              >
                                {e["Description"]}{" "}
                              </div>
                            </div>
                            <div
                              // data-tooltip-id="my-tooltip"
                              // data-tooltip-content={languageSNData && languageSNData["inboxsidebar_awatingfeedbackicontooltip_text"]? languageSNData["inboxsidebar_awatingfeedbackicontooltip_text"] : "Awaiting your feedback"}
                              // data-tooltip-offset={4}
                              style={{
                                width: "10%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {e["additionalTicketDetails"][
                                "ExclamationMark"
                              ] && (
                                <div
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={
                                    languageSNData &&
                                    languageSNData[
                                      "inboxsidebar_awatingfeedbackicontooltip_text"
                                    ]
                                      ? languageSNData[
                                          "inboxsidebar_awatingfeedbackicontooltip_text"
                                        ]
                                      : "Awaiting your feedback"
                                  }
                                  data-tooltip-offset={4}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <ExclamationMark
                                    scale={1.3}
                                    altText={"ExclamationMark"}
                                    color=""
                                  />
                                </div>
                              )}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
            : ""}
          {inboxLoading && (
            <div style={{ height: "fit-content" }}>
              {/* {["1", "1", "1"].map(() => ( */}
              <div
                style={{
                  height: "109px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "258.369px",
                    borderRadius: "3px",
                    height: "75px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      height: "24px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "97px", background: "#EBEAF4" }}>
                      <div className="Skeleton"></div>
                    </div>
                    <div style={{ width: "97px", background: "#EBEAF4" }}>
                      <div className="Skeleton"></div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "1px",
                      height: "49px",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        width: "212px",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          height: "24px",
                          background: "#EBEAF4",
                        }}
                      >
                        <div className="Skeleton"></div>
                      </div>
                      <div
                        style={{
                          height: "24px",
                          background: "#EBEAF4",
                        }}
                      >
                        <div className="Skeleton"></div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "calc(100% - 212px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "24px",
                          height: "24px",
                          background: "#EBEAF4",
                        }}
                      >
                        <div className="Skeleton"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </TicketsContainer>
    </div>
  );
};

export default InboxSidebar;
