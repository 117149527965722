import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as close } from './dismiss.svg';
import styled from "styled-components";
const CloseIcon = styled(close)`
  
`

type CloseLogoProps = {
  scale?: number;
  altText: string;
};

const Close: React.FC<CloseLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{"marginTop": "-10px", "width": "1rem", marginLeft: "1px"}}>
      <CloseIcon/>
    </IconWrapper>
  );
};

export default Close;