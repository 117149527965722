import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as search } from './Search.svg';
import styled from "styled-components";
const SearchIcon = styled(search)`
  
`
type SearchLogoProps = {
  scale?: number;
  altText: string;
};

const SearchRed: React.FC<SearchLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{"marginTop": "2px", "width": "1rem", marginLeft: "1px"}}>
      <SearchIcon/>
    </IconWrapper>
  );
};

export default SearchRed;