//Library
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import {} from "react-router-dom";
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";
import DOCIcon from "../../Icons/Doc_extension_icon";
import Download from "../../Icons/Download_icon";
import Close from "../../Icons/Header_close_icon/Close";
import ImgIcon from "../../Icons/img_extension_icon";
import PDFIcon from "../../Icons/pdf_extension_icon";
import InboxChat from "../InboxChatComps";

//Files & Functions
import { DownloadFile } from "./StyledComponent";

//Components

//Icons

type InboxMenuProps = {
  ticket: any;
  setSidebar: any;
  sidebar: any;
};

const InboxMenu: React.FC<InboxMenuProps> = ({ ticket, setSidebar, sidebar }) => {
  return (
    <div style={{ width: "100%",}}>
      <InboxChat ticket={ticket} setSidebar={setSidebar} sidebar={sidebar} />
    </div>
  );
};

export default InboxMenu;
