import React from 'react';
import styled from "styled-components";

import { IconWrapper } from '../styled-components';
import { ReactComponent as home } from './home.svg';
import { ReactComponent as WhiteHome } from './WhiteHome.svg';


const HomeIcon = styled(home)`
  
`

const WhiteHomeIcon = styled(WhiteHome)`
  
`

type HomeLogoProps = {
  scale?: number;
  altText: string;
  color: string;
};

const Home: React.FC<HomeLogoProps> = ({ scale = 1, altText, color }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{"width": "1rem"}}>
      {color==="white"?
      <WhiteHome/>
      :
      <HomeIcon/>
}
    </IconWrapper>
  );
};

export default Home;