import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as star } from "./Star.svg";
import { ReactComponent as whitestar } from "./WhiteStar.svg";
import { ReactComponent as starpink } from "./StarPink.svg";
import styled from "styled-components";
const StarIcon = styled(star)``;
const StarWhiteIcon = styled(whitestar)``;
const StarPinkIcon = styled(starpink)``;

type StarLogoProps = {
  scale?: number;
  altText: string;
  color: string;
};

const Star: React.FC<StarLogoProps> = ({ scale = 1, altText, color }) => {
  return (
    <IconWrapper
      width={0.6 * scale}
      height={0.8 * scale}
      aria-label={altText}
      style={{ marginTop: color === "white" ? "" : "-2px" }}
    >
      {color === "white" ? (
        <StarWhiteIcon />
      ) : color === "pink" ? (
        <StarPinkIcon />
      ) : (
        <StarIcon />
      )}
    </IconWrapper>
  );
};

export default Star;
