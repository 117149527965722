//Library
import React, { useEffect, useState } from "react";
import Chat from "../../Icons/Footer-icons/chat-icon";

//Files & Functions
import {
  MassageContainer,
  MassageContainerText1,
  MassageContainerText2,
  MassageContainerButton,
  ArticleHeading,
  CloseButton,
} from "./StyledComponent";
import Close from "../../Icons/Header_close_icon/Close";
import ReactTextareaAutosize from "react-textarea-autosize";
import {
  usehomeSNData,
  uselanguageSNData,
} from "../../hooks/UseApiContext/UseApiContext";
import RequestForm from "../../Icons/Request_form_icon/Request_form";
import Document from "../../Icons/Document_icon/Document";
import { useNavigate } from "react-router-dom";

//Components

//Icons

type PopUpWindowProps = {
  visible?: any;
  setvisible?: any;
  editNumber?: any;
  setEditNumber?: any;
  from?: any;
  currentVersion?: any;
  articlesData?: any;
};

const PopUpWindow: React.FC<PopUpWindowProps> = ({
  visible,
  setvisible,
  editNumber,
  setEditNumber,
  from,
  currentVersion,
  articlesData,
}) => {
  const languageSNData = uselanguageSNData();
  const [newNumber, setNewNumber] = useState("");
  const navigate = useNavigate();
  const inputTextHandler = (e) => {
    if (from === "versionUpdate") {
    } else if (from === "reOpen") {
      setNewNumber(e.target.value);
    } else if (from === "editNumber") {
      setNewNumber(e.target.value.replace(/[^0-9\s+]/g, ""));
    }
  };
  const homeSNData = usehomeSNData();

  function get_icon(icon_name: string) {
    switch (icon_name) {
      case "editNumber":
        return (
          languageSNData &&
          languageSNData["callform_toastmessage_headingtitle"] &&
          languageSNData["callform_toastmessage_headingtitle"]
        );
      case "versionUpdate":
        return (
          languageSNData &&
          languageSNData["popupwindow_toastmessage_text"] &&
          languageSNData["popupwindow_toastmessage_text"].replace(
            "{$$}",
            currentVersion
          )
        );
      case "showArticles":
        return languageSNData &&
          languageSNData["showmatchingarticles_toastmessage_headingtitle"]
          ? languageSNData["showmatchingarticles_toastmessage_headingtitle"]
          : "Matching articles:";
      case "reOpen":
        return (
          languageSNData &&
          languageSNData["InboxReopen_toastmessage_headingtitle"] &&
          languageSNData["InboxReopen_toastmessage_headingtitle"]
        );
    }
  }

  function buttons(icon_name: string) {
    switch (icon_name) {
      case "editNumber":
      case "reOpen":
        return (
          <div style={{ width: "fit-content", display: "flex" }}>
            <MassageContainerButton
              color="var(--secondary-Purple-light, #675B98)"
              hoverColor="#EBEAF4"
              backgroundColor="white"
              style={{
                width: "fit-content",
              }}
              onClick={() => {
                setvisible(false);
              }}
            >
              {from === "reOpen"
                ? languageSNData &&
                  languageSNData["inboxreopen_toastmassage_cancel"] &&
                  languageSNData["inboxreopen_toastmassage_cancel"]
                : languageSNData &&
                  languageSNData["callform_toastcancelbutton_cancel"]
                ? languageSNData["callform_toastcancelbutton_cancel"]
                : "Cancel"}
            </MassageContainerButton>
            <MassageContainerButton
              color="#FFFFFF"
              hoverColor="linear-gradient(90deg, #4a2878 0%, #755b98 100%)"
              backgroundColor=" #755b98"
              onClick={EditNumberLogic}
              style={{
                width: "fit-content",
                height: "38px",
                color: "#FFFFFF",
                fontSize: "16px",
                borderRadius: "3px",
                padding: "7px 15px",
                marginLeft: from === "versionUpdate" ? "" : "16px",
                cursor: "pointer",
              }}
            >
              {from === "reOpen"
                ? languageSNData &&
                  languageSNData["inboxreopen_toastmessage_reopen"] &&
                  languageSNData["inboxreopen_toastmessage_reopen"]
                : languageSNData &&
                  languageSNData["callform_toasteditbutton_edit"]
                ? languageSNData["callform_toasteditbutton_edit"]
                : "Edit"}
            </MassageContainerButton>
          </div>
        );
      case "versionUpdate":
        return (
          <div style={{ width: "fit-content", display: "flex" }}>
            <MassageContainerButton
              color="#FFFFFF"
              hoverColor="linear-gradient(90deg, #4a2878 0%, #755b98 100%)"
              backgroundColor=" #755b98"
              onClick={EditNumberLogic}
              style={{
                width: "fit-content",
                height: "38px",
                fontSize: "16px",
                borderRadius: "3px",
                padding: "7px 15px",
                marginLeft: from === "versionUpdate" ? "" : "16px",
                cursor: "pointer",
              }}
            >
              OK
            </MassageContainerButton>
          </div>
        );
      case "showArticles":
        return (
          <div
            style={{
              width: "fit-content",
              height: "168px",
              overflowY: "auto",
            }}
          >
            {articlesData &&
              articlesData["data"] &&
              articlesData["data"]["search"] &&
              articlesData["data"]["search"]["searchResults"].map(
                (e: any, key) => (
                  <div
                    onClick={() =>
                      navigate(
                        e["table"] && e["table"] === "sc_cat_item"
                          ? "/form?form=" + e["link"]
                          : "/article?sys_id=" + e["sysId"]
                      )
                    }
                    className="article"
                    style={{
                      width: "fit-content",
                      height: "fit-content",
                      padding: "5px 8px 5px 8px",
                    }}
                  >
                    <div
                      style={{
                        width: "264px",
                        height: "fit-content",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "-3px",
                          width: "24px",
                          height: "29px",
                        }}
                      >
                        {" "}
                        {e["table"] && e["table"] === "sc_cat_item" ? (
                          <RequestForm
                            scale={0.7}
                            altText="RequestForm"
                            viewType=""
                          />
                        ) : (
                          <Document
                            scale={0.8}
                            altText="document"
                            viewType="row"
                          />
                        )}
                      </div>
                      <ArticleHeading
                        dangerouslySetInnerHTML={{
                          __html: e["title"],
                        }}
                      ></ArticleHeading>
                    </div>
                  </div>
                )
              )}
          </div>
        );
    }
  }

  function EditNumberLogic() {
    if (from === "versionUpdate") {
      setvisible(false);
    } else if (from === "reOpen" && newNumber !== "") {
      setEditNumber(newNumber);
      setvisible(false);
    } else if (from === "newNumber" && newNumber !== "") {
      setEditNumber(newNumber);
      setvisible(false);
      localStorage.setItem("newNumber", newNumber.toString());
    }
  }

  function closePopUp() {
    setvisible(false);
  }

  return (
    <MassageContainer
      style={{
        position: "absolute",
        zIndex: "99",
        justifyContent: from === "versionUpdate" ? "space-between" : "",
        minHeight: from === "versionUpdate" ? "200px" : "",
        width: "",
      }}
    >
      <CloseButton>
        <div
          className="back-button-hover"
          onClick={closePopUp}
          style={{ width: "25px", opacity: "0.50" }}
        >
          <Close scale={0.6} altText="Minimize" color="" />
        </div>
      </CloseButton>
      <MassageContainerText1
        className="FontRoboto"
        style={{
          fontSize: from === "versionUpdate" ? "22px" : "",
          padding: from === "versionUpdate" ? "0px 32px" : "",
          fontWeight: from === "versionUpdate" ? "400" : "",
          color: from === "showArticles" ? "#4A2878" : "",
        }}
      >
        {get_icon(from)}
      </MassageContainerText1>
      {(from === "editNumber" || from === "reOpen") && (
        <MassageContainerText2>
          {from === "reOpen"
            ? languageSNData &&
              languageSNData["inbox_toastmassage_inputheadingtext"]
              ? languageSNData["inbox_toastmassage_inputheadingtext"]
              : "Please add some comment"
            : languageSNData &&
              languageSNData["callform_toastmassage_inputheadingtext"]
            ? languageSNData["callform_toastmassage_inputheadingtext"]
            : "The number added is only for the specific call"}
        </MassageContainerText2>
      )}
      {(from === "reOpen" || from === "editNumber") && (
        <div
          style={{
            border: "1.5px solid var(--grey-medium, #959199)",
            borderRadius: "4px",
            width: "320px",
            height: "56px",
            padding: "15px 10px",
            marginTop: "32px",
          }}
        >
          <input
            type="text"
            min={10}
            minLength={10}
            // maxLength={10}
            style={{
              outline: "none",
              display: "block",
              border: "none",
              resize: "none",
              borderRadius: "4px",
              lineHeight: "20px",
              fontSize: "16px",
              color: "#0E090C",
            }}
            value={newNumber}
            onChange={inputTextHandler}
          />
        </div>
      )}
      <div
        style={{
          height: "fit-content",
          minHeight: "48px",
          marginTop: from === "showArticles" ? "10px" : "38px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {buttons(from)}
      </div>
    </MassageContainer>
  );
};

export default PopUpWindow;
