import React from 'react';

import { IconWrapper } from '../styled-components';
// import { ReactComponent as funImage } from '../Doc_extension_icon/docIcon.svg';
// import { ReactComponent as funImage } from './notDatapageImage.svg';
// import { ReactComponent as funImage } from './Asset6.svg';
import { ReactComponent as funImage } from './Inbox_A.svg';

import styled from "styled-components";
const FunImageIcon = styled(funImage)`
  
`

type FunImageLogoProps = {
  scale?: number;
  altText: string;
};

const FunImage: React.FC<FunImageLogoProps> = ({ scale = 1, altText }) => {
  // var noDataImage = require('./nodataicon.png');
  // var noDataImage = require('./noDataShow.png');
  return (
    <IconWrapper width={1.7* scale} height={1.5 * scale} aria-label={altText} style={{}}>
      {/* <img src={noDataImage}></img> */}
      <FunImageIcon />
    </IconWrapper>
  );
};

export default FunImage;