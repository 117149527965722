import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as delete_icon } from "./Delete.svg";
import styled from "styled-components";
const DeleteIcon = styled(delete_icon)``;
type DeleteLogoProps = {
  scale?: number;
  altText: string;
};

const Delete: React.FC<DeleteLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2 * scale} height={4 * scale} aria-label={altText}>
      <DeleteIcon />
    </IconWrapper>
  );
};

export default Delete;
