import React from 'react';

import { IconWrapper } from '../../styled-components';
import { ReactComponent as call } from './call.svg';
import { ReactComponent as CallBlue } from './callblue.svg';
import styled from "styled-components";
const CallIcon = styled(call)`
  
`

const CallBlueIcon = styled(CallBlue)`
  
`
type CallLogoProps = {
  scale?: number;
  altText: string;
};

const Call: React.FC<CallLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2 * scale} height={5 * scale} aria-label={altText} style={{marginTop:scale===0.9?"-12px": "-10px"}}>
      {altText==="callblue"?
      <CallBlueIcon/>:
      <CallIcon/>
      }
      
    </IconWrapper>
  );
};

export default Call;