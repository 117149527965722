import React from "react";

//Files & Functions
import {} from "./StyledComponent";

//Components

//Icons

type AvatarProps = {
  homedata: any;
  FontSize: any;
  MarginLeft: any;
  MarginTop: any;
  inboxNameImage: boolean;
  Color: any;
  fontColor: any;
};

const Avatar: React.FC<AvatarProps> = ({
  homedata,
  FontSize,
  MarginLeft,
  MarginTop,
  inboxNameImage,
  Color,
  fontColor,
}) => {
  return (
    // Todo imporve logic
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: Color
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            // height: "100%",
            width: "fit-content",
            margin: "auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <b
            style={{
              // marginTop: MarginTop,
              // marginLeft: MarginLeft,
              color: fontColor,
              fontSize: FontSize,
              fontWeight: "700",
              height: "fit-content",
              width: "fit-content",
              padding: "1px",
            }}
          >
            {inboxNameImage
              ? homedata &&
                homedata.split(" ") &&
                homedata.split(" ")[0].charAt(0)
              : homedata &&
                homedata["FirstName"] &&
                homedata["FirstName"].charAt(0)}
          </b>
          <b
            style={{
              // marginTop: MarginTop,
              color: fontColor,
              fontSize: FontSize,
              fontWeight: "700",
              height: "fit-content",
              width: "fit-content",
              padding: "1px",
            }}
          >
            {inboxNameImage
              ? homedata &&
                homedata.includes(" ") &&
                homedata.split(" ") &&
                homedata.split(" ")[1].charAt(0)
              : homedata &&
                homedata["LastName"] &&
                homedata["LastName"].charAt(0)}
          </b>
        </div>
      </div>
    </div>
  );
};

export default Avatar;
