import React from 'react';

import { IconWrapper } from '../styled-components';

import { ReactComponent as SopraSteriaBlack } from './sopra-steria-black-icon.svg';

type SopraSteriaIconProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
  scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
  altText: string;
  /**
   * If true the icon would have white text instead of black.
   */
};

const SopraSteriaIcon: React.FC<SopraSteriaIconProps> = ({ scale = 1, altText}) => {
  return (
    <IconWrapper width={8 * scale} height={2 * scale} aria-label={altText}>
      { <SopraSteriaBlack/>}
    </IconWrapper>
  );
};

export default SopraSteriaIcon;