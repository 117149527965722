import styled from "styled-components";

export const ProgressContainer = styled.div`
  @media (max-width: 1200px) {
    display: flex;
  }
`;

export const Container = styled.div`
  height: 20px;
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 1200px) {
    display: flex;
    width: 37px;
    margin-top: 0px;
    flex-direction: column;
    height: 100%;
  }
`;

export const StateBar = styled.div`
  width: 30px;
  @media (max-width: 1200px) {
    height: 20px;
  }
`;

type StateLineDivProps = {
  borderType: any;
};

export const StateLine = styled.div<StateLineDivProps>`
  width: 51px;
  height: 11px;
  border-bottom: ${(props: StateLineDivProps) => props.borderType};
  opacity: 60%;
  @media (max-width: 1200px) {
    width: 17px;
    height: 30px;
    border-bottom: none;
    border-right: ${(props: StateLineDivProps) => props.borderType};
  }
`;

export const ContainerStatus = styled.div`
  height: 32px;
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    display: flex;
    width: 110px;
    flex-direction: column;
    height: 100%;
    margin-top: 5px;
  }
`;

type StatusTextDivProps = {
  width: any;
};

export const StatusText = styled.div`
  width: ${(props: StatusTextDivProps) => props.width};
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  color: #49484d;
  @media (max-width: 1200px) {
    margin-bottom: 7px;
    height: 20px;
    width: 100%;
  }
`;
