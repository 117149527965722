import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as information } from './about.svg';
import styled from "styled-components";
const InformationIconBlack = styled(information)`
  
`

type InformationLogoProps = {
  scale?: number;
  altText: string;
};

const Information: React.FC<InformationLogoProps> = ({ scale = 1, altText }) => { 
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{ "width": "1rem", marginTop: '5px'}}>
      <InformationIconBlack/>
    </IconWrapper>
  );
};

export default Information;