// @ts-nocheck
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import reportWebVitals from "./reportWebVitals";
import config from "./constant/config";
import { MenuProvider } from "./hooks/useMenuContext/useMenuContext";
import { SNDataProvider } from "./hooks/UseApiContext/UseApiContext";
import { msalConfig } from "./authentication/authConfig";
import ApplicationRouter from "./authentication/applicationRouter";
import "bootstrap/dist/css/bootstrap.min.css";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { MatomoProvider } from "@datapunt/matomo-tracker-react";
import { instance } from "./matomoInstance";

export const msalInstance = new PublicClientApplication(msalConfig);

// Sentry.init({
//   dsn: "https://f3ba1acd1a2e43bf9ba621ca0ed63780@o4504716862554112.ingest.sentry.io/4504716934184960",
//   integrations: [new BrowserTracing()],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <SNDataProvider>
        <MenuProvider>
          <MatomoProvider value={instance}>
            <ApplicationRouter />
          </MatomoProvider>
        </MenuProvider>
      </SNDataProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
