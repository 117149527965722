import React, { ReactNode } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import Error501page from '../ApiErrorPage';

type ErrorBoundaryProps = {
  children: ReactNode;
  onReset: () => void;
  resetKeys: any;
};

function ErrorBoundary({ children, onReset, resetKeys }: ErrorBoundaryProps) {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onReset={onReset} resetKeys={[resetKeys]}>
      {children}
    </ReactErrorBoundary>
  );
}

function ErrorFallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
  return (
    <div>
      <Error501page/>
    </div>
  );
}

export default ErrorBoundary;