import {
  useRefreshSavedArticle,
  useSaved,
  useSetRefreshSavedArticle,
  useSetSaved,
  usedisplaySize,
} from "../../hooks/useMenuContext/useMenuContext";
import Close from "../../Icons/Header_close_icon/Close";
import {
  uselanguageSNData,
  usesaveSNArticles,
} from "../../hooks/UseApiContext/UseApiContext";
import Document from "../../Icons/Document_icon/Document";
import { useNavigate } from "react-router-dom";
import {
  ArticleLinkBox,
  MakeShadowBackground,
  SaveArticleBox,
  SavedArticleBox,
  SavedArticleContent,
  OpenArticle,
} from "./StyledComponent";
import Bookmarks from "../../Icons/Star_icon/Bookmarks";
import { getSNJSON } from "../../api";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import { useEffect, useState } from "react";
import RequestForm from "../../Icons/Request_form_icon/Request_form";

type SavedArticleProps = {
  // show_saved_article = boolean;
};

const SavedArticle: React.FC<SavedArticleProps> = ({}) => {
  const saved = useSaved();
  const setSaved = useSetSaved();
  const saveSNArticles = usesaveSNArticles();
  const navigate = useNavigate();
  const languageSNData = uselanguageSNData();
  const displaySize = usedisplaySize();
  const [fetchAddSaveArticleJSON, performAddSaveArticleJSON] =
    useApiAction(getSNJSON);
  const setRefreshSavedArticle = useSetRefreshSavedArticle();
  const refreshSavedArticle = useRefreshSavedArticle();
  const [savedArticle, setSavedArticle] = useState(
    JSON.parse(localStorage.getItem("savedArticle"))
  );
  useEffect(() => {
    if (
      fetchAddSaveArticleJSON.data &&
      fetchAddSaveArticleJSON.data.result &&
      fetchAddSaveArticleJSON.data.result.code === "200"
    ) {
      setRefreshSavedArticle(!refreshSavedArticle);
    }
  }, [fetchAddSaveArticleJSON.data]);
  function viewArticle(Sys_ID, table, link) {
    if(table === "kb_knowledge"){
      navigate("/article?sys_id=" + Sys_ID);
    }
    else if(table === "sc_cat_item"){
      navigate("/form?form=" + link);
    }
    setSaved(!saved);
  }
  function SaveArticleLogic(id, table, add) {
    setSaved(true);
    let method = "Post";
    let apiURL = add? "urlgetRemoveFavouriteArticle" : "urlAddSaveArticle";
    let payload = { favouriteID: id, favouriteTable: table }
    let Field = [method, apiURL, "", payload]
    performAddSaveArticleJSON(Field)
  }

  return (
    <div>
      <div
        onClick={() => setSaved(false)}
        style={{
          position: "absolute",
          top: "0px",
          background: "rgba(0, 0, 0, 0.5)",
          display: saved ? "flex" : "none",
          justifyContent: "center",
          zIndex: "3",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      ></div>
      <SaveArticleBox>
        <div
          style={{
            height: "68px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid #E6E6E6",
          }}
        >
          <div
            onClick={() => setSaved(false)}
            style={{
              width: "50px",
              height: "68px",
              position: "absolute",
              right: "0",
              top: "20px",
            }}
          >
            <div
              className="back-button-hover"
              style={{
                width: "fit-content",
                margin: "auto",
                cursor: "pointer",
                height: "fit-content",
                borderRadius: "3px",
              }}
            >
              <Close scale={0.8} altText="Close" color="" />
            </div>
          </div>
          <div
            style={{
              fontSize: "28px",
              fontWeight: "400",
              fontFamily: "Roboto slab",
              width: "fit-content",
              height: "fit-content",
            }}
          >
            {languageSNData && languageSNData["savearticle_bookmarks_title"]
              ? languageSNData["savearticle_bookmarks_title"]
              : "Bookmarks"}
          </div>
        </div>
        <div
          style={{
            overflowY: "scroll",
            height: "470px",
          }}
        >
          {savedArticle && savedArticle["result"].length > 0 ? (
            savedArticle["result"].map((e: any, index: any) => {
              return (
                <div
                  key={index}
                  style={{
                    borderBottom: "1px solid #E6E6E6",
                    height: "105px",
                    paddingBottom: "6px",
                    paddingTop: "8px",
                    paddingLeft: "2px",
                  }}
                >
                  <SavedArticleBox className="back-button-hover">
                    <div
                      style={{
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        onClick={() => viewArticle(e["favouriteID"], e["favouriteTable"], e["favLink"])}
                        style={{
                          width: "fit-content",
                          height: "fit-content",
                          marginTop: "-4px",
                        }}
                      >
                        {e["favouriteTable"] === "kb_knowledge"? 
                          <Document
                            scale={0.7}
                            altText="document"
                            viewType="row"
                          />
                          :
                          <RequestForm
                            scale={0.7}
                            altText="RequestForm"
                            viewType="row"
                          />
                        }
                      </div>
                    </div>
                    <div
                      style={{
                        width: "421px",
                        display: "flex",
                      }}
                    >
                      <SavedArticleContent
                        onClick={() => viewArticle(e["favouriteID"], e["favouriteTable"], e["favLink"])}
                      >
                        <div className="article-title-box">
                          {e["favouriteTitle"]}
                        </div>
                        <div style={{ height: "55px" }}>
                          <div
                            className="article-description"
                            dangerouslySetInnerHTML={{
                              __html: e["favouriteContent"],
                            }}
                          ></div>
                        </div>
                      </SavedArticleContent>
                      <ArticleLinkBox
                        style={{
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            height: "fit-content",
                            zIndex: "1",
                          }}
                          onClick={() => {
                            SaveArticleLogic(
                              e["favouriteID"],
                              e["favouriteTable"],
                              saveSNArticles &&
                                saveSNArticles["result"] &&
                                saveSNArticles["result"].filter(
                                  (s) => s["favouriteID"] === e["favouriteID"]
                                ).length
                            );
                          }}
                        >
                          <Bookmarks
                            scale={0.9}
                            altText="Saved Article"
                            save={
                              saveSNArticles &&
                              saveSNArticles["result"] &&
                              saveSNArticles["result"].filter(
                                (s) => s["favouriteID"] === e["favouriteID"]
                              ).length
                            }
                            marginLeft="5px"
                          />
                        </div>
                          <OpenArticle
                            style={{
                              height: "fit-content",
                            }}
                            onClick={() => viewArticle(e["favouriteID"], e["favouriteTable"], e["favLink"])}
                          >
                            {" "}
                            {languageSNData &&
                            languageSNData["savearticle_openlink_text"]
                              ? languageSNData["savearticle_openlink_text"]
                              : ""}
                          </OpenArticle>
                      </ArticleLinkBox>
                    </div>
                  </SavedArticleBox>
                </div>
              );
            })
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: displaySize !== "isMobile" && "center",
                marginTop: displaySize === "isMobile" && "32px",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "436px",
                  height: "fit-content",
                  textAlign: "center",
                  fontSize: "18px",
                  fontFamily: "Inter",
                  color: "#959199",
                  lineHeight: "24px",
                }}
              >
                {languageSNData && languageSNData["bookmark_default_text"]
                  ? languageSNData["bookmark_default_text"]
                  : ""}
              </div>
            </div>
          )}
        </div>
      </SaveArticleBox>
    </div>
  );
};
export default SavedArticle;
