import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as incedent } from './warning.svg';
import styled from "styled-components";
const IncedentIcon = styled(incedent)`
  
`

type IncedentLogoProps = {
  scale?: number;
  altText: string;
};

const Incedent: React.FC<IncedentLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText} style={{ "width": "1rem", marginTop: "-3px"}}>
      <IncedentIcon/>
    </IconWrapper>
  );
};

export default Incedent;