//Library
import React, { useState, useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

//Files & Functions
import {
  Container,
  MobileContainer,
  Header,
  HeaderText,
  IframContainer,
} from "./StyledComponent";
import config from "../../constant/config";
import { usehomeSNData } from "../../hooks/UseApiContext/UseApiContext";
import { useMsal } from "@azure/msal-react";
import Back from "../../Icons/Back_icon/Back";

//Components

//Icons

type ChatProps = {};

const Chat: React.FC<ChatProps> = ({}) => {
  const { accounts } = useMsal();
  const location = useLocation();
  const [language, setLanguage] = useState("en");
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState("Desktop");

  useEffect(() => {
    setLanguage(
      location && location["search"] ? location["search"].split("=")[1] : "en"
    );
  }, [location && location["search"]]);

  useEffect(() => {
    setIsMobile(
      navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
        ? "Mobile"
        : "Desktop"
    );
  }, []);

  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      {isMobile !== "Mobile" ? (
        <>
          {accounts[0] && accounts[0] && accounts[0]["username"] ? (
            <iframe
              src={
                config.api.urlFrameURL +
                accounts[0]["username"] +
                "&sysparm_requester_session_language=" +
                language
              }
              style={{ width: "100%", height: "100%" }}
            ></iframe>
          ) : (
            ""
          )}
        </>
      ) : (
        <MobileContainer>
          <IframContainer>
            {accounts[0] && accounts[0] && accounts[0]["username"] ? (
              <iframe
                src={
                  config.api.urlFrameURL +
                  accounts[0]["username"] +
                  "&sysparm_requester_session_language=" +
                  language
                }
                style={{ width: "100%", height: "100%", background: "#FFFFFF" }}
              ></iframe>
            ) : (
              ""
            )}
          </IframContainer>
        </MobileContainer>
      )}
    </div>
  );
};
export default Chat;
