// @ts-nocheck
import styled from "styled-components";

export const MassageContainerButton = styled.div`
  background-color: #675b98;
  color: #ffffff;
  font-size: 16px;
  border-radius: 3px;
  padding: 7px 15px;
  cursor: pointer;
  :hover {
    background: linear-gradient(90deg, #4a2878 0%, #675b98 100%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #4a2878;
  }
`;

export const Menucontainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 500px;
  height: 252px;
  text-align: center;
`;

export const MassageContainer = styled.div`
  width: 306px;
  height: fit-content;
  position: absolute;
  background: #ffffff;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-1%, -58%);
  ${props => props.isOpen && `
  margin: 0 auto;
  left: 0;
  right: 0;
  transform: translate(-9%, -58%);
  `}
`;

export const SetIcon = styled.div`
  height: 20%;
  width: 30%;
  position: absolute;
  top: 17%;
  right: 17%;
  font-size: 20px;
`;

export const MassageContainerText1 = styled.div`
  text-align: center;
  font-weight: 500;
  height: 40px;
  width: fir-content;
  font-size: 28px;
  color: #0e090c;
`;

export const MassageContainerText2 = styled.div`
  height: fit-content;
  width: 230px;
  font-size: 14px;
  margin-top: 12px;
  margin-left: 38px;
  color: #767675;
`;

export const MassageContainerButton1 = styled.div`
  // background-color: #755b98;
  color: #675b98;
  font-size: 16px;
  border-radius: 3px;
  padding: 7px 15px;
  :hover {
    background: linear-gradient(90deg, #4a2878 0%, #755b98 100%);
    color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #4a2878;
  }
`;

export const HomeButton = styled.div`
  height: 35px;
  width: fit-content;
  margin-top: 30px;
  background-color: #dd296e;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  display: flex;
  :hover {
    background: linear-gradient(90deg, #cf022b 0%, #ea5599 100%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  :active {
    background: #d10726;
  }
`;

export const SetSopraIcon = styled.div`
  height: 40px;
  width: 150px;
  position: absolute;
  bottom: 6px;
  left: 25px;
  font-size: 20px;
`;
export const ProgressBar = styled.div`
  width: 323px;
  height: 108px;
  @media (max-width: 1200px) {
    width: 151px;
    height: 201px;
  }
`;
