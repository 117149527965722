//Library
import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

//Files & Functions
import { getSNJSON } from "../../api/index";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import {
  usehomeSNData,
  uselanguageSNData,
} from "../../hooks/UseApiContext/UseApiContext";
import {
  usedisplaySize,
  useSetSaved,
  useSaved,
  useSetRefreshSavedArticle,
  useRefreshSavedArticle,
} from "../../hooks/useMenuContext/useMenuContext";
import Back from "../../Icons/Back_icon/Back";
import Document from "../../Icons/Document_icon/Document";
import Footer from "../FooterComps";
import {
  ArticleContent,
  ArticleMainContainer,
  OtherAtricleContainer,
  ArticleHeadingContainer,
} from "./StyledComponent";
import Bookmarks from "../../Icons/Star_icon/Bookmarks";
import Star from "../../Icons/StarIcon";
import InputField from "../InputFieldComps";
import SavedArticle from "../SavedArticleComp";

//Components

//Icons

type FullArticleProps = {};

const FullArticle: React.FC<FullArticleProps> = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const displaySize = usedisplaySize();
  const [fullArticleSNData, setFullArticleSNData] = useState("");
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const dataSN = fullArticleSNData && fullArticleSNData["result"]["action"];
  const [fetchFullArticleJSON, performFullArticleJSON] =
    useApiAction(getSNJSON);
  const [fetchAddSaveArticleJSON, performAddSaveArticleJSON] =
    useApiAction(getSNJSON);
  const languageSNData = uselanguageSNData();
  const is_favourite =
    fullArticleSNData &&
    fullArticleSNData["result"] &&
    fullArticleSNData["result"]["is_favourite"];
  const favourite_ID =
    fullArticleSNData &&
    fullArticleSNData["result"] &&
    fullArticleSNData["result"]["sys_id"];
  const [acknowledge, setAcknowledge] = useState(false);
  const setSaved = useSetSaved();
  const saved = useSaved();
  const setRefreshSavedArticle = useSetRefreshSavedArticle();
  const refreshSavedArticle = useRefreshSavedArticle();
  function SavedArticleShow() {
    setSaved(!saved);
  }

  useEffect(() => {
    if (fetchFullArticleJSON.data && fetchFullArticleJSON.data["result"]) {
      setFullArticleSNData(fetchFullArticleJSON.data);
    } else {
      var query = location.search.includes("?sys_id=")
        ? location.search.split("?sys_id=")[1]
        : "";
      var Feild = ["Get", "urlFullArticle", query, {}];
      performFullArticleJSON(Feild);
    }
  }, [fetchFullArticleJSON.data, fullArticleSNData]);

  useEffect(() => {
    var query = location.search.includes("?sys_id=")
      ? location.search.split("?sys_id=")[1]
      : "";
    var Feild = ["Get", "urlFullArticle", query, {}];
    performFullArticleJSON(Feild);
  }, [shouldRefresh, location, fetchAddSaveArticleJSON]);

  const handleClick = (new_sys_id) => {
    navigate("/article?sys_id=" + new_sys_id);
    setShouldRefresh(!shouldRefresh);
  };
  function SaveArticleLogic(id, is_favourite) {
    var method = "Post";
    var apiURL = is_favourite
      ? "urlgetRemoveFavouriteArticle"
      : "urlAddSaveArticle";
    var payload = { favouriteID: id, favouriteTable: "kb_knowledge" };
    var Field = [method, apiURL, "", payload];
    performAddSaveArticleJSON(Field);
    is_favourite === false && setAcknowledge(true);
  }

  useEffect(() => {
    if (
      fetchAddSaveArticleJSON.data &&
      fetchAddSaveArticleJSON.data.result &&
      fetchAddSaveArticleJSON.data.result.code === "200"
    ) {
      setRefreshSavedArticle(!refreshSavedArticle);
      setTimeout(() => {
        setAcknowledge(false);
      }, 1000);
    }
  }, [fetchAddSaveArticleJSON.data]);

  return (
    <div>
      {fullArticleSNData && fullArticleSNData["result"] ? (
        <div>
          {displaySize !== "isMobile" ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="back-button-hover"
                onClick={() => {
                  navigate(-1);
                }}
                style={{
                  border: "none",
                  width: "fit-content",
                  height: "30px",
                  position: "absolute",
                  top: "70px",
                  left: "40px",
                  display: "flex",
                  borderRadius: "3px",
                  background: "none",
                }}
              >
                <Back scale={0.7} altText="Back" type="" />
                <div style={{ marginTop: "1px" }}>
                  {languageSNData ? languageSNData["global_back_gotoback"] : ""}
                </div>
              </button>
              <ArticleMainContainer>
                {acknowledge &&
                fetchAddSaveArticleJSON &&
                fetchAddSaveArticleJSON.data &&
                fetchAddSaveArticleJSON.data.result &&
                fetchAddSaveArticleJSON.data.result.Status ? (
                  <div
                    style={{
                      position: "absolute",
                      width: "176px",
                      height: "100px",
                      top: "40%",
                      left: "calc(50% - 88px)",
                      zIndex: "1",
                      background: "#FFFFFF",
                      display: "flex",
                      padding: "0px, 0px, 32px, 0px",
                      borderRadius: "50px",
                      boxShadow: "0px 0px 10px 0px rgba(7, 40, 75, 0.15)",
                      alignItems: "center",
                      border: "1px solid #E6E6E6",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        width: "",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "30px",
                          height: "fit-content",
                          marginLeft: "-5px",
                        }}
                      >
                        <Bookmarks
                          scale={0.9}
                          altText="Saved Article"
                          save={true}
                          marginLeft="5px"
                        />
                      </div>
                      <div
                        style={{
                          width: "fit-content",
                          height: "fit-content",
                        }}
                      >
                        {languageSNData && languageSNData["header_saved_text"]
                          ? languageSNData["header_saved_text"]
                          : "Saved"}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="article-container">
                  <div style={{ display: "flex", height: "fit-content" }}>
                    <ArticleHeadingContainer
                      className="FontRoboto"
                      style={{
                        textAlign: "left",
                        fontSize: "36px",
                        fontWeight: "500",
                        color: "#0E090C",
                        height: "fit-content",
                        position: "relative",
                      }}
                    >
                      {fullArticleSNData["result"]["short_description"]}
                    </ArticleHeadingContainer>
                    <div
                      onClick={() =>
                        SaveArticleLogic(favourite_ID, is_favourite)
                      }
                      style={{
                        width: "fit-content",
                        height: "fit-content",
                        marginTop: "14px",
                      }}
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={
                        languageSNData &&
                        languageSNData["fullarticle_saveicontooltip_text"]
                          ? languageSNData["fullarticle_saveicontooltip_text"]
                          : "Save"
                      }
                      data-tooltip-offset={-4}
                      data-tooltip-place="bottom"
                    >
                      <Bookmarks
                        scale={1.1}
                        save={is_favourite}
                        marginLeft="10px"
                        altText=""
                      />
                    </div>
                  </div>
                  {Array.isArray(fullArticleSNData["result"]["content"]) ? (
                    <div className="new-article-container">
                      {fullArticleSNData["result"]["content"].map(
                        (item: any, index: any) => {
                          return (
                            <ArticleContent key={index}>
                              <div
                                style={{
                                  fontSize: "22px",
                                  fontWeight: "500",
                                  color: "#4A4A49",
                                  height: "fit-content",
                                }}
                              >
                                {item["label"]}
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item["content"],
                                }}
                                style={{
                                  textAlign: "left",
                                  fontSize: "18px",
                                  fontWeight: "400",
                                  color: "#767675",
                                  height: "fit-content",
                                }}
                              ></div>
                            </ArticleContent>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <div className="articles">
                      <div
                        style={{
                          height: "fit-content",
                          textAlign: "left",
                          padding: "10px 10px 10px 0px",
                          width: "97%",
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            fullArticleSNData["result"] &&
                            fullArticleSNData["result"]["content"],
                        }}
                      ></div>
                    </div>
                  )}
                </div>
                <OtherAtricleContainer>
                  <div style={{ height: "209px" }}>
                    <div
                      style={{
                        width: "94px",
                        height: "17px",
                        fontSize: "14px",
                        color: "#4A2878",
                      }}
                    >
                      {languageSNData &&
                      languageSNData["fullarticle_otherarticle_titletext"]
                        ? languageSNData["fullarticle_otherarticle_titletext"]
                        : "Other articles"}
                    </div>
                    {fullArticleSNData &&
                    fullArticleSNData["result"] &&
                    fullArticleSNData["result"]["relatedArticles"]
                      ? fullArticleSNData["result"]["relatedArticles"].map(
                          (e: any, index: any) => {
                            return (
                              <div
                                className="back-button-hover other-article-options"
                                key={index}
                                onClick={() => handleClick(e["sys_id"])}
                              >
                                <div className="article-icon-container">
                                  <Document
                                    scale={0.8}
                                    altText="Document"
                                    viewType="row"
                                  />
                                </div>
                                <div className="article-title-name">
                                  {e["short_description"]}
                                </div>
                              </div>
                            );
                          }
                        )
                      : ""}
                    {/* <div className="back-button-hover other-article-options">
                        <div className="article-icon-container">
                          <Document scale={0.8} altText="Document" viewType="row" />
                        </div>
                        <div className="article-title-name">
                          Title and everything important
                        </div>
                      </div> */}
                  </div>
                </OtherAtricleContainer>
              </ArticleMainContainer>

              <div
                style={{
                  height: "7%",
                  position: "absolute",
                  left: "20px",
                  bottom: "8px",
                  width: "98.5%",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "734.29px",
                    height: "55px",
                    display: "flex",
                    alignItems: "center",
                    background: "white",
                  }}
                >
                  <Footer dataSN={dataSN} move_footer={"bottom"} />
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                background: "#EBEAF4",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <div>
                {/* <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "50px",
                    marginTop: "40px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    className="back-button-hover"
                    onClick={() => {
                      navigate(-1);
                    }}
                    style={{
                      border: "none",
                      width: "fit-content",
                      height: "30px",
                      display: "flex",
                      background: "#EBEAF4",
                    }}
                  >
                    <Back scale={0.7} altText="Back" type="" />
                  </button>
                  <div
                    style={{
                      width: "48px",
                      borderRadius: "100%",
                      background: "#B6B0CE",
                      height: "48px",
                      textAlign: "center",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => SavedArticleShow()}
                  >
                    {" "}
                    <Star scale={2.4} altText="Minimize" color="white" />
                  </div>
                </div> */}
                <div
                  style={{
                    height: "56px",
                    width: "100%",
                    background: "white",
                    borderRadius: "50px",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "15px",
                  }}
                >
                  <InputField />
                </div>
                <ArticleMainContainer>
                  <div className="article-container">
                    <div style={{ display: "flex", height: "fit-content" }}>
                      <ArticleHeadingContainer
                        style={{
                          textAlign: "left",
                          fontSize: "28px",
                          fontWeight: "400",
                          color: "#0E090C",
                          height: "fit-content",
                          position: "relative",
                          fontFamily: "Roboto Slab",
                        }}
                      >
                        {fullArticleSNData["result"]["short_description"]}
                      </ArticleHeadingContainer>
                      <div
                        onClick={() =>
                          SaveArticleLogic(favourite_ID, is_favourite)
                        }
                        style={{
                          width: "fit-content",
                          height: "fit-content",
                          marginTop: "14px",
                        }}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Save"
                        data-tooltip-offset={-4}
                        data-tooltip-place="bottom"
                      >
                        <Bookmarks
                          scale={1.1}
                          save={is_favourite}
                          marginLeft="10px"
                          altText=""
                        />
                      </div>
                    </div>
                    {Array.isArray(fullArticleSNData["result"]["content"]) ? (
                      <div className="new-article-container">
                        {fullArticleSNData["result"]["content"].map(
                          (item: any, index: any) => {
                            return (
                              <ArticleContent key={index}>
                                <div
                                  style={{
                                    fontSize: "22px",
                                    fontWeight: "500",
                                    color: "#4A4A49",
                                    height: "fit-content",
                                  }}
                                >
                                  {item["label"]}
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item["content"],
                                  }}
                                  style={{
                                    textAlign: "left",
                                    fontSize: "18px",
                                    fontWeight: "400",
                                    color: "#767675",
                                    height: "fit-content",
                                    wordBreak: "break-word",
                                  }}
                                ></div>
                              </ArticleContent>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      <div className="articles">
                        <div
                          style={{
                            height: "fit-content",
                            textAlign: "left",
                            width: "100%",
                            paddingBottom: "10px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html:
                              fullArticleSNData["result"] &&
                              fullArticleSNData["result"]["content"],
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                </ArticleMainContainer>
              </div>
            </div>
          )}
        </div>
      ) : languageSNData ? (
        languageSNData["notification_loading_text"]
      ) : (
        ""
      )}
    </div>
  );
};

export default FullArticle;
