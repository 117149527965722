import styled from "styled-components";

export const PillContainer = styled.nav`
width: fit-content;
height: fit-content;
background: linear-gradient(90deg, #CF022B 0%, #EA5599 100%);
font-size: 10px;
font-weight: 600;
color: #FFFFFF;
padding: 1px 4px 0px 4px;
border-radius: 8px;
margin-left: 2px;
margin-top: 16px;
@media (max-width: 480px) {
    margin-top: 2px;
    padding: 0px 4px 0px 4px;
    font-size: 8px;
    font-weight: 600;
 }
`;
export const PillButtonContainer = styled.nav`
@media (max-width: 480px) {
    height: fit-content;
    width: fit-content;
    z-index: 2;
    position: absolute;
    margin-left: 12px;
    margin-top: -3px;
}
`;
