// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Error501 from "../../Icons/Error501_icon/Error501";
import Error404 from "../../Icons/Error404_icon/Error404";
import Loading from "../../Components/LoadingComps";
import config from "../../constant/config";

import {
  ErrorMainContainer,
  ErrorTitleText,
  HomeButtonContainer,
  IconContainer,
} from "./styled-component";
import {
  uselanguageSNData,
  useerrorSNApi,
} from "../../hooks/UseApiContext/UseApiContext";
import RightSupport from "../../Icons/RightSupportIcon";
import SopraSteriaIcon from "../../Icons/SopraSteriaIcon";
import { usedisplaySize } from "../../hooks/useMenuContext/useMenuContext";
import NoResultFound from "../../Icons/NoResultFound";
import { useMsal } from "@azure/msal-react";

function Error501page() {
  const location = useLocation();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const languageSNData = uselanguageSNData();
  const displaySize = usedisplaySize();
  let status_code = new URLSearchParams(location.search).get("error");

  function refreshPage(status_code) {
    if(status_code && status_code === "400"){
      handleLogout(instance)
    }
    else{
      navigate("/", { replace: true });
      window.location.reload();
    }
  }

  function signOutClickHandler(instance){
    const logoutRequest = {
      account: instance.getAccountByHomeId(config.clientId),
      mainWindowRedirectUri: config.redirectUri,
    };
    instance.logoutPopup(logoutRequest);
  }

  function handleLogout(){
      navigate("/", { replace: true });
      signOutClickHandler(instance)
  }

  function getErrorTexts(code: string) {
    let title, message;
    switch (code) {
      case "500":
        title =
          languageSNData && languageSNData["error501_error500title_text"]
            ? languageSNData["error501_error500title_text"]
            : "Internal Server Error";
        message =
          languageSNData && languageSNData["error501_error500subtitle_text"]
            ? languageSNData["error501_error500subtitle_text"]
            : "It's not you, it's us";
        break;
      case "501":
        title =
          languageSNData && languageSNData["error501_error501title_text"]
            ? languageSNData["error501_error501title_text"]
            : "Not Implemented";
        message =
          languageSNData && languageSNData["error501_error501subtitle_text"]
            ? languageSNData["error501_error501subtitle_text"]
            : "The server is currently unable to handle the request.";
        break;
      case "400":
        title =
          // languageSNData &&
          // languageSNData["pagenotfound_error400badrequesttitle_text"]
          //   ? languageSNData["pagenotfound_error400badrequesttitle_text"]
          //   : "Bad Request";
          languageSNData &&
          languageSNData["pagenotfound_error400sessionouttitle_text"]
            ? languageSNData["pagenotfound_error400sessionouttitle_text"]
            : "Session Timeout";
        message =
          // languageSNData && languageSNData["pagenotfound_error400subtitle_text"]
          //   ? languageSNData["pagenotfound_error400subtitle_text"]
          //   : "The requested resource could not be found on the server.";
          languageSNData && languageSNData["pagenotfound_error400sessionoutsubtitle_text"]
            ? languageSNData["pagenotfound_error400sessionoutsubtitle_text"]
            : "Your session has expired. To continue, please sign in again";
        break;
      case "401":
        title =
          languageSNData &&
          languageSNData["pagenotfound_error401unauthorisedtitle_text"]
            ? languageSNData["pagenotfound_error401unauthorisedtitle_text"]
            : "Unauthorized";
        message =
          languageSNData && languageSNData["pagenotfound_error401subtitle_text"]
            ? languageSNData["pagenotfound_error401subtitle_text"]
            : "I don't think we need anything, we can just show login page if required.";
        break;
      case "404":
        title =
          languageSNData &&
          languageSNData["pagenotfound_error404pagenotfoundtitle_text"]
            ? languageSNData["pagenotfound_error404pagenotfoundtitle_text"]
            : "Page Not found";
        message =
          languageSNData && languageSNData["pagenotfound_error404subtitle_text"]
            ? languageSNData["pagenotfound_error404subtitle_text"]
            : "We can't seem to find the page you are looking for";
        break;
      default:
        title =
          languageSNData &&
          languageSNData["error501_somethingwentwrongtitle_text"]
            ? languageSNData["error501_somethingwentwrongtitle_text"]
            : "Something Went Wrong";
        message =
          languageSNData && languageSNData["error501_error501subtitle_text"]
            ? languageSNData["error501_error501subtitle_text"]
            : "The server is currently unable to handle the request.";
    }

    return { title, message };
  }

  return (
    <ErrorMainContainer>
      {displaySize === "isMobile" && (
        <div
          style={{
            width: "100%",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            alignItems: "center",
          }}
        >
          <RightSupport scale={0.9} altText="Maximize" />
        </div>
      )}
      <div className="content-container">
        <IconContainer>
          {status_code >= "500" ? (
            <Error501 scale={9} altText="Error404" />
          ) : (
            status_code==="400"? <NoResultFound scale={9} altText="SessionTimeout" /> : <Error404 scale={9} altText="Error404" />
          )}
        </IconContainer>
        <ErrorTitleText>
          {languageSNData && languageSNData["error501_error_text"]
            ? languageSNData["error501_error_text"]
            : "Error"}{" "}
          {status_code}:{" "}
          {displaySize !== "isMobile" ? getErrorTexts(status_code).title :(
          <div
            style={{
              height: "fit-content",
              fontfamily: "Roboto Slab",
              fontstyle: "normal",
              fontweight: "400",
              fontsize: "28px",
              textalign: "center",
            }}
          >
            {getErrorTexts(status_code).title}
          </div>
          )}
        </ErrorTitleText>
        {status_code !== "" && (
          <>
            {displaySize !== "isMobile" ? (
              <div className="massage-container">
                {getErrorTexts(status_code).message}
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "fit-content",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "16px",
                }}
              >
                <div
                  style={{
                    height: "fit-content",
                    maxHeight: "110px",
                    width: "211px",
                    alignSelf: "center",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "500px",
                    lineHeight: "19px",
                    color: "#959199",
                    textAlign: "center",
                  }}
                >
                  {getErrorTexts(status_code).message}
                </div>
              </div>
            )}
          </>
        )}
        <HomeButtonContainer>
          <button
            onClick={() => {
              refreshPage(status_code)
            }}
            style={{ border: "none", background: "none" }}
            className="button-title-text"
          >
            {status_code !== "400"?
             languageSNData && languageSNData["error501_returnhome_text"]
              ? languageSNData["error501_returnhome_text"]
              : "Return home"
              :
              languageSNData && languageSNData["error400_relogin_text"]
                ? languageSNData["error400_relogin_text"]
                : "Relogin"
            }
          </button>
        </HomeButtonContainer>
      </div>
      {displaySize === "isMobile" && (
        <div
          style={{
            width: "100%",
            position: "absolute",
            height: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bottom: "30px",
          }}
        >
          <SopraSteriaIcon scale={1} altText="Minimize" />
        </div>
      )}
    </ErrorMainContainer>
  );
}
export default Error501page;
