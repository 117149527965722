// @ts-nocheck
//Library
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import CallForm from "../CallFormComps";
import CallStatus from "../CallStatusComps";
import Chat from "../ChatComps";
import Form from "../FormComps";
import FullArticle from "../FullArticleComps";

//Files & Functions

//Components
import Home from "../HomeComps";
import Inbox from "../InboxComps";
import Logout from "../LogoutComps";
import Search from "../SearchComps";
import TicketForm from "../TicketFormComps";
import TicketStatus from "../TicketStatusComps";
import LoginComponet from "../../pages/login/LoginPage";
import Loading from "../LoadingComps";
import OfflinePage from "../OfflinePageComps/OfflinePage";
import ErrorPage from "../../pages/ApiErrorPage";
import ErrorBoundary from "../../pages/ErrorBoundaryPage/ErrorBoundary";

//Icons

type MainProps = {};

const RouterComp: React.FC<MainProps> = ({}) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route
        path="/home"
        element={
          <ErrorBoundary resetKeys={[true]}>
            <Home />
          </ErrorBoundary>
        }
      />
      <Route
        path="/search"
        element={
          <ErrorBoundary>
            <Search />
          </ErrorBoundary>
        }
      />
      <Route
        path="/article"
        element={
          <ErrorBoundary>
            <FullArticle />
          </ErrorBoundary>
        }
      />
      <Route
        path="/ticketform"
        element={
          <ErrorBoundary>
            <TicketForm />
          </ErrorBoundary>
        }
      />
      <Route
        path="ticketstatus"
        element={
          <ErrorBoundary>
            <TicketStatus />
          </ErrorBoundary>
        }
      />
      <Route
        path="/requeststatus"
        element={
          <ErrorBoundary>
            <TicketStatus />
          </ErrorBoundary>
        }
      />
      <Route
        path="/callform"
        element={
          <ErrorBoundary>
            <CallForm />
          </ErrorBoundary>
        }
      />
      <Route
        path="callstatus"
        element={
          <ErrorBoundary>
            <CallStatus />
          </ErrorBoundary>
        }
      />
      <Route path="/chat" element={<Chat />} />
      <Route
        path="/form"
        element={
          <ErrorBoundary>
            <Form />
          </ErrorBoundary>
        }
      />
      <Route
        path="/inbox"
        element={
          <ErrorBoundary resetKeys={[false]}>
            <Inbox />
          </ErrorBoundary>
        }
      />
      <Route
        path="/login"
        element={
          <ErrorBoundary>
            <Loading />
          </ErrorBoundary>
        }
      />
      <Route
        path="/logout"
        element={
          <ErrorBoundary>
            <Logout />
          </ErrorBoundary>
        }
      />
      <Route path="/healthcheck" element={<LoginComponet />} />
      <Route path="*" element={<OfflinePage />} />
      <Route path="/internalserver" element={<ErrorPage />} />
    </Routes>
  );
};

export default RouterComp;
