import axios from "axios";
import { url } from "inspector";
import config from "../constant/config";

type UrlProps = {
  type: any;
};

function scan(obj) {
  let k;
  if (obj instanceof Object) {
    for (k in obj) {
      if (obj.hasOwnProperty(k)) {
        //recursive call to scan property
        scan(obj[k]);
        if (
          obj[k] !== null &&
          Object.entries(obj).length === 0 &&
          obj.constructor === Object
        ) {
          obj[k] = "";
        }
      }
    }
  } else {
    //not an Object so obj[k] here is a value
  }
}

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    scan(response);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// SN API

export async function getSNJSON(Propes) {
  let Request_Type = Propes[0];
  let URL = Propes[1];
  let Qurey = Propes[2];
  let Payload = Propes[3];

  function getURL(URL) {
    switch (URL) {
      case "urlHome":
        return config.api.urlHome;
      case "urlInbox":
        return config.api.urlInbox + Qurey;
      case "urlCallDetails":
        return config.api.urlCallDetails;
      case "urlLanguage":
        return config.api.urlLanguage + Qurey;
      case "urlSearchData":
        return config.api.urlSearchData + Qurey;
      case "urlSuggestion":
        return config.api.urlSuggestion + Qurey;
      case "urlChangeLanguage":
        return config.api.urlChangeLanguage + Qurey;
      case "urlFullArticle":
        return config.api.urlFullArticle + Qurey;
      case "urlComment":
        return config.api.urlComment;
      case "urlAttachment":
        return config.api.urlAttachment;
      case "urlUpdateVeiwEndUser":
        return config.api.urlUpdateVeiwEndUser;
      case "urlCreateTicket":
        return config.api.urlCreateTicket;
      case "urlUpdateDiviceID":
        return config.api.urlUpdateDiviceID;
      case "urlCallBack":
        return config.api.urlCallBack;
      case "urlGetQueue":
        return config.api.urlGetQueue;
      case "urlcancelCallBackQueue":
        return config.api.urlcancelCallBackQueue;
      case "urlCheckIfUserSessionIsActive":
        return config.api.urlCheckIfUserSessionIsActive + Qurey;
      case "urlReOpenTicket":
        return config.api.urlReOpenTicket;
      case "urlDeleteAttachment":
        return config.api.urlDeleteAttachment;
      case "urlAddSaveArticle":
        return config.api.urlAddSaveArticle;
      case "urlgetRemoveFavouriteArticle":
        return config.api.urlgetRemoveFavouriteArticle;
      case "urlgetFavouriteArticle":
        return config.api.urlgetFavouriteArticle;
      case "urlAppVersion":
        return config.api.urlPostAppVersion;
    }
  }

  function Request(Request_Type) {
    switch (Request_Type) {
      case "Get":
        return axios.get(getURL(URL));
      case "Put":
        return axios.put(getURL(URL), Payload);
      case "Post":
        return axios.post(getURL(URL), Payload);
    }
  }

  return Request(Request_Type);
}
