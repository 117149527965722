import styled from "styled-components";

export const Container = styled.div`
z-index: 2;
height: auto;
maxHeight: 263px;
width: 384px;
position: absolute;
top: 75px;
left: 20px;
display: block;
overflow-y:  hidden;
@media (max-width: 480px) {
  height: fit-content;
  max-height: 230px;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 10%;
  display: block;
  overflow-y: auto;
  }
`;
export const NotificationView = styled.div`
  width: 99.9%;
  min-height: 36px;
  height: auto;
  max-height: 82px;
  display: flex;
  border-radius: 4px;
  margin-bottom: 7px;
  @media (max-width: 480px) {
    width: 99.9%;
    height: 52px;
    display: flex;
    border-radius: 30px;
    margin-bottom: 7px;
    padding: 8px;
  }
`;

export const MessageContainer = styled.div`
  width: 90%;
  height: auto;
  maxheight: 80px;
  padding: 4px;
  @media (max-width: 480px) {
    height: 36px;
    padding: 0px;
    max-height: 36px;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
  }
`;
export const IconContainer = styled.div`
  width: 40px;
  height: auto;
  max-height: 82px;
  margin-top: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    width: 25px;
    margin-right: 5px;
  }
`;
