import styled from "styled-components";

export const Articles = styled.div`
    width: 98.6%;
    height: 120px;
    border-bottom:1px solid #E6E6E6;
    .showButton{
        visibility: hidden;
    }
    :hover{
        background: #f0f0f0 !important;
        color: black !important;
        .showButton{
            visibility: visible;
        }
    }
    
`
export const SearchData = styled.div`
    overflow-y: searchdata["data"] ? "scroll" : "-moz-hidden-unscrollable";
    height: '75%';
    ::-webkit-scrollbar {
        width: 4px;
       
      }
`