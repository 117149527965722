//Library
import React, { useState, useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";

//Files & Functions
import { HeaderContainer, NavButtonMobile } from "./StyledComponent";
import {
  useMobileNavBar,
  useSetMobileNavBar,
} from "../../hooks/useMenuContext/useMenuContext";

//Components
import PinkPillButton from "../PinkPillCounter";

//Icons
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";
import Home from "../../Icons/MobileHome_icon";
import Inbox from "../../Icons/MobileInbox_icon";
import PopUp from "../OmniButtonPopUp";
import Plus from "../../Icons/Plus_icon";
import Minus from "../../Icons/Minus_icon";

type MobileHeaderProps = {};

const MobileHeader: React.FC<MobileHeaderProps> = ({}) => {
  const languageSNData = uselanguageSNData();
  const navigate = useNavigate();
  const mobileNavBar = useMobileNavBar();
  const setMobileNavBar = useSetMobileNavBar();

  const buttonClick = () => {
    setMobileNavBar(!mobileNavBar);
  };
  const location = useLocation();

  return (
    <div>
      {mobileNavBar && <PopUp />}
      <div style={{ height: "100%", width: "100%" }}>
        <HeaderContainer>
          <div
            style={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => {
              navigate("/home");
            }}
          >
            <div
              style={{
                textAlign: "center",
                width: "24px",
                height: "24px",
                marginTop: "4px",
              }}
            >
              <Home
                scale={location.pathname === "/home" ? 0.9 : 0.8}
                altText={location.pathname === "/home" ? "active" : ""}
              />
            </div>
            <div
              style={{
                height: "fit-content",
                textAlign: "center",
                fontSize: "10px",
                fontWeight: "600",
                color: "#767675",
              }}
            >
              {languageSNData ? languageSNData["header_home_gotohome"] : ""}
            </div>
          </div>

          <NavButtonMobile
            style={{
              width: "80px",
              height: "80px",
              position: "absolute",
              bottom: "20px",
              background: "#DD296E",
              borderRadius: "40px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
            onClick={() => {
              buttonClick();
            }}
          >
            {mobileNavBar ? (
              <Minus scale={2.5} altText="Minus" />
            ) : (
              <Plus scale={2.5} altText="plus" />
            )}
          </NavButtonMobile>
          <div
            style={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          ></div>
          <div
            style={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => {
              navigate("/inbox");
            }}
          >
           <div style={{width: 'fit-content',
           display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: "column",
          height: 'fit-content',
          marginTop: '2px',
          }}>
            <div
              style={{
                width: "25px",
                height: "25px",
                alignSelf: "center",
              }}
            >
              <PinkPillButton/>
              <Inbox
                scale={0.72}
                altText={location.pathname === "/inbox" ? "active" : ""}
              />
            </div>
            <div
              style={{
                height: "fit-content",
                width: 'fit-content',
                textAlign: "center",
                fontSize: "10px",
                fontWeight: "600",
                color: "#767675",
              }}
            >
              {languageSNData ? languageSNData["header_inbox_gotoinbox"] : ""}
            </div>
            </div>
          </div>
        </HeaderContainer>
      </div>
    </div>
  );
};

export default MobileHeader;
