import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as checkmark } from "./Checkmark.svg";
import { ReactComponent as checkmarkforSearchpage } from "./Checkmark_copy1.svg";
import styled from "styled-components";

const CheckmarkIcon = styled(checkmark)``;
const CheckmarkforSearchPageIcon = styled(checkmarkforSearchpage)``;
type CheckmarkLogoProps = {
  scale?: number;
  altText: string;
  viewIn: string;
};

const Checkmark: React.FC<CheckmarkLogoProps> = ({ scale = 1, altText, viewIn }) => {
  return (
    <IconWrapper
      width={2 * scale}
      height={2 * scale}
      aria-label={altText}
      style={{ marginLeft: "-1px", marginTop: "-2px" }}
    >
      {viewIn === "inbox"? <CheckmarkIcon /> : <CheckmarkforSearchPageIcon/>}
    </IconWrapper>
  );
};

export default Checkmark;
