import React from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import axios from "axios";
import {interceptorLoginRequest} from "../authentication/authConfig";


const RequestInterceptor = ({ children }) => {
    const {instance, accounts} = useMsal();
    const account = useAccount(accounts[0]);
    
    /* eslint-disable no-param-reassign*/
    axios.interceptors.request.use(async (config) => {
        if(!account) {
            throw Error('No active account! Verify a user has been signed in');
        }

        const response = await instance.acquireTokenSilent({
            ...interceptorLoginRequest(),
            account,
        });

        const bearer = `Bearer ${response.accessToken}`;
        const id = response.idToken;

        config.headers.Authorization = bearer;
        config.headers.idToken = id;
        return config;
    });

    return (
        <>
            {children}
        </>
    )
}

export default RequestInterceptor;