import React from 'react';

import { IconWrapper } from '../styled-components';

// import { ReactComponent as Icon } from './SetCallingIcon.svg';
import { ReactComponent as Icon } from './Call_woman.svg';

type SetCallingIconProps = {
  /**
   * Scale the icon relative to a base size of 2rem
   */
//   scale?: number;
  /**
   * An alternative text that is read by a screen reader for accessibility purposes.
   */
//   altText: string;
  /**
   * If true the icon would have white text instead of black.
   */
};

const SetCallingIcon: React.FC<SetCallingIconProps> = () => {
  return (
    <Icon/>
  );
};

export default SetCallingIcon;