import React from "react";
import styled from "styled-components";

import { IconWrapper } from "../../styled-components";
import { ReactComponent as chat } from "./chat.svg";
import { ReactComponent as ChatBlue } from "./chatblue.svg";
import { ReactComponent as WhiteChat } from "./WhiteChat.svg";
import { ReactComponent as PinkChat } from "./PinkChat.svg";

const ChatIcon = styled(chat)``;

const WhiteChatIcon = styled(WhiteChat)``;

const ChatBlueIcon = styled(ChatBlue)``;

type ChatLogoProps = {
  scale?: number;
  altText: string;
  color: string;
};

const Chat: React.FC<ChatLogoProps> = ({ scale = 1, altText, color }) => {
  return (
    <IconWrapper
      width={2 * scale}
      height={4 * scale}
      aria-label={altText}
      style={{ marginTop: scale === 0.9 ? "-12px" : "-8px" }}
    >
      {color === "white" ? (
        <WhiteChat />
      ) : color === "pink" ? (
        <PinkChat />
      ) : color === "chatblue" ? (
        <ChatBlueIcon />
      ) : (
        <ChatIcon />
      )}
    </IconWrapper>
  );
};

export default Chat;
