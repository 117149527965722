import {useState} from "react";
import constate from "constate";

function useApi() {
    const [homeSNData, setHomeSNData] = useState("");
    const [inboxSNData, setInboxSNData] = useState("");
    const [languageSNData, setLanguageSNData] = useState("");
    const [errorSNApi, setErrorSNApi] = useState({});
    const [saveSNArticles, setSaveSNArticles] = useState("")

    return {
        errorSNApi: errorSNApi,
        setErrorSNApi: setErrorSNApi,
        homeSNData: homeSNData,
        setHomeSNData: setHomeSNData,
        inboxSNData: inboxSNData,
        setInboxSNData: setInboxSNData,
        languageSNData: languageSNData,
        setLanguageSNData: setLanguageSNData,
        saveSNArticles: saveSNArticles,
        setSaveSNArticles: setSaveSNArticles
    };
}

const [
    SNDataProvider,
    useerrorSNApi,
    usesetErrorSNApi,
    usehomeSNData,
    usesetHomeSNData,
    useinboxSNData,
    usesetInboxSNData,
    uselanguageSNData,
    usesetLanguageSNData,
    usesaveSNArticles,
    usesetSaveSNArticles,
    
] = constate(
    useApi,
    value => value.errorSNApi,
    value => value.setErrorSNApi,
    value => value.homeSNData,
    value => value.setHomeSNData,
    value => value.inboxSNData,
    value => value.setInboxSNData,
    value => value.languageSNData,
    value => value.setLanguageSNData,
    value => value.saveSNArticles,
    value => value.setSaveSNArticles,
   
);

export {
    SNDataProvider,
    useerrorSNApi,
    usesetErrorSNApi,
    usehomeSNData,
    usesetHomeSNData,
    useinboxSNData,
    usesetInboxSNData,
    uselanguageSNData,
    usesetLanguageSNData,
    usesaveSNArticles,
    usesetSaveSNArticles,
  
}