//Library
import React, { useEffect, useState, useRef } from "react";

//Files & Functions
import { usehomeSNData } from "../../hooks/UseApiContext/UseApiContext";
import {
  useInputText,
  useSetInputText,
} from "../../hooks/useMenuContext/useMenuContext";
import { } from "./StyledComponent";
import { useNavigate } from "react-router";

//Components

//Icons
import Clock from "../../Icons/Clock_icon";
import Search from "../../Icons/Search-icon";

type RecentSearchProps = {
  suggestion: any;
};

const RecentSearch: React.FC<RecentSearchProps> = ({ suggestion }) => {
  const inputText = useInputText();
  const setInputText = useSetInputText();
  const homeSNData = usehomeSNData();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedIndexSuggestion, setSelectedIndexSuggestion] = useState(-1);

  function Suggestion(key) {
    setInputText(key);
    navigate(
      "/search?query=" + key,
      { replace: true }
    );
  }

  function submitted() {
    if (inputText.length < 3 && selectedIndex !== -1) {
      setInputText(homeSNData["previousSearches"][selectedIndex]);
      navigate(
        "/search?query=" + homeSNData["previousSearches"][selectedIndex],
        { replace: true }
      );
    } else if (selectedIndexSuggestion !== -1 && inputText.length > 3) {
      if (inputText.trim().length !== 0) {
        setInputText(suggestion["result"][selectedIndexSuggestion]);
        navigate(
          "/search?query=" + suggestion["result"][selectedIndexSuggestion],
          { replace: true }
        );
      }
    }
  }

  function useKey(key, cb) {
    const callbackRef = useRef(cb);
    useEffect(() => {
      callbackRef.current = cb;
    });

    useEffect(() => {
      function handle(event) {
        if (event.code === key) {
          callbackRef.current(event);
        }
      }
      document.addEventListener("keypress", handle);
      return () => document.removeEventListener("keypress", handle);
    }, [key]);
  }

  // Calling Enter Key Function
  useKey("Enter", submitted);

  useEffect(() => {
    if (inputText.length < 3) {
      const handleKeyDown = (event) => {
        if (event.key === "ArrowDown" && selectedIndex < 2) {
          setSelectedIndex(
            (selectedIndex + 1) % homeSNData["previousSearches"].length
          );
        } else if (event.key === "ArrowUp" && selectedIndex > 0) {
          setSelectedIndex(
            (selectedIndex - 1 + homeSNData["previousSearches"].length) %
            homeSNData["previousSearches"].length
          );
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    } else {
      const handleKeyDown = (event) => {
        if (event.key === "ArrowDown" && selectedIndexSuggestion < 2) {
          setSelectedIndexSuggestion(
            (selectedIndexSuggestion + 1) % suggestion["result"].length
          );
        } else if (event.key === "ArrowUp" && selectedIndexSuggestion > 0) {
          setSelectedIndexSuggestion(
            (selectedIndexSuggestion - 1 + suggestion["result"].length) %
            suggestion["result"].length
          );
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [
    selectedIndex,
    selectedIndexSuggestion,
    homeSNData["previousSearches"].length,
    suggestion["result"],
  ]);

  return (
    <div style={{ overflowY: "scroll", height: "110px", }}>
      {inputText.length > 3
        ? suggestion &&
        suggestion["result"].map((e, key) => (
          <div style={{
            flexDirection: 'row',
            display: 'flex',
            height: '34px',
            margin: '2px',
            alignItems: 'center'
          }}>
            <div style={{
              width: 'fit-content',
              height: 'fit-content',
              paddingBottom: '5px'
            }}>
              <Search
                scale={0.63}
                altText="Clock"
              />
            </div>
            <div
              key={key}
              className="back-button-hover"
              style={{
                backgroundColor:
                  key === selectedIndexSuggestion ? "#EBEAF4" : "white",
                width: "480px",
                height: "34px",
                padding: "8px",
                fontSize: "14px",
                color: "#767675",
              }}
              onClick={() => Suggestion(e)}
            >
              {e}
            </div>
          </div>
        ))
        : homeSNData && homeSNData["previousSearches"]
          ? homeSNData["previousSearches"].map((e, key) => (
            <div style={{
              flexDirection: 'row',
              display: 'flex',
              height: '34px',
              margin: '2px',
              alignItems: 'center'
            }}>
              <div style={{
                width: 'fit-content',
                height: 'fit-content',
                paddingBottom: '5px'
              }}>
                <Clock
                  scale={0.7}
                  altText="Clock"
                />
              </div>
              <div
                onClick={() => Suggestion(e)}
                className="back-button-hover"
                key={key}
                style={{
                  backgroundColor: key === selectedIndex ? "#EBEAF4 " : "white",
                  width: "480px",
                  height: "34px",
                  padding: "8px",
                  fontSize: "14px",
                  color: "#767675",
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {e}
              </div>
            </div>
          ))
          : ""}
    </div>
  );
};

export default RecentSearch;
