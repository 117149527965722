import React, { useEffect } from 'react';

import { IconWrapper } from '../../styled-components';
import { ReactComponent as ticket } from './ticket.svg';
import { ReactComponent as ticketwhite } from './ticketwhite.svg';
import { ReactComponent as ticketblue } from './ticketblue.svg';
import styled from "styled-components";
const TicketIcon = styled(ticket)`
`
const TicketWhiteIcon = styled(ticketwhite)`
  
`
const TicketblueIcon = styled(ticketblue)`
  
`
type TicketLogoProps = {
  scale?: number;
  altText: string;
  color: string;
};

const Ticket: React.FC<TicketLogoProps> = ({ scale = 1, altText, color }) => {
  return (
    <IconWrapper width={2 * scale} height={5 * scale} aria-label={altText} style={{marginTop:scale===0.9?"-12px": "-9px"}}>
      {color==="white"?
          <TicketWhiteIcon/>
      :
      altText==="ticketblue"?
      <TicketblueIcon/>:
      <TicketIcon/>}
    </IconWrapper>
  );
};

export default Ticket;