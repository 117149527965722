import React from 'react';

import { IconWrapper } from '../styled-components';
import { ReactComponent as exclamationMark } from './Exclamation_mark.svg';
import { ReactComponent as blackExclamationMark } from './BlackExclamationMarkIcon.svg';
import styled from "styled-components";
const ExclamationMarkIcon = styled(exclamationMark)`
  
`
const BlackExclamationMarkIcon = styled(blackExclamationMark)`
  
`


type ExclamationMarkLogoProps = {
  scale?: number;
  altText: string;
  color:string;
};

const ExclamationMark: React.FC<ExclamationMarkLogoProps> = ({ scale = 1, altText, color }) => {
  return (
    <IconWrapper width={1.1* scale} height={1.1 * scale} aria-label={altText} style={{"width": ".3rem"}}>
      {color==="black"?
<BlackExclamationMarkIcon/>:
      <ExclamationMarkIcon/>}
    </IconWrapper>
  );
};

export default ExclamationMark;