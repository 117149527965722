import React from "react";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import RequestInterceptor from "../api/RequestInterceptor";
import App from "../App";
import Login from '../pages/login/login'

const ApplicationRouter = () => {
    return (
        <>
            <AuthenticatedTemplate>
               <RequestInterceptor>
                    <App/>
                </RequestInterceptor>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login/>
            </UnauthenticatedTemplate>
        </>
    );
};

export default ApplicationRouter;