import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as homeicon } from "./Home.svg";
import { ReactComponent as activehomeicon } from "./ActiveHome.svg";
import styled from "styled-components";
const HomeIcon = styled(homeicon)``;
const ActiveHomeIcon = styled(activehomeicon)``;

type HomeIconLogoProps = {
  scale?: number;
  altText: string;
};

const Home: React.FC<HomeIconLogoProps> = ({ scale = 1, altText }) => {
  return (
    <IconWrapper width={2 * scale} height={2 * scale} aria-label={altText}>
      {altText === "active" ? <ActiveHomeIcon /> : <HomeIcon />}
    </IconWrapper>
  );
};

export default Home;
