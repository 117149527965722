import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as minus } from "./Rectangle 3467613minusIcon.svg";
import styled from "styled-components";
const MinusIcon = styled(minus)``;

type MinusLogoProps = {
  scale?: number;
  altText: string;
};

const Minus: React.FC<MinusLogoProps> = ({ scale = 3, altText }) => {
  return (
    
    <IconWrapper
      width={0.6 * scale}
      height={0.6 * scale}
      aria-label={altText}
    >
      <MinusIcon />
    </IconWrapper>
  );
};

export default Minus;
