import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as bookmarks } from "./star_icon.svg";
import { ReactComponent as bookmarksfilled } from "./star_icon_filledred.svg";
import styled from "styled-components";
const BookmarksIcon = styled(bookmarks)`
  &: hover{
    stroke: #DD296E
  }
`;
const BookmarksFilledIcon = styled(bookmarksfilled)``;

type BookmarksLogoProps = {
  scale?: number;
  altText: string;
  save: boolean;
  marginLeft: string;
};

const Bookmarks: React.FC<BookmarksLogoProps> = ({ scale = 1, altText, save, marginLeft }) => {
  return (
    <IconWrapper
      width={2 * scale}
      height={2 * scale}
      aria-label={altText}
      style={{
        width: "1rem",
        marginLeft: marginLeft? marginLeft : "",
      }}
    >
      {save? <BookmarksFilledIcon/> : <BookmarksIcon />}
    </IconWrapper>
  );
};

export default Bookmarks;
