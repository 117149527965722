// @ts-nocheck
//Library
import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//Files & Functions
import {
  usehomeSNData,
  uselanguageSNData,
} from "../../hooks/UseApiContext/UseApiContext";
import {
  useInputText,
  useSetInputText,
  useAnimation,
  useSetAnimation,
  usedisplaySize,
} from "../../hooks/useMenuContext/useMenuContext";
import "./Input.css";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";
import { getSNJSON } from "../../api";

//Components

//Icons
import Search from "../../Icons/Search-icon";
import SearchRed from "../../Icons/SearchRed_icon";
import RecentSearch from "../RecentSearchComps";

type InputFieldProps = {};

const InputField: React.FC<InputFieldProps> = ({}) => {
  const [fetchSuggestionJSON, performFetchSuggestionJSON] =
    useApiAction(getSNJSON);
  const [suggestion, setSuggestion] = useState("");
  const wrapperRef = useRef();
  const displaySize = usedisplaySize();
  const location = useLocation();
  const navigate = useNavigate();
  const inputText = useInputText();
  const setInputText = useSetInputText();
  const homeSNData = usehomeSNData();
  const LanguageSNData = uselanguageSNData();
  const animation = useAnimation();
  const setAnimation = useSetAnimation();
  const inputRef = useRef(null);

  const [searchText, setSearchText] = useState(inputText);
  // const [searchText, setSearchText] = useState(
  //   location.search.includes("?query=")
  //     ? decodeURIComponent(location.search.split("?query=")[1])
  //     :
  //     inputText
  // );

  // useEffect(() => {
  //   setSearchText(
  //     location.search.includes("?query=")
  //       ? location.search.split("?query=")[1].replaceAll("%20", " ")
  //       : inputText
  //   );
  // }, [location]);

  useEffect(() => {
    if (inputText.length > 3) {
      if (fetchSuggestionJSON.data && fetchSuggestionJSON.data["result"]) {
        setSuggestion(fetchSuggestionJSON.data);
      }
    }
  }, [fetchSuggestionJSON.data, suggestion, inputText]);

  useEffect(() => {
    var Feild = ["Get", "urlSuggestion", inputText, {}];
    let recallSuggestion;
    if (inputText.length > 3) {
      if (recallSuggestion !== inputText) {
        performFetchSuggestionJSON(Feild);
      }
    }
    recallSuggestion = inputText;
  }, [inputText]);

  const inputTextHandler = (e) => {
    setSearchText(e.target.value.trimStart());
    setInputText(e.target.value.trimStart());
    localStorage.setItem(
      "searchItem",
      inputText || searchText ? inputText || searchText : ""
    );
  };

  useEffect(() => {
    setSearchText(inputText);
  }, [inputText]);

  useEffect(() => {
    // setSearchText(
    //   // inputText === "" && location.search.includes("?query=")
    //   //   ? decodeURIComponent(location.search.split("?query=")[1])
    //   //   :
    //     inputText
    // );

    if (performance.getEntriesByType("navigation")[0].type === "reload") {
      setSearchText(
        inputText === "" && location.search.includes("?query=")
          ? decodeURIComponent(location.search.split("?query=")[1])
          : inputText
      );
      localStorage.setItem(
        "searchItem",
        inputText || searchText ? inputText || searchText : ""
      );
      setInputText(
        inputText === "" && location.search.includes("?query=")
          ? decodeURIComponent(location.search.split("?query=")[1])
          : inputText
      );
    } else {
      setSearchText(inputText);
    }
  }, [location.search]);

  useEffect(() => {
    if (
      inputRef.current &&
      animation === false &&
      location.pathname === "/home"
    ) {
      inputRef.current.focus();
    }
  }, [animation, isInputFocused]);

  function isInputFocused() {
    return document.activeElement === inputRef.current;
  }

  function Submit() {
    if (searchText !== "" && inputText.trim().length !== 0) {
      inputRef.current.blur();
      navigate("/search?query=" + searchText, { replace: true });
    }
  }

  function useKey(key, cb) {
    const callbackRef = useRef(cb);
    useEffect(() => {
      callbackRef.current = cb;
    });

    useEffect(() => {
      function handle(event) {
        if (event.code === key) {
          callbackRef.current(event);
        }
      }
      document.addEventListener("keypress", handle);
      return () => document.removeEventListener("keypress", handle);
    }, [key]);
  }

  // Calling Enter Key Function
  useKey("Enter", Submit);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        animation === false &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        setAnimation(true);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [animation]);

  return (
    <div ref={wrapperRef}>
      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <div style={{ display: "flex", height: "fit-content" }}> */}
        <div
          className={displaySize === "isMobile" ? "" : "InputHover"}
          style={{
            height: displaySize === "isMobile" ? "40px" : "44px",
            borderLeft:
              displaySize === "isMobile"
                ? ""
                : searchText === "" && animation
                ? "1.5px solid #49484D"
                : "1.5px solid #DD296E",
            borderTop:
              displaySize === "isMobile"
                ? ""
                : searchText === "" && animation
                ? "1.5px solid #49484D"
                : "1.5px solid #DD296E",
            borderBottom:
              displaySize === "isMobile"
                ? ""
                : searchText === "" && animation
                ? "1.5px solid #49484D"
                : "1.5px solid #DD296E",
            borderRadius: "3px 0px 0px 3px",
            background: "#FFFFFF",
            display: "flex",
            zIndex: "1",
            width: displaySize === "isMobile" ? "100%" : "calc(100% - 86px)",
          }}
        >
          {displaySize === "isMobile" ? (
            <div style={{ width: "10px" }}></div>
          ) : (
            <button
              style={{
                width: "38px",
                height: "35px",
                marginTop: "3px",
                background: "#FFFFFF",
                border: "none",
                marginRight: displaySize === "isMobile" && "5px",
              }}
              onClick={Submit}
            >
              {displaySize !== "isMobile" ? (
                <Search scale={0.8} altText="Send" />
              ) : (
                <SearchRed scale={0.8} altText="Send" />
              )}
            </button>
          )}
          {animation && location.pathname === "/home" && searchText === "" ? (
            <div
              onClick={() => setAnimation(false)}
              style={{
                width: "calc(100% - 38px)",
                display: "flex",
                padding: "6px 0px 0px 0px",
              }}
            >
              {/* <div
                style={{
                  width: "fit-content",
                  height: "32px",
                  fontSize: "20px",
                }}
              >
                {LanguageSNData ? LanguageSNData["home_try_text"] : ""}
              </div>
              <div style={{ width: "6px" }}> </div> */}
              <div
                style={{
                  width: "fit-content",
                  height: "100px",
                  fontSize: "20px",
                  display: "flex",
                }}
              >
                {" "}
                {displaySize !== "isMobile" && LanguageSNData
                  ? LanguageSNData["home_try_text"] + ""
                  : ""}
                {homeSNData &&
                homeSNData["HomePageDetails"] &&
                homeSNData["HomePageDetails"]["HoverText"] ? (
                  <div className="Input_animation">
                    {homeSNData["HomePageDetails"]["HoverText"]
                      .split(",")
                      .map((e, k) => (
                        <div key={k} style={{ height: "30px" }}>
                          <p
                            style={{
                              textAlign: "left",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            &nbsp;"{e}"
                          </p>
                        </div>
                      ))}
                  </div>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          ) : (
            <input
              ref={inputRef}
              style={{
                width: "calc(100% - 38px)",
                height: "39px",
                outline: "none",
                fontSize: "20px",
                color: "#4A4A49",
                border: "none",
                marginTop: "2px",
                background: "#FFFFFF",
              }}
              value={searchText}
              onClick={() => setAnimation(false)}
              onChange={inputTextHandler}
            />
          )}
        </div>
        {displaySize === "isMobile" ? (
          <button
            className="hover-search"
            style={{
              width: "fit-content",
              zIndex: "0",
              border: "none",
              height: "100%",
              color: "white",
              padding: "0px 17px",
              borderRadius: "100%",
            }}
            onClick={Submit}
          >
            <Search scale={0.8} altText="whiteSearch" />
          </button>
        ) : (
          <button
            className="hover-search"
            style={{
              height: displaySize === "isMobile" ? "40px" : "44px",
              width: "86px",
              marginTop: "0px",
              zIndex: "0",
              border: "none",
              color: "white",
              borderRadius: "0px 5px 5px 0px",
            }}
            onClick={Submit}
          >
            {LanguageSNData ? LanguageSNData["homepage_searchbutton_text"] : ""}
          </button>
        )}
      </div>
      {animation ||
      (homeSNData && homeSNData["previousSearches"].length === 0) ||
      (suggestion && suggestion["result"].length === 0) ? (
        ""
      ) : (
        <div
          className="suggestion-box"
          style={{
            height: "fit-content",
            maxHeight: "125px",
            border: displaySize === "isMobile" ? "" : "1px solid #D9D9D9",
            boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.05)",
            padding: "8px",
            borderRadius: "3px",
            background: "white",
            position: location.pathname === "/home" ? "static" : "absolute",
            display:
              displaySize === "isMobile"
                ? "none"
                : location.pathname === "/home" ||
                  (isInputFocused() &&
                    (inputText || inputText === "") &&
                    animation === false)
                ? "block"
                : "none",
            zIndex: "1",
          }}
        >
          <RecentSearch suggestion={suggestion} />
        </div>
      )}
    </div>
  );
};

export default InputField;
