import React from "react";

import { IconWrapper } from "../styled-components";
import { ReactComponent as funImage } from "./Asset6.svg";

import styled from "styled-components";
const FunImageIcon = styled(funImage)``;

type SubmitTicketLogoProps = {
  scale?: number;
  altText: string;
};

const SubmitTicket: React.FC<SubmitTicketLogoProps> = ({
  scale = 1,
  altText,
}) => {
  // var noDataImage = require('./nodataicon.png');
  // var noDataImage = require('./noDataShow.png');
  return (
    <IconWrapper aria-label={altText} style={{ width: "100%", height: "100%" }}>
      {/* <img src={noDataImage}></img> */}
      <FunImageIcon />
    </IconWrapper>
  );
};

export default SubmitTicket;
