import styled from "styled-components";

export const HeaderContainer = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  .header-drag {
    -webkit-app-region: drag;
  }
`;

export const Nav = styled.div`
  padding: 6% 3%;
  @media (min-height: 720px) {
    padding: 5% 3%;
  }
  @media (min-height: 920px) {
    padding: 4% 3%;
  }
`;

export const ChatContentCenter = styled.div`
  width: 76%;
  height: 43px;
`;

export const ChatContentLeft = styled.div`
  width: 9%;
  height: 100%;
  padding: 5px 10px;
`;

export const ContentLeft = styled.div`
  min-width: 208px;
  width: auto;
  height: 100%;
  display: flex;
`;

type ContentCenterDivProps = {
  width: any;
};

export const ContentCenter = styled.div<ContentCenterDivProps>`
  width: ${(props: ContentCenterDivProps) => props.width};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ContentRight = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Content = styled.div`
  width: 40px;
  height: 100%;
`;

export const SaveArticleButton = styled.div`
  border-radius: 100px;
  border: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 28px;
  width: 74px;
  cursor: pointer;

  :hover {
    .hover-saved-button {
      color: #dd296e;
      font-weight: 600;
    }
    .svg-icon-hover {
      stroke: #dd296e;
      stroke-width: 1px;
    }
  }
`;
