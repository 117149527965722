//Library
import React, { useState, useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

//Files & Functions
import {
  SidebarContainer,
  ChatContainer,
  DetailContainer,
  MenuContainer,
} from "./StyledComponent";

//Components

//Icons
import InboxHeader from "../InboxHeaderComps";
import InboxMenu from "../InboxMenu";
import InboxSidebar from "../InboxSidebarComps";
import {
  useinboxSNData,
  uselanguageSNData,
  usesetInboxSNData,
} from "../../hooks/UseApiContext/UseApiContext";
import InboxChat from "../InboxChatComps";
import FunImage from "../../Icons/InboxNoData";
import {
  useInboxRecall,
  useSetInboxRecall,
  useTicketData,
  usedisplaySize,
} from "../../hooks/useMenuContext/useMenuContext";
import Back from "../../Icons/Back_icon/Back";
import Attachment from "../../Icons/Attachment_icon";
import Send from "../../Icons/Send_icon";
import { ProgressBar } from "../InboxReopneComps/StyledComponent";
import TicketProgressBar from "../TicketProgressBarComps";
import Ticketprogress from "../TicketProgressComps";
import Reopen from "../InboxReopneComps";
import { getSNJSON } from "../../api";
import { useApiAction } from "../../hooks/use-api-action/use-api-action";

type InboxProps = {};

const Inbox: React.FC<InboxProps> = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const ticketData = useTicketData();
  const displaySize = usedisplaySize();
  const inboxSNData = useinboxSNData();
  const setInboxSNData = usesetInboxSNData();
  const languageSNData = uselanguageSNData();
  const inboxRecall = useInboxRecall();
  const setInboxRecall = useSetInboxRecall();
  const [sidebar, setSidebar] = useState(false);
  const [activeTicket, setActiveTicket] = useState("");
  const [ticket, setTicket] = useState("");
  const [switchInfoComment, setSwitchInfoComment] = useState("Comment");

  const MobileComment = (event) => {
    setSwitchInfoComment(event);
  };
  useEffect(() => {
    setActiveTicket(
      location.search.includes("?Number=")
        ? location.search.split("?Number=")[1]
        : ""
    );
  }, [location]);

  useEffect(() => {
    if ("Tickets" in ticketData) {
      setTicket(
        ticketData && ticketData["Tickets"] && ticketData["Tickets"][0]
      );
      let dummyInboxSNData = inboxSNData;
      const item =
        dummyInboxSNData &&
        dummyInboxSNData["Tickets"].filter((setticket) => {
          return (
            setticket["Number"] === activeTicket ||
            setticket["Number"] === ticketData["Tickets"][0]["Number"]
          );
        });

      if (item.length === 0) {
        dummyInboxSNData &&
          dummyInboxSNData["Tickets"] &&
          dummyInboxSNData["Tickets"].unshift(
            ticketData && ticketData["Tickets"] && ticketData["Tickets"][0]
          );
        setInboxSNData(dummyInboxSNData);
      }
    } else {
      const item =
        inboxSNData &&
        inboxSNData["Tickets"].filter((setticket) => {
          return setticket["Number"] === activeTicket;
        });
      setTicket(item[0]);
    }
  }, [ticketData]);

  useEffect(() => {
    const item =
      inboxSNData &&
      inboxSNData["Tickets"].filter((setticket) => {
        return setticket["Number"] === activeTicket;
      });
    setTicket(item[0]);
  }, [activeTicket, inboxSNData]);

  return (
    <div>
      {displaySize === "isMobile" ? (
        <div
          style={{
            position:
              activeTicket === "" || switchInfoComment === "Info"
                ? "unset"
                : "absolute",
            width:
              activeTicket === "" || switchInfoComment === "Info" ? "" : "100%",
            height:
              activeTicket === "" || switchInfoComment === "Info" ? "" : "100%",
            zIndex:
              activeTicket === "" || switchInfoComment === "Info" ? "" : "1",
            background:
              activeTicket === "" || switchInfoComment === "Info"
                ? ""
                : "white",
          }}
        >
          {/* <div
            style={{
              height: "68px",
              fontSize: "28px",
              borderBottom: "1px solid #E6E6E6",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
             <div
              style={{
                background: "white",
                width: "fit-content",
                height: "fit-content",
                marginLeft: "10px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                paddingBottom: "6px",
                marginRight: "10px",
              }}
              onClick={() => {
                navigate(activeTicket !== "" ? "/inbox" : "/");
              }}
            >
              <Back scale={1} altText="Back" type="mobile" />
            </div>
             <div
              className="FontRoboto"
              style={{
                height: displaySize !== "isMobile" ? "fit-content" : "40px",
                marginLeft: "4px",
                marginTop: displaySize !== "isMobile" ? "8px" : "0px",
                color: "#000000",
                fontWeight: "400",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {activeTicket !== ""
                ? ticket && ticket["Short description"]
                : languageSNData
                ? languageSNData["header_inbox_gotoinbox"]
                : ""}
            </div>
          </div> */}
          <div style={{ height: "calc(100% - 68px)" }}>
            {activeTicket !== "" ? (
              <div>
                <div style={{ height: "48px", display: "flex" }}>
                  <div
                    onClick={() => MobileComment("Info")}
                    style={{
                      width: "50%",
                      textAlign: "center",
                      paddingTop: "10px",
                      borderBottom:
                        switchInfoComment === "Info"
                          ? "2px solid #D10726"
                          : "1px solid #E6E6E6",
                    }}
                  >
                    {languageSNData &&
                    languageSNData["mobile_inbox_info_header"]
                      ? languageSNData["mobile_inbox_info_header"]
                      : ""}
                  </div>
                  <div
                    onClick={() => MobileComment("Comment")}
                    style={{
                      width: "50%",
                      textAlign: "center",
                      paddingTop: "10px",
                      borderBottom:
                        switchInfoComment === "Comment"
                          ? "2px solid #D10726"
                          : "1px solid #E6E6E6",
                    }}
                  >
                    {languageSNData &&
                    languageSNData["mobile_inbox_comment_header"]
                      ? languageSNData["mobile_inbox_comment_header"]
                      : ""}
                  </div>
                </div>
                <div style={{ height: "calc(100% - 48px)" }}>
                  {switchInfoComment === "Info" ? (
                    <div style={{ overflowY: "auto" }}>
                      <div
                        style={{
                          height: "fit-content",
                          maxHeight: "96px",
                          padding: "16px",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              height: "fit-content",
                              maxHeight: "42px",
                              lineHeight: "20.8px",
                              fontWeight: "600",
                              fontSize: "16px",
                              color: "#0E090C",
                            }}
                          >
                            {ticket && ticket["Short description"]}
                          </div>
                          <div
                            style={{
                              height: "22px",
                              fontSize: "14px",
                              lineHeight: "14px",
                              color: "#4A4A49",
                              marginTop: "4px",
                            }}
                          >
                            {ticket && ticket["State"]}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          height: "fit-content",
                          marginBottom: "32px",
                          marginTop: "32px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {ticket && ticket["isResolved"] ? (
                            <Reopen ticket={ticket} sidebar={"true"} />
                          ) : ticket && ticket["CommentsSupported"] ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ProgressBar>
                                <TicketProgressBar
                                  ticket={ticket}
                                  sidebar={"true"}
                                />
                              </ProgressBar>
                            </div>
                          ) : (
                            <Ticketprogress ticket={ticket} sidebar={"true"} />
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          height: "17px",
                          marginTop: "8px",
                          marginLeft: "16px",
                          marginRight: "16px",
                          marginBottom: "4px",
                          fontSize: "14px",
                          lineHeight: "16.94px",
                          fontWeight: "600",
                          color: "#0E090C",
                        }}
                      >
                        {languageSNData["inbox_description_text"]
                          ? languageSNData["inbox_description_text"]
                          : ""}
                      </div>
                      <div
                        style={{
                          height: "fit-content",
                          marginLeft: "16px",
                          marginRight: "16px",
                          width: "calc(100% - 32px)",
                        }}
                      >
                        {ticket && ticket["Description"]}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          height: "fit-content",
                        }}
                      >
                        <DetailContainer
                          style={{
                            width: sidebar ? "" : "100%",
                          }}
                        >
                          <InboxHeader
                            setSidebar={setSidebar}
                            sidebar={sidebar}
                            ticket={ticket}
                          />
                        </DetailContainer>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div style={{ height: "100%" }}>
                        <InboxMenu
                          ticket={ticket}
                          setSidebar={setSidebar}
                          sidebar={sidebar}
                        />
                        -
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <InboxSidebar />
            )}
          </div>
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          <SidebarContainer>
            <InboxSidebar />
          </SidebarContainer>
          <ChatContainer>
            <div style={{ display: "flex", borderLeft: "8px solid #EBEAF4" }}>
              {activeTicket === "" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FunImage scale={18} altText={"FunImage"} />
                </div>
              ) : (
                <div style={{ width: "100%", display: "flex" }}>
                  <DetailContainer
                    style={{
                      width: sidebar ? "" : "100%",
                    }}
                  >
                    <InboxHeader
                      setSidebar={setSidebar}
                      sidebar={sidebar}
                      ticket={ticket}
                    />
                  </DetailContainer>
                  <MenuContainer
                    style={{
                      display: sidebar ? "" : "none",
                      borderLeft: sidebar ? "8px solid #EBEAF4" : "",
                    }}
                  >
                    <InboxMenu
                      ticket={ticket}
                      setSidebar={setSidebar}
                      sidebar={sidebar}
                    />{" "}
                  </MenuContainer>
                </div>
              )}
            </div>
          </ChatContainer>
        </div>
      )}
    </div>
  );
};

export default Inbox;
