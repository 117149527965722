import styled from "styled-components";

export const MainPageHeader = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
`;

export const MainPageContainer = styled.div`
  width: 100%;
  border: 8px solid #ebeaf4;
  height: calc(100% - 48px);
`;

export const MobileMainPageContainer = styled.div`
  width: 100%;
`;
