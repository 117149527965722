//Library
import React from "react";
import { useLocation } from "react-router-dom";

//Files & Functions
import { BubbleButton } from "./StyledComponent";
import useChatLogin from "../../utils/utilsFunction";
import { uselanguageSNData } from "../../hooks/UseApiContext/UseApiContext";

//Components

//Icons
import Chat from "../../Icons/Footer-icons/chat-icon";
import SopraSteriaIcon from "../../Icons/SopraSteriaIcon";

type IconFooterProps = {
  showBubbleIcon: boolean;
};

const IconFooter: React.FC<IconFooterProps> = ({ showBubbleIcon }) => {
  const location = useLocation();
  const { chatLogin, sNLogin } = useChatLogin();
  const languageSNData = uselanguageSNData();

  return (
    <>
      {location.pathname !== "/chat" &&
      location.pathname !== "/login" &&
      location.pathname !== "/inbox" &&
      location.pathname !== "/healthcheck" ? (
        <div
          style={{
            width: "98.5%",
            position: "absolute",
            bottom: "15px",
            height: "60px",
            left: "8px",
            right: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "200px",
              height: "70%",
              padding: "15px 0px 0px 18px",
            }}
          >
            <SopraSteriaIcon scale={1} altText="SopraSteria" />
          </div>
          <div
            style={{
              width: "65px",
              height: "100%",
              paddingRight: "5px",
              zIndex: "1",
            }}
          >
            {showBubbleIcon ? (
              <BubbleButton
                onClick={() => chatLogin("")}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={
                  languageSNData &&
                  languageSNData["home_bubblechattooltip_text "]
                    ? languageSNData["home_bubblechattooltip_text "]
                    : "Chat"
                }
                data-tooltip-offset={4}
                data-tooltip-place="left"
              >
                <Chat scale={0.8} altText="Chat" color="white" />
              </BubbleButton>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default IconFooter;
