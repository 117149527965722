import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { LoginPageHeader, LoginPageContainer } from "./StyledComponent";
import {
  useAppScreenSize,
  useSetAppScreenSize,
} from "../../hooks/useMenuContext/useMenuContext";

import Header from "../../Components/HeaderComps";

import "../../App.css";
import LoginComponet from "./LoginPage";
import SopraSteriaIcon from "../../Icons/SopraSteriaIcon";
import Close from "../../Icons/Header_close_icon/Close";
import Maximize from "../../Icons/Maximize_icon/Maximize";
import Minimize from "../../Icons/Minimize_icon/Minimize";
import RightSupport from "../../Icons/RightSupportIcon";
import OfflinePage from "../../Components/OfflinePageComps/OfflinePage";

const Login = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const appScreenSize = useAppScreenSize();
  const setAppScreenSize = useSetAppScreenSize();

  // function SNChatHide() {
  //   // window.electron.notificationApi.chileClose("chileClose");
  // }
  // useEffect(() => {
  //   // SNChatHide();
  // }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (window.screen.width === width) {
      setAppScreenSize("");
    } else {
      setAppScreenSize("Minimize");
    }
  }, [width]);
  function maximizeIcon() {
    // window.electron.notificationApi.sendNotification("test");
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "white",
        borderRadius: "4px",
      }}
    >
      <Router>
        <LoginPageHeader>
          <div style={{ display: "flex" }}>
            <div
              style={{
                // width: "fit-content",
                display: "flex",
                width: "112px",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="header-drag"
            ></div>
            <div
              className="header-drag"
              style={{
                // width: "fit-content",
                display: "flex",
                width: "calc(100% - 224px)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ width: "120px", height: "27px" }}>
                <RightSupport scale={0.9} altText="Maximize" />
              </div>
            </div>
            {/* <div
              style={{
                // width: "fit-content",
                display: "flex",
                width: "112px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  width: "111px",
                  height: "33px",
                  display: "flex",
                }}
              >
                <div
                  onClick={() => {
                    // window.electron.notificationApi.minimize("minimize");
                  }}
                  className="back-button-hover"
                  style={{
                    width: "26px",
                    height: "26px",
                    zIndex: "99",
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                    borderRadius: "4px",
                  }}
                >
                  <Minimize scale={0.4} altText="Minimize" color={"black"} />
                </div>
                <div
                  onClick={() => {
                    maximizeIcon();
                  }}
                  className="back-button-hover"
                  style={{
                    width: "26px",
                    height: "26px",
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                    borderRadius: "4px",
                  }}
                >
                  <Maximize
                    scale={0.3}
                    altText={appScreenSize === "" ? "Minimize" : "Maximize"}
                  />
                </div>
                <div
                  onClick={() => {
                    // window.electron.notificationApi.Close("Close");
                  }}
                  className="back-button-hover"
                  style={{
                    borderRadius: "4px",
                    width: "26px",
                    height: "26px",
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <Close scale={0.6} altText="Close" color={"black"} />
                </div>
              </div>
            </div> */}
          </div>
        </LoginPageHeader>
        <LoginPageContainer>
          <Routes>
            <Route path="/healthcheck" element={<LoginComponet />} />
            <Route
              path="*"
              element={!navigator.onLine ? <OfflinePage /> : <LoginComponet />}
            />
          </Routes>
        </LoginPageContainer>
        <div
          style={{
            height: "40px",
            width: "100%",
            position: "absolute",
            bottom: "0px",
            fontSize: "20px",
            color: "#0E090C",
            fontFamily: "serif",
          }}
        >
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <SopraSteriaIcon scale={1} altText="Minimize" />
          </div>
        </div>
      </Router>
    </div>
  );
};

export default Login;
